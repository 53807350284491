import React from "react";
import { Route, Switch } from "react-router-dom";

import VehiclesPage from "../components/VehiclesPage";
import VehiclePage from "../components/VehiclePage";

import DefaultLayout from "../components/DefaultLayout";

const VehiclesRoute = ({ incidentsEnabled }) => (
  <DefaultLayout>
    <Switch>
      <Route exact path="/vehicles">
        <VehiclesPage />
      </Route>
      <Route path="/vehicles/:id">
        <VehiclePage incidentsEnabled={incidentsEnabled} />
      </Route>
    </Switch>
  </DefaultLayout>
);

export default VehiclesRoute;
