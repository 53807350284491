import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import { useParams } from "react-router-dom";
import Vehicle from "../containers/Vehicle";

const styles = () => ({
  flexBox: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
});

const VehiclePage = ({ classes, incidentsEnabled }) => {
  let { id: vehicleId } = useParams();
  return (
    <section className={classes.flexBox}>
      <Vehicle id={vehicleId} incidentsEnabled={incidentsEnabled} />
    </section>
  );
};

VehiclePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VehiclePage);
