import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import moment from "moment";
import ShowMarkerIcon from "@mui/icons-material/LocationOn";
import IconButton from "@mui/material/IconButton";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";

const styles = (theme) => ({
  root: {
    paddingBottom: theme.spacing(1),
    maxHeight: "40vh",
    overflow: "auto",
  },
});

const SafetyEventsTable = ({ safetyEvents, onNodeSelected, classes }) => {
  return (
    <div className={classes.root}>
      {safetyEvents.totalCount > 0 && (
        <List dense>
          {safetyEvents.edges.map(({ cursor, node }) => (
            <ListItemButton key={cursor} onClick={() => onNodeSelected(node)}>
              <ListItemText
                primary={node.title}
                secondary={
                  <span>
                    {moment(node.occurredAt).format("L")} at{" "}
                    {moment(node.occurredAt).format("LT")} - <b>{node.speed}</b>
                    <small>
                      <abbr>MPH</abbr>
                    </small>
                    {node.speedLimit && (
                      <span>
                        {" "}
                        ({node.speedLimit}
                        <small>
                          <abbr>MPH</abbr>
                        </small>{" "}
                        limit)
                      </span>
                    )}
                  </span>
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => onNodeSelected(node)}
                >
                  <ShowMarkerIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItemButton>
          ))}
        </List>
      )}
      {safetyEvents.totalCount === 0 && <p>No events recorded</p>}
    </div>
  );
};

SafetyEventsTable.propTypes = {
  safetyEvents: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onNodeSelected: PropTypes.func.isRequired,
};

export default withStyles(styles)(SafetyEventsTable);
