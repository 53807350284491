import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import UpdateIncident from "../containers/UpdateIncident";
import IncidentEvidence from "../components/IncidentEvidence";
import IncidentEvidenceForm from "../components/IncidentEvidenceForm";
import RequestVehicleFootageDialog from "../containers/RequestVehicleFootageDialog";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import RequestMoreIcon from "@mui/icons-material/AddToPhotos";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Snackbar from "@mui/material/Snackbar";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { DISMISSED } from "../EvidenceStatus";
import makeStyles from "@mui/styles/makeStyles";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import INCIDENT_FRAGMENT from "../fragments/incident";

const VEHICLE_CAMERA_QUERY = gql`
  query Node($id: ID!) {
    node(id: $id) {
      ... on Vehicle {
        device {
          cameras {
            id
            location
          }
        }
      }
    }
  }
`;

const UPDATE_INCIDENT_EVIDENCE = gql`
  mutation updateIncident($input: UpdateIncidentInput!) {
    incidents {
      update(input: $input) {
        incident {
          ...incident
        }
      }
    }
  }

  ${INCIDENT_FRAGMENT}
`;

const useStyles = makeStyles((theme) => ({
  actions: {
    display: "flex",
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonBox: {
    marginLeft: "auto",
  },
}));

const IncidentEvidenceCard = ({ incident, className, editable }) => {
  const classes = useStyles();

  const getInitialFormValuesFrom = (incident) => {
    return {
      evidence: {
        evidence: incident.attachedEvidence.map(
          ({ key, status, description }) => ({
            key,
            status,
            description,
            markForDeletion: false,
          })
        ),
      },
    };
  };

  const [initialFormValues, setInitialFormValues] = React.useState({
    ...{
      attachEvidence: {
        evidence: [],
      },
    },
    ...getInitialFormValuesFrom(incident),
  });

  const [dirtied, setDirtied] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [moreFootageOpen, setMoreFootageOpen] = React.useState(false);
  const [moreFootageSnackOpen, setMoreFootageSnackOpen] = React.useState(false);
  const [showDismissed, setShowDismissed] = React.useState(false);
  const [disableSave, setDisableSave] = React.useState(true);

  React.useEffect(() => {
    const resetInitialise = dirtied && !editing;

    if (!resetInitialise) return;

    setDirtied(false);
    setInitialFormValues((prev) => ({
      ...prev,
      ...getInitialFormValuesFrom(incident),
    }));
  }, [dirtied, editing]);

  const [updateEvidenceMutation] = useMutation(UPDATE_INCIDENT_EVIDENCE);

  const updateEvidence = ({ key, values }) => {
    const variables = {
      input: {
        incidentId: incident.id,
        evidence: {
          evidence: [{ key, ...values }],
        },
      },
    };

    return updateEvidenceMutation({ variables }).then(() => {
      return setDirtied(true);
    });
  };

  const handleShowDismissedChange = (event) => {
    setShowDismissed(event.target.checked);
  };

  const handleMoreFootageSnackClose = () => {
    setMoreFootageSnackOpen(false);
  };

  const handleOpenMoreFootage = () => {
    setMoreFootageOpen(true);
  };

  const handleMoreFootageClose = () => {
    setMoreFootageOpen(false);
  };

  const handleMoreFootageRequested = () => {
    setMoreFootageOpen(false);
    setMoreFootageSnackOpen(true);
  };

  const handleUpdated = () => {
    setEditing(false);
  };

  const handleEditClick = () => {
    setDirtied(true);
    setEditing(true);
    setDisableSave(true);
  };

  const handleCancelEdit = (reset) => () => {
    setEditing(false);
    setDisableSave(true);
    reset();
  };

  const onConfirm = () => {
    setDisableSave(false);
  };

  const dismissedEvidenceCount = incident.attachedEvidence.filter(
    (attachment) => attachment.status === DISMISSED
  ).length;
  const hasDismissedEvidence = dismissedEvidenceCount > 0;

  let actions = (
    <div>
      {hasDismissedEvidence && (
        <FormControlLabel
          control={
            <Switch
              checked={showDismissed}
              onChange={handleShowDismissedChange}
            />
          }
          label={`Show dismissed (${dismissedEvidenceCount})`}
          disabled={!hasDismissedEvidence}
        />
      )}
      {editable && !editing && (
        <Button
          variant="text"
          startIcon={<RequestMoreIcon />}
          onClick={handleOpenMoreFootage}
        >
          Add footage
        </Button>
      )}
      {editable && !editing && (
        <Button
          variant="text"
          startIcon={<EditIcon />}
          onClick={handleEditClick}
        >
          Edit
        </Button>
      )}
    </div>
  );

  return (
    <UpdateIncident
      incidentId={incident.id}
      initialFormValues={initialFormValues}
      onUpdated={handleUpdated}
      render={({ handleSubmit, submitting, dirty, reset, values, error }) => (
        <Card className={className}>
          <CardHeader title="Evidence" action={actions} />
          <CardContent>
            {!editing && (
              <IncidentEvidence
                editable={editable}
                updateEvidence={updateEvidence}
                showDismissed={showDismissed}
                attachedEvidence={incident.attachedEvidence}
                deviceMediaRetrievals={incident.deviceMediaRetrievals}
                footageRequests={incident.footageRequests}
              />
            )}

            <Query
              query={VEHICLE_CAMERA_QUERY}
              variables={{ id: incident.vehicleId }}
            >
              {({ data }) => (
                <RequestVehicleFootageDialog
                  incidentId={incident.id}
                  vehicleId={incident.vehicleId}
                  defaultDate={moment(incident.occurredAt).format("YYYY-MM-DD")}
                  open={moreFootageOpen}
                  onClose={handleMoreFootageClose}
                  onRequested={handleMoreFootageRequested}
                  availableCameras={
                    data && data.node ? data.node.device.cameras : []
                  }
                />
              )}
            </Query>
            <Snackbar
              open={moreFootageSnackOpen}
              autoHideDuration={6000}
              onClose={handleMoreFootageSnackClose}
              ContentProps={{
                "aria-describedby": "message-id",
              }}
              message={<span id="message-id">Footage requested</span>}
            />

            {editing && (
              <IncidentEvidenceForm
                incidentId={incident.id}
                attachedEvidence={incident.attachedEvidence}
                newEvidence={values.attachEvidence}
                onConfirm={onConfirm}
              />
            )}
            {error && <p>{error}</p>}
          </CardContent>
          {editing && (
            <CardActions className={classes.actions} disableSpacing>
              <div className={classes.buttonBox}>
                <Button
                  aria-label="Cancel"
                  onClick={handleCancelEdit(reset)}
                  disabled={submitting}
                  color="primary"
                  className={classes.button}
                >
                  Cancel
                </Button>
                <Button
                  aria-label="Save changes"
                  onClick={handleSubmit}
                  disabled={submitting || (disableSave && !dirty)}
                  color="primary"
                  className={classes.button}
                >
                  Save changes
                </Button>
              </div>
            </CardActions>
          )}
        </Card>
      )}
    />
  );
};

IncidentEvidenceCard.propTypes = {
  incident: PropTypes.object.isRequired,
  editable: PropTypes.bool,
};

export default IncidentEvidenceCard;
