import React, { useMemo } from "react";
import { Bar } from "@visx/shape";
import { Group } from "@visx/group";
import { AxisBottom } from "@visx/axis";
import { scaleBand, scaleLinear } from "@visx/scale";
import { useTheme } from "@mui/material/styles";

const getTimeOfDay = (d) => d.hour;
const getTimeOfDayFrequency = (d) => d.frequency;
const formatTimeOfDay = (hour) => `${hour.toString().padStart(2, "0")}:00`;

const defaultMargin = { top: 8, right: 8, bottom: 8, left: 8 };

function StatsByTimeOfDay({ data, width, height, margin = defaultMargin }) {
  const theme = useTheme();
  const barFill = theme.palette.primary.light;
  const barLabelFill = theme.palette.primary.dark;
  const barLabelFillAlt = theme.palette.primary.contrastText;
  const axisColor = theme.palette.text.primary;
  const bottomAxisHeight = 25;
  const leftAxisWidth = 0;
  const xMax = width - margin.left - margin.right - leftAxisWidth;
  const yMax = height - margin.top - margin.bottom - bottomAxisHeight;

  const timeOfDayScale = useMemo(
    () =>
      scaleBand({
        range: [0, xMax],
        round: true,
        domain: data.map(getTimeOfDay),
        padding: 0.1,
      }),
    [xMax]
  );
  const yScale = useMemo(
    () =>
      scaleLinear({
        range: [yMax, 0],
        round: true,
        domain: [0, Math.max(...data.map(getTimeOfDayFrequency))],
      }),
    [yMax]
  );

  return width < 10 ? null : (
    <svg width={width} height={height}>
      <Group top={margin.top} left={margin.left + leftAxisWidth}>
        {data.map((d) => {
          const timeOfDay = getTimeOfDay(d);
          const frequency = getTimeOfDayFrequency(d);
          const barWidth = timeOfDayScale.bandwidth();
          const barHeight = yMax - (yScale(frequency || 0) ?? 0);
          const barX = timeOfDayScale(timeOfDay);
          const barY = yMax - barHeight;
          const altLabelPosition = yScale(frequency) < margin.top + 5;
          return (
            <Group key={`bar-${timeOfDay}`}>
              <Bar
                x={barX}
                y={barY}
                width={barWidth}
                height={barHeight}
                fill={barFill}
              />
              {frequency && (
                <text
                  x={timeOfDayScale(timeOfDay) + timeOfDayScale.bandwidth() / 2}
                  y={altLabelPosition ? barY + 15 : yScale(frequency) - 5}
                  fontSize={frequency > 99 ? 10 : 12}
                  fill={altLabelPosition ? barLabelFillAlt : barLabelFill}
                  textAnchor="middle"
                >
                  {frequency}
                </text>
              )}
            </Group>
          );
        })}
      </Group>
      <AxisBottom
        top={height - margin.bottom - bottomAxisHeight}
        left={margin.left + leftAxisWidth}
        scale={timeOfDayScale}
        tickFormat={formatTimeOfDay}
        stroke={axisColor}
        tickStroke={axisColor}
        tickLabelProps={{
          fill: axisColor,
          fontSize: 11,
          textAnchor: "middle",
        }}
      />
    </svg>
  );
}

export default StatsByTimeOfDay;
