import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import withStyles from "@mui/styles/withStyles";
import { useMutation } from "@apollo/client";

import LinearProgress from "@mui/material/LinearProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Form } from "react-final-form";

import DriverList from "../components/DriverList";
import CreateDriverFab from "../components/CreateDriverFab";
import DriverForm from "../components/DriverForm";

import NextPreviousPagination from "../components/NextPreviousPagination";

import { mapToStatus } from "../DriverStatus";

const DRIVERS_QUERY = gql`
  query AllDrivers($after: String, $before: String, $filter: DriverFilter) {
    allDrivers(after: $after, before: $before, filter: $filter) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          name
          telephone
          email
          active
          fobId
        }
      }
    }
  }
`;

const UPDATE_DRIVERS = gql`
  mutation updateDriver($input: UpdateDriverInput!) {
    drivers {
      update(input: $input) {
        driver {
          email
          id
          name
          telephone
          active
          fobId
        }
        error {
          code
          message
        }
      }
    }
  }
`;

const DEACTIVATE_DRIVER = gql`
  mutation DeactivateDriver($input: DeactivateDriverInputType!) {
    drivers {
      deactivate(input: $input) {
        driver {
          id
          active
        }
        error {
          code
          message
        }
      }
    }
  }
`;

const REACTIVATE_DRIVER = gql`
  mutation ReactivateDriver($input: ReactivateDriverInputType!) {
    drivers {
      reactivate(input: $input) {
        driver {
          id
          active
        }
        error {
          code
          message
        }
      }
    }
  }
`;

const styles = (theme) => ({
  fab: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },

  displayRight: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
});

const Drivers = ({ search, classes }) => {
  const [open, setOpen] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [driver, setDriver] = useState({});
  const [driverId, setDriverId] = useState();
  const [driverStatus, setDriverStatus] = useState();
  const [statusUpdated, setStatusUpdated] = React.useState(false);

  const variablesGraphQL = {
    filter: { name_like: search.name, isActive: mapToStatus(search.status) },
  };

  const [deactivateDriver, { loading: loadingDeActivate }] = useMutation(
    DEACTIVATE_DRIVER,
    {
      refetchQueries: [
        {
          query: DRIVERS_QUERY,
        },
      ],
    }
  );

  const [reactivateDriver, { loading: loadingActivate }] = useMutation(
    REACTIVATE_DRIVER,
    {
      refetchQueries: [
        {
          query: DRIVERS_QUERY,
        },
      ],
    }
  );

  const handleDeactivateDriver = (id) => {
    const variables = { input: { id } };

    return deactivateDriver({ variables }).then(() => {
      setDriverStatus(false);
      setStatusUpdated(true);
    });
  };

  const handleReactivateDriver = (id) => {
    const variables = { input: { id } };

    return reactivateDriver({ variables }).then(() => {
      setDriverStatus(true);
      setStatusUpdated(true);
    });
  };

  const handleOpenEdit = (allDrivers) => {
    setOpen(true);

    setDriver(allDrivers);
    setDriverId(allDrivers.id);
    setDriverStatus(Boolean(allDrivers.active));
  };

  const handleCloseEdit = () => {
    setOpen(false);
    setStatusUpdated(false);
  };

  const handleSubmit = (updateDriver) => (detail) => {
    const variables = { input: { id: driverId, detail } };
    updateDriver({ variables }).then(() => {
      setOpen(false);
    });
  };

  const [updateDriver] = useMutation(UPDATE_DRIVERS, {
    refetchQueries: [
      {
        query: DRIVERS_QUERY,
      },
    ],
  });

  return (
    <Fragment>
      <Query query={DRIVERS_QUERY} variables={variablesGraphQL}>
        {({ loading, error, data, fetchMore }) => {
          if (loading) return <LinearProgress />;
          if (error) return JSON.stringify(error);
          return (
            <Form
              initialValues={{
                name: driver.name,
                fobId: driver.fobId,
                contact: {
                  email: driver.email,
                  telephone: driver.telephone,
                },
              }}
              onSubmit={handleSubmit(updateDriver)}
              render={({ handleSubmit, submitting, dirty }) => (
                <Fragment>
                  <DriverList
                    allDrivers={data.allDrivers}
                    handleOpenEdit={handleOpenEdit}
                  />

                  <NextPreviousPagination
                    {...{
                      fetchMore,
                      loading,
                      pageInfo: data.allDrivers.pageInfo,
                    }}
                  />

                  <CreateDriverFab className={classes.fab} />
                  <Dialog open={open}>
                    <DialogTitle>
                      Edit driver
                      <Button
                        onClick={() =>
                          driverStatus
                            ? handleDeactivateDriver(driver.id)
                            : handleReactivateDriver(driver.id)
                        }
                        className={classes.displayRight}
                        disabled={loadingActivate || loadingDeActivate}
                        size="small"
                      >
                        {driverStatus ? "Hide driver" : "Activate driver"}
                      </Button>
                    </DialogTitle>
                    <DialogContent>
                      <DriverForm value={driver} />
                    </DialogContent>
                    <DialogActions>
                      <div style={{ flexGrow: 1 }} hidden={!statusUpdated}>
                        <Button onClick={handleCloseEdit} color="secondary">
                          Close
                        </Button>
                      </div>
                      <Button
                        onClick={handleCloseEdit}
                        color="primary"
                        disabled={updating}
                      >
                        Cancel
                      </Button>

                      <Button
                        color="primary"
                        autoFocus
                        onClick={handleSubmit}
                        disabled={submitting || !dirty}
                      >
                        Save
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Fragment>
              )}
            />
          );
        }}
      </Query>
    </Fragment>
  );
};

Drivers.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Drivers);
