import React from "react";
import { makeStyles } from "@mui/styles";

import AdapterNavLink from "../components/form/NavLink";
import Hidden from "@mui/material/Hidden";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import VehicleDrawerMenu from "../containers/VehicleDrawerMenu";
import { PermissionValues } from "../containers/GroupPermissions";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  active: {
    background: theme.palette.action.selected,
  },
  topItem: {
    marginTop: theme.spacing(1),
  },
  drawerPaper: {
    width: drawerWidth,
  },
}));

const version = `v.${process.env.REACT_APP_VERSION || "[dev]"}`;

const DrawerItems = ({ tenantFeatures, externalLinks, groupPermissions }) => {
  const classes = useStyles();

  const linkAllowed = (link) => {
    var require = link.requiredPermissions ?? [];

    return (
      require.length === 0 ||
      !!require.some((p) => groupPermissions.find((g) => g === p))
    );
  };

  const isSupportTracking = tenantFeatures?.isSupportTracking || false;

  const isEnabled = (property, value) =>
    tenantFeatures?.[property] && groupPermissions.includes(value);

  const { Vehicles, Incidents, DriverReview, NearMiss } = PermissionValues;

  const vehiclesEnabled = isEnabled("vehiclesEnabled", Vehicles);
  const incidentsEnabled = isEnabled("incidentsEnabled", Incidents);
  const driverReviewEnabled = isEnabled("driverReviewEnabled", DriverReview);
  const nearMissEnabled = isEnabled("nearMissEnabled", NearMiss);
  const vehicleGroup =
    vehiclesEnabled || incidentsEnabled || driverReviewEnabled;
  return (
    <>
      <div className={classes.toolbar} />
      <List>
        {(vehicleGroup || !nearMissEnabled) && (
          <ListItemButton
            className={classes.topItem}
            component={AdapterNavLink}
            to="/"
            exact={true}
            activeClassName={classes.active}
          >
            <ListItemIcon>
              <Icon>dashboard</Icon>
            </ListItemIcon>
            <ListItemText primary={"Dashboard"} />
          </ListItemButton>
        )}

        {incidentsEnabled && (
          <ListItemButton
            component={AdapterNavLink}
            to="/incidents"
            activeClassName={classes.active}
          >
            <ListItemIcon>
              <Icon>notification_important</Icon>
            </ListItemIcon>
            <ListItemText primary={"Incidents"} />
          </ListItemButton>
        )}

        {vehiclesEnabled && (
          <ListItemButton
            component={AdapterNavLink}
            to="/which-vehicle"
            activeClassName={classes.active}
          >
            <ListItemIcon>
              <Icon>help</Icon>
            </ListItemIcon>
            <ListItemText primary={"Which vehicle?"} />
          </ListItemButton>
        )}

        {incidentsEnabled && (
          <ListItemButton
            component={AdapterNavLink}
            to="/claims"
            activeClassName={classes.active}
          >
            <ListItemIcon>
              <Icon>work</Icon>
            </ListItemIcon>
            <ListItemText primary="Claims" />
          </ListItemButton>
        )}

        {driverReviewEnabled && (
          <ListItemButton
            component={AdapterNavLink}
            to="/driver-review"
            activeClassName={classes.active}
          >
            <ListItemIcon>
              <Icon>airline_seat_recline_normal</Icon>
            </ListItemIcon>
            <ListItemText primary="Driver review" />
          </ListItemButton>
        )}

        {isSupportTracking && vehicleGroup && (
          <ListItemButton
            component={AdapterNavLink}
            to="/external-tracking"
            activeClassName={classes.active}
          >
            <ListItemIcon>
              <Icon>map</Icon>
            </ListItemIcon>
            <ListItemText primary={"Tracking"} />
          </ListItemButton>
        )}

        {nearMissEnabled && (
          <ListItemButton
            component={AdapterNavLink}
            to="/near-miss"
            activeClassName={classes.active}
          >
            <ListItemIcon>
              <Icon>personal_injury</Icon>
            </ListItemIcon>
            <ListItemText primary={"Near-miss"} />
          </ListItemButton>
        )}
        {externalLinks &&
          externalLinks.filter(linkAllowed).map((item, index) => (
            <ListItemButton
              key={index}
              component="a"
              href={item.url}
              rel="noopener noreferrer"
              target="_blank"
            >
              <ListItemIcon>
                <Icon>{item.iconName}</Icon>
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          ))}
        <Divider />

        {vehiclesEnabled && <VehicleDrawerMenu />}

        <ListItem>
          <ListItemText secondary={version} />
        </ListItem>
      </List>
    </>
  );
};

const AppDrawer = ({
  mobileOpen,
  handleDrawerToggle,
  tenantFeatures,
  externalLinks,
  groupPermissions,
}) => {
  const classes = useStyles();
  const drawer = (
    <DrawerItems
      tenantFeatures={tenantFeatures}
      externalLinks={externalLinks}
      groupPermissions={groupPermissions}
    />
  );

  return (
    <>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor={"left"}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </>
  );
};

export default AppDrawer;
