import React from "react";
import withStyles from '@mui/styles/withStyles';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import AnchorLink from "@mui/material/Link";
import Chip from '@mui/material/Chip';

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: "600px",
  },
  tableWrapper: {
    overflowX: "auto",
  },
  roleChip: {
    marginRight: theme.spacing(2),
  },
  roleChipLeft: {
    marginLeft: theme.spacing(1),
  },
  actionsCell: {
    textAlign: "right",
  },
  justifyCell: {
    textAlign: "center",
  },
});

const DriverList = ({
  classes = {},
  allDrivers,
  handleOpenEdit,
}) => {
  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Telephone</TableCell>
              <TableCell>Email address</TableCell>
              <TableCell>Fob Id</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allDrivers &&
              allDrivers.edges.map(({ node: driver }) => (
                <TableRow key={driver.id} hover>
                  <TableCell variant="head">
                    {driver.name}
                    {driver.active == false && <Chip size="small" label="hidden" className={classes.roleChipLeft} />}
                  </TableCell>
                  <TableCell>
                    {driver.telephone && (
                      <AnchorLink href={`tel:${driver.telephone}`}>
                        {driver.telephone}
                      </AnchorLink>
                    )}
                  </TableCell>
                  <TableCell>
                    {driver.email && (
                      <AnchorLink href={`mailto:${driver.email}`}>
                        {driver.email}
                      </AnchorLink>
                    )}
                  </TableCell>
                  <TableCell variant="head">{driver.fobId}</TableCell>
                  <TableCell className={classes.actionsCell}>
                    <Tooltip title="Edit this driver">
                      <IconButton onClick={() => handleOpenEdit(driver)} size="large">
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default withStyles(styles)(DriverList);