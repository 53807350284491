import React, { useRef, useEffect, useState } from "react";

import { WebGLPlayer } from "./webgl";
import { PCMPlayer } from "./pcm-player";
import makeStyles from "@mui/styles/makeStyles";
import { PlayCircleOutline as PlayIcon } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import PauseIcon from "@mui/icons-material/Pause";
import Slider from "@mui/material/Slider";

const useStyles = makeStyles((theme) => ({
  player: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  playIconOverlay: {
    display: "grid",
    placeItems: "center",
    top: "25%",
    bottom: "35%",
    left: "35%",
    right: "35%",
    position: "absolute",
    textAlign: "center",
    background: "rgba(0,0,0,0.85)",
    borderRadius: theme.spacing(2),
    cursor: "pointer",
    zIndex: 1,
    color: "#fff",
    "&:hover": {
      background: `radial-gradient(#2e2e2e, rgba(0,0,0,0.85))`,
    },
  },
  playIcon: {
    width: "90%",
    height: "90%",
  },
  loadingIcon: {
    width: "50%",
    height: "50%",
    color: "white",
  },
  videoOffLabel: {
    color: "#c5c5c5",
    bottom: "35%",
    width: "100%",
    position: "absolute",
    textAlign: "center",
    fontWeight: "600",
    fontSize: "20px",
  },
  overlayControls: {
    position: "absolute",
    bottom: "22px",
    left: "18px",
    display: "flex",
    gap: "10px",
  },
  stopIcon: {
    color: "white",
    cursor: "pointer",
  },
  fullscreenIcon: {
    color: "white",
    cursor: "pointer",
  },
  progressBar: {
    position: "absolute",
    bottom: "0",
    width: "100%",
    color: "white",
  },
  slider: {
    width: "90%",
    margin: "auto",
    color: "#ffffff",
    display: "block",
    cursor: "default",
  },
}));

const calculatePercentage = (beginTime, endTime, currentTime) => {
  const percentage = ((currentTime - beginTime) / (endTime - beginTime)) * 100;
  return Math.round(percentage);
};

const WebSocketVideoPlayer = ({ sources, onPlayClick, onPauseClick, play }) => {
  const classes = useStyles();
  const [loadingVideo, setLoadingVideo] = useState(true);

  const canvasRef = useRef(null);
  const [currentSlider, setCurrentSlider] = useState(0);

  useEffect(() => {
    const socketData = sources.find((x) => x.type === "websocket");
    const { endTime, beginTime, fileBegin } = JSON.parse(
      socketData?.openParams
    );

    let fromTime = new Date().getTime();
    if (!canvasRef.current) {
      console.log("Not ready");
      return;
    }

    if (!play) {
      console.log("Click play to start");
      return;
    }

    const webgl = new WebGLPlayer(canvasRef.current, {
      preserveDrawingBuffer: false,
    });

    var interval = 0;
    var interval_vidoe = 0;
    let totalSecondPlayVideo = 0;
    const MEDIA_TYPE_VIDEO_HEAD = 0;
    const MEDIA_TYPE_VIDEO_I = 1;
    const MEDIA_TYPE_VIDEO_P = 2;

    const MEDIA_TYPE_AUDIO = 4;

    //play mode
    const PLAY_STREAM_MODE_FILE = 1;
    const PLAY_STREAM_MODE_REAL = 2;
    const PLAY_STREAM_MODE_STREAM = 3;

    const g_play_mode = PLAY_STREAM_MODE_FILE;

    const MEDIA_DATA_CACHE_TIME = 3;
    let m_CacheDataTime = MEDIA_DATA_CACHE_TIME;
    let m_bEnableCacheData = 0;
    //
    let g_wsclose = 0;
    let g_play_ms = 40;
    let g_need_keyframe = 1;
    let video_play_timestamp = 0;
    let audio_play_timestamp = 0;

    let video_buffer_list = [];
    let audio_buffer_list = [];

    //frame rate
    let m_u64FrameRate_TimeFlag = 0;
    let m_nFrameCount = 0;
    let m_nTmpFrameCount = 0;
    let m_nFrameFirstTime = 0;
    let m_nFrameEndTime = 0;

    //vidoe dec
    let vdec = 0;
    let g_cacheBuffer = null;
    let g_cacheBufferSize = 0;
    let videoCallback = null;

    //audio dec
    let g_adec = 0;
    let g_audio_buffer = null;
    let g_audio_buffer_size = 0;
    let audioCallback = null;

    //pcm player
    let pcmplayer = new PCMPlayer({
      encoding: "16bitInt",
      channels: 1,
      sampleRate: 8000,
      flushingTime: 80,
    });

    pcmplayer.setMode(g_play_mode);

    function IsTimeOut(tstamp, nTime) {
      const CurrentTimeStamp = new Date().getTime();
      if (CurrentTimeStamp - tstamp > nTime) {
        return 1;
      }

      return 0;
    }

    Module.print = console.log;
    Module.printErr = console.log;
    Module.setStatus = (status) => console.log(`Status: ${status}`);

    Module.onRuntimeInitialized = (fullScreen) => {
      console.log("onRuntimeInitialized", fullScreen);
      videoCallback = Module.addFunction(datacallback, "viiidii");
      audioCallback = Module.addFunction(audioDataCallback, "viiid");

      console.log("_xaddtest: " + Module._xaddtest(16, 90));
      var canvas = canvasRef.current;
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      if (fullScreen) {
        webgl.fullscreen();
      }
    };

    let ws = new WebSocket(socketData.source);
    ws.binaryType = socketData.binaryType;
    ws.onopen = function () {
      setLoadingVideo(true);

      ws.send(socketData.openParams);
    };

    ws.onclose = function () {
      setLoadingVideo(false);

      console.log("onclose and videobuf.size=" + video_buffer_list.length);
      g_wsclose = 1;

      if (g_play_mode === PLAY_STREAM_MODE_FILE) return;

      video_buffer_list = [];
      audio_buffer_list = [];
      console.log("onclose..real close");
      OnUninitilize();
    };

    ws.onmessage = function (msg) {
      if (g_wsclose === 1) {
        console.log("ws. av datat coming..");
      }

      const abuffer = new Uint8Array(msg.data);

      const cMediaType =
        abuffer[0] |
        (abuffer[1] << 8) |
        (abuffer[2] << 16) |
        (abuffer[3] << 24);
      const nSize =
        abuffer[4] |
        (abuffer[5] << 8) |
        (abuffer[6] << 16) |
        (abuffer[7] << 24);

      const nLeftTimeStamp =
        abuffer[8] |
        (abuffer[9] << 8) |
        (abuffer[10] << 16) |
        (abuffer[11] << 24);
      const nRightTimeStamp =
        abuffer[12] |
        (abuffer[13] << 8) |
        (abuffer[14] << 16) |
        (abuffer[15] << 24);

      const n64TimeStamp = nLeftTimeStamp + 2 ** 32 * nRightTimeStamp;

      if (cMediaType === MEDIA_TYPE_VIDEO_HEAD) {
        if (interval_vidoe === 0) {
          interval_vidoe = setInterval(function xVideo() {
            displayLoop();
          }, 10);
        }

        //av_config header
        const typedArray = abuffer.slice(16);
        let nVideoCodeType =
          typedArray[4] |
          (typedArray[5] << 8) |
          (typedArray[6] << 16) |
          (typedArray[7] << 24);
        let nAudioCodeType =
          typedArray[8] |
          (typedArray[9] << 8) |
          (typedArray[10] << 16) |
          (typedArray[11] << 24);

        //1. create video dec
        if (vdec !== 0) {
          Module._FreeVideoDecoder(vdec);
          vdec = 0;
        }

        // H264:{0,1}  H265:{2,3,265,256}
        if (nVideoCodeType === 0 || nVideoCodeType === 1) {
          nVideoCodeType = 0;
        } else if (
          nVideoCodeType === 2 ||
          nVideoCodeType === 3 ||
          nVideoCodeType === 265 ||
          nVideoCodeType === 256
        ) {
          nVideoCodeType = 2;
        }

        vdec = Module._CreateVideoDecoder(nVideoCodeType);
        if (vdec !== 0) {
          Module._SetVideoDataCb(vdec, videoCallback);
        }

        if (g_adec !== 0) {
          Module._FreeAudioDecoder(g_adec);
          g_adec = 0;
        }

        g_adec = Module._CreateAudioDecoder(nAudioCodeType);

        if (g_adec !== 0) {
          Module._SetAudioDataCb(g_adec, audioCallback);
        }
      }

      if (cMediaType === MEDIA_TYPE_AUDIO) {
        if (interval === 0) {
          interval = setInterval(function xAudoe() {
            AudioDisplayLoop();
          }, 10);
        }

        if (g_adec !== 0) {
          InitAudioBuffer(nSize);
          const typedArray = abuffer.slice(16);

          const aduioObj = {
            l: nSize,
            t: cMediaType,
            s: n64TimeStamp,
            d: typedArray,
          };

          audio_buffer_list.push(aduioObj);
        }
      } else if (
        cMediaType === MEDIA_TYPE_VIDEO_I ||
        cMediaType === MEDIA_TYPE_VIDEO_P
      ) {
        if (vdec !== 0) {
          InitBuffer(nSize);
          const typedArray = abuffer.slice(16);

          const vidoeObj = {
            l: nSize,
            t: cMediaType,
            s: n64TimeStamp,
            d: typedArray,
          };

          if (IsTimeOut(m_u64FrameRate_TimeFlag, 10000)) {
            m_nTmpFrameCount++;
            if (m_nFrameFirstTime === 0) {
              m_nFrameFirstTime = n64TimeStamp;
            }
            m_nFrameEndTime = n64TimeStamp;

            if (m_nFrameEndTime - m_nFrameFirstTime >= 1000 * 1000) {
              g_play_ms = 1000 / m_nTmpFrameCount;
              m_nFrameCount = m_nTmpFrameCount;

              m_nTmpFrameCount = 0;
              m_nFrameFirstTime = 0;
              m_nFrameEndTime = 0;
            }
          }

          video_buffer_list.push(vidoeObj);
        }
      }
    };

    function OnUninitilize() {
      console.log("*************  OnUninitilize  ************** ");
      if (vdec !== 0) {
        Module._FreeVideoDecoder(vdec);
        vdec = 0;
      }

      if (g_adec !== 0) {
        Module._FreeAudioDecoder(g_adec);
        g_adec = 0;
      }

      if (pcmplayer) {
        pcmplayer.destroy();
        pcmplayer = null;
      }

      if (g_audio_buffer != null) {
        Module._xFree(g_audio_buffer);
        g_audio_buffer_size = null;
        g_audio_buffer = null;
      }

      if (g_cacheBuffer != null) {
        Module._xFree(g_cacheBuffer);
        g_cacheBuffer = null;
        g_cacheBufferSize = null;
      }

      if (interval !== 0) {
        clearInterval(interval);
        interval = 0;
      }

      if (interval_vidoe !== 0) {
        clearInterval(interval_vidoe);
        interval_vidoe = 0;
      }
    }

    function InitBuffer(nSize) {
      if (g_cacheBufferSize === 0) {
        //g_cacheBuffer = Module._malloc(nSize);
        g_cacheBuffer = Module._xMalloc(nSize);
        g_cacheBufferSize = nSize;
        console.log("11111 videobuf size: " + nSize);
        setLoadingVideo(false);
      } else if (nSize > g_cacheBufferSize) {
        if (g_cacheBuffer != null) {
          Module._xFree(g_cacheBuffer);
        }

        g_cacheBuffer = Module._xMalloc(nSize);
        g_cacheBufferSize = nSize;
        console.log("2222 videobuf size: " + nSize);
      }
    }

    function InitAudioBuffer(nSize) {
      if (g_audio_buffer_size === 0) {
        //g_cacheBuffer = Module._malloc(nSize);
        g_audio_buffer = Module._xMalloc(nSize);
        g_audio_buffer_size = nSize;
        console.log("11111 audiobuff size: " + nSize);
      } else if (nSize > g_audio_buffer_size) {
        if (g_audio_buffer != null) {
          Module._xFree(g_audio_buffer);
        }

        g_audio_buffer = Module._xMalloc(nSize);
        g_audio_buffer_size = nSize;
        console.log("22222 audiobuff size: " + nSize);
      }
    }

    function datacallback(JSObj, buff, size, timestamp, width, height) {
      const outArray = Module.HEAPU8.subarray(buff, buff + size);
      const data = new Uint8Array(outArray);

      webgl.renderFrame(
        data,
        width,
        height,
        width * height,
        (width / 2) * (height / 2)
      );
    }

    function audioDataCallback(JSObj, buff, size, timestamp) {
      var outArray = Module.HEAPU8.subarray(buff, buff + size);
      var data = new Uint8Array(outArray);

      pcmplayer.feed(data);
    }

    //vidoe
    function displayLoop() {
      const video_frame_count = video_buffer_list.length;

      if (!g_wsclose && m_bEnableCacheData) {
        const nSecodes = video_frame_count / m_nFrameCount;
        if (nSecodes >= m_CacheDataTime) {
          m_bEnableCacheData = 0;
        } else {
          return;
        }
      }

      if (video_buffer_list.length !== 0) {
        let bPlay = 0;
        let playMs = g_play_ms;
        if (video_play_timestamp === 0) {
          video_play_timestamp = new Date().getTime();
          bPlay = 1;
        }

        if (g_play_mode === PLAY_STREAM_MODE_FILE) {
          var CurrentTimeStamp = new Date().getTime();
          if (CurrentTimeStamp - video_play_timestamp >= g_play_ms) {
            video_play_timestamp = CurrentTimeStamp;
            bPlay = 1;
          }
        } else if (g_play_mode === PLAY_STREAM_MODE_REAL) {
          {
            bPlay = 1;
          }
        } else {
          let bDropData = 0;
          if (bPlay === 0) {
            {
              const tmp_nCnt = 1000 / g_play_ms;
              if (video_frame_count <= 3) {
                playMs = playMs + g_play_ms / 4;
              } else if (
                video_frame_count > tmp_nCnt / 2 &&
                video_frame_count < tmp_nCnt
              ) {
              } else if (video_frame_count > tmp_nCnt) {
                const buffer_total_second =
                  (video_frame_count * g_play_ms) / 1000;
                if (buffer_total_second >= 1 && buffer_total_second <= 2) {
                  playMs = playMs - 10;
                } else if (
                  buffer_total_second > MEDIA_DATA_CACHE_TIME + 1 &&
                  buffer_total_second < MEDIA_DATA_CACHE_TIME + 2
                ) {
                  playMs = playMs - g_play_ms / 4;
                } else {
                  bDropData = 1;
                  g_need_keyframe = 1;
                  while (video_buffer_list.length > 0) {
                    video_buffer_list.shift();
                    const video_frame = video_buffer_list[0];
                    const tmpTotal_second =
                      (video_buffer_list.length * g_play_ms) / 1000;
                    if (
                      video_frame.t === MEDIA_TYPE_VIDEO_I &&
                      tmpTotal_second <= 2
                    ) {
                      ClearAudio(video_frame.s);
                      break;
                    }
                  }
                }
              }
            }

            if (bDropData === 0) {
              if (playMs < 0) {
                playMs = 0;
              }

              var CurrentTimeStamp = new Date().getTime();
              if (CurrentTimeStamp - video_play_timestamp >= playMs) {
                video_play_timestamp = CurrentTimeStamp;
                bPlay = 1;
              }
            } else {
              video_play_timestamp = new Date().getTime();
              bPlay = 1;
            }
          }
        }

        if (bPlay) {
          const video_frame = video_buffer_list[0];
          if (g_need_keyframe) {
            if (video_frame.t !== MEDIA_TYPE_VIDEO_I) {
              video_buffer_list.shift();
              return;
            }
            g_need_keyframe = 0;
          }

          Module.HEAPU8.set(video_frame.d, g_cacheBuffer);
          const nRet = Module._InputVideoData(
            vdec,
            video_frame.t,
            g_cacheBuffer,
            video_frame.l,
            video_frame.s
          );
          video_buffer_list.shift();
        }
      } else {
        if (g_play_mode === PLAY_STREAM_MODE_STREAM) {
          m_bEnableCacheData = 1;
        } else if (g_play_mode === PLAY_STREAM_MODE_FILE) {
          m_bEnableCacheData = 1;
        }
      }
      // console.log("video_play_timestamp", video_play_timestamp);

      var differenceInSeconds = (video_play_timestamp - fromTime) / 1000;

      if (differenceInSeconds > 1) {
        fromTime = video_play_timestamp;
        const currentTime = beginTime + (totalSecondPlayVideo += 1);
        var percentage = calculatePercentage(fileBegin, endTime, currentTime);

        setCurrentSlider(percentage);
      }

      if (
        g_wsclose &&
        video_buffer_list.length === 0 &&
        audio_buffer_list.length === 0
      ) {
        OnUninitilize();
      }
    }

    function ClearAudio(u64TimeStamp) {
      while (audio_buffer_list.length > 0) {
        const audio_frame = audio_buffer_list[0];
        if (audio_frame.s > u64TimeStamp) {
          break;
        }
        audio_buffer_list.shift();
      }
    }

    function AudioDisplayLoop() {
      if (!g_wsclose && m_bEnableCacheData) {
        return;
      }

      if (audio_buffer_list.length !== 0) {
        if (audio_play_timestamp === 0) {
          //毫秒时间
          audio_play_timestamp = new Date().getTime();
        } else {
          const audio_ms = 80;

          const CurrentTimeStamp = new Date().getTime();
          if (CurrentTimeStamp - audio_play_timestamp >= audio_ms) {
            audio_play_timestamp = CurrentTimeStamp;
          }
        }

        const audio_frame = audio_buffer_list[0];
        Module.HEAPU8.set(audio_frame.d, g_audio_buffer);
        const nRet = Module._InputAudioData(
          g_adec,
          g_audio_buffer,
          audio_frame.l,
          audio_frame.s
        );

        audio_buffer_list.shift();
      }
    }

    Module.onRuntimeInitialized(false);

    return () => {
      console.log("unmount");

      if (pcmplayer != null) pcmplayer.destroy();
      ws.close();
    };
  }, [canvasRef, play]);

  const onFullscreenClick = () => {
    Module.onRuntimeInitialized(true);
  };

  return (
    <>
      {!play ? (
        <div className={classes.playIconOverlay} onClick={onPlayClick}>
          <PlayIcon className={classes.playIcon} />
        </div>
      ) : (
        <>
          <canvas ref={canvasRef} className={classes.player} />
          {loadingVideo && (
            <div className={classes.playIconOverlay}>
              <CircularProgress className={classes.loadingIcon} />
            </div>
          )}

          {!loadingVideo && (
            <>
              <div className={classes.overlayControls}>
                <PauseIcon
                  className={classes.stopIcon}
                  onClick={onPauseClick}
                />
                <FullscreenIcon
                  className={classes.fullscreenIcon}
                  onClick={onFullscreenClick}
                />
              </div>
              <div className={classes.progressBar}>
                <Slider
                  value={currentSlider}
                  min={0}
                  max={100}
                  className={classes.slider}
                  size="small"
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default WebSocketVideoPlayer;
