import React from "react";
// import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

import TomTomIntegrationPanel from "../components/TomTom/IntegrationPanel";
import OrbcommIntegrationPanel from "../components/Orbcomm/IntegrationPanel";

const useStyles = makeStyles((theme) => ({
  root: {},
  marginTop: {
    marginTop: theme.spacing(3),
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
}));

const IntegrationsPage = () => {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" className={classes.marginBottom}>
        <Link color="inherit" to="/admin" component={RouterLink}>
          Admin
        </Link>
        <Typography color="textPrimary">Integrations</Typography>
      </Breadcrumbs>
      <Typography variant="h5" gutterBottom>
        Integrations
      </Typography>

      <TomTomIntegrationPanel />
      <div className={classes.marginTop}></div>
      <OrbcommIntegrationPanel />
    </section>
  );
};

IntegrationsPage.propTypes = {};

export default IntegrationsPage;
