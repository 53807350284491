import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";

import { Field } from "react-final-form";
import TextField from "../components/form/TextField";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import FormHelperText from "@mui/material/FormHelperText";

const validateNewPassword = (value) => {
  if (!value) return `Password is required`;

  if (value.length < 5)
    return "Password must be at least 5 characters long. Longer is recommended";
};

const styles = (theme) => ({
  progress: {
    margin: `${theme.spacing(4)} auto ${theme.spacing(2)} auto`,
  },
  error: {
    marginTop: theme.spacing(1),
  },
});

const ResetPasswordForm = ({ classes, disabled, submitting, valid, error }) => (
  <div>
    <Field
      component={TextField}
      name="newPassword"
      type="password"
      required
      validate={validateNewPassword}
      margin="normal"
      autoComplete="off"
      label="New password"
      disabled={disabled}
      fullWidth
    />
    <div>
      <Button
        disabled={!valid || disabled}
        type="submit"
        variant="contained"
        color="secondary"
      >
        Save
      </Button>
      {submitting && (
        <LinearProgress color="secondary" className={classes.progress} />
      )}
    </div>
    {error && (
      <FormHelperText className={classes.error} error>
        {error}
      </FormHelperText>
    )}
  </div>
);

ResetPasswordForm.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool,
  error: PropTypes.string,
};

export default withStyles(styles)(ResetPasswordForm);
