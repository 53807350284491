import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import NewIncidentOnClick from "./NewIncidentOnClick";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  kpiContent: {
    padding: 0,
  },
  kpi: {
    fontSize: "3em",
    textAlign: "center",
    margin: 0,
  },
  permission: {
    fontSize: "1em",
    textAlign: "center",
    margin: 0,
  },
}));

const INCIDENT_COUNT_QUERY = gql`
  query IncidentCount($first: Int!) {
    incidents {
      all(first: $first, filter: { status: ACTIVE }) {
        totalCount
        edges {
          cursor
          node {
            id
            reference
            occurredAt
            driver {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const DashboardOpenIncidentCountCard = ({ rowCount = 5, incidentsEnabled }) => {
  const classes = useStyles();

  const { loading, error, data } = useQuery(INCIDENT_COUNT_QUERY, {
    variables: { first: rowCount },
    fetchPolicy: "cache-and-network",
  });

  return (
    <Card>
      <CardHeader title="Open incidents" />
      <CardContent className={classes.kpiContent}>
        <Typography component="div">
          {loading && <p className={classes.kpi}>...</p>}
          {error && <p className={classes.kpi}>err</p>}
          {!loading && !error && (
            <div>
              <p className={classes.kpi}>{data.incidents.all.totalCount}</p>

              {!incidentsEnabled ? (
                <p className={classes.permission}>
                  You do not have permission to view incidents
                </p>
              ) : (
                Boolean(data.incidents.all.edges.length) && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>&nbsp;</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Driver</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.incidents.all.edges.map(({ cursor, node }) => (
                        <TableRow key={cursor}>
                          <TableCell>
                            <Link
                              component={RouterLink}
                              className={classes.boldLink}
                              to={`/incidents/${node.id}`}
                            >
                              {node.reference}
                            </Link>
                          </TableCell>
                          <TableCell>
                            <span>
                              {moment(node.occurredAt).format("L")}{" "}
                              {moment(node.occurredAt).format("LT")}
                            </span>
                          </TableCell>
                          <TableCell>
                            {node.driver && node.driver.name}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )
              )}
            </div>
          )}
        </Typography>
      </CardContent>
      {incidentsEnabled && (
        <CardActions>
          <Button
            size="small"
            color="primary"
            component={RouterLink}
            to="/incidents?status=ACTIVE"
          >
            View all
          </Button>
          <NewIncidentOnClick>
            {({ onClick }) => (
              <Button size="small" color="primary" onClick={onClick}>
                Add new
              </Button>
            )}
          </NewIncidentOnClick>
        </CardActions>
      )}
    </Card>
  );
};

DashboardOpenIncidentCountCard.propTypes = {
  rowCount: PropTypes.number,
};

export default DashboardOpenIncidentCountCard;
