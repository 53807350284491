import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";
import { fuzzyFromMb } from "../FuzzyData";

import Tooltip from "@mui/material/Tooltip";
import LinearProgress from "@mui/material/LinearProgress";

import {
  WARNING_PERCENT,
  WARNING2_PERCENT,
  OVER_PERCENT,
} from "../components/DashboardDataUsageCard";

import { red, orange, amber, green } from "@mui/material/colors";

const styles = (theme) => ({
  percentValue: {
    float: "left",
    paddingLeft: theme.spacing(1),
  },
  root: {
    width: "100%",
  },
  progressContainer: {
    border: `1px solid ${theme.palette.divider}`,
    minWidth: "70px",
  },
  percentProgress: {
    background: theme.palette.divider,
    height: "20px",
  },
  progressWarn: {
    background: theme.palette.warning.main,
  },
  progressDanger: {
    background: theme.palette.error.main,
  },
  percentValueWarn: {
    color: theme.palette.warning.contrastText,
  },
  percentValueDanger: {
    color: theme.palette.error.contrastText,
  },
  progress: {
    height: theme.spacing(2),
  },
  progressOkColour: {
    backgroundColor: green[100],
  },
  progressOkBarColour: {
    backgroundColor: green[500],
  },
  progressWarningColour: {
    backgroundColor: amber[100],
  },
  progressWarningBarColour: {
    backgroundColor: amber[500],
  },
  progressWarning2Colour: {
    backgroundColor: orange[100],
  },
  progressWarning2BarColour: {
    backgroundColor: orange[500],
  },
  progressOverusageColour: {
    backgroundColor: red[100],
  },
  progressOverusageBarColour: {
    backgroundColor: red[500],
  },
});

const VehicleTableDataUsageCell = ({
  classes,
  monthlyAllocation,
  totalUsed,
  lastUpdated,
}) => {
  const hasData = totalUsed !== null;
  const percentageUsed = !hasData
    ? null
    : Math.min(100, Math.round((totalUsed / monthlyAllocation) * 100));

  return !hasData ? (
    <div className={classes.root} />
  ) : (
    <div className={classes.root}>
      <Tooltip
        title={`${
          fuzzyFromMb(totalUsed).formatted
        } of data used this month (as of ${moment(lastUpdated).fromNow()})`}
      >
        <LinearProgress
          variant="determinate"
          value={percentageUsed}
          classes={{
            colorPrimary: classNames(classes.progressOkColour, {
              [classes.progressWarningColour]: percentageUsed > WARNING_PERCENT,
              [classes.progressWarning2Colour]:
                percentageUsed > WARNING2_PERCENT,
              [classes.progressOverusageColour]: percentageUsed >= OVER_PERCENT,
            }),
            barColorPrimary: classNames(classes.progressOkBarColour, {
              [classes.progressWarningBarColour]:
                percentageUsed > WARNING_PERCENT,
              [classes.progressWarning2BarColour]:
                percentageUsed > WARNING2_PERCENT,
              [classes.progressOverusageBarColour]:
                percentageUsed >= OVER_PERCENT,
            }),
          }}
          className={classes.progress}
        />
      </Tooltip>
    </div>
  );
};

VehicleTableDataUsageCell.propTypes = {
  monthlyAllocation: PropTypes.number,
  totalUsed: PropTypes.number,
  lastUpdated: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VehicleTableDataUsageCell);
