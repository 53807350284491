import React from "react";
import { Route, Switch } from "react-router-dom";

import NoRoutePage from "../components/NoRoutePage";
import AdminPage from "../components/AdminPage";
import VehiclesAdminPage from "../components/VehiclesAdminPage";
import DriversPage from "../components/DriversPage";
import DepotsPage from "../components/DepotsPage";
import IntegrationsPage from "../components/IntegrationsPage";
import GroupsRoute from "../components/GroupsRoute";
import MembersPage from "../components/MembersPage";
import PolicyAdminPage from "../components/PolicyAdminPage";

import DefaultLayout from "../components/DefaultLayout";

const AdminRoute = () => (
  <DefaultLayout>
    <Switch>
      <Route exact path="/admin">
        <AdminPage />
      </Route>
      <Route path="/admin/vehicles">
        <VehiclesAdminPage />
      </Route>
      <Route path="/admin/drivers">
        <DriversPage />
      </Route>
      <Route path="/admin/depots">
        <DepotsPage />
      </Route>
      <Route path="/admin/integrations">
        <IntegrationsPage />
      </Route>
      <Route path="/admin/groups">
        <GroupsRoute />
      </Route>
      <Route path="/admin/members">
        <MembersPage />
      </Route>
      <Route path="/admin/insurer-policy">
        <PolicyAdminPage />
      </Route>
      <Route>
        <NoRoutePage />
      </Route>
    </Switch>
  </DefaultLayout>
);

export default AdminRoute;
