import React, { Fragment } from "react";
import withStyles from '@mui/styles/withStyles';
import PropTypes from "prop-types";
import { Drawer, Hidden } from "@mui/material";

const styles = theme => ({
  drawerPaper: {
    width: 320,
    left: 241,
    top: 65,
    height: "auto",
    maxHeight: "calc(100% - 98px)",
    overflowY: "auto"
  },
  mobile: {
    width: 320,
    top: 56,
    height: "auto",
    maxHeight: "calc(100% - 98px)",
    overflowY: "auto"
  }
});

const WhichVehicleDrawer = ({
  classes,
  children,
  openDrawer,
  handleDrawerClose
}) => {
  return (
    <Fragment>
      <Hidden mdDown implementation="css">
        <Drawer
          variant="persistent"
          anchor={"left"}
          open={openDrawer}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={handleDrawerClose}
        >
          {children}
        </Drawer>
      </Hidden>
      <Hidden mdUp>
        <Drawer
          variant="persistent"
          anchor={"left"}
          open={openDrawer}
          classes={{
            paper: classes.mobile
          }}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true
          }}
        >
          {children}
        </Drawer>
      </Hidden>
    </Fragment>
  );
};

WhichVehicleDrawer.propTypes = {
    handleDrawerClose: PropTypes.func.isRequired,
    openDrawer: PropTypes.bool.isRequired
};

export default withStyles(styles)(WhichVehicleDrawer);
