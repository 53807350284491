import React from "react";
import Typography from "@mui/material/Typography";

import DefaultLayout from "../components/DefaultLayout";

const AboutPage = () => (
  <DefaultLayout>
    <Typography variant="h5" gutterBottom>
      About
    </Typography>
  </DefaultLayout>
);

export default AboutPage;
