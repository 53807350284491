import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Grid, Slider, IconButton } from "@mui/material";
import { PlayArrow as PlayIcon, Pause as PauseIcon } from "@mui/icons-material";
import { debounce } from "lodash";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  html5Video: {
    maxWidth: "100%",
  },
  controls: {
    display: "flex",
    alignItems: "center",
    background: theme.palette.grey["A400"],
    padding: theme.spacing(1),
    color: theme.palette.getContrastText(theme.palette.grey["A400"]),
    marginBottom: theme.spacing(2),
  },
  controlButton: {
    color: theme.palette.getContrastText(theme.palette.grey["A400"]),
  },
  flexGrow: {
    flexGrow: 1,
  },
  playContainer: {
    marginRight: theme.spacing(1),
  },
  sliderContainer: {
    marginRight: theme.spacing(2),
  },
}));

const FootageGrid = ({ footage }) => {
  const classes = useStyles();
  const videoRefs = React.useRef([]);

  const handlePlayButtonClick = () => {
    for (let i = 0; i < videoRefs.current.length; i++)
      videoRefs.current[i].play();
  };

  const handlePauseButtonClick = () => {
    for (let i = 0; i < videoRefs.current.length; i++)
      videoRefs.current[i].pause();
  };

  const debouncedSeek = React.useCallback(
    debounce((seekTo) => {
      for (let i = 0; i < videoRefs.current.length; i++)
        videoRefs.current[i].currentTime = seekTo;
    }, 200),
    [videoRefs]
  );
  const [sliderState, setSliderState] = React.useState({
    currentTime: 0,
    duration: 0,
  });
  const handleSliderChange = (_, newValue) => {
    setSliderState((prev) => ({ ...prev, currentTime: newValue }));
    debouncedSeek(newValue);
  };

  React.useEffect(() => {
    const videoElements = videoRefs.current;
    if (videoElements.length === 0) return;

    const firstRef = videoElements[0];

    const timeupdateEventListner = (ev) => {
      const currentTime = ev.target.currentTime;
      const duration = ev.target.duration;
      setSliderState((prev) => ({ ...prev, currentTime, duration }));
    };
    firstRef.addEventListener("timeupdate", timeupdateEventListner);

    const clickEventListner = (ev) => {
      const newPaused = !firstRef.paused;
      for (let i = 0; i < videoElements.length; i++)
        newPaused ? videoElements[i].pause() : videoElements[i].play();
    };
    for (let i = 0; i < videoElements.length; i++)
      videoElements[i].addEventListener("click", clickEventListner);

    return () => {
      firstRef.removeEventListener("timeupdate", timeupdateEventListner);
      firstRef.removeEventListener("click", clickEventListner);
      for (let i = 0; i < videoElements.length; i++)
        videoElements[i]?.removeEventListener("click", clickEventListner);
    };
  }, [videoRefs]);

  return (
    <div>
      <Grid container spacing={1}>
        {footage.map(({ signedUrl }, idx) => (
          <Grid item md={6} key={`item-${idx}`}>
            {signedUrl && (
              <video
                ref={(el) => (videoRefs.current[idx] = el)}
                className={classes.html5Video}
                // controls
                muted
                autoPlay
              >
                Sorry, your browser does not support embedded videos
                <source src={signedUrl} />
              </video>
            )}
          </Grid>
        ))}
      </Grid>
      {videoRefs.current[0] && (
        <div className={classes.controls}>
          <div className={classes.playContainer}>
            {videoRefs.current[0]?.paused && (
              <IconButton
                className={classes.controlButton}
                onClick={handlePlayButtonClick}
                size="large">
                <PlayIcon />
              </IconButton>
            )}
            {!videoRefs.current[0]?.paused && (
              <IconButton
                className={classes.controlButton}
                onClick={handlePauseButtonClick}
                size="large">
                <PauseIcon />
              </IconButton>
            )}
          </div>
          <div className={clsx(classes.flexGrow, classes.sliderContainer)}>
            <Slider
              value={sliderState.currentTime}
              max={sliderState.duration}
              step={0.1}
              onChange={handleSliderChange}
              aria-labelledby="continuous-slider"
            />
          </div>
          <div>
            {dayjs
              .unix(Math.round(videoRefs.current[0].currentTime))
              .format("mm:ss")}{" "}
            /{" "}
            {dayjs
              .unix(Math.round(videoRefs.current[0].duration))
              .format("mm:ss")}
          </div>
        </div>
      )}
    </div>
  );
};

export default FootageGrid;
