import React from "react";
import SettingsPanel from "./IntegrationSettingsPanel/SettingsPanel";
import useIntegrationSettings from "./useIntegrationSettings";

const IntegrationPanel = () => {
  const { loading, data, error } = useIntegrationSettings();

  const orbcomm = data && data.integrations.orbcomm;
  const settings = orbcomm && orbcomm.settings;
  const status = orbcomm && orbcomm.status;

  return <SettingsPanel {...{ status, error, settings, loading }} />;
};

IntegrationPanel.propTypes = {};

export default IntegrationPanel;
