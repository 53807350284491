import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import moment from "moment";
import MarkdownIt from "markdown-it";
import RequestDriverAccountButton from "../containers/RequestDriverAccountButton";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import AdapterLink from "../components/form/Link";
import AnchorLink from "@mui/material/Link";

const markdown = new MarkdownIt();

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  card: {
    marginBottom: theme.spacing(2)
  }
});

const IncidentCoreDetails = ({ incident, classes }) => (
  <div>
    <Grid spacing={2} container>
      <Grid item xs={12} sm={6}>
        <p>
          Vehicle:{" "}
          <AnchorLink component={AdapterLink} to={`/vehicles/${incident.vehicleId}`}>
            {incident.vehicle.vrm}
          </AnchorLink>
        </p>
      </Grid>
      <Grid item xs={12} sm={6}>
        <p>
          Driver:{" "}
          {incident.driver && (
            <span>
              {incident.driver.name}
              {incident.driver.email && (
                <AnchorLink href={`mailto:${incident.driver.email}`}>
                  <br />
                  {incident.driver.email}
                </AnchorLink>
              )}
              {incident.driver.telephone && (
                <AnchorLink href={`tel:${incident.driver.telephone}`}>
                  <br />
                  {incident.driver.telephone}
                </AnchorLink>
              )}
            </span>
          )}
        </p>
        {incident.driver && (
          <div>
            Account requested:{" "}
            {incident.driverAccount.requestedOn
              ? moment(incident.driverAccount.requestedOn).calendar()
              : "No"}
            <RequestDriverAccountButton
              incidentId={incident.id}
              accountRequestedOn={incident.driverAccount.requestedOn}
            />
          </div>
        )}
      </Grid>
    </Grid>
    <Divider />
    <p>Occurred at: {moment(incident.occurredAt).format("LLL")}</p>
    <p>Location: {incident.location}</p>
    <Divider />

    <p>Details of incident:</p>
    <blockquote
      dangerouslySetInnerHTML={{
        __html: markdown.render(incident.accountOfIncident)
      }}
    />
    <p>Vehicle Speed: {incident.vehicleSpeed}</p>
  </div>
);

IncidentCoreDetails.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(IncidentCoreDetails);
