import React from "react";
import { makeStyles } from "@mui/styles";
import { NavLink } from "react-router-dom";
import Hidden from "@mui/material/Hidden";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AppBarUserButton from "./AppBarUserButton";
import SwitchIcon from "@mui/icons-material/CompareArrows";
import SwitchTenantButton from "../containers/SwitchTenantButton";
import Button from "@mui/material/Button";
import NewIncidentOnClick from "../components/NewIncidentOnClick";

const { PUBLIC_URL } = process.env;

const useStyles = makeStyles((theme) => ({
  title: {
    flex: 1,
    display: "flex",
    alignItems: "center",
  },
  titleLink: {
    color: "inherit",
    textDecoration: "none",
  },
  logo: {
    height: "30px",
    marginRight: theme.spacing(1),
  },
  appBar: {
    position: "fixed",
    zIndex: theme.zIndex.drawer + 1,
  },
  tenantName: {
    marginRight: theme.spacing(2),
  },
  iconLeft: {
    marginRight: theme.spacing(1),
  },
}));

function AppBar({
  onToggleDrawer,
  isAuthenticated,
  account,
  incidentsEnabled = false,
}) {
  const classes = useStyles();

  return (
    <MuiAppBar className={classes.appBar} color="default" position="sticky">
      <Toolbar>
        {onToggleDrawer && (
          <Hidden mdUp>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
              onClick={onToggleDrawer}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
        )}
        <Typography variant="h6" color="inherit" className={classes.title}>
          <NavLink className={classes.titleLink} to="/">
            <img
              src={`${PUBLIC_URL}/logo.svg`}
              alt="Fastview360 logo"
              className={classes.logo}
            />
          </NavLink>
        </Typography>
        <Hidden mdDown>
          <Typography
            variant="h6"
            color="inherit"
            className={classes.tenantName}
          >
            <SwitchTenantButton>
              {({ click }) => (
                <IconButton
                  onClick={click}
                  size="small"
                  className={classes.iconLeft}
                >
                  <SwitchIcon />
                </IconButton>
              )}
            </SwitchTenantButton>
            {isAuthenticated && account?.tenant?.name}
          </Typography>
        </Hidden>

        {incidentsEnabled && (
          <NewIncidentOnClick>
            {({ onClick }) => (
              <Button
                onClick={onClick}
                className={classes.iconLeft}
                variant="outlined"
                color="primary"
              >
                New incident
              </Button>
            )}
          </NewIncidentOnClick>
        )}

        <AppBarUserButton />
      </Toolbar>
    </MuiAppBar>
  );
}

export default AppBar;
