import React from "react";
import Typography from "@mui/material/Typography";

import { Box, Paper } from "@mui/material";
import RestrictLayout from "../components/RestrictLayout";
import { useAuth } from "../AuthContext";

const SuspendedPage = () => {
  const { account } = useAuth();

  return (
    <RestrictLayout>
      <Box display={"flex"} justifyContent={"center"}>
        <Box
          maxWidth={{ xs: "none", sm: "400px" }}
          margin={2}
          marginTop={4}
          width={"100%"}
        >
          <Paper>
            <Box
              sx={{
                background: (theme) => theme.palette.warning.main,
                color: (theme) => theme.palette.warning.contrastText,
                padding: 4,
                borderTopLeftRadius: (theme) => theme.shape.borderRadius,
                borderTopRightRadius: (theme) => theme.shape.borderRadius,
              }}
            >
              <Typography variant="h5" component="h3" color="inherit">
                Account suspended
              </Typography>
            </Box>
            <Box px={2} py={4}>
              <p>
                The <em>{account.tenant.name}</em> account has been suspended.
                Please contact our support team using the details below to
                unlock.
              </p>
              <p>
                <a href="mailto:support@fastview360.co.uk">
                  support@fastview360.co.uk
                </a>
              </p>
              <p>
                <a href="tel:01270 360 360">01270 360 360</a>
              </p>
            </Box>
          </Paper>
        </Box>
      </Box>
    </RestrictLayout>
  );
};

export default SuspendedPage;
