import React from "react";
import { Route, Switch } from "react-router-dom";

import IncidentsPage from "../components/IncidentsPage";
import IncidentPage from "../components/IncidentPage";

import DefaultLayout from "../components/DefaultLayout";

const IncidentsRoute = () => (
  <DefaultLayout>
    <Switch>
      <Route exact path="/incidents">
        <IncidentsPage />
      </Route>
      <Route path="/incidents/:id">
        <IncidentPage />
      </Route>
    </Switch>
  </DefaultLayout>
);

export default IncidentsRoute;
