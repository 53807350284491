import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import DefaultLayout from "./DefaultLayout";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import gql from "graphql-tag";
import { makeStyles } from "@mui/styles";

const EXTERNAL_TRACKING_QUERY = gql`
  query {
    externalTracking {
      urlRedirect
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  flexBox: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  iframe: {
    width: "100%",
    height: "100%",
    border: "none",
  },
}));

const ExternalTrackingPage = () => {
  const { data } = useQuery(EXTERNAL_TRACKING_QUERY);
  const [externalTrackingLink, setExternalTracking] = useState();
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;
  const classes = useStyles();
  console.log(data?.externalTracking?.urlRedirect);

  useEffect(() => {
    const handleExternalTracking = () => {
      if (
        data &&
        data.externalTracking?.urlRedirect &&
        currentPath === "/external-tracking"
      ) {
        window.open(data.externalTracking.urlRedirect, "_blank");
        history.goBack();
      }
    };

    handleExternalTracking();
  }, [data, currentPath, history]);

  return (
    <DefaultLayout>
      <Typography variant="h5" gutterBottom>
        Tracking Data
      </Typography>
      {externalTrackingLink ? (
        <section className={classes.flexBox}>
          <iframe src={externalTrackingLink} className={classes.iframe} />
        </section>
      ) : (
        <LinearProgress />
      )}
    </DefaultLayout>
  );
};

export default ExternalTrackingPage;
