import React from "react";
import moment from "moment";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { Link as RouterLink } from "react-router-dom";
import { Link, Grid } from "@mui/material";
import { amber, green } from "@mui/material/colors";

import {
  DoneAll as NoIssuesIcon,
  OfflineBolt as ProlongedOfflineIcon,
  FiberDvr as SystemFaultIcon,
  Camera as CameraNotRecordingIcon,
} from "@mui/icons-material";

import { mapToDisplay } from "../CameraNames";

const useStyles = makeStyles((theme) => ({
  p2: { padding: theme.spacing(2) },
  px2: { padding: `0 ${theme.spacing(2)}` },
  noIssuesContainer: {
    background: green[100],
    display: "flex",
    alignItems: "center",
  },
  noIssuesIcon: { color: green[500], marginRight: theme.spacing(2) },
  lastUpdatedHint: {
    color: theme.palette.text.hint,
    ...theme.typography.caption,
  },
  cameraSince: {
    // color: theme.palette.text.hint,
  },
  issueTypeIcon: {
    marginRight: theme.spacing(1),
  },
  tableContainer: {
    // borderTop: `1px solid ${theme.palette.divider}`,
  },
  row: {
    backgroundColor: amber[50],
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    paddingLeft: 0,
    "&:hover": {
      backgroundColor: amber[100],
    },
  },
  alternateRow: {
    backgroundColor: theme.palette.background.default,
    // ["&:hover"]: {
    //   backgroundColor: theme.palette.background.active,
    // },
  },
  cellContent: {
    display: "flex",
    alignItems: "center",
  },
  faultCellRoot: {
    display: "flex",
  },
  vrmLink: {
    ...theme.typography.button,
  },
}));

const DashboardVehiclesWithHealthIssuesTable = ({ data, error }) => {
  const classes = useStyles();

  return (
    <>
      {error && <p className={""}>err</p>}
      {data?.vehicles.allWithHealthIssues.totalCount === 0 && (
        <div className={clsx(classes.p2, classes.noIssuesContainer)}>
          <div className={classes.noIssuesIcon}>
            <NoIssuesIcon />
          </div>
          <div>
            Great news, we haven't found any issues, all devices appear to be
            working fully.
          </div>
        </div>
      )}
      {data?.vehicles.allWithHealthIssues.totalCount > 0 && (
        <div className={classes.tableContainer}>
          {data?.vehicles.allWithHealthIssues.edges.map(
            ({ node, cursor }, idx) => (
              <div
                key={cursor}
                className={clsx(classes.row, {
                  [classes.alternateRow]: idx % 2 !== 0,
                })}
              >
                <div className={classes.px2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3} lg={2}>
                      <div className={classes.cellContent}>
                        <Link
                          className={classes.vrmLink}
                          color="inherit"
                          to={`/vehicles/${node.vehicle.id}`}
                          component={RouterLink}
                        >
                          {node.vehicle.vrm}
                        </Link>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={9} lg={10}>
                      <div className={classes.faultCellRoot}>
                        <div className={classes.faultIconContainer}>
                          {node.issueType === "SYSTEM_FAULT" && (
                            <SystemFaultIcon
                              className={classes.issueTypeIcon}
                            />
                          )}
                          {node.issueType === "CAMERA_NOT_RECORDING" && (
                            <CameraNotRecordingIcon
                              className={classes.issueTypeIcon}
                            />
                          )}
                          {node.issueType === "PROLONGED_OFFLINE" && (
                            <ProlongedOfflineIcon
                              className={classes.issueTypeIcon}
                            />
                          )}
                        </div>
                        <div className={classes.faultDescriptionContainer}>
                          {node.issueType === "SYSTEM_FAULT" && (
                            <>
                              <div>
                                No recorded footage detected on any camera for{" "}
                                <b>
                                  {moment(
                                    node.vehicle.device.health.dvr.since
                                  ).fromNow(true)}
                                </b>
                              </div>
                              <div className={classes.lastUpdatedHint}>
                                Last updated{" "}
                                {moment(
                                  node.vehicle.device.health.dvr.asAt
                                ).format("lll")}
                              </div>
                            </>
                          )}
                          {node.issueType === "CAMERA_NOT_RECORDING" && (
                            <>
                              No recorded footage detected on the following
                              cameras:{" "}
                              <ul>
                                {node.vehicle.device.health.cameras
                                  .filter((x) => x.status === "NO_FOOTAGE")
                                  .map((x, idx) => (
                                    <li key={idx}>
                                      {mapToDisplay(x.camera)}{" "}
                                      <span className={classes.cameraSince}>
                                        (since {moment(x.since).format("lll")})
                                      </span>
                                    </li>
                                  ))}
                              </ul>
                              <div className={classes.lastUpdatedHint}>
                                Last updated{" "}
                                {moment(
                                  node.vehicle.device.health.dvr.asAt
                                ).format("lll")}
                              </div>
                            </>
                          )}
                          {node.issueType === "PROLONGED_OFFLINE" && (
                            <>
                              Health check not completed in{" "}
                              <b>
                                {moment(
                                  node.vehicle.device.health.dvr.asAt
                                ).fromNow(true)}
                              </b>
                              .
                              <div className={classes.lastUpdatedHint}>
                                Last updated{" "}
                                {moment(
                                  node.vehicle.device.health.dvr.asAt
                                ).format("lll")}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            )
          )}
        </div>
      )}
    </>
  );
};

DashboardVehiclesWithHealthIssuesTable.propTypes = {};

export default DashboardVehiclesWithHealthIssuesTable;
