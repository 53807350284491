import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import Grid from "@mui/material/Grid";

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  card: {
    marginBottom: theme.spacing(2)
  }
});

const IncidentPropertyDamage = ({ value, classes }) => (
  <div>
    <p>{!value.detail ? "No detail entered" : "Detail entered"}</p>
    {value.detail && (
      <Grid container spacing={2}>
        <Grid item md={6}>
          Detail: {value.detail}
        </Grid>
      </Grid>
    )}
  </div>
);

IncidentPropertyDamage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(IncidentPropertyDamage);
