import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import withStyles from '@mui/styles/withStyles';
import classNames from "classnames";

import { OK, mapToDisplay } from "../CameraHealth";

import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

import { orange, green } from '@mui/material/colors';

const cameraHealthStyles = () => ({
  cameraOkIcon: {
    color: green[500]
  },
  cameraIssueIcon: {
    color: orange[500]
  }
});

const CameraHealthIcon = ({
  classes,
  className,
  camera: { camera, status, asAt, since }
}) => {
  const tip = (
    <span>
      <b>{camera}</b>: {mapToDisplay(status)} for {moment(since).fromNow(true)}{" "}
      (last updated: {moment(asAt).format("lll")})
    </span>
  );
  return (
    <Tooltip title={tip}>
      <Icon
        className={classNames(className, {
          [classes.cameraOkIcon]: status === OK,
          [classes.cameraIssueIcon]: status !== OK
        })}
      >
        {status === OK ? "camera" : "error_outline"}
      </Icon>
    </Tooltip>
  );
};

CameraHealthIcon.propTypes = {
  camera: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired
};

export default withStyles(cameraHealthStyles)(CameraHealthIcon);
