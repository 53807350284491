import React from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { useDropzone } from "react-dropzone";
import { Typography } from "@mui/material";

const GET_SIGNED_URL = gql`
  mutation SignUpload(
    $nearMissId: ID!
    $filename: String!
    $contentType: String!
  ) {
    nearMisses {
      signEvidenceUpload(
        input: {
          nearMissId: $nearMissId
          filename: $filename
          contentType: $contentType
        }
      ) {
        signedUrl
        fileKey
        media {
          type
          source {
            src
            contentType
          }
        }
      }
    }
  }
`;

const FileUpload = ({
  children,
  getSignedUrl,
  onProgress,
  onUploadReady,
  onError,
}) => {
  const [progress, setProgress] = React.useState(0);
  const [dropped, setDropped] = React.useState(false);

  const handleFileUploadComplete = React.useCallback(
    ({ acceptedFiles, file, preSign }) => {
      setProgress((prev) => (prev += 1 / acceptedFiles.length));

      onUploadReady && onUploadReady({ file, preSign });
    },
    [onUploadReady]
  );

  React.useEffect(() => {
    if (!dropped) return;
    onProgress({ total: Number(progress.toFixed(2)) });
  }, [dropped, progress, onProgress]);

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      setDropped(true);
      setProgress(0);

      for (let i = 0; i < acceptedFiles.length; i++) {
        const file = acceptedFiles[i];

        const formData = new FormData();
        formData.append("file", file);

        getSignedUrl(file).then((preSign) => {
          const responsePromise = fetch(
            new Request(preSign.signedUrl, {
              method: "PUT",
              body: file,
              headers: new Headers({
                "Content-Type": file.type,
                "Access-Control-Allow-Origin": "*",
              }),
            })
          );
          responsePromise.then((response) => {
            if (response.status !== 200) {
              console.log("onError", { response });
              onError && onError({ file, preSign });

              return response.text().then((body) => console.error(body));
            }

            handleFileUploadComplete({ acceptedFiles, file, preSign });
          });
        });
      }
    },
    [getSignedUrl, handleFileUploadComplete, onError]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {children({ isDragActive })}
    </div>
  );
};

const EvidenceUpload = ({ nearMissId, onFinish }) => {
  const [progress, setProgress] = React.useState(null);
  const [uploading, setUploading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const [signEvidenceUpload] = useMutation(GET_SIGNED_URL);

  const handleProgress = ({ total }) => {
    console.log("progress", total);
    setProgress(total);
    setUploading(total < 1);
    setError(null);
  };

  const handleError = (error) => {
    console.log("error", error);
    setProgress(0);
    setUploading(false);
    setError("Error");
  };

  const handleGetPreSignedUrl = ({ name, type }) => {
    return signEvidenceUpload({
      variables: { nearMissId, filename: name, contentType: type },
    }).then(({ data, error }) => {
      if (error) console.error({ error });
      return data.nearMisses.signEvidenceUpload;
    });
  };

  const handleUploadReady = ({ file, preSign }) => {
    setProgress(null);

    const uploaded = {
      fileKey: preSign.fileKey,
      filename: file.name,
      contentType: file.type,
      media: preSign.media,
    };
    return onFinish(uploaded);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "stretch",
      }}
    >
      <FileUpload
        getSignedUrl={handleGetPreSignedUrl}
        onProgress={handleProgress}
        onError={handleError}
        onUploadReady={handleUploadReady}
      >
        {({ isDragActive }) => (
          <>
            <Typography variant="h6" gutterBottom>
              Upload files
            </Typography>
            {isDragActive ? (
              <span>Drop the files here ...</span>
            ) : (
              <span>Drag and drop files here, or click to select files</span>
            )}

            {uploading && (
              <LinearProgress
                variant="determinate"
                value={progress * 100 || 0}
                sx={{ marginBottom: "8px" }}
              />
            )}
            {uploading && (
              <LinearProgress
                variant="indeterminate"
                sx={{ marginBottom: "8px" }}
              />
            )}
          </>
        )}
      </FileUpload>
    </Box>
  );
};

EvidenceUpload.propTypes = {
  nearMissId: PropTypes.string.isRequired,
  onFinish: PropTypes.func,
};

export default EvidenceUpload;
