import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Star as StarredIcon,
  StarBorder as UnstarredIcon,
  NotInterested as NoFootageIcon,
  PlayCircleOutline as AwaitingDownloadIcon,
  PlayCircleOutline as DownloadingIcon,
  PlayCircleFilled as PlayIcon,
  CloudDownload as FetchFootageIcon,
  ErrorOutline as ErroredIcon,
  VideocamOff as VideoNotSupportedIcon,
} from "@mui/icons-material";
import {
  Link,
  IconButton,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { DISMISSED, REVIEWED, NEEDS_REVIEW, toDisplayName } from "./Statuses";
import FootageStatuses from "./FootageStatuses";

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(2),
    padding: `${theme.spacing(2)} 0`,
    [theme.breakpoints.up("sm")]: {},
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    [theme.breakpoints.up("lg")]: {},
  },
  footageContainer: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
  },
  contentContainer: {
    flexGrow: 1,
    maxWidth: theme.breakpoints.values.lg,
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(2),
      display: "block",
    },
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
  },
  infoContainer: {
    flexGrow: 1,
  },
  actionsRoot: {
    display: "flex",
    alignItems: "baseline",
    marginTop: theme.spacing(2),
  },
  actionsContainer: {
    display: "flex",
    alignItems: "center",
  },
  dismissButton: {
    marginRight: theme.spacing(2),
  },
  formControl: {
    marginRight: theme.spacing(2),
  },
  eventTitle: {
    ...theme.typography.h6,
    marginBottom: theme.spacing(1),
  },
  infoItem: {
    marginBottom: theme.spacing(0.5),
  },
  posterImage: {
    maxWidth: "100%",
  },
  footagePosterContainer: {
    cursor: "pointer",
    // width: "300px",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "300px",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "none",
    },
  },
  footageInfoBoxOuter: {
    width: "100%",
    height: "100%",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.hint,
    display: "flex",
    alignItems: "center",
    // padding: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
  },
  footageInfoBoxInner: {
    margin: "auto",
  },
  footageInfo: {
    display: "flex",
    alignItems: "center",
  },
  footageInfoIcon: {
    marginRight: theme.spacing(1),
  },
}));

const EventItem = ({
  node,
  onStarClicked,
  onUnstarClicked,
  onSetStatusClicked,
  onFetchFootageClicked,
  onExpandEventClicked,
}) => {
  const classes = useStyles();

  const [starring, setStarring] = React.useState(false);
  const [unstarring, setUnstarring] = React.useState(false);
  const [settingStatus, setSettingStatus] = React.useState(false);

  const handleStarClicked = () => {
    setStarring(true);
    onStarClicked(node.id).then(() => {
      setStarring(false);
    });
  };

  const handleUnstarClicked = () => {
    setUnstarring(true);
    onUnstarClicked(node.id).then(() => {
      setUnstarring(false);
    });
  };

  const handleStatusChanged = (event) => {
    setSettingStatus(true);
    onSetStatusClicked(node.id, event.target.value).then(() => {
      setSettingStatus(false);
    });
  };

  const handleDismissButtonClicked = () => {
    setSettingStatus(true);
    onSetStatusClicked(node.id, DISMISSED).then(() => {
      setSettingStatus(false);
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.footageContainer}>
        {node.linkedFootage.status === "NOT_REQUESTED" &&
          node.vehicle.hasVideoSupport && (
            <div className={classes.footageInfoBoxOuter}>
              <div className={classes.footageInfoBoxInner}>
                <Tooltip title="Request footage of event">
                  <div>
                    <Button onClick={() => onFetchFootageClicked(node.id)}>
                      <FetchFootageIcon className={classes.footageInfoIcon} />{" "}
                      Request footage
                    </Button>
                  </div>
                </Tooltip>
              </div>
            </div>
          )}
        {node.linkedFootage.status === "NOT_REQUESTED" &&
          !node.vehicle.hasVideoSupport && (
            <div className={classes.footageInfoBoxOuter}>
              <div className={classes.footageInfoBoxInner}>
                <Tooltip title="The vehicle does not have a video device fitted">
                  <div className={classes.footageInfo}>
                    <VideoNotSupportedIcon
                      className={classes.footageInfoIcon}
                    />{" "}
                    Video not supported
                  </div>
                </Tooltip>
              </div>
            </div>
          )}
        {node.linkedFootage.status === "REQUESTED" && (
          <div className={classes.footageInfoBoxOuter}>
            <div className={classes.footageInfoBoxInner}>
              <Tooltip title="Footage has been requested, it will be available here once it has been downloaded">
                <div className={classes.footageInfo}>
                  <AwaitingDownloadIcon className={classes.footageInfoIcon} />{" "}
                  Awaiting download
                </div>
              </Tooltip>
            </div>
          </div>
        )}
        {node.linkedFootage.status === "ACKNOWLEDGED" && (
          <div className={classes.footageInfoBoxOuter}>
            <div className={classes.footageInfoBoxInner}>
              <Tooltip title="Footage request has been recevied">
                <div className={classes.footageInfo}>
                  <AwaitingDownloadIcon className={classes.footageInfoIcon} />{" "}
                  Request received
                </div>
              </Tooltip>
            </div>
          </div>
        )}
        {node.linkedFootage.status === "FULFILLED" &&
          node.linkedFootage.footage.length === 0 && (
            <div className={classes.footageInfoBoxOuter}>
              <div className={classes.footageInfoBoxInner}>
                <Tooltip title="No footage available">
                  <div className={classes.footageInfo}>
                    <NoFootageIcon className={classes.footageInfoIcon} /> No
                    footage available
                  </div>
                </Tooltip>
              </div>
            </div>
          )}
        {node.linkedFootage.status === "FULFILLED" &&
          node.linkedFootage.footage.length > 0 && (
            <>
              {node.linkedFootage.footage[0].status === "ERRORED" && (
                <div className={classes.footageInfoBoxOuter}>
                  <div className={classes.footageInfoBoxInner}>
                    <Tooltip title="Failed to download footage">
                      <div className={classes.footageInfo}>
                        <ErroredIcon className={classes.footageInfoIcon} />{" "}
                        Errored
                      </div>
                    </Tooltip>
                  </div>
                </div>
              )}
              {node.linkedFootage.footage[0].status !== "ERRORED" &&
                node.linkedFootage.footage[0].status !== "COMPLETED" && (
                  <div className={classes.footageInfoBoxOuter}>
                    <div className={classes.footageInfoBoxInner}>
                      <Tooltip
                        title={`Footage is ${FootageStatuses.toDisplayName(
                          node.linkedFootage.footage[0].status
                        )}`}
                      >
                        <div className={classes.footageInfo}>
                          <DownloadingIcon
                            className={classes.footageInfoIcon}
                          />{" "}
                          {FootageStatuses.toDisplayName(
                            node.linkedFootage.footage[0].status
                          )}
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                )}
              {node.linkedFootage.footage[0].status === "COMPLETED" &&
                !Boolean(node.linkedFootage.footage[0].signedPosterUrl) && (
                  <div className={classes.footageInfoBoxOuter}>
                    <div className={classes.footageInfoBoxInner}>
                      <Tooltip title={node.linkedFootage.footage[0].status}>
                        <div>
                          <Button
                            onClick={() => onExpandEventClicked(node)}
                            variant="contained"
                            color="primary"
                          >
                            <PlayIcon className={classes.footageInfoIcon} />{" "}
                            Play
                          </Button>
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                )}
              {node.linkedFootage.footage[0].status === "COMPLETED" &&
                Boolean(node.linkedFootage.footage[0].signedPosterUrl) && (
                  <div className={classes.footagePosterContainer}>
                    <Tooltip title="Click to play">
                      <div onClick={() => onExpandEventClicked(node)}>
                        {node.linkedFootage.footage[0].signedPosterUrl && (
                          <img
                            className={classes.posterImage}
                            src={node.linkedFootage.footage[0].signedPosterUrl}
                          />
                        )}
                      </div>
                    </Tooltip>
                  </div>
                )}
            </>
          )}
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.infoContainer}>
          <div className={classes.eventTitle}>{node.title}</div>
          <div className={classes.infoItem}>
            {moment(node.occurredAt).format("lll")}
          </div>
          <div className={classes.infoItem}>{node.vehicle.vrm}</div>
          {node.speed && (
            <div className={classes.infoItem}>
              {node.speed}
              <small>MPH</small>{" "}
              {node.speedLimit && (
                <span>
                  ({node.speedLimit}
                  <small>MPH</small> limit)
                </span>
              )}
            </div>
          )}
          {node.driver && (
            <div className={classes.infoItem}>
              <div>{node.driver.name}</div>
            </div>
          )}
          <div>
            <Link
              href={`https://maps.google.com?q=${node.coord.lat},${node.coord.lng}`}
              target="_blank"
              title="Open location in Google maps"
            >
              {node.addressLabel
                ? node.addressLabel
                : `${node.coord.lat}, ${node.coord.lng}`}
            </Link>
          </div>
        </div>
        <div className={classes.actionsRoot}>
          <div className={classes.actionsContainer}>
            {DISMISSED !== node.status && (
              <div>
                <Button
                  variant="text"
                  onClick={handleDismissButtonClicked}
                  disabled={settingStatus}
                  className={classes.dismissButton}
                >
                  Dismiss
                </Button>
              </div>
            )}
            <div>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={node.status}
                  onChange={handleStatusChanged}
                  label="Status"
                  disabled={settingStatus}
                >
                  <MenuItem value={NEEDS_REVIEW}>
                    {toDisplayName(NEEDS_REVIEW)}
                  </MenuItem>
                  <MenuItem value={REVIEWED}>
                    {toDisplayName(REVIEWED)}
                  </MenuItem>
                  <MenuItem value={DISMISSED}>
                    {toDisplayName(DISMISSED)}
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              {node.starred ? (
                <IconButton
                  onClick={handleUnstarClicked}
                  color="inherit"
                  disabled={unstarring}
                  size="large">
                  <StarredIcon />
                </IconButton>
              ) : (
                <IconButton
                  onClick={handleStarClicked}
                  color="inherit"
                  disabled={starring}
                  size="large">
                  <UnstarredIcon />
                </IconButton>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

EventItem.propTypes = {
  node: PropTypes.object.isRequired,
  onStarClicked: PropTypes.func.isRequired,
  onUnstarClicked: PropTypes.func.isRequired,
  onSetStatusClicked: PropTypes.func.isRequired,
  onFetchFootageClicked: PropTypes.func.isRequired,
  onExpandEventClicked: PropTypes.func.isRequired,
};

export default EventItem;
