import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import moment from "moment";

import { Form } from "react-final-form";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import makeStyles from "@mui/styles/makeStyles";

import CreateLiveShareTrackingForm from "../components/CreateLiveShareTrackingForm";

const withMobileDialog = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="lg" fullScreen={false} />;

const VEHICLES_QUERY = gql`
  query VehicleSearch {
    vehicles {
      all(first: 350) {
        edges {
          node {
            id
            vrm
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  actions: {
    display: "flex",
  },
  button: {
    margin: theme.spacing(1),
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
  buttonBox: {
    marginLeft: "auto",
  },
  itemContainer: {
    paddingTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
}));

const CreateLiveShareTrackingDialog = ({
  open,
  fullScreen,
  handleCreateDialogClick,
  handleCreateFormSubmit,
}) => {
  const classes = useStyles();

  const [error, setError] = useState();
  const [stateFormValues, setStateFormValues] = useState({
    defaultDateStart: moment(),
    defaultDateEnd: moment().add(1, "days"),
    listVehicle: [],
  });

  const { data } = useQuery(VEHICLES_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    open && setError();
  }, [open]);

  const options =
    data?.vehicles?.all?.edges?.map(({ node: vehicle }) => ({
      id: vehicle.id,
      name: vehicle.vrm,
    })) || [];

  const getOptionLabel = (value) => value?.name || "";

  const handleChange = (value) => {
    var selectedData = Array.from(new Set(value?.map(({ id }) => id) ?? []));

    setStateFormValues({ ...stateFormValues, listVehicle: selectedData });
  };

  const handleFormSubmit = (data) => {
    const { defaultDateStart, defaultDateEnd, listVehicle } = stateFormValues;
    const accessStart = data.accessStart || defaultDateStart;
    const accessEnd = data.accessEnd || defaultDateEnd;

    if (!accessStart.isBefore(accessEnd)) {
      setStateFormValues({
        ...stateFormValues,
        defaultDateStart: accessStart,
        defaultDateEnd: accessEnd,
      });
      setError("Please select a start date that is earlier than the end date.");
      return;
    }

    if (!listVehicle?.length) {
      setStateFormValues({
        ...stateFormValues,
      });

      setError("Please select a vehicle to share live location.");
      return;
    }
    const variables = {
      input: {
        accessStart: accessStart.format("YYYY-MM-DDTHH:mm:ssZ"),
        accessEnd: accessEnd.format("YYYY-MM-DDTHH:mm:ssZ"),
        vehicleIds: listVehicle,
      },
    };
    handleCreateFormSubmit(variables);
    setError(null);
    setStateFormValues({
      ...stateFormValues,
      defaultDateStart: moment(),
      defaultDateEnd: moment().add(1, "days"),
      listVehicle: [],
    });
  };

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      onClose={handleCreateDialogClick}
      aria-labelledby="form-dialog-title"
    >
      <Form
        onSubmit={handleFormSubmit}
        render={({ handleSubmit, submitting, submitError }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">
              Add Share live tracking
            </DialogTitle>
            <DialogContent>
              <CreateLiveShareTrackingForm
                getOptionLabel={getOptionLabel}
                handleChange={handleChange}
                options={options}
                classes={classes}
                defaultDateStart={stateFormValues.defaultDateStart}
                defaultDateEnd={stateFormValues.defaultDateEnd}
              />
              {(error || submitError) && (
                <FormHelperText error={true}>{error}</FormHelperText>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                disabled={submitting}
                onClick={handleCreateDialogClick}
                color="primary"
              >
                Cancel
              </Button>
              <Button disabled={submitting} type="submit" color="primary">
                Save
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

CreateLiveShareTrackingDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCreateDialogClick: PropTypes.func.isRequired,
  handleCreateFormSubmit: PropTypes.func.isRequired,
};

export default withMobileDialog()(CreateLiveShareTrackingDialog);
