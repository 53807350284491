import React from "react";
import TextField from "@mui/material/TextField";

export default ({
  input: { name, onChange, value, type, onBlur },
  meta,
  ...rest
}) => (
  <TextField
    variant="outlined"
    helperText={(meta.touched && meta.error) || meta.submitError || undefined}
    error={Boolean((meta.error && meta.touched) || meta.submitError)}
    name={name}
    onChange={onChange}
    onBlur={onBlur}
    value={value}
    type={type}
    {...rest}
  />
);
