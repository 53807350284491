import React from "react";
import withStyles from '@mui/styles/withStyles';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Icon } from "@mui/material";

const styles = theme => ({
  root: {
    width: "100%"
  },
  table: {
    minWidth: "600px"
  },
  tableWrapper: {
    overflowX: "auto"
  }
});

const PolicyList = ({ classes, data, onOpenEditDialog }) => {
  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Number</TableCell>
              <TableCell>Insurer name</TableCell>
              <TableCell>File claim submission email</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.edges &&
              data.edges.map(({ node: policy, cursor }) => (
                <TableRow key={cursor} hover>
                  <TableCell variant="head">{policy.number}</TableCell>
                  <TableCell>{policy.insurerName}</TableCell>
                  <TableCell>{policy.fileClaimSubmissionEmail}</TableCell>
                  <TableCell>
                    <Tooltip title="Edit this policy">
                      <IconButton onClick={() => onOpenEditDialog(policy)} size="large">
                        <Icon>edit</Icon>
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default withStyles(styles)(PolicyList);
