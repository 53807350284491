import React from "react";
import PropTypes from "prop-types";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

import CreateMemberDialog from "../containers/CreateMemberDialog";

const CreateMemberFab = ({ className }) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Fab color="secondary" className={className} onClick={handleOpen}>
        <AddIcon />
      </Fab>
      <CreateMemberDialog open={open} handleClose={handleClose} />
    </>
  );
};

CreateMemberFab.propTypes = {
  className: PropTypes.string,
};

export default CreateMemberFab;
