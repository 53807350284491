import { Link } from "react-router-dom";
import { Box, Button, Divider, Stack } from "@mui/material";
import moment from "moment";

import StatusChip from "../near-miss/StatusChip";

function NearMissList({ nearMissConnection }) {
  const nearMisses = nearMissConnection.edges;
  return (
    <Stack
      component={"ul"}
      role="list"
      style={{ listStyleType: "none", padding: 0, margin: 0 }}
      spacing={1}
      divider={<Divider flexItem />}
    >
      {nearMisses.map(({ node: nearMiss }) => (
        <li
          key={nearMiss.id}
          style={{
            cursor: "pointer",
          }}
        >
          <Link
            to={`/near-miss/${nearMiss.id}`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Stack direction="row" spacing={2}>
              <Box flexGrow={1}>
                <Stack spacing={2} direction="row">
                  <Box color={"text.secondary"}># {nearMiss.reference}</Box>
                  <Box>{nearMiss.category.name} </Box>
                  <div>
                    <StatusChip status={nearMiss.status} />
                  </div>
                </Stack>
                <Stack
                  color={"text.secondary"}
                  direction="row"
                  alignItems="center"
                  gap={2}
                >
                  <div>
                    <small>
                      Raised on {moment(nearMiss.eventDate).format("lll")}
                    </small>
                  </div>
                  {nearMiss.assignee && (
                    <>
                      <svg
                        viewBox="0 0 2 2"
                        style={{
                          width: "0.125rem",
                          height: "0.125rem",
                          fill: "currentcolor",
                        }}
                      >
                        <circle cx={1} cy={1} r={1} />
                      </svg>
                      <div>
                        <small>Assigned to {nearMiss.assignee.fullName}</small>
                      </div>
                    </>
                  )}
                  <svg
                    viewBox="0 0 2 2"
                    style={{
                      width: "0.125rem",
                      height: "0.125rem",
                      fill: "currentcolor",
                    }}
                  >
                    <circle cx={1} cy={1} r={1} />
                  </svg>
                  <div>
                    <small>{nearMiss.depot.name}</small>
                  </div>
                  <svg
                    viewBox="0 0 2 2"
                    style={{
                      width: "0.125rem",
                      height: "0.125rem",
                      fill: "currentcolor",
                    }}
                  >
                    <circle cx={1} cy={1} r={1} />
                  </svg>
                  <div>
                    <small> {nearMiss.location}</small>
                  </div>
                </Stack>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <div>
                  <Button variant="outlined" color="primary" component="div">
                    View
                  </Button>
                </div>
              </Box>
            </Stack>
          </Link>
        </li>
      ))}
    </Stack>
  );
}

export default NearMissList;
