import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import withTheme from "@mui/styles/withTheme";
import moment from "moment";
import makeStyles from "@mui/styles/makeStyles";
import { red, amber } from "@mui/material/colors";
import GoogleMap from "./GoogleMap";
import VehicleTrackingVideos from "../components/VehicleTrackingVideos";
import { Marker, Polyline } from "@react-google-maps/api";

import VehicleTrackingNodeInfoWindow from "../components/VehicleTrackingNodeInfoWindow";
import VehicleSafetyEventNodeInfoWindow from "../components/VehicleSafetyEventNodeInfoWindow";
import VehicleStopInfoWindow from "../components/VehicleStopInfoWindow";
import VehicleTrackingMapControls from "../components/VehicleTrackingMapControls";
import parking from "../icons/Parking.svg";
import warning from "../icons/Warning.svg";
import { useParentSize } from "@visx/responsive";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import classNames from "classnames";
import JourneyGraph from "./JourneyGraph";

const useStyles = makeStyles((theme) => ({
  map: {
    minHeight: "500px",
  },
  rootContainer: {
    flexDirection: "column",
    height: "100%",
    width: "100%",
    position: "relative",
  },
  containerMap: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      minHeight: "calc(100% - 107px)",
    },
  },
  mapContainer: { width: "100%" },
  videoContainer: {
    display: "none",
    marginLeft: "10px",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  videoContainerMobile: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },

  mapContainerJourneyGraph: {
    marginTop: "0px",
    bottom: 0,
    overflow: "hidden",
    [theme.breakpoints.up("sm")]: {
      marginTop: "10px",
    },
  },
  paneresizer: {
    display: "none",
    cursor: "pointer",
    margin: 0,
    left: 0,
    position: "relative",
    left: "5px",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    width: "10px",
    "&:before": {
      fontFamily: "Material Icons",
      content: '"\\e5cb"',
      position: "absolute",
      top: "50%",
      marginTop: "-16px",
      marginLeft: "-6px",
      fontSize: "22px",
      color: "#5f6368",
    },
    " &:hover": {
      "&:before": {
        borderColor: "#999",
      },
    },
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
  },
  active: {
    width: "18px",
    display: "none",
    "&:before": {
      fontFamily: "Material Icons",
      content: '"\\e5cc"',
      marginLeft: "-7px",
    },
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
  },
}));

const closestLocation = (targetCoords, nodes) => {
  // const vectorDistance = (dx, dy) => {
  //   return Math.sqrt(dx * dx + dy * dy);
  // };
  const relativeDistance = (dx, dy) => {
    return dx * dx + dy * dy;
  };

  const locationDistance = (location1, location2) => {
    var dx = location1.lat - location2.lat,
      dy = location1.lng - location2.lng;

    return relativeDistance(dx, dy);
  };

  return nodes.reduce((prev, curr) => {
    var prevDistance = locationDistance(targetCoords, prev.coord),
      currDistance = locationDistance(targetCoords, curr.coord);
    return prevDistance < currDistance ? prev : curr;
  });
};

const VehicleTrackingMap = ({
  vehicleId,
  vehicleIsOnline,
  trackingData,
  cameraData,
  safetyEventData,
  onCreateIncident,
  incidentsEnabled,
  mapClassName,
  supportsVideo,
  initialDate,
  onSubmitQueryData,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { parentRef, width, height } = useParentSize({ debounceTime: 150 });

  const vehicleCameras = cameraData?.video;

  const [mapRef, setMapRef] = useState(null);

  const loadHandler = (map) => {
    setMapRef(map);

    fitBounds(map);
  };
  const trackingPath = useMemo(
    () => trackingData.markers.map((node) => node.coord),
    [trackingData]
  );

  const [zoom, setZoom] = useState(10);
  const fitBounds = (map) => {
    if (!trackingData?.markers?.length) {
      const defaultLocation = new window.google.maps.Point(17.5, 17.5);
      map.setCenter(defaultLocation);
      setZoom(6);
      return;
    }
    const bounds = new window.google.maps.LatLngBounds();
    trackingData.markers.forEach((node) => {
      bounds.extend(node.coord);
    });
    map.fitBounds(bounds);
  };
  const [defaultCenter, setDefaultCenter] = useState(trackingPath[0]);

  useEffect(() => {
    const startMarker = trackingData[0];
    if (!startMarker) return;
    setDefaultCenter(startMarker.coord);
  }, [trackingData]);

  const [footageSearchTracking, setFootageSearchTracking] = useState(null);

  const [extraContentDisplay, setExtraContentDisplay] = useState(null);
  const [timeSelected, setTimeSelected] = useState(null);

  const [selectedTrackingEdge, setSelectedTrackingEdge] = useState(null);
  const [showTrackingNodeInfoWindow, setShowTrackingNodeInfoWindow] =
    useState(false);

  const [selectedSafetyEventNode, setSelectedSafetyEventNode] = useState(null);

  const [selectedStop, setSelectedStop] = useState(null);
  const [paneResizer, setPaneResizer] = useState(false);
  const [hiddenPaneResizer, setHiddenPaneResizer] = useState(false);

  useEffect(() => {
    setSelectedTrackingEdge(null);
    setSelectedSafetyEventNode(null);
    setExtraContentDisplay(null);
    setSelectedStop(null);
  }, [trackingData]);

  useEffect(() => {
    setDefaultCenter(trackingPath[0]);
  }, [trackingPath]);

  const handleTrackingPathClick = (ev) => {
    const latLng = { lat: ev.latLng.lat(), lng: ev.latLng.lng() };
    var closestNode = closestLocation(latLng, trackingData.markers);

    var index = trackingData.markers.indexOf(closestNode);

    handleTrackingNodeSelected(index);
  };

  const handleTrackingTimeSelected = (selectedTimestamp) => {
    if (!trackingData?.markers?.length) {
      handleLoadFootageTracking(selectedTimestamp);
      return;
    }

    let closestMarker = trackingData.markers.reduce((prev, curr) => {
      if (!prev) return curr;
      let prevDelta = Math.abs(
        new Date(prev.timestamp) - new Date(selectedTimestamp)
      );
      let currDelta = Math.abs(
        new Date(curr.timestamp) - new Date(selectedTimestamp)
      );
      return prevDelta < currDelta ? prev : curr;
    });

    let closestNodeIndex = trackingData.markers.indexOf(closestMarker);

    handleNewSelection({ closestNodeIndex, timestamp: selectedTimestamp });
  };

  const handleTrackingNodeSelected = (index) => {
    handleNewSelection({ closestNodeIndex: index });
  };

  const handleNewSelection = ({ closestNodeIndex, timestamp }) => {
    var node = trackingData.markers[closestNodeIndex];

    if (!node) return;

    const selectedTimestamp = timestamp ?? node.timestamp;

    handleLoadFootageTracking(selectedTimestamp);
    setExtraContentDisplay(null);
    setSelectedTrackingEdge({ index: closestNodeIndex, node });
    setShowTrackingNodeInfoWindow(true);
    setSelectedSafetyEventNode(null);
    setSelectedStop(null);

    let drive = trackingData.drives.reduce((prev, curr) => {
      return curr.start <= selectedTimestamp && curr.end >= selectedTimestamp
        ? curr
        : prev;
    }, null);

    let driveIndex = trackingData.drives.indexOf(drive);
    setSelectedDriveIndex(driveIndex >= 0 ? driveIndex : null);
  };

  const [selectedRouteMarkersPath, setSelectedRouteMarkersPath] = useState([]);
  const [selectedDriveIndex, setSelectedDriveIndex] = useState(null);

  React.useEffect(() => {
    let drive = trackingData.drives[selectedDriveIndex];

    if (!drive) {
      setSelectedRouteMarkersPath([]);
      return;
    }

    var path = trackingData.markers
      .filter(
        (marker) =>
          marker.timestamp >= drive.start && marker.timestamp <= drive.end
      )
      .map((marker) => marker.coord);

    setSelectedRouteMarkersPath(path);
  }, [trackingData, selectedDriveIndex]);

  const handleLoadFootageTracking = (timeStamp) => {
    setTimeSelected(new Date(timeStamp));

    if (!vehicleIsOnline) return;

    const tsNode = moment(timeStamp);
    var searchTrackingQuery = {
      id: vehicleId,
      source: "ALL",
      from: tsNode.format(),
      until: tsNode.add(1, "m").format(),
    };

    setFootageSearchTracking(searchTrackingQuery);
  };

  const handleCloseTrackingInfoWindow = () => {
    setShowTrackingNodeInfoWindow(false);
    setSelectedDriveIndex(null);
  };

  const handleSafetyEventNodeSelected = (node) => {
    handleLoadFootageTracking(node.occurredAt);
    setExtraContentDisplay(null);
    setShowTrackingNodeInfoWindow(false);
    setSelectedSafetyEventNode(node);

    setSelectedStop(null);
  };

  const handleCloseSafetyEventInfoWindow = () => {
    setSelectedSafetyEventNode(null);
  };

  const handleHiddenPaneResizer = (status) => {
    setHiddenPaneResizer(status);
  };

  const handleStopSelected = (stop) => {
    setShowTrackingNodeInfoWindow(false);

    if (stop.start && stop.end) {
      stop = { ...stop, from: stop.start, to: stop.end };
    }

    handleLoadFootageTracking(stop.from);
    setSelectedStop(stop);
    setExtraContentDisplay(null);
    setSelectedSafetyEventNode(null);
  };

  const handleCloseStopInfoWindow = () => {
    setSelectedStop(null);
  };

  const handlePaneResizer = () => {
    setPaneResizer(!paneResizer);
  };

  const handleCreateIncident = (value) => () => {
    const ts = moment(value);
    const start = ts.format("YYYY-MM-DDTHH:mm:ss");
    const end = ts.add(2, "m").format("YYYY-MM-DDTHH:mm");

    onCreateIncident({ start, end });
  };

  return (
    <>
      <div className={classes.rootContainer}>
        <div className={classes.containerMap}>
          <div className={classes.mapContainer}>
            <GoogleMap
              align="right"
              loadHandler={loadHandler}
              mapClassName={mapClassName}
              options={{ mapTypeControl: true }}
              defaultZoom={zoom}
              center={defaultCenter}
            >
              {mapRef && (
                <VehicleTrackingMapControls
                  initialDate={initialDate}
                  onSubmitQueryData={onSubmitQueryData}
                  expandContent={extraContentDisplay}
                  onSetExpandedContent={setExtraContentDisplay}
                  {...{
                    mapRef,
                    speedData: trackingData.markers,
                    onTrackingNodeSelected: handleTrackingNodeSelected,
                    safetyEventData,
                    onSafetyEventNodeSelected: handleSafetyEventNodeSelected,
                  }}
                />
              )}
              {selectedTrackingEdge?.node?.coord &&
                showTrackingNodeInfoWindow && (
                  <VehicleTrackingNodeInfoWindow
                    node={selectedTrackingEdge.node}
                    journey={trackingData.drives[selectedDriveIndex]}
                    onCloseTrackingInfoWindow={handleCloseTrackingInfoWindow}
                    onCreateIncident={handleCreateIncident}
                    supportsVideo={supportsVideo}
                    incidentsEnabled={incidentsEnabled}
                  />
                )}
              {safetyEventData?.totalCount > 0 &&
                safetyEventData.edges.map(({ cursor, node }) => (
                  <Marker
                    key={cursor}
                    position={node.coord}
                    icon={warning}
                    zIndex={Date.now()}
                    onClick={() => handleSafetyEventNodeSelected(node)}
                  />
                ))}
              {selectedSafetyEventNode && (
                <VehicleSafetyEventNodeInfoWindow
                  position={selectedSafetyEventNode.coord}
                  node={selectedSafetyEventNode}
                  onCloseWindow={handleCloseSafetyEventInfoWindow}
                  onCreateIncident={handleCreateIncident}
                  supportsVideo={supportsVideo}
                  incidentsEnabled={incidentsEnabled}
                />
              )}
              {selectedStop && (
                <VehicleStopInfoWindow
                  position={selectedStop.coord}
                  stop={selectedStop}
                  onCloseWindow={handleCloseStopInfoWindow}
                  onCreateIncident={handleCreateIncident}
                  supportsVideo={supportsVideo}
                  incidentsEnabled={incidentsEnabled}
                  zIndex={Date.now()}
                />
              )}
              <Polyline
                path={trackingPath}
                options={{
                  // strokeOpacity: 0.8,
                  strokeWeight: 6,
                  strokeColor: red[900],
                  icons: [
                    {
                      offset: "0%",
                      icon: {
                        path: "M -2,-2 2,2 M 2,-2 -2,2",
                        strokeColor: "#292",
                        strokeWeight: 6,
                        scale: 3,
                      },
                    },
                    {
                      repeat: "100px",
                      icon: {
                        strokeColor: "white",
                        strokeWeight: 3,
                        strokeOpacity: 1,
                        path: window.google?.maps?.SymbolPath
                          ?.FORWARD_OPEN_ARROW,
                        scale: 1.5,
                      },
                    },
                    {
                      offset: "100%",
                      icon: {
                        path: "M -2,0 0,-2 2,0 0,2 z",
                        strokeColor: "#F00",
                        fillColor: "#F00",
                        fillOpacity: 1,
                        scale: 3,
                      },
                    },
                  ],
                  // zIndex: 10
                }}
                onClick={handleTrackingPathClick}
              />
              {/* PolyLine for selectedRouteMarkers */}
              <Polyline
                path={selectedRouteMarkersPath}
                options={{
                  // strokeOpacity: 0.8,
                  strokeWeight: 6,
                  strokeColor: amber[800],
                  icons: [
                    {
                      repeat: "100px",
                      icon: {
                        strokeColor: "white",
                        strokeWeight: 3,
                        strokeOpacity: 1,
                        path: window.google?.maps?.SymbolPath
                          ?.FORWARD_OPEN_ARROW,
                        scale: 1.5,
                      },
                    },
                  ],
                }}
                onClick={handleTrackingPathClick}
              />

              {trackingData.stops.map((item, idx) => (
                <Marker
                  key={`item-${idx}`}
                  position={item.coord}
                  icon={{ url: parking }}
                  onClick={() => handleStopSelected(item)}
                />
              ))}
            </GoogleMap>
          </div>
          {supportsVideo && vehicleCameras.length > 0 && (
            <>
              {!hiddenPaneResizer && (
                <div
                  className={classNames(classes.paneresizer, {
                    [classes.active]: paneResizer,
                  })}
                  onClick={handlePaneResizer}
                ></div>
              )}

              {!isMobile && (
                <div className={classes.videoContainer}>
                  <VehicleTrackingVideos
                    {...{
                      isMobile,
                      vehicleIsOnline,
                      vehicleCameras,
                      paneResizer,
                      footageSearchTracking,
                      onHiddenPaneResizer: handleHiddenPaneResizer,
                    }}
                  />
                </div>
              )}
            </>
          )}
        </div>
        <div className={classes.mapContainerJourneyGraph}>
          <div ref={parentRef}>
            <JourneyGraph
              {...{
                speedData: trackingData.markers,
                onTimeSelected: handleTrackingTimeSelected,
                onSafetyEventSelected: handleSafetyEventNodeSelected,
                timeSelected,
                safetyEventData,
                width: width - 6,
                height: 110,
                dateSelected: initialDate,
              }}
            />
          </div>
        </div>
        {isMobile && (
          <div className={classes.videoContainerMobile}>
            <VehicleTrackingVideos
              {...{
                isMobile,
                vehicleIsOnline,
                vehicleCameras,
                paneResizer,
                footageSearchTracking,
                onHiddenPaneResizer: handleHiddenPaneResizer,
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

VehicleTrackingMap.propTypes = {
  initialDate: PropTypes.string,
  onSubmitQueryData: PropTypes.func.isRequired,
  trackingData: PropTypes.object.isRequired,
  safetyEventData: PropTypes.object.isRequired,
  onCreateIncident: PropTypes.func.isRequired,
  mapClassName: PropTypes.string,
  supportsVideo: PropTypes.bool.isRequired,
};

export default withTheme(VehicleTrackingMap);
