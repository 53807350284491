import React, { useRef, useState, useEffect } from "react";

import flvjs from "flv.js";
import Hls from "hls.js";
import {
  PlayCircleOutline as PlayIcon,
  MoreVert as MenuIcon,
} from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import { Menu, MenuItem, IconButton } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  player: {
    maxWidth: "100%",
    width: "100%",
  },
  root: {
    margin: 0,
    position: "relative",
    // display: "flex",
  },
  caption: {
    top: 0,
    position: "absolute",
    left: 0,
    right: 0,
    textAlign: "center",
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.85) 10%, rgba(0,0,0,0) 100%)",
    paddingBottom: theme.spacing(2),
    color: "#fff",
    display: "flex",
    alignItems: "center",
  },
  cationLabel: {
    flexGrow: 1,
  },
  playIconOverlay: {
    display: "grid",
    placeItems: "center",
    top: "30%",
    bottom: "30%",
    left: "35%",
    right: "35%",
    position: "absolute",
    textAlign: "center",
    background: "rgba(0,0,0,0.85)",
    borderRadius: theme.spacing(2),
    cursor: "pointer",
    zIndex: 1,
    color: "#fff",
    "&:hover": {
      background: `radial-gradient(#2e2e2e, rgba(0,0,0,0.85))`,
    },
  },
  playIcon: {
    width: "90%",
    height: "90%",
  },
  menuLeft: {
    width: "34px",
  },
}));

const rtmpPlayerSetup = (video, src, onPauseClick) => {
  let flvPlayer = flvjs.createPlayer(
    {
      type: "flv",
      url: src,
    },
    {
      isLive: true,
      enableStashBuffer: false,
    }
  );

  flvPlayer.attachMediaElement(video);
  flvPlayer.load();
  flvPlayer.play();

  const destroyPlayer = (player) => {
    player.pause();
    player.unload();
    player.detachMediaElement();
    player.destroy();
  };

  const volumnchangeEventListener = () => {
    flvPlayer.muted = true;
  };

  video.addEventListener("pause", onPauseClick);
  video.addEventListener("volumechange", volumnchangeEventListener);

  return () => {
    video.removeEventListener("pause", onPauseClick);
    video.removeEventListener("volumechange", volumnchangeEventListener);

    destroyPlayer(flvPlayer);
  };
};

const hlsPlayerSetup = (video, src, onPauseClick) => {
  let hls = new Hls({
    enableWorker: true,
    autoStartLoad: false,
  });
  hls.attachMedia(video);

  hls.on(Hls.Events.MEDIA_ATTACHED, () => {
    hls.loadSource(src);
  });
  hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
    console.log(
      "manifest loaded, found " + data.levels.length + " quality level"
    );
  });

  hls.on(Hls.Events.ERROR, (event, data) => {
    var errorType = data.type;
    var errorDetails = data.details;
    var errorFatal = data.fatal;

    console.error({ errorType, errorDetails, errorFatal });

    if (data.fatal) {
      switch (data.type) {
        case Hls.ErrorTypes.NETWORK_ERROR:
          // try to recover network error
          console.log("fatal network error encountered, try to recover");
          errorDetails === "manifestLoadTimeOut"
            ? hls.loadSource(src)
            : hls.startLoad();
          break;
        case Hls.ErrorTypes.MEDIA_ERROR:
          console.log("fatal media error encountered, try to recover");
          hls.recoverMediaError();
          break;
        default:
          console.log("fatal error encountered, can not recover");
          // cannot recover
          hls.destroy();
          hls = null;
          break;
      }
    }
  });

  hls.startLoad();

  const volumnchangeEventListener = () => {
    hls.muted = true;
  };
  video.addEventListener("pause", onPauseClick);
  video.addEventListener("volumechange", volumnchangeEventListener);

  return () => {
    video.removeEventListener("pause", onPauseClick);
    video.removeEventListener("volumechange", volumnchangeEventListener);

    hls.detachMedia();
    hls.destroy();
    hls = null;
  };
};

const VideoPlayer = ({
  label,
  sources,
  onSetCameraLocationClick,
  onPlayClick,
  onPauseClick,
  play,
}) => {
  const classes = useStyles();
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (play && video) {
      const hlsSupported = Hls.isSupported();
      const hlsNativeSupported = video.canPlayType(
        "application/vnd.apple.mpegurl"
      );
      const flvSupported = flvjs.isSupported();

      if (flvSupported) {
        const src = sources.find((x) => x.type === "rtmp")?.source;

        return rtmpPlayerSetup(video, src, onPauseClick);
      } else if (hlsNativeSupported) {
        const src = sources.find((x) => x.type === "hls")?.source;
        video.src = src;
      } else if (hlsSupported) {
        const src = sources.find((x) => x.type === "hls")?.source;
        return hlsPlayerSetup(video, src, onPauseClick);
      }
    }
  }, [play, sources]);

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const menuOpen = Boolean(menuAnchorEl);
  const handleMenuButtonClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => setMenuAnchorEl(null);

  const handleCameraLocationClick = () => {
    setMenuAnchorEl(null);
    onSetCameraLocationClick();
  };

  return (
    <figure className={classes.root}>
      {!play ? (
        <>
          <div className={classes.playIconOverlay} onClick={onPlayClick}>
            <PlayIcon className={classes.playIcon} />
          </div>
          <video poster="/live-video-poster.svg"></video>
        </>
      ) : (
        <video
          className={classes.player}
          ref={videoRef}
          poster="/live-video-poster.svg"
          controls
          muted
          autoPlay={false}
        >
          Sorry, your browser doesn't support embedded videos.
        </video>
      )}
      <figcaption className={classes.caption}>
        <div className={classes.menuLeft}></div>
        <div className={classes.cationLabel}>{label}</div>
        <div>
          <IconButton
            color="inherit"
            aria-controls={menuOpen ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen ? "true" : undefined}
            onClick={handleMenuButtonClick}
            size="small"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={menuAnchorEl}
            open={menuOpen}
            onClose={handleMenuClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleCameraLocationClick}>
              Rename camera
            </MenuItem>
          </Menu>
        </div>
      </figcaption>
    </figure>
  );
};

export default VideoPlayer;
