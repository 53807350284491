import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

import Groups from "../containers/Groups";

const styles = (theme) => ({
  root: {
    marginBottom: 56,
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
});

const GroupsPage = ({ classes }) => {
  return (
    <section className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" className={classes.marginBottom}>
        <Link color="inherit" to="/admin" component={RouterLink}>
          Admin
        </Link>
        <Typography color="textPrimary">User Groups</Typography>
      </Breadcrumbs>
      <Typography variant="h5" gutterBottom className={classes.marginBottom}>
        User groups
      </Typography>
      <Typography variant="body2" gutterBottom className={classes.marginBottom}>
        With user groups, you can organize team members, specify team
        permissons, and grant access to depots.
      </Typography>

      <Groups />
    </section>
  );
};

GroupsPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GroupsPage);
