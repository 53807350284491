import React from "react";
import withStyles from "@mui/styles/withStyles";

import TextField from "../components/form/TextField";
import Checkbox from "../components/form/Checkbox";
import { Field } from "react-final-form";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

const validateName = (value) => !value && "Name is required";

const styles = (theme) => ({
  marginTop: {
    marginTop: theme.spacing(-3),
  },
});

const CreateGroupForm = ({ classes, groupPermissions }) => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Field
            component={TextField}
            name="name"
            required
            validate={validateName}
            margin="dense"
            autoComplete="off"
            label="Name"
            fullWidth
            autoFocus
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Field
            component={Checkbox}
            name="defaultAccessToNewDepots"
            label="Default depot access"
            type="checkbox"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={12} className={classes.marginTop}>
          <Typography variant="caption">
            Members have access to new team depots
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="subtitle2">Team permissions</Typography>
        </Grid>
        <Grid item xs={12} sm={12} className={classes.marginTop}>
          <Field
            component={Checkbox}
            name="isTeamAdmin"
            label="Administer team"
            type="checkbox"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={12} className={classes.marginTop}>
          <Typography variant="caption">
            Admins can update team settings and administer account
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="subtitle2">Access permissions</Typography>
        </Grid>
        <Grid item xs={12} sm={12} className={classes.marginTop}>
          {groupPermissions.map((camera) => (
            <Field
              key={camera.value}
              id={camera.value}
              value={camera.value}
              component={Checkbox}
              name="groupPermissions"
              label={camera.name}
              type="checkbox"
              margin="normal"
            />
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(CreateGroupForm);
