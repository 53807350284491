import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";
import moment from "moment";

import AdapterLink from "../components/form/Link";

import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";

import Button from "@mui/material/Button";
import Hidden from "@mui/material/Hidden";

import OnlineStatusIcon from "../components/OnlineStatusIcon";
import DvrHealthIcon from "../components/DvrHealthIcon";
import CameraHealthIcon from "../components/CameraHealthIcon";
import VehicleTableDataUsageCell from "./VehicleTableDataUsageCell";

const styles = (theme) => ({
  dvrIcon: {
    marginRight: theme.spacing(4),
  },
  cameraIcon: {
    marginRight: theme.spacing(0.5),
  },
  gridRow: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  alternateRow: {
    background: theme.palette.background.default,
  },
  depotRow: {
    background: theme.palette.action.hover,
    fontWeight: "bolder",
  },
  onlineCount: {
    fontWeight: "normal",
  },
  onlineStatusIcon: {
    marginRight: theme.spacing(2),
  },
  verticalCenterContainer: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  flexEnd: {
    justifyContent: "flex-end",
  },
  vrmContainer: {
    marginRight: theme.spacing(2),
  },
  headerCell: {
    color: theme.palette.text.secondary,
  },
  incidentBadgeContainer: {
    marginLeft: "auto",
    marginRight: theme.spacing(1),
  },
});

const DashboardVehiclesTableHeader = ({ classes }) => (
  <Hidden smDown>
    <div className={classNames(classes.gridRow, classes.headerCell)}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          Online, Registration and Driver
        </Grid>
        <Grid item xs={6} sm={4} md={6} lg={4} xl={3}>
          DVR and camera status
        </Grid>
        <Grid item xs={6} sm={2} xl={1}>
          Data usage
        </Grid>
      </Grid>
    </div>
  </Hidden>
);

DashboardVehiclesTableHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};
const DashboardVehiclesTableHeaderWithStyles = withStyles(styles)(
  DashboardVehiclesTableHeader
);

const DashboardVehiclesTableRows = ({
  allVehicles,
  incidentsOccurredGte,
  classes,
}) => (
  <div>
    {allVehicles.edges.map(({ cursor, node }, idx) => (
      <div
        key={cursor}
        className={classNames(classes.gridRow, {
          [classes.alternateRow]: idx % 2 !== 0,
        })}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <div className={classes.verticalCenterContainer}>
              <OnlineStatusIcon
                online={node.live.online}
                className={classes.onlineStatusIcon}
              />
              <div className={classes.vrmContainer}>
                <Button to={`/vehicles/${node.id}`} component={AdapterLink}>
                  {node.vrm}
                </Button>
              </div>
              <div className={classes.driverContainer}>
                {node.driver && node.driver.name}
              </div>
              {Boolean(node.incidents.totalCount) && (
                <div className={classes.incidentBadgeContainer}>
                  <Tooltip
                    title={`${node.incidents.totalCount} incident${
                      node.incidents.totalCount === 1 ? "" : "s"
                    } since ${moment(incidentsOccurredGte).format("LLL")}`}
                  >
                    <IconButton
                      component={AdapterLink}
                      to={`/incidents/?vehicle=${
                        node.id
                      }&occurredAtGte=${moment(incidentsOccurredGte).format(
                        "YYYY-MM-DD"
                      )}`}
                      size="large"
                    >
                      <Badge
                        className={classes.incidentBadge}
                        badgeContent={node.incidents.totalCount}
                        color="primary"
                      >
                        <Icon>notification_important</Icon>
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={6} sm={4} md={6} lg={4} xl={3}>
            <div className={classNames(classes.verticalCenterContainer)}>
              {node.hasVideoSupport ? (
                <>
                  <DvrHealthIcon
                    dvr={node.device.health.dvr}
                    className={classes.dvrIcon}
                  />
                  <div>
                    {node.device.health.cameras.map((c, idx) => (
                      <CameraHealthIcon
                        key={`camera${idx}`}
                        className={classes.cameraIcon}
                        camera={c}
                      />
                    ))}
                  </div>
                </>
              ) : (
                "-"
              )}
            </div>
          </Grid>
          <Grid item xs={6} sm={2} xl={1}>
            <div
              className={classNames(
                classes.verticalCenterContainer,
                classes.flexEnd
              )}
            >
              <VehicleTableDataUsageCell
                monthlyAllocation={node.device.dataUsage.monthlyAllocation}
                totalUsed={node.device.dataUsage.monthToDate.total}
                lastUpdated={node.device.dataUsage.monthToDate.lastUpdated}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    ))}
  </div>
);

DashboardVehiclesTableRows.propTypes = {
  allVehicles: PropTypes.object.isRequired,
  incidentsOccurredGte: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

const DashboardVehiclesTableRowsWithStyles = withStyles(styles)(
  DashboardVehiclesTableRows
);

const DashboardVehiclesTable = ({
  allVehicles,
  incidentsOccurredGte,
  classes,
}) => (
  <>
    <DashboardVehiclesTableHeaderWithStyles classes={classes} />
    <DashboardVehiclesTableRowsWithStyles
      {...{ allVehicles, incidentsOccurredGte, classes }}
    />
  </>
);

DashboardVehiclesTable.propTypes = {
  allVehicles: PropTypes.object.isRequired,
  incidentsOccurredGte: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

const DashboardVehiclesTableWithStyles = withStyles(styles)(
  DashboardVehiclesTableRows
);

const DashboardVehiclesDepotHeaderRow = ({ depot, classes }) => (
  <div
    className={classNames(
      classes.gridRow,
      classes.headerCell,
      classes.depotRow
    )}
  >
    <Grid container spacing={2}>
      <Grid item xs={10} sm={10} md={8} lg={10}>
        {depot.name}{" "}
        <small className={classes.onlineCount}>
          ({depot.vehicles.edges.filter(({ node }) => node.live.online).length}/
          {depot.vehicles.totalCount})
        </small>
      </Grid>
      {/* <Grid item xs={2} sm={2} lg={2}>
        
      </Grid> */}
    </Grid>
  </div>
);

DashboardVehiclesDepotHeaderRow.propTypes = {
  classes: PropTypes.object.isRequired,
};
const DashboardVehiclesDepotHeaderRowWithStyles = withStyles(styles)(
  DashboardVehiclesDepotHeaderRow
);

export {
  DashboardVehiclesDepotHeaderRowWithStyles as DashboardVehiclesDepotHeaderRow,
  DashboardVehiclesTableWithStyles as DashboardVehiclesTable,
  DashboardVehiclesTableRowsWithStyles as DashboardVehiclesTableRows,
  DashboardVehiclesTableHeaderWithStyles as DashboardVehiclesTableHeader,
};

// export default withStyles(styles)(DashboardVehiclesTable);
