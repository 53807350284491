import React, { Fragment } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import moment from "moment";
import withStyles from "@mui/styles/withStyles";

import CLAIM_FRAGMENT from "../fragments/claim";

import { mapToDisplay } from "../ClaimStatus";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import AnchorLink from "@mui/material/Link";

const QUERY = gql`
  query Node($id: ID!) {
    node(id: $id) {
      ...claim
    }
  }

  ${CLAIM_FRAGMENT}
`;

export { QUERY };

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  status: {
    textAlign: "right",
  },
});

class Incident extends React.Component {
  render() {
    const { id, classes } = this.props;

    return (
      <Fragment>
        <Query query={QUERY} variables={{ id }}>
          {({ loading, error, data }) => {
            if (loading) return <LinearProgress />;
            if (error) return JSON.stringify(error);

            const { node: claim } = data;

            return (
              <Fragment>
                <Typography variant="h5" gutterBottom>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      Claim: <b>{claim.reference}</b>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.status}>
                      {mapToDisplay(claim.status)}
                    </Grid>
                  </Grid>
                </Typography>

                <Paper className={classes.paper}>
                  <p>
                    Incident occured: {moment(claim.occurredAt).format("LLL")}
                  </p>
                  <p>Location: {claim.location}</p>
                  <Grid spacing={2} container>
                    <Grid item xs={12} sm={6}>
                      <p>Vehicle: {claim.vehicle.vrm}</p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p>
                        Driver:{" "}
                        {claim.driver && (
                          <span>
                            {claim.driver.name}
                            {claim.driver.email && (
                              <AnchorLink href={`mailto:${claim.driver.email}`}>
                                <br />
                                {claim.driver.email}
                              </AnchorLink>
                            )}
                            {claim.driver.telephone && (
                              <AnchorLink
                                href={`tel:${claim.driver.telephone}`}
                              >
                                <br />
                                {claim.driver.telephone}
                              </AnchorLink>
                            )}
                          </span>
                        )}
                      </p>
                    </Grid>
                  </Grid>
                </Paper>
                {claim.file && (
                  <Paper className={classes.paper}>
                    <p>Filed: {moment(claim.file.filedAt).format("LLL")}</p>
                    <p>
                      <label>Recipients:</label>{" "}
                      {claim.file.recipients.join("; ")}
                    </p>
                    <Button
                      component={"a"}
                      href={claim.file.src}
                      target="_blank"
                      variant="contained"
                      color="secondary"
                    >
                      View filed form
                    </Button>
                  </Paper>
                )}
              </Fragment>
            );
          }}
        </Query>
      </Fragment>
    );
  }
}

Incident.propTypes = {
  id: PropTypes.string.isRequired,
};

export default withStyles(styles)(Incident);
