import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import VehicleTrackingVideoPlayer from "../containers/VehicleTrackingVideoPlayer";
import IvmsWebSocketVideoPlayer from "../ivms-player/WebSocketVideoPlayer";
import { mapToDisplay } from "../CameraNames";

const useStyles = makeStyles((theme) => ({
  videoitem: {
    margin: 0,
    position: "relative",
    backgroundColor: "#767676",
    height: "200px",
    [theme.breakpoints.up("sm")]: {
      height: "100%",
    },
  },
  videoCationLabel: {
    color: "#ffff",
    textAlign: "center",
    marginTop: "10px",
    top: 0,
    left: 0,
    right: 0,
    position: "absolute",
  },
  videoOffLabel: {
    color: "#c5c5c5",
    bottom: "35%",
    width: "100%",
    position: "absolute",
    textAlign: "center",
    fontWeight: "600",
    fontSize: "20px",
  },
}));

const isSupportWebSocket = (footages) => {
  return footages?.node?.streams?.some((x) => x.type === "websocket");
};

const VehicleTrackingVideoStream = ({
  isDeviceOnline,
  cameraVideo,
  footageTracking,
}) => {
  const classes = useStyles();
  const [camerasTracking, setCamerasTracking] = useState();
  const [playing, setPlaying] = useState({});
  const handlePlayClick = (idx) => {
    setPlaying((prev) => ({ ...prev, [idx]: true }));
  };

  const handlePauseClick = (idx) => {
    setPlaying((prev) => ({ ...prev, [idx]: false }));
  };

  if (footageTracking && cameraVideo) {
    const cameraVideosMap = cameraVideo.map((item) => {
      return {
        ...item,
        footages: footageTracking.filter(
          (x) => x.node.cameraId == item.cameraId
        ),
      };
    });

    useEffect(() => setCamerasTracking(cameraVideosMap), []);
  }
  return (
    <>
      {cameraVideo.map((video, idx) => (
        <div className={classes.videoitem} key={`video-${idx}`}>
          {camerasTracking &&
            camerasTracking[idx].footages[0] &&
            (isSupportWebSocket(camerasTracking[idx].footages[0]) ? (
              <IvmsWebSocketVideoPlayer
                sources={camerasTracking[idx].footages[0]?.node?.streams}
                play={playing[idx]}
                onPlayClick={() => handlePlayClick(idx)}
                onPauseClick={() => handlePauseClick(idx)}
              />
            ) : (
              <VehicleTrackingVideoPlayer
                sources={camerasTracking[idx].footages[0]?.node?.streams}
                play={playing[idx]}
                onPlayClick={() => handlePlayClick(idx)}
                onPauseClick={() => handlePauseClick(idx)}
              />
            ))}

          <div className={classes.videoCationLabel}>
            {mapToDisplay(video.camera)}
          </div>
          {!isDeviceOnline && (
            <div className={classes.videoOffLabel}>Vehicle is offline</div>
          )}

          {isDeviceOnline &&
            (!camerasTracking ||
              (!camerasTracking[idx].footages[0] && (
                <div className={classes.videoOffLabel}>Not found footage</div>
              )))}
        </div>
      ))}
    </>
  );
};

VehicleTrackingVideoStream.propTypes = {
  cameraVideo: PropTypes.array.isRequired,
};

export default VehicleTrackingVideoStream;
