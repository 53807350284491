import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import VideoPlayer from "../containers/RecordedVideoPlayer";

const useStyles = makeStyles({
  html5Video: {
    maxWidth: "100%",
  },
});

const VehicleStreamRecordedFootageDialog = ({ open, onClose, stream }) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={Boolean(open)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title" sx={{ m: 0, p: 2 }}>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 2,
              top: 2,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        {stream && (
          <VideoPlayer label={stream.label} sources={stream.sources} />
        )}
      </DialogContent>
    </Dialog>
  );
};

VehicleStreamRecordedFootageDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  stream: PropTypes.object,
};

export default VehicleStreamRecordedFootageDialog;
