import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import { FORM_ERROR } from "final-form";

import { Form } from "react-final-form";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";

import CreateGroupForm from "../components/CreateGroupForm";
import { useHistory } from "react-router-dom";
import GroupPermissions from "./GroupPermissions";

// FIXME checkout https://mui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
const withMobileDialog = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="lg" fullScreen={false} />;

const ADD_USERGROUP = gql`
  mutation AddUserGroup($input: AddUserGroupInputType!) {
    account {
      userGroups {
        add(input: $input) {
          error {
            code
            message
          }
          userGroup {
            id
            name
          }
        }
      }
    }
  }
`;

const CreateGroupDialog = ({ open, fullScreen, handleClose }) => {
  const history = useHistory();
  const [addUserGroup] = useMutation(ADD_USERGROUP);

  const handleFormSubmit = (form) => {
    const variables = { input: { ...form } };

    return addUserGroup({ variables }).then(
      ({
        data: {
          account: {
            userGroups: {
              add: { error, userGroup },
            },
          },
        },
      }) => {
        if (error !== null) {
          const message = `${error.message} (${error.code})`;
          return {
            [FORM_ERROR]: message,
          };
        }
        history.push(`/admin/groups/${userGroup.id}`);
      }
    );
  };

  const groupPermissions = GroupPermissions();

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <Form
        onSubmit={handleFormSubmit}
        initialValues={{}}
        render={({ handleSubmit, submitting, submitError }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">Add user group</DialogTitle>
            <DialogContent>
              <CreateGroupForm groupPermissions={groupPermissions} />
              {submitError && (
                <FormHelperText error={true}>{submitError}</FormHelperText>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                disabled={submitting}
                onClick={handleClose}
                color="primary"
              >
                Cancel
              </Button>
              <Button disabled={submitting} type="submit" color="primary">
                Save
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

CreateGroupDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withMobileDialog()(CreateGroupDialog);
