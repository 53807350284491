import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import ordinal from "ordinal";

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  card: {
    marginBottom: theme.spacing(2)
  }
});

const IncidentWitness = ({ value, classes }) => (
  <div>
    {value.witnesses.length === 0 && <p>Witness not entered</p>}
    {value.witnesses.length !== 0 &&
      value.witnesses.map((x, idx) => (
        <Card key={`wit-${idx}`} className={classes.card}>
          <CardHeader title={`${ordinal(idx + 1)} witness`} />
          <CardContent>
            <Grid container spacing={2}>
              {x.name && (
                <Grid item md={4}>
                  Name: {x.name}
                </Grid>
              )}
              {x.contactNumber && (
                <Grid item md={4}>
                  Contact number: {x.contactNumber}
                </Grid>
              )}
              {x.emailAddress && (
                <Grid item md={4}>
                  Email address: {x.emailAddress}
                </Grid>
              )}
              {x.address && (
                <Grid item md={4}>
                  Address: {x.address}
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      ))}
  </div>
);

IncidentWitness.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(IncidentWitness);
