import React from "react";
import PropTypes from "prop-types";

import { Form } from "react-final-form";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormHelperText from "@mui/material/FormHelperText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import CloseDepotForm from "../components/CloseDepotForm";
import { DialogContentText } from "@mui/material";

// FIXME checkout https://mui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
const withMobileDialog = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="lg" fullScreen={false} />;

const CloseDepotDialog = ({
  open,
  handleClose,
  handleFormSubmit,
  depotId,
  errorCode,
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
    maxWidth="xs"
    fullWidth
    aria-labelledby="form-dialog-title"
  >
    <Form
      onSubmit={handleFormSubmit}
      // initialValues={initialFormValues}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">Close depot</DialogTitle>

          <DialogContent>
            <DialogContentText>
              Close this depot and move any vehicles in it to the selected depot
            </DialogContentText>
            <CloseDepotForm errorCode={errorCode} depotId={depotId} />
            {errorCode && (
              <FormHelperText error={true}>
                {"Must select a different depot to move vehicles to"}
              </FormHelperText>
            )}
          </DialogContent>
          <DialogActions>
            <Button disabled={submitting} onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button disabled={submitting} type="submit" color="primary">
              Close
            </Button>
          </DialogActions>
        </form>
      )}
    />
  </Dialog>
);

CloseDepotDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withMobileDialog()(CloseDepotDialog);
