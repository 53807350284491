import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";

import { Field } from "react-final-form";
import Grid from "@mui/material/Grid";
import TextField from "../components/form/TextField";
import DriverSelect from "../containers/DriverSelect";
import VehicleSelect from "../containers/VehicleSelect";
import RequestDriverAccountButton from "../containers/RequestDriverAccountButton";
import Divider from "@mui/material/Divider";

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

const validateRequired = (fieldName) => (value) =>
  !value && `${fieldName} is required`;

const IncidentCoreDetailsForm = ({ incident, values, setFieldValue }) => (
  <div>
    <Grid spacing={2} container>
      <Grid item xs={12} sm={6}>
        <VehicleSelect
          name="vehicle.vehicleId"
          required
          defaultValue={incident.vehicle}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DriverSelect
          allowNew
          name="driver.driverId"
          onNewDriverCreated={(value) =>
            setFieldValue("driver.driverId", value)
          }
        />
        {incident.driverId && !incident.driverAccount.requestedOn && (
          <RequestDriverAccountButton
            disabled={
              incident.driverId !== (values.driver && values.driver.driverId)
            }
            incidentId={incident.id}
            accountRequestedOn={incident.driverAccount.requestedOn}
          />
        )}
      </Grid>
    </Grid>
    <Divider />
    <Grid spacing={2} container>
      <Grid item xs={6} sm={6}>
        <Field
          component={TextField}
          name="incident.incidentDate"
          validate={validateRequired("Incident date")}
          margin="normal"
          required
          label="Incident date"
          InputLabelProps={{
            shrink: true,
          }}
          type="date"
          fullWidth
        />
      </Grid>
      <Grid item xs={6} sm={6}>
        <Field
          component={TextField}
          name="incident.incidentTime"
          validate={validateRequired("Incident time")}
          margin="normal"
          required
          label="Incident time"
          InputLabelProps={{
            shrink: true,
          }}
          type="time"
          fullWidth
        />
      </Grid>
    </Grid>

    <Field
      component={TextField}
      name="incident.location"
      margin="normal"
      label="Location"
      fullWidth
    />
    <Divider />
    <Field
      component={TextField}
      name="account.detailsOfIncident"
      margin="normal"
      multiline
      minRows={2}
      maxRows={10}
      label="Details of incident"
      placeholder="Give a brief account of the incident"
      fullWidth
    />
    <Grid spacing={2} container>
      <Grid item xs={12} sm={6}>
        <Field
          component={TextField}
          name="account.vehicleSpeed"
          margin="normal"
          type="number"
          inputProps={{ min: 0, max: 100 }}
          label="Vehicle speed"
          placeholder="MPH"
          fullWidth
        />
      </Grid>
    </Grid>
  </div>
);

IncidentCoreDetailsForm.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IncidentCoreDetailsForm);
