import React, { Fragment } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import withStyles from "@mui/styles/withStyles";

import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

import List from "../components/VehicleList";

const QUERY = gql`
  query VehicleList {
    vehicles {
      all(first: 500, filter: {}) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            id
            vrm
          }
        }
      }
    }
  }
`;

const styles = (theme) => ({
  loading: {
    marginBottom: theme.spacing(2),
  },
});

class VehicleList extends React.Component {
  state = { q: "" };

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Query query={QUERY} variables={{}} fetchPolicy="cache-and-network">
          {({ loading, error, data }) => {
            if (loading && (!data || !data.vehicles.all))
              return <LinearProgress className={classes.loading} />;
            if (error) return JSON.stringify(error);

            return (
              <Fragment>
                {loading && <LinearProgress className={classes.loading} />}
                <List allVehicles={data.vehicles.all} />
                {data.vehicles.all.totalCount === 0 && (
                  <Typography component="p">No incidents found.</Typography>
                )}
              </Fragment>
            );
          }}
        </Query>
      </Fragment>
    );
  }
}

VehicleList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VehicleList);
