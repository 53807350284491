import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";

import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import UsersIcon from "@mui/icons-material/Person";
import VehiclesIcon from "@mui/icons-material/LocalShipping";
import DriversIcon from "@mui/icons-material/PersonPin";
import DepotsIcon from "@mui/icons-material/DeviceHub";
import IntegrationsIcon from "@mui/icons-material/Extension";
import GroupIcon from "@mui/icons-material/Group";
import PolicyIcon from "@mui/icons-material/Policy";
import Paper from "@mui/material/Paper";

const styles = (theme) => ({
  itemContainer: {
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  icon: {
    fontSize: 56,
  },
});

const AdminPage = ({ classes }) => {
  const pages = [
    {
      routeTo: "/admin/vehicles",
      icon: VehiclesIcon,
      title: "Vehicles",
    },
    {
      routeTo: "/admin/drivers",
      icon: DriversIcon,
      title: "Drivers",
    },
    {
      routeTo: "/admin/depots",
      icon: DepotsIcon,
      title: "Depots",
    },
    {
      routeTo: "/admin/integrations",
      icon: IntegrationsIcon,
      title: "Integrations",
    },
    {
      routeTo: "/admin/members",
      icon: UsersIcon,
      title: "Members",
    },
    {
      routeTo: "/admin/groups",
      icon: GroupIcon,
      title: "Groups",
    },
    {
      routeTo: "/admin/insurer-policy",
      icon: PolicyIcon,
      title: "Insurer policy",
    },
  ];

  return (
    <section>
      <Typography variant="h5" gutterBottom>
        Admin
      </Typography>

      <Grid container spacing={2}>
        {pages.map(({ routeTo, title, icon: Icon }) => (
          <Grid key={routeTo} item xs={6} sm={4} md={3} lg={2}>
            <Paper elevation={0}>
              <Link
                component={RouterLink}
                className={classes.link}
                to={routeTo}
              >
                <div className={classes.itemContainer}>
                  <Icon className={classes.icon} />
                  <Typography variant="subtitle1">{title}</Typography>
                </div>
              </Link>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </section>
  );
};

AdminPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminPage);
