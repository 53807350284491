import React from "react";
import withStyles from "@mui/styles/withStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";

const styles = () => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: "600px",
  },
  tableWrapper: {
    overflowX: "auto",
  },
  actionsCell: {
    textAlign: "right",
  },
});

const VehicleAdminList = ({
  classes = {},
  allVehicles,
  handleEditClick,
  onMoveDepot,
}) => (
  <div className={classes.root}>
    <div className={classes.tableWrapper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Driver</TableCell>
            <TableCell>Device</TableCell>
            <TableCell>Depot</TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allVehicles.edges.map(({ cursor, node: vehicle }) => (
            <TableRow key={cursor} hover>
              <TableCell variant="head">{vehicle.vrm}</TableCell>
              <TableCell>{(vehicle.driver || []).name}</TableCell>
              <TableCell>{vehicle.deviceId}</TableCell>
              <TableCell>
                {vehicle.depot && (
                  <Tooltip title="Move depot">
                    <Button
                      onClick={() => onMoveDepot(vehicle.id, vehicle.depot.id)}
                      variant="text"
                    >
                      {vehicle.depot.name}
                    </Button>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell className={classes.actionsCell}>
                <Tooltip title="Edit this vehicle">
                  <IconButton
                    onClick={() => handleEditClick(vehicle)}
                    size="large"
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  </div>
);

export default withStyles(styles)(VehicleAdminList);
