import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import withStyles from "@mui/styles/withStyles";
import { useQuery, useMutation } from "@apollo/client";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";

import LiveShareTrackingsList from "../components/LiveShareTrackingsList";
import CreateLiveShareTrackingFab from "../components/CreateLiveShareTrackingFab";

const LIVE_SHARE_TRACKINGS_QUERY = gql`
  {
    liveShareTrackings {
      all {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            id
            tenantId
            accessStart
            accessEnd
            permission {
              vehicles {
                id
                vrm
              }
            }
            externalLink
            isExpired
          }
        }
      }
    }
  }
`;

const CREATE_LIVE_SHARE_TRACKING = gql`
  mutation Create($input: CreateLiveShareTrackingInputType!) {
    liveShareTrackings {
      create(liveShareTracking: $input) {
        liveShareTracking {
          id
          externalLink
        }
        error {
          code
          message
        }
      }
    }
  }
`;

const REMOVE_LIVE_SHARE_TRACKING = gql`
  mutation RemoveLiveShareTracking($id: ID!) {
    liveShareTrackings {
      remove(id: $id) {
        error {
          code
          message
        }
      }
    }
  }
`;

const styles = (theme) => ({
  fab: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  error: {
    color: "red",
  },
});

const LiveShareTrackings = ({ classes }) => {
  const [liveShareTrackingId, setLiveShareTrackingId] = useState();

  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);
  const [deleteError, setDeleteError] = useState();

  const [copyTitleTooltip, setCopyTitleTooltip] = useState("Click to copy");

  const { loading, error, data } = useQuery(LIVE_SHARE_TRACKINGS_QUERY, {
    fetchPolicy: "cache-and-network",
  });
  const [remove, { loading: updating }] = useMutation(
    REMOVE_LIVE_SHARE_TRACKING,
    {
      refetchQueries: () => [
        {
          query: LIVE_SHARE_TRACKINGS_QUERY,
        },
      ],
    }
  );
  const [createLiveShareTracking] = useMutation(CREATE_LIVE_SHARE_TRACKING);

  var liveShareTrackings = data && data?.liveShareTrackings?.all?.edges;

  if (loading && !data) return <LinearProgress className={classes.loading} />;

  if (error) return JSON.stringify(error);

  const handleCopyClick = (link) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setCopyTitleTooltip("Link copied");
        setTimeout(() => {
          setCopyTitleTooltip("Click to copy");
        }, 1500);
      })
      .catch((error) => {
        setCopyTitleTooltip("Copying failed", error);
      });
  };

  const handleCreateFormSubmit = (variables) => {
    createLiveShareTracking({
      variables,
      refetchQueries: [
        {
          query: LIVE_SHARE_TRACKINGS_QUERY,
        },
      ],
    })
      .then(() => {
        //   setCreateError(null);
        handleCreateDialogClick();
      })
      .catch((error) => {
        const message = `${error.message} (${error.code})`;
        // setCreateError(message);
        throw error;
      });
  };

  const handleCreateDialogClick = () => {
    setCreateDialogOpen((prevOpen) => !prevOpen);
  };

  const handleOpenDeleteDialog = (id) => {
    setLiveShareTrackingId(id);
  };

  const handleCloseDeleteDialog = () => {
    setLiveShareTrackingId(null);
    setDeleteError(null);
  };

  const handleRemoveLiveShareTracking = () => {
    const variables = { id: liveShareTrackingId };
    remove({ variables }).then(
      ({
        data: {
          liveShareTrackings: {
            remove: { error },
          },
        },
      }) => {
        if (error !== null) {
          const message = `${error.message} (${error.code})`;
          setDeleteError(message);
        } else {
          setLiveShareTrackingId(null);
        }
      }
    );
  };

  return (
    <Fragment>
      <LiveShareTrackingsList
        data={liveShareTrackings}
        handleCopyClick={handleCopyClick}
        copyTitleTooltip={copyTitleTooltip}
        onOpenDeleteDialog={handleOpenDeleteDialog}
      />
      <CreateLiveShareTrackingFab
        className={classes.fab}
        isCreateDialogOpen={isCreateDialogOpen}
        handleCreateDialogClick={handleCreateDialogClick}
        handleCreateFormSubmit={handleCreateFormSubmit}
      />

      <Dialog
        open={Boolean(liveShareTrackingId)}
        onClose={handleCloseDeleteDialog}
      >
        <DialogTitle>Remove live share tracking</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Confirm you wish to remove this live share tracking.
          </DialogContentText>
          {deleteError && (
            <DialogContentText className={classes.error}>
              {deleteError}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDeleteDialog}
            color="primary"
            disabled={updating}
          >
            Cancel
          </Button>
          <Button
            onClick={handleRemoveLiveShareTracking}
            color="primary"
            autoFocus
            disabled={updating}
          >
            Yes, remove
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

LiveShareTrackings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LiveShareTrackings);
