import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import classNames from "classnames";

import { OK, UNKNOWN, isErrored, mapToDisplay } from "../DvrHealth";

import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

import { orange, green, grey } from '@mui/material/colors';

const styles = () => ({
  unknownIcon: {
    color: grey[400]
  },
  okIcon: {
    color: green[300]
  },
  errorIcon: {
    color: orange[500]
  }
});

const DvrHealthIcon = ({ classes, className, dvr: { status } }) => {
  const tip = `DVR health: ${mapToDisplay(status)}`;
  // (
  //   <span>
  //     <b>{camera}</b>: {mapToDisplay(status)} for {moment(since).fromNow(true)}{" "}
  //     (last updated: {moment(asAt).format("lll")})
  //   </span>
  // );
  return (
    <Tooltip title={tip}>
      <Icon
        className={classNames(className, {
          [classes.okIcon]: status === OK,
          [classes.unknownIcon]: status === UNKNOWN,
          [classes.errorIcon]: isErrored(status)
        })}
      >
        fiber_dvr
      </Icon>
    </Tooltip>
  );
};

DvrHealthIcon.propTypes = {
  dvr: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default withStyles(styles)(DvrHealthIcon);
