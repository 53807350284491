import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import introspectionResult from "./introspection-result";

const { REACT_APP_API_ENDPOINT, REACT_APP_VERSION, REACT_APP_CLIENT_NAME } =
  process.env;

const baseEndpoint = `${REACT_APP_API_ENDPOINT}${
  REACT_APP_API_ENDPOINT.endsWith("/") ? "" : "/"
}`;

const endpointUri = `${baseEndpoint}graphql`;

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem("id_token");
  operation.setContext({
    headers: {
      authorization: `Bearer ${token}`,
      "client-name": REACT_APP_CLIENT_NAME,
      "client-version": REACT_APP_VERSION,
    },
  });

  return forward(operation);
});

const errorLoggingLink = onError((error) => {
  console.error("apollo http error", error);
});

const logoutLink = onError(({ networkError }) => {
  if (networkError && networkError.statusCode === 401) {
    // logout();
    console.warn("401 Unauthorized error. TODO: Sign out");
  }
});

const cache = new InMemoryCache({
  ...introspectionResult,
  typePolicies: {
    Vehicle: {
      fields: {
        live: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
      },
    },
    DeviceType: {
      merge: true,
    },
    IncidentGroupQuery: {
      merge: true,
    },
    VehicleGroupQuery: {
      merge: true,
    },
    AccountGroupQuery: {
      merge: true,
    },
    UserGroupDepotsType: {
      merge: true,
    },
    IncidentGroupMutation: {
      merge: true,
    },
    MemberConnection: {
      merge: true,
    },
    NearMissGroupQuery: {
      merge: true,
    },
  },
});

const httpLink = new HttpLink({
  uri: endpointUri,
});

const resolvers = {};

const client = new ApolloClient({
  cache,
  resolvers,
  link: from([logoutLink, errorLoggingLink, authMiddleware, httpLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
});

export default client;
