import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

import Depots from "../containers/Depots";

const styles = (theme) => ({
  root: {
    marginBottom: 56,
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
});

const DepotsPage = ({ classes }) => (
  <section className={classes.root}>
    <Breadcrumbs aria-label="breadcrumb" className={classes.marginBottom}>
      <Link color="inherit" to="/admin" component={RouterLink}>
        Admin
      </Link>
      <Typography color="textPrimary">Depots</Typography>
    </Breadcrumbs>
    <Typography variant="h5" gutterBottom>
      Depots
    </Typography>

    <Depots />
  </section>
);

DepotsPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DepotsPage);
