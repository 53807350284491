import React from "react";
import PropTypes from "prop-types";
import { GoogleMap } from "@react-google-maps/api";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import CircularProgress from "@mui/material/CircularProgress";

const mapRender = (status) => {
  if (status === Status.LOADING)
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress variant="indeterminate" />
        <div style={{ marginTop: "16px" }}>Loading map</div>
      </div>
    );
  if (status === Status.FAILURE)
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ marginTop: "16px", color: "#ccc" }}>
          Failed to load map
        </div>
      </div>
    );
  return null;
};

const { REACT_APP_GOOGLE_MAPS_API_KEY: mapsApiKey } = process.env;

export const DEFAULT_CENTER = { lat: 53.15, lng: -1.58 };
const DEFAULT_ZOOM = 6;

const MapWithProps = ({
  children,
  loadHandler,
  defaultZoom,
  defaultCenter,
  center,
  options,
  handleClick,
  handleZoomChanged,
  handleDragEnd,
  ...rest
}) => (
  <Wrapper
    apiKey={mapsApiKey}
    render={mapRender}
    libraries={["places"]} // , "geometry", "drawing"
  >
    <GoogleMap
      id="live-map"
      mapContainerStyle={{
        height: "100%",
        minHeight: "460px",
      }}
      onLoad={loadHandler}
      zoom={defaultZoom || DEFAULT_ZOOM}
      center={center || DEFAULT_CENTER}
      onClick={handleClick}
      onZoomChanged={handleZoomChanged}
      onDragEnd={handleDragEnd}
      options={{
        mapTypeControl: false,
        streetViewControl: false,
        ...options,
      }}
      {...rest}
    >
      {children}
    </GoogleMap>
  </Wrapper>
);

MapWithProps.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onClick: PropTypes.func,
  center: PropTypes.any,
};

export default MapWithProps;
