import React from "react";
import PropTypes from "prop-types";

import DriverSelect from "../containers/DriverSelect";
import TextField from "../components/form/TextField";
import { Field } from "react-final-form";

const validateVrm = (value) => !value && "VRM is required";
const validateVin = (value) =>
  !value || value.length === 17 ? null : "VIN must be 17 characters";

const VehicleForm = ({ setFieldValue }) => {
  return (
    <div>
      <Field
        component={TextField}
        name="vrm"
        required
        validate={validateVrm}
        margin="dense"
        autoComplete="off"
        label="VRM"
        fullWidth
        autoFocus
      />
      <Field
        component={TextField}
        name="vin"
        validate={validateVin}
        margin="dense"
        autoComplete="off"
        label="VIN"
        fullWidth
      />

      <DriverSelect
        allowNew
        onNewDriverCreated={(value) => setFieldValue("driverId", value)}
        name="driverId"
      />
    </div>
  );
};

VehicleForm.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
};

export default VehicleForm;
