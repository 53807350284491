import React, { useState } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import qs from "querystringify";
import { useLocation, useParams } from "react-router-dom";

import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Incident from "../containers/Incident";

const styles = (theme) => ({
  close: {
    padding: theme.spacing(0.5),
  },
});

const IncidentPage = ({ classes }) => {
  const { search } = useLocation();
  const { id: incidentId } = useParams();

  const [newSnackbarOpen, setNewSnackbarOpen] = useState(
    qs.parse(search).new !== undefined
  );

  function handleClose(_, reason) {
    if (reason === "clickaway") return;

    setNewSnackbarOpen(false);
  }

  return (
    <section>
      <Incident id={incidentId} />
      <Snackbar
        open={newSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">Incident created</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={handleClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </section>
  );
};

IncidentPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IncidentPage);
