import React, { Fragment } from "react";
import gql from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";

import { FORM_ERROR } from "final-form";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

import CreateDepotDialog from "../containers/CreateDepotDialog";

const CREATE_DEPOT = gql`
  mutation Create($input: CreateDepotInputType!) {
    depots {
      create(depot: $input) {
        depot {
          id
          closed
          name
        }
        error {
          code
          message
        }
      }
    }
  }
`;

const DEPOTS_QUERY = gql`
  query AllDepots {
    depots {
      all {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            id
            name
            vehicles {
              totalCount
              pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
              }
              edges {
                cursor
                node {
                  id
                  vrm
                  live {
                    online
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

class CreateDepotFab extends React.Component {
  state = {
    open: false,
  };

  render() {
    const { className } = this.props;
    const { open } = this.state;

    return (
      <Fragment>
        <Fab
          color="secondary"
          className={className}
          onClick={this.handleOpen.bind(this)}
        >
          <AddIcon />
        </Fab>
        <Mutation
          mutation={CREATE_DEPOT}
          refetchQueries={(data) => [
            {
              query: DEPOTS_QUERY,
            },
          ]}
        >
          {(create, { data }) => (
            <CreateDepotDialog
              open={open}
              handleSubmit={this.handleSubmit(create)}
              handleClose={this.handleClose.bind(this)}
            />
          )}
        </Mutation>
      </Fragment>
    );
  }

  handleSubmit = (create) => (form) => {
    const variables = { input: { ...form } };

    return create({ variables }).then(
      ({
        data: {
          depots: {
            create: { error },
          },
        },
      }) => {
        if (error !== null) {
          const message = `${error.message} (${error.code})`;
          return { [FORM_ERROR]: message };
        }
        this.handleClose();
      }
    );
  };

  handleOpen() {
    this.setState((_) => ({ open: true }));
  }

  handleClose() {
    this.setState((_) => ({ open: false }));
  }
}

export default CreateDepotFab;
