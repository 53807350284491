import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";
import Policies from "../containers/Policies";

const styles = (theme) => ({
  fab: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
});

const PolicyAdminPage = ({ classes }) => (
  <section>
    <Breadcrumbs aria-label="breadcrumb" className={classes.marginBottom}>
      <Link color="inherit" to="/admin" component={RouterLink}>
        Admin
      </Link>
      <Typography color="textPrimary">Insurer policy</Typography>
    </Breadcrumbs>
    <Typography variant="h5" gutterBottom>
      Insurer policy
    </Typography>

    <Policies />
  </section>
);

PolicyAdminPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PolicyAdminPage);
