import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";

import DefaultLayout from "../components/DefaultLayout";
import LiveMap from "../containers/LiveMap";

const styles = (theme) => ({
  root: {
    margin: theme.spacing(-3),
  },
  map: {
    // height: "400px",
    height: "calc(100vh - 64px);",
  },
});

const LiveMapPage = ({ classes }) => {
  return (
    <DefaultLayout>
      <div className={classes.root}>
        <div className={classes.map}>
          <LiveMap />
        </div>
      </div>
    </DefaultLayout>
  );
};

LiveMapPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LiveMapPage);
