import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { Query } from "@apollo/client/react/components";

const TENANTS_QUERY = gql`
  query AllTenants {
    allTenants(first: 10) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          name
        }
      }
    }
  }
`;

const TenantsQuery = ({ children }) => {
  return (
    <Query query={TENANTS_QUERY}>
      {(result) => {
        return children(result);
      }}
    </Query>
  );
};

TenantsQuery.propTypes = {
  children: PropTypes.func.isRequired,
};

export default TenantsQuery;
