import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const QUERY = gql`
  query {
    integrations {
      orbcomm {
        status
        settings {
          accessKey
          automaticallyFetchFiles
        }
      }
    }
  }
`;

const useIntegrationSettings = () => {
  const { loading, error, data } = useQuery(QUERY, {
    fetchPolicy: "cache-and-network",
  });

  return { loading, error, data };
};

export default useIntegrationSettings;
