import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { Form } from "react-final-form";
import { FORM_ERROR } from "final-form";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import GroupPermissions from "./GroupPermissions";

import CreateGroupForm from "../components/CreateGroupForm";

// FIXME checkout https://mui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
const withMobileDialog = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="lg" fullScreen={false} />;

const EDIT_GROUP = gql`
  mutation EditUserGroup($input: EditUserGroupInputType!) {
    account {
      userGroups {
        edit(input: $input) {
          error {
            code
            message
          }
          userGroup {
            id
            name
            groupPermissions
            defaultAccessToNewDepots
            isTeamAdmin
            members {
              totalCount
              edges {
                cursor
                node {
                  id
                  name
                  emailAddress
                }
              }
            }
            depots {
              totalCount
              edges {
                cursor
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

const EditGroupDialog = ({ onCloseDialog, groupData, groupId, open }) => {
  const [editGroup, { loading: updating }] = useMutation(EDIT_GROUP);

  const handleEditGroup = (form) => {
    const variables = { input: { userGroupId: groupId, ...form } };
    editGroup({ variables }).then(
      ({
        data: {
          account: {
            userGroups: {
              edit: { error },
            },
          },
        },
      }) => {
        if (error !== null) {
          const message = `${error.message} (${error.code})`;
          return {
            [FORM_ERROR]: message,
          };
        }
        onCloseDialog();
      }
    );
  };

  const groupPermissions = GroupPermissions();
  const groupDataSelected = groupData?.groupPermissions.map((x) => x) ?? [];

  return (
    <Dialog open={open} onClose={onCloseDialog}>
      <Form
        onSubmit={handleEditGroup}
        initialValues={{
          name: groupData.name,
          defaultAccessToNewDepots: groupData.defaultAccessToNewDepots,
          isTeamAdmin: groupData.isTeamAdmin,
          groupPermissions: groupDataSelected,
        }}
        render={({ handleSubmit, submitError }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle>Edit group</DialogTitle>
            <DialogContent>
              <CreateGroupForm groupPermissions={groupPermissions} />
              {submitError && (
                <FormHelperText error={true}>{submitError}</FormHelperText>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={onCloseDialog}
                color="primary"
                disabled={updating}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                autoFocus
                disabled={updating}
              >
                Save
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

EditGroupDialog.propTypes = {
  onCloseDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withMobileDialog()(EditGroupDialog);
