import React, { useState } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import { Form } from "react-final-form";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";

import ActivateForm from "./ActivateForm";

// FIXME checkout https://mui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
const withMobileDialog = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="lg" fullScreen={false} />;

const ACTIVATE = gql`
  mutation Activate($input: ActivateTomTomIntegrationInputType!) {
    integrations {
      tomTom {
        activate(input: $input) {
          integration {
            settings {
              credentials {
                accountName
                username
              }
              groupName
            }
            status
            error {
              code
            }
          }
          error {
            code
            message
          }
        }
      }
    }
  }
`;

const ActivateDialog = ({ open, fullScreen, handleClose, onActivate }) => {
  const [activate] = useMutation(ACTIVATE);
  const [errorMess, setErrorMess] = useState();
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = (form) => {
    setLoading(true);
    const variables = { input: { ...form } };
    activate({ variables }).then(
      ({
        data: {
          integrations: {
            tomTom: {
              activate: { error, integration },
            },
          },
        },
      }) => {
        if (error !== null) {
          switch (error.code) {
            case 200610:
              setErrorMess(
                "You do not have permission to enable TomTom integration"
              );
              break;
            case 200611:
              setErrorMess("TomTom integration is already active");
              break;
            case 200612:
              setErrorMess(
                "The username and password provided cannot be used to access WEBFLEET"
              );
              break;
            case 200613:
              setErrorMess("Group with provided name not found in WEBFLEET");
              break;
            default:
              setErrorMess("Error!!!");
              break;
          }
          setLoading(false);
          return;
        }
        onActivate(integration && integration.settings);
        setLoading(false);
        handleClose();
      }
    );
  };

  return (
    <Dialog
      open={Boolean(open === "activate")}
      fullScreen={fullScreen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <Form
        onSubmit={handleFormSubmit}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">
              Add TomTom integration
            </DialogTitle>
            <DialogContent>
              <ActivateForm />
              {errorMess && (
                <FormHelperText error={true}>{errorMess}</FormHelperText>
              )}
            </DialogContent>
            <DialogActions>
              <Button disabled={loading} onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button disabled={loading} type="submit" color="primary">
                Activate
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

ActivateDialog.propTypes = {
  open: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withMobileDialog()(ActivateDialog);
