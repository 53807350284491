import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const QUERY = gql`
  query TomTomIntegrationSettings {
    integrations {
      tomTom {
        status
        error {
          code
        }
        settings {
          credentials {
            accountName
            username
          }
          groupName
        }
      }
    }
  }
`;

const useIntegrationSettings = () => {
  const { loading, error, data } = useQuery(QUERY, {
    fetchPolicy: "cache-and-network",
    // variables: { vehicleId, driverId, start, end }
  });

  return { loading, error, data };
};

export default useIntegrationSettings;
