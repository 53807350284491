import React from "react";
import withStyles from '@mui/styles/withStyles';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Icon } from "@mui/material";

const styles = theme => ({
  root: {
    width: "100%"
  },
  table: {
    minWidth: "600px"
  },
  tableWrapper: {
    overflowX: "auto"
  },
  roleChip: {
    marginRight: theme.spacing(2)
  },
  justifyCell: {
    textAlign: "center"
  },
  action: {
    textAlign: "right"
  },
  marginRight: {
    marginRight: theme.spacing(2)
  }
});

const GroupDepotsList = ({ classes = {}, onOpenDialog, depots }) => {
  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableBody>
            {depots &&
              depots.edges &&
              depots.edges.map(({ node: depot, cursor }) => (
                <TableRow key={cursor} hover>
                  <TableCell>{depot.name}</TableCell>
                  <TableCell className={classes.action}>
                    <Tooltip title="Remove this depot from group">
                      <IconButton onClick={() => onOpenDialog(depot.id)} size="large">
                        <Icon>cancel</Icon>
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default withStyles(styles)(GroupDepotsList);
