import React from "react";
import AdapterLink from "../components/form/Link";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

const VehicleList = ({ allVehicles }) => (
  <List>
    {allVehicles.edges.map(({ cursor, node: vehicle }) => {
      return (
        <ListItemButton
          key={cursor}
          component={AdapterLink}
          to={`/vehicles/${vehicle.id}`}
        >
          <ListItemText primary={vehicle.vrm} />
        </ListItemButton>
      );
    })}
  </List>
);

export default VehicleList;
