import React from "react";
import Typography from "@mui/material/Typography";

import { Box, Paper } from "@mui/material";
import RestrictLayout from "../components/RestrictLayout";

const NoAccountsPage = () => {
  return (
    <RestrictLayout>
      <Box display={"flex"} justifyContent={"center"}>
        <Box
          maxWidth={{ xs: "none", sm: "400px" }}
          margin={2}
          marginTop={4}
          width={"100%"}
        >
          <Paper>
            <Box
              sx={{
                background: (theme) => theme.palette.warning.main,
                color: (theme) => theme.palette.warning.contrastText,
                padding: 4,
                borderTopLeftRadius: (theme) => theme.shape.borderRadius,
                borderTopRightRadius: (theme) => theme.shape.borderRadius,
              }}
            >
              <Typography variant="h5" component="h3" color="inherit">
                No accounts found
              </Typography>
            </Box>
            <Box px={2} py={4}>
              <p>Your sign in is not linked to any accounts.</p>
              <p>
                Please speak to your Fastview360 administrator or contact our
                support team using the details below.
              </p>
              <p>
                <a href="mailto:support@fastview360.co.uk">
                  support@fastview360.co.uk
                </a>
              </p>
              <p>
                <a href="tel:01270 360 360">01270 360 360</a>
              </p>
            </Box>
          </Paper>
        </Box>
      </Box>
    </RestrictLayout>

    // <RestrictLayout>
    //   <div>
    //     <Grid spacing={2} container>
    //       <Grid item xs={12}>
    //         <Box className={classes.suspendedMesage}>
    //           <Typography>You don't have permission to this system</Typography>
    //         </Box>
    //       </Grid>
    //       <Grid item xs={12} md={6} lg={4}>
    //         <Card>
    //           <CardHeader title="Access Restricted" />
    //           <CardContent>
    //             <Typography>
    //               Please contact the support to active your account
    //             </Typography>
    //           </CardContent>
    //         </Card>
    //       </Grid>
    //       <Grid item xs={12} md={6} lg={8}>
    //         <DashboardLiveMapCard vehiclesEnabled={false} />
    //       </Grid>
    //     </Grid>
    //   </div>
    // </RestrictLayout>
  );
};

export default NoAccountsPage;
