/**
 * Remove __typename property in request payload
 */
export const removeTypename = value => {
  if (value === null || value === undefined) {
    return value;
  } else if (Array.isArray(value)) {
    return value.map(v => removeTypename(v));
  } else if (typeof value === "object") {
    const newObj = {};
    Object.entries(value).forEach(([key, v]) => {
      if (key !== "__typename") {
        newObj[key] = removeTypename(v);
      }
    });
    return newObj;
  }
  return value;
};

export const randomColour = () => {
  const chars = "0123456789ABCDEF".split("");
  let color = "#";
  for (let i = 0; i < 6; i++) color += chars[Math.floor(Math.random() * 16)];
  return color;
};

export const delay = (id, func, time) => {
  return (() => {
    clearTimeout(id);
    return setTimeout(() => {
      func();
    }, time);
  })();
};

// export const validateEmail = email => {
//   let re = /^([a-zA-Z0-9_.-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/i;
//   return re.test(email);
// };
