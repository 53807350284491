import React from "react";

const ActiveContent = ({ settings }) => (
  <div>
    <p>
      Account name:{" "}
      {settings && settings.credentials && settings.credentials.accountName}
    </p>
    <p>
      Username:{" "}
      {settings && settings.credentials && settings.credentials.username}
    </p>
    <p>Password: *****************</p>
    <p>Group name: {settings && settings.groupName}</p>
  </div>
);

export default ActiveContent;
