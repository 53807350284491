export const ADMIN_PAGE_VISIT = "admin-page:visit";

const UserRules = { static: [] };

const AdminRules = {
  static: [...UserRules.static, ADMIN_PAGE_VISIT]
};

const OwnerRules = {
  static: [...AdminRules.static, ADMIN_PAGE_VISIT]
};

const rules = {
  ADMIN: AdminRules,
  OWNER: OwnerRules,
  User: UserRules
};

export default rules;
