import React from "react";
import PropTypes from "prop-types";

import { Button, FormControl } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import { Form as FinalForm, Field } from "react-final-form";
import classnames from "classnames";

import DriverSelect from "../../containers/DriverSelect";
import VehicleSelect from "../../containers/VehicleSelect";
import TextField from "../../components/form/TextField";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 180,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  applyButton: {
    marginBottom: theme.spacing(1),
  },
  padding: {
    padding: theme.spacing(1),
  },
  form: {
    marginBottom: theme.spacing(2),
    display: "flex",
    flexWrap: "wrap",
    alignContent: "center",
  },
}));

const Form = ({ onSubmit, initialValues }) => {
  const classes = useStyles();

  return (
    <FinalForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
          className={classnames(classes.form, classes.padding)}
        >
          <FormControl className={classes.formControl}>
            <Field
              className={classes.time}
              component={TextField}
              fullWidth
              name="start"
              required
              label="Start"
              InputLabelProps={{
                shrink: true,
              }}
              type="date"
              onBlur={handleSubmit}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Field
              className={classes.time}
              component={TextField}
              fullWidth
              name="end"
              required
              label="End"
              InputLabelProps={{
                shrink: true,
              }}
              type="date"
              onBlur={handleSubmit}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <DriverSelect name="driverId" noContacts />
          </FormControl>
          <FormControl className={classes.formControl}>
            <VehicleSelect name="vehicleId" />
          </FormControl>
          <Button
            type="submit"
            color="primary"
            variant="outlined"
            className={classes.applyButton}
          >
            Apply
          </Button>
        </form>
      )}
    />
  );
};

export default Form;
