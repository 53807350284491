import { Box, Divider, Skeleton, Stack } from "@mui/material";

function StatsSkeleton() {
  return (
    <Stack spacing={2}>
      <Skeleton variant="rectangular" height={200} />
      <Skeleton variant="rectangular" height={150} />
    </Stack>
  );
}

export default StatsSkeleton;
