import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";

// FIXME checkout https://mui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
const withMobileDialog = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="lg" fullScreen={false} />;

const MUTATION = gql`
  mutation Change($input: ChangeAutomaticFootageFetchingInputType!) {
    integrations {
      orbcomm {
        changeAutomaticFootageFetching(input: $input) {
          error {
            code
            message
          }
        }
      }
    }
  }
`;

const QUERY = gql`
  query {
    integrations {
      orbcomm {
        status
        settings {
          accessKey
          automaticallyFetchFiles
        }
      }
    }
  }
`;

const ChangeAutomaticFootageFetchingDialog = ({
  open,
  fullScreen,
  handleClose,
  checked,
}) => {
  const [errorMess, setErrorMess] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [change] = useMutation(MUTATION, {
    refetchQueries: () => [{ query: QUERY }],
    awaitRefetchQueries: true,
  });

  const handleChange = () => {
    setLoading(true);
    const variables = { input: { automaticallyFetchFiles: !checked } };

    change({ variables }).then(
      ({
        data: {
          integrations: {
            orbcomm: {
              changeAutomaticFootageFetching: { error },
            },
          },
        },
      }) => {
        if (error !== null) {
          const message = `${error.message} (${error.code})`;
          setErrorMess(message);
          setLoading(false);
          return;
        }
        setLoading(false);
        handleClose();
      }
    );
  };

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Change automatic footage fetching
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure to {checked ? "disable" : "enable"} automatic footage
          fetching?
        </DialogContentText>
        {errorMess && <FormHelperText error={true}>{errorMess}</FormHelperText>}
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleClose} color="primary">
          Close
        </Button>
        <Button disabled={loading} onClick={handleChange} color="primary">
          {checked ? "Disable" : "Enable"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ChangeAutomaticFootageFetchingDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withMobileDialog()(ChangeAutomaticFootageFetchingDialog);
