import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { useForm } from "react-final-form";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import throttle from "lodash/throttle";

const VEHICLES_QUERY = gql`
  query VehicleSearch($query: String) {
    vehicles {
      all(first: 50, filter: { vrmLike: $query }) {
        edges {
          node {
            id
            vrm
          }
        }
      }
    }
  }
`;

export { VEHICLES_QUERY };

const VehicleSelect = ({ name, required, defaultValue }) => {
  const [value, setValue] = React.useState(defaultValue);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [queryValue, setQueryValue] = React.useState("");

  const { data, loading, error } = useQuery(VEHICLES_QUERY, {
    variables: { query: queryValue },
    fetchPolicy: "cache-first",
  });

  const { change: setFormValue } = useForm();

  const throttled = React.useCallback(
    throttle((newValue) => setQueryValue(newValue), 1000),
    []
  );

  React.useEffect(() => throttled(inputValue), [inputValue]);

  React.useEffect(
    () => setFormValue(name, value?.id),
    [value, setFormValue, name]
  );

  React.useEffect(() => {
    let results = data?.vehicles.all.edges.map(({ node }) => node);

    if (inputValue === "") {
      setOptions(value ? [value] : [...(results || [])]);
      return undefined;
    }

    let newOptions = [];

    if (value) {
      newOptions = [value];
    }

    if (results) {
      newOptions = [...newOptions, ...results];
    }

    setOptions(newOptions);
  }, [value, inputValue, data]);

  return (
    <div>
      <Autocomplete
        fullWidth
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.vrm
        }
        filterOptions={(x) => x}
        options={options}
        loading={loading}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            required={required}
            {...params}
            label="Vehicle"
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        renderOption={(props, option) => {
          return <li {...props}>{option.vrm}</li>;
        }}
      />
    </div>
  );
};

VehicleSelect.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export default VehicleSelect;
