import React from "react";
import withStyles from '@mui/styles/withStyles';
import classNames from "classnames";
import moment from "moment";
import AdapterLink from "../components/form/Link";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AnchorLink from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { ACTIVE, mapToDisplay } from "../IncidentStatus";

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  bold: {
    fontWeight: "500"
  },
  verticalAlign: {
    display: "inline-flex",
    alignItems: "center"
  },
  iconMarginLeft: {
    marginLeft: theme.spacing(1)
  },
  status: {
    color: theme.palette.text.hint,
    paddingLeft: theme.spacing(2)
  },
  statusActive: {
    color: theme.palette.secondary.main
  }
});

const IncidentTable = ({ classes = {}, allIncidents }) => (
  <div className={classes.table}>
    {allIncidents.edges.map(({ cursor, node: incident }) => {
      return (
        <Paper key={cursor} className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={3}>
              <AnchorLink
                className={classNames(classes.bold, classes.verticalAlign)}
                component={AdapterLink}
                to={`/incidents/${incident.id}`}
              >
                <span>{incident.reference}</span>
                <Icon className={classes.iconMarginLeft}>arrow_forward</Icon>
              </AnchorLink>
              <span
                className={classNames(classes.status, {
                  [classes.statusActive]: incident.status === ACTIVE
                })}
              >
                {mapToDisplay(incident.status)}
                {incident.claim && (
                  <Tooltip title="View claim">
                    <IconButton
                      size="small"
                      color="inherit"
                      component={AdapterLink}
                      to={`/claims/${incident.claim.id}`}
                    >
                      <Icon>forward</Icon>
                    </IconButton>
                  </Tooltip>
                )}
              </span>
            </Grid>
            <Grid item xs={6} sm={4}>
              <span>{moment(incident.occurredAt).format("LLL")}</span>
            </Grid>
            <Grid item xs={6} sm={2}>
              <AnchorLink
                component={AdapterLink}
                to={`/vehicles/${incident.vehicleId}`}
              >
                {incident.vehicle.vrm}
              </AnchorLink>
            </Grid>
            <Grid item xs={6} sm={3}>
              {incident.driver && incident.driver.name}
            </Grid>
            {incident.location && (
              <Grid item xs={12}>
                {incident.location}
              </Grid>
            )}
          </Grid>
        </Paper>
      );
    })}
  </div>
);

export default withStyles(styles)(IncidentTable);
