import React, { Fragment } from "react";
import gql from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import Typography from "@mui/material/Typography";
import EditUserEmailPreferences from "../components/EditUserEmailPreferences";

import DefaultLayout from "../components/DefaultLayout";

const ME_QUERY = gql`
  query {
    me {
      who {
        id
        ... on User {
          name
          email
          emailPreferences {
            eventButtonIncidents
            newNearMissIsRecorded
          }
        }
      }
    }
  }
`;
const AccountPage = ({ nearMissEnabled }) => (
  <DefaultLayout>
    <Typography variant="h5" gutterBottom>
      Account
    </Typography>

    <Query query={ME_QUERY}>
      {({ loading, error, data }) => {
        if (loading) return "Loading...";

        if (error) return JSON.stringify(error);

        const {
          me: { who },
        } = data;
        return (
          <Fragment>
            <p>Name: {who.name}</p>
            <p>ID: {who.id}</p>
            <p>Email: {who.email}</p>
            <Typography variant="h6" gutterBottom>
              Email notification preferences
            </Typography>
            <EditUserEmailPreferences
              userId={who.id}
              emailPreferences={who.emailPreferences}
              nearMissEnabled={nearMissEnabled}
            />
          </Fragment>
        );
      }}
    </Query>
  </DefaultLayout>
);

export default AccountPage;
