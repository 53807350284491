import React from "react";
import PlaceAMarkerMap from "../components/PlaceAMarkerMap";

class SelectLocationMap extends React.Component {
  state = {
    latLng: null
  };

  constructor() {
    super();
    this.handleMapClick = this.handleMapClick.bind(this);
  }

  render() {
    const { latLng } = this.state;
    return <PlaceAMarkerMap position={latLng} onClick={this.handleMapClick} />;
  }

  handleMapClick = latLng => {
    this.setState({ latLng });
    this.props.onLocationSelected(latLng);
  };
}

export default SelectLocationMap;
