import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";

import LiveShareTrackings from "../containers/LiveShareTrackings";
import DefaultLayout from "../components/DefaultLayout";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 56,
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
}));

const LiveShareTrackingPage = () => {
  const classes = useStyles();

  return (
    <DefaultLayout>
      <Box className={classes.root}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.marginBottom}>
          <Link color="inherit" to="#" component={RouterLink}>
            Settings
          </Link>
          <Typography color="textPrimary">Shared live tracking</Typography>
        </Breadcrumbs>
        <Typography variant="h5" gutterBottom className={classes.marginBottom}>
          Shared live tracking
        </Typography>

        <LiveShareTrackings />
      </Box>
    </DefaultLayout>
  );
};

export default LiveShareTrackingPage;
