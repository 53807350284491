import React from "react";
import { Icon, IconButton, Tooltip } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Switch from "@mui/material/Switch";

import { copy } from "../../../Clipboard";
import ChangeAutomaticFootageFetchingDialog from "./ChangeAutomaticFootageFetchingDialog";

const useStyles = makeStyles(theme => ({
  icon: {
    transform: "rotate(-270deg)",
    fontSize: 18
  },
  switch: {
    marginLeft: theme.spacing(1)
  },
  marginTop: {
    marginTop: -12
  }
}));

const ActiveContent = ({ settings, automaticallyFetchFiles }) => {
  const classes = useStyles();
  const id = "activeContent";
  const [checked, setChecked] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => setChecked(Boolean(automaticallyFetchFiles)), [
    automaticallyFetchFiles
  ]);

  const handleChange = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div id={id}>
      <p>
        Access key ID: {settings && settings.accessKey}{" "}
        {settings && (
          <Tooltip title="Copy">
            <IconButton onClick={() => copy(id, settings.accessKey)} size="large">
              <Icon className={classes.icon}>filter_none</Icon>
            </IconButton>
          </Tooltip>
        )}
      </p>
      <p className={classes.marginTop}>
        Automatically fetch event footage
        <Switch
          className={classes.switch}
          checked={checked}
          onChange={handleChange}
          name="automaticallyFetchEvent"
        />
      </p>

      <ChangeAutomaticFootageFetchingDialog
        open={open}
        handleClose={handleClose}
        checked={checked}
      />
    </div>
  );
};

export default ActiveContent;
