import gql from "graphql-tag";

export const NearMissesQuery = gql`
  query NearMisses(
    $after: String
    $before: String
    $filter: FilterNearMiss!
    $filterStats: NearMissStatsFilter!
  ) {
    nearMisses {
      all(after: $after, before: $before, filter: $filter) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          endCursor
          startCursor
        }
        edges {
          cursor
          node {
            id
            reference
            status
            eventDate
            location
            category {
              id
              name
            }
            depot {
              id
              name
            }
          }
        }
      }
      timeOfDayStats(filter: $filterStats) {
        hour
        frequency
      }
      categoriesStats(filter: $filterStats) {
        categoryId
        frequency
      }
    }
  }
`;

export const CategoriesQuery = gql`
  query AllNearMissCategories {
    nearMissCategories {
      all {
        edges {
          cursor
          node {
            id
            name
          }
        }
      }
    }
  }
`;

export const DepotsQuery = gql`
  query AllDepots {
    depots {
      all {
        edges {
          cursor
          node {
            id
            name
          }
        }
      }
    }
  }
`;
