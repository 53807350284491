import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import withStyles from "@mui/styles/withStyles";

import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

import IncidentTable from "../components/IncidentTable";

const QUERY = gql`
  query IncidentSearch($filter: IncidentFilter) {
    incidents {
      all(first: 1000, filter: $filter) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            id
            status
            reference
            location
            driverId
            driver {
              id
              name
            }
            vehicleId
            vehicle {
              id
              vrm
            }
            occurredAt
            claim {
              id
            }
          }
        }
      }
    }
  }
`;

const styles = (theme) => ({
  loading: {
    marginBottom: theme.spacing(2),
  },
});

const IncidentSearch = ({ search, classes }) => {
  const variables = {
    filter: {
      driver_id: search.driverId,
      occurredAtGte: search.occurredAtGte,
      occurredAtLt: search.occurredAtLt,
      reference: search.reference,
      status: search.status,
      vehicle_id: search.vehicle,
    },
  };

  return (
    <>
      {/* <pre>{JSON.stringify(search)}</pre> */}
      <Query
        query={QUERY}
        variables={variables}
        fetchPolicy="cache-and-network"
      >
        {({ loading, error, data }) => {
          if (loading && (!data || !data.incidents))
            return <LinearProgress className={classes.loading} />;
          if (error) return JSON.stringify(error);

          return (
            <>
              {loading && <LinearProgress className={classes.loading} />}
              <IncidentTable allIncidents={data.incidents.all} />
              {data.incidents.all.totalCount === 0 && (
                <Typography component="p">No incidents found.</Typography>
              )}
            </>
          );
        }}
      </Query>
    </>
  );
};

IncidentSearch.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IncidentSearch);
