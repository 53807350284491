export const ACTIVE = "ACTIVE";
export const CLOSED = "CLOSED";
export const FILING = "FILING";
export const FILED = "FILED";

export const mapToDisplay = key => {
  if (key === ACTIVE) return "Active";
  if (key === FILING) return "Filing";
  if (key === FILED) return "Filed";

  return key;
};
