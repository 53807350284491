import React from "react";
import TextField from "../components/form/TextField";
import { Field } from "react-final-form";
import Grid from "@mui/material/Grid";

const CreatePolicyForm = () => (
  <div>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Field
          component={TextField}
          name="number"
          required
          margin="dense"
          autoComplete="off"
          label="Number"
          fullWidth
          autoFocus
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          component={TextField}
          name="insurerName"
          margin="dense"
          autoComplete="off"
          label="Insurer name"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          component={TextField}
          name="fileClaimSubmissionEmail"
          margin="dense"
          type="email"
          autoComplete="off"
          label="File claim submission email"
          fullWidth
          required
        />
      </Grid>
    </Grid>
  </div>
);

export default CreatePolicyForm;
