import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Icon, Button, IconButton, Grid, Link } from "@mui/material";
import AdapterLink from "../components/form/Link";
import { OverlayView, OverlayViewF } from "@react-google-maps/api";
import moment from "moment";

import { red, green } from "@mui/material/colors";

const useStyles = makeStyles((theme) => ({
  mapControlRoot: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    padding: `0 ${theme.spacing(1)}`,
  },
  labelMarker: {
    position: "relative",
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    width: "100%",
    height: 20,
    background: "#fff",
    color: "#505558",
    borderRadius: 4,
    bottom: 34,
    left: 14,
    border: "solid 1px #d6d6d6",
    borderBottom: "none",
    borderRight: "none",
    // boxShadow: "0 0 1px rgba(0,0,0,.6)",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#d6d6d6",
    },
  },
  labelText: {
    fontSize: 11,
    fontWeight: "bold",
    color: "#505558",
  },
  labelIcon: {
    fontSize: 12,
    marginLeft: 4,
    marginRight: 4,
  },
  arrow: {
    position: "absolute",
    zIndex: -1,
    borderColor: "#fff transparent transparent #fff",
    borderStyle: "solid",
    borderWidth: 14,
    transform: "skewX(-35deg) rotate(17deg)",
    bottom: 15,
    left: 10,
    border: "solid 1px #d6d6d6",
    boxShadow: "-1px -1px 1px rgba(0,0,0,.6)",
  },
  labelDetail: {
    width: 260,
    backgroundColor: "#fff",
    position: "absolute",
    top: -34,
    zIndex: -2,
    left: 14,
    borderRadius: 4,
    border: "solid 1px #d6d6d6",
  },
  clearIcon: {
    position: "absolute",
    right: 2,
    top: 2,
    fontSize: 16,
    cursor: "pointer",
  },
  details: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  flex: {
    display: "flex",
    margin: theme.spacing(0.5),
    // color: "#505558"
  },
  text: {
    marginLeft: theme.spacing(1),
    alignSelf: "center",
  },
  icon: {
    fontSize: 18,
    color: theme.palette.grey["500"],
  },
  floatRight: {
    display: "flex",
    justifyContent: "flex-end",
  },
  liveViewButton: {
    height: theme.spacing(2),
    fontSize: theme.spacing(1),
  },
  directionIcon: {
    fontSize: 16,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    color: theme.palette.grey["900"],
  },
  // parkingIcon: {
  //   fontSize: 11,
  //   backgroundColor: "#a2aaaf",
  //   color: "white",
  //   width: 12,
  //   height: 12,
  //   textAlign: "center",
  //   borderRadius: 2,
  //   marginLeft: theme.spacing(0.5),
  //   marginRight: theme.spacing(0.5)
  // },
  zoomInIcon: {
    fontSize: 18,
  },
  fab: {
    padding: 4,
    backgroundColor: "#E0E0E0",
    height: "fit-content",
    width: "fit-content",
  },
  center: { alignSelf: "center", textAlign: "center" },
}));

export const Overlayview = ({
  live,
  expand,
  vrm,
  handleOpenExpansion,
  driver,
  handleCloseExpansion,
  id,
  onZoomIn,
  livePage,
}) => {
  const classes = useStyles();

  return (
    <OverlayViewF
      key={id}
      position={live.coord}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <>
        <div
          className={classes.arrow}
          style={{ zIndex: expand === vrm && 9 }}
        ></div>
        <div
          className={classes.labelMarker}
          onClick={() => handleOpenExpansion(vrm)}
          style={{ zIndex: expand === vrm && 10 }}
        >
          {live.heading && live.online ? (
            <Icon
              className={classes.directionIcon}
              style={{ transform: `rotate(${live.heading}deg)` }}
            >
              navigation
            </Icon>
          ) : (
            <Icon className={classes.directionIcon}>local_parking</Icon>
          )}
          <span className={classes.labelText}>{vrm}</span>
          <Icon
            className={classes.labelIcon}
            style={{ color: live.online ? green[400] : red[400] }}
          >
            lens
          </Icon>
        </div>
        <div
          className={classes.labelDetail}
          style={{
            display: expand === vrm ? "block" : "none",
            zIndex: expand === vrm && 8,
          }}
        >
          <Icon onClick={handleCloseExpansion} className={classes.clearIcon}>
            clear
          </Icon>
          <div className={classes.details}>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <p className={classes.flex}>
                  <Icon className={classes.icon}>room</Icon>{" "}
                  <span className={classes.text}>
                    <Link
                      href={`https://maps.google.com?q=${live.coord.lat},${live.coord.lng}`}
                      target="_blank"
                      title="Open location in Google maps"
                    >
                      {live.coord.lat}, {live.coord.lng}
                    </Link>
                  </span>
                </p>
              </Grid>
              <Grid item xs={3} className={classes.center}>
                <IconButton
                  className={classes.fab}
                  onClick={() => onZoomIn(live.coord)}
                  size="large"
                >
                  <Icon className={classes.zoomInIcon}>zoom_in</Icon>
                </IconButton>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <p className={classes.flex}>
                  <Icon className={classes.icon}>speed</Icon>{" "}
                  <span className={classes.text}>{live.speed} MPH</span>
                </p>
              </Grid>
              {driver && !live.driver && (
                <Grid item xs={6}>
                  <p className={classes.flex}>
                    <Icon
                      className={classes.icon}
                      title="Driver assigned to vehicle"
                    >
                      perm_identity
                    </Icon>{" "}
                    <span className={classes.text}>{driver.name}</span>
                  </p>
                </Grid>
              )}
              {live.driver && (
                <Grid item xs={6}>
                  <p className={classes.flex}>
                    <Icon className={classes.icon} title="Driver in vehicle">
                      perm_identity
                    </Icon>{" "}
                    <span className={classes.text}>{live.driver.name}</span>
                  </p>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={0}>
              {live.timestamp && (
                <Grid item xs={12}>
                  <p className={classes.flex}>
                    <Icon className={classes.icon} title="Last timestamp">
                      access_time
                    </Icon>{" "}
                    <span className={classes.text}>
                      {moment(live.timestamp).format("L")}{" "}
                      {moment(live.timestamp).format("LTS")}
                    </span>
                  </p>
                </Grid>
              )}
            </Grid>{" "}
            {!livePage && (
              <Grid container spacing={0}>
                <Grid item xs={12} className={classes.floatRight}>
                  <Button
                    variant="contained"
                    size="small"
                    to={`/vehicles/${id}`}
                    component={AdapterLink}
                    className={classes.liveViewButton}
                  >
                    Live view
                  </Button>
                </Grid>
              </Grid>
            )}
          </div>
        </div>
      </>
    </OverlayViewF>
  );
};

export default Overlayview;
