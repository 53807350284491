import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Form } from "react-final-form";
import TextField from "../components/form/TextField";
import { Field } from "react-final-form";
import { OnBlur, OnChange } from "react-final-form-listeners";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  form: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    flexWrap: "wrap",
  },
  input: {
    marginBottom: theme.spacing(1),
  },
  progressButtonWrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

const validateRequired = (value) => !value && "Required";
const validateDateFormat = (value) =>
  value &&
  !/^[\d]{2,4}[/-][\d]{2}[/-][\d]{2,4}$/.test(value) &&
  "Invalid date format. dd/mm/yyyy";

const validateDateInPast = (value) =>
  moment().isBefore(moment(value)) && "Must be in the past";

const composeValidators =
  (...validators) =>
  (value, form) =>
    validators.reduce(
      (error, validator) => error || validator(value, form),
      undefined
    );

const validateDate = composeValidators(
  validateRequired,
  validateDateFormat,
  validateDateInPast
);

const VehicleTrackingForm = ({ classes, initialDate, loading, onSubmit }) => {
  const initialValues = {
    date: (initialDate && moment(initialDate).format("YYYY-MM-DD")) || "",
  };

  const dateChangeTimeoutRef = useRef(null);

  const handleFormChange = (value, previous) => {
    const valueDate = value;
    const previouDate = previous;

    if (dateChangeTimeoutRef.current) {
      clearTimeout(dateChangeTimeoutRef.current);
    }

    dateChangeTimeoutRef.current = setTimeout(() => {
      const dateMoment = moment(valueDate);
      if (dateMoment.isValid()) {
        const valueDay = dateMoment.format("DD");
        const previousDay = moment(previouDate).format("DD");

        if (valueDay != previousDay) {
          onSubmit({ date: valueDate });
        }
      }
    }, 1300);
  };

  return (
    <Form
      onSubmit={(form) => {
        handleFormChange(form);
      }}
      initialValues={initialValues}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <Field
            component={TextField}
            name="date"
            type="date"
            validate={validateDate}
            margin="dense"
            autoComplete="off"
            placeholder="Day"
            className={classes.input}
            disabled={submitting}
            variant="outlined"
            inputProps={{
              max: moment().format("YYYY-MM-DD"),
            }}
          />
          <OnChange name="date">
            {(value, previous) => {
              handleFormChange(value, previous);
            }}
          </OnChange>
        </form>
      )}
    />
  );
};

VehicleTrackingForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialDate: PropTypes.string,
};

export default withStyles(styles)(VehicleTrackingForm);
