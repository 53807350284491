import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default ({
  input: { name, onChange, checked, value, ...restInput },
  meta,
  label,
  icon,
  ...rest
}) =>
  Boolean(label) ? (
    <FormControlLabel
      control={
        <Checkbox
          {...rest}
          name={name}
          inputProps={restInput}
          onChange={onChange}
          checked={Boolean(checked)}
          icon={icon}
        />
      }
      label={label}
    />
  ) : (
    <Checkbox
      {...rest}
      name={name}
      inputProps={restInput}
      onChange={onChange}
      checked={Boolean(checked)}
      icon={icon}
    />
  );
