import React from "react";
import withStyles from "@mui/styles/withStyles";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import { Icon, Chip } from "@mui/material";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: "600px",
  },
  tableWrapper: {
    overflowX: "auto",
  },
  roleChip: {
    marginRight: theme.spacing(2),
  },
  justifyCell: {
    textAlign: "center",
  },
});

const DepotsList = ({
  classes = {},
  allDepots,
  handleOpenEdit,
  onOpenDialogCloseDepot,
}) => {
  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              {/* <TableCell>Status</TableCell> */}
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allDepots &&
              allDepots.edges.map(({ node: depot }) => (
                <TableRow key={depot.id} hover>
                  <TableCell variant="head">{depot.name}</TableCell>
                  {/* <TableCell>
                    <Chip label="Open" variant="outlined" />
                  </TableCell> */}
                  <TableCell>
                    {allDepots.totalCount !== 1 && (
                      <Tooltip title="Close depot">
                        <IconButton
                          onClick={() => onOpenDialogCloseDepot(depot.id)}
                          size="large"
                        >
                          <Icon>work_off</Icon>
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Rename this depot">
                      <IconButton
                        onClick={() => handleOpenEdit(depot)}
                        size="large"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default withStyles(styles)(DepotsList);
