export const ISSUE_RAISED = "ISSUE_RAISED";
export const IN_PROGRESS = "IN_PROGRESS";
export const COMPLETE = "COMPLETE";

export const toDisplayName = (status) => {
  if (ISSUE_RAISED === status) return "Issue Raised";
  if (IN_PROGRESS === status) return "In Progress";
  if (COMPLETE === status) return "Complete";
  return `** ${status}`;
};
