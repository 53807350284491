import React from "react";
import {
  Typography,
  Stack,
  Box,
  Paper,
  TextField,
  Button,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import markdown from "../../markdown";

import moment from "moment";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { timelineItemClasses } from "@mui/lab/TimelineItem";

function ActivityHeader({ userFullName, action, date }) {
  return (
    <Stack direction={"row"} spacing={2}>
      <Box flexGrow={1} fontSize={"body2.fontSize"}>
        <b>{userFullName}</b>{" "}
        <Box display={"inline"} color={"text.secondary"}>
          {action}
        </Box>
        .
      </Box>
      <Box
        fontSize={"body2.fontSize"}
        sx={{ whiteSpace: "nowrap" }}
        color={"text.secondary"}
        title={moment(date).format("lll")}
      >
        {moment(date).fromNow(true)}
      </Box>
    </Stack>
  );
}

function ActivityItem({ children }) {
  return <Box mb={0}>{children}</Box>;
}

function ActivityCommentItem({ children }) {
  return (
    <Box mb={0}>
      <Paper variant="outlined" sx={{ padding: 1 }}>
        {children}
      </Paper>
    </Box>
  );
}

function ActivityItemSkeleton() {
  return (
    <Stack direction={"row"} spacing={2}>
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"20%"} />
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"70%"} />
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"10%"} />
    </Stack>
  );
}

function SidePanel({ nearMiss, loading, onSubmitAddComment, addingComment }) {
  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Activity
      </Typography>
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <ActivityItem>
              {loading ? (
                <ActivityItemSkeleton />
              ) : (
                <ActivityHeader
                  userFullName={nearMiss.createdByUser.name}
                  action={"created the near miss"}
                  date={nearMiss.createdAt}
                />
              )}
            </ActivityItem>
          </TimelineContent>
        </TimelineItem>
        {!loading &&
          nearMiss.comments?.edges.map(({ node: comment, cursor }) => (
            <TimelineItem key={cursor}>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <ActivityCommentItem>
                  <ActivityHeader
                    userFullName={comment.createdByUser.name}
                    action={"commented"}
                    date={comment.createdAt}
                  />
                  <Box color={"text.secondary"}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: markdown.render(comment.mdText ?? ""),
                      }}
                    />
                  </Box>
                </ActivityCommentItem>
              </TimelineContent>
            </TimelineItem>
          ))}
        {addingComment && (
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <ActivityItem>
                <ActivityItemSkeleton />
              </ActivityItem>
            </TimelineContent>
          </TimelineItem>
        )}
      </Timeline>

      <div>
        <AddCommentForm
          disabled={loading || addingComment}
          onSubmit={onSubmitAddComment}
        />
      </div>
    </div>
  );
}

function AddCommentForm({ onSubmit, disabled }) {
  const inputRef = React.useRef(null);

  const handleSubmit = async (ev) => {
    await onSubmit(ev);

    inputRef.current.value = "";
    inputRef.current.focus();
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        inputRef={inputRef}
        disabled={disabled}
        name="comment"
        label="Your comment"
        placeholder="Add your comment..."
        multiline
        required
        maxRows={10}
        fullWidth
        defaultValue=""
      />
      <Box mt={1} textAlign={"end"}>
        <Button type="submit" disabled={disabled} variant="outlined">
          Comment
        </Button>
      </Box>
    </form>
  );
}

export default SidePanel;
