import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import RequireAuth from "../components/RequireAuth";

import SignInPage from "../components/SignInPage";
import SignUpPage from "../components/SignUpPage";
import ForgottenPasswordPage from "../components/ForgottenPasswordPage";
import ResetPasswordPage from "../components/ResetPasswordPage";

import DashboardPage from "../components/DashboardPage";
import AboutPage from "../components/AboutPage";
import IncidentsRoute from "../components/IncidentsRoute";
import VehiclesRoute from "../components/VehiclesRoute";
import ClaimsRoute from "../components/ClaimsRoute";
import AdminRoute from "../components/AdminRoute";
import NoRoutePage from "../components/NoRoutePage";
import LogoutPage from "../components/LogoutPage";
import AccountPage from "../components/AccountPage";
import LiveMapPage from "../components/LiveMapPage";
import WhichVehiclePage from "../components/WhichVehiclePage";
import DriverReviewPage from "../pages/driver-review";
import NearMissIndexPage from "../pages/near-miss.index/route";
import NearMissPage from "../pages/near-miss.$id/route";
import ExternalTrackingPage from "../components/ExternalTrackingPage";
import { PermissionValues } from "../containers/GroupPermissions";
import LiveShareTrackingPage from "../components/LiveShareTrackingPage";
import SuspendedPage from "../components/SuspendedPage";
import NoAccountsPage from "../components/NoAccountsPage";

import { useAuth } from "../AuthContext";
import LogRocket from "logrocket";
import RequireActiveAccount from "./RequireActiveAccount";

const Routes = () => {
  const { isAuthenticated, user, account } = useAuth();

  React.useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    LogRocket.identify(user.id, {
      name: user.name,
      email: user.email,
    });

    LogRocket.track(
      `Tenant:${account && account.tenant && account.tenant.name}`
    );
  });

  const tenantFeatures = account?.tenant?.features || {
    isSupportTracking: account?.tenant?.isSupportTracking || false,
    nearMissEnabled: account?.tenant?.nearMissEnabled || true,
    vehiclesEnabled: true,
    incidentsEnabled: true,
    driverReviewEnabled: true,
  };

  const isSupportTracking = tenantFeatures?.isSupportTracking || false;
  const groupPermissions = account?.groupPermissions || [
    "vehicles",
    "incidents",
    "driver-review",
    "near-miss",
    "live-share-tracking",
  ];

  const isEnabled = (property, value) =>
    tenantFeatures?.[property] && groupPermissions.includes(value);

  const { Vehicles, Incidents, DriverReview, NearMiss, LiveShareTracking } =
    PermissionValues;

  const vehiclesEnabled = isEnabled("vehiclesEnabled", Vehicles);
  const incidentsEnabled = isEnabled("incidentsEnabled", Incidents);
  const driverReviewEnabled = isEnabled("driverReviewEnabled", DriverReview);
  const nearMissEnabled = isEnabled("nearMissEnabled", NearMiss);
  const liveShareTrackingEnabled = isEnabled(
    "vehiclesEnabled",
    LiveShareTracking
  );

  const vehicleGroup =
    vehiclesEnabled || incidentsEnabled || driverReviewEnabled;

  return (
    <Switch>
      <Route path="/lo/forgotten" exact>
        <ForgottenPasswordPage />
      </Route>
      <Route path="/lo/reset" exact>
        <ResetPasswordPage />
      </Route>
      <Route path="/login">
        <SignInPage />
      </Route>
      <Route path="/signup">
        <SignUpPage />
      </Route>
      <Route path="/logout">
        <LogoutPage />
      </Route>
      <Route path="/" exact>
        <RequireAuth>
          <RequireActiveAccount>
            {vehicleGroup ? (
              <DashboardPage
                incidentsEnabled={incidentsEnabled}
                vehiclesEnabled={vehiclesEnabled}
              />
            ) : nearMissEnabled ? (
              <Redirect to="/near-miss" />
            ) : (
              <DashboardPage />
            )}
          </RequireActiveAccount>
        </RequireAuth>
      </Route>
      <Route path="/live-map" exact>
        <RequireAuth>
          <RequireActiveAccount>
            <LiveMapPage />
          </RequireActiveAccount>
        </RequireAuth>
      </Route>
      {incidentsEnabled && (
        <Route path="/incidents">
          <RequireAuth>
            <RequireActiveAccount>
              <IncidentsRoute />
            </RequireActiveAccount>
          </RequireAuth>
        </Route>
      )}
      {vehiclesEnabled && (
        <Route path="/vehicles">
          <RequireAuth>
            <RequireActiveAccount>
              <VehiclesRoute incidentsEnabled={incidentsEnabled} />
            </RequireActiveAccount>
          </RequireAuth>
        </Route>
      )}
      {incidentsEnabled && (
        <Route path="/claims">
          <RequireAuth>
            <RequireActiveAccount>
              <ClaimsRoute />
            </RequireActiveAccount>
          </RequireAuth>
        </Route>
      )}
      <Route path="/account">
        <RequireAuth>
          <AccountPage nearMissEnabled={nearMissEnabled} />
        </RequireAuth>
      </Route>
      <Route path="/about">
        <RequireAuth skipAccountCheck>
          <AboutPage />
        </RequireAuth>
      </Route>
      <Route path="/admin">
        <RequireAuth roles={["ADMIN", "OWNER"]}>
          <AdminRoute />
        </RequireAuth>
      </Route>
      {vehiclesEnabled && (
        <Route path="/which-vehicle">
          <RequireAuth>
            <RequireActiveAccount>
              <WhichVehiclePage />
            </RequireActiveAccount>
          </RequireAuth>
        </Route>
      )}
      {driverReviewEnabled && (
        <Route path="/driver-review">
          <RequireAuth>
            <RequireActiveAccount>
              <DriverReviewPage />
            </RequireActiveAccount>
          </RequireAuth>
        </Route>
      )}
      {nearMissEnabled && (
        <Route path="/near-miss">
          <RequireAuth>
            <RequireActiveAccount>
              <Switch>
                <Route exact path="/near-miss">
                  <RequireAuth>
                    <NearMissIndexPage />
                  </RequireAuth>
                </Route>
                <Route path="/near-miss/:id">
                  <RequireAuth>
                    <NearMissPage />
                  </RequireAuth>
                </Route>
              </Switch>
            </RequireActiveAccount>
          </RequireAuth>
        </Route>
      )}

      {isSupportTracking && vehicleGroup && (
        <Route path="/external-tracking">
          <RequireAuth>
            <RequireActiveAccount>
              <ExternalTrackingPage />
            </RequireActiveAccount>
          </RequireAuth>
        </Route>
      )}

      {liveShareTrackingEnabled && (
        <Route path="/settings/shared-live-tracking">
          <RequireAuth>
            <RequireActiveAccount>
              <LiveShareTrackingPage />
            </RequireActiveAccount>
          </RequireAuth>
        </Route>
      )}

      <Route path="/no-accounts">
        <RequireAuth skipAccountCheck>
          <NoAccountsPage />
        </RequireAuth>
      </Route>

      <Route path="/suspended">
        <RequireAuth>
          <SuspendedPage />
        </RequireAuth>
      </Route>

      <Route>
        <NoRoutePage />
      </Route>
    </Switch>
  );
};

export default Routes;
