import React from "react";
import withStyles from "@mui/styles/withStyles";

import TextField from "../../form/TextField";
import { Field } from "react-final-form";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

const validateName = (value) => !value && "Input is required";

const styles = (theme) => ({
  marginTop: {
    marginTop: theme.spacing(-1),
  },
});

const ActivateForm = ({ classes }) => (
  <div>
    <Grid container spacing={2}>
      <Grid item lg={8} md={8} xs={12} sm={8}>
        <Field
          component={TextField}
          name="accountName"
          required
          validate={validateName}
          margin="dense"
          autoComplete="off"
          label="Account name"
          fullWidth
          autoFocus
        />
      </Grid>
      <Grid item xs={12} sm={12} className={classes.marginTop}>
        <Typography variant="caption">Your WEBFLEET account name</Typography>
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="username"
          required
          validate={validateName}
          margin="dense"
          autoComplete="off"
          label="Username"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="password"
          required
          validate={validateName}
          margin="dense"
          autoComplete="off"
          label="Password"
          type="password"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={12} className={classes.marginTop}>
        <Typography variant="caption">
          Username and password for user with access to WEBFLEET. If you want,
          you can set up a new "Fastview360" user through webfleet and enter the
          details here
        </Typography>
      </Grid>
      <Grid item lg={8} md={8} xs={12} sm={8}>
        <Field
          component={TextField}
          name="groupName"
          required
          validate={validateName}
          margin="dense"
          autoComplete="off"
          label="Group name"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={12} className={classes.marginTop}>
        <Typography variant="caption">
          Only events for vehicles in this group will sync
        </Typography>
      </Grid>
    </Grid>
  </div>
);

export default withStyles(styles)(ActivateForm);
