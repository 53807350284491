import React from "react";

import Typography from "@mui/material/Typography";

import VehicleList from "../containers/VehicleList";

const VehiclesPage = () => (
  <section>
    <Typography variant="h5" gutterBottom>
      Vehicles
    </Typography>

    <VehicleList />
  </section>
);

export default VehiclesPage;
