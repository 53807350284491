import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";

import AdapterNavLink from "../components/form/NavLink";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import OnlineStatusIcon from "../components/OnlineStatusIcon";

const styles = (theme) => ({
  active: {
    background: theme.palette.action.selected,
  },
});

const VehicleMenuListItem = ({ vehicle, className, classes }) => (
  <ListItemButton
    component={AdapterNavLink}
    to={`/vehicles/${vehicle.id}`}
    activeClassName={classes.active}
    className={className}
  >
    <ListItemIcon>
      <OnlineStatusIcon online={vehicle.live.online} />
    </ListItemIcon>
    <ListItemText primary={vehicle.vrm} />
  </ListItemButton>
);

VehicleMenuListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  vehicle: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(VehicleMenuListItem);
