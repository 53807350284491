import React, { useState } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import withStyles from "@mui/styles/withStyles";
import { useMutation } from "@apollo/client";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";

// FIXME checkout https://mui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
const withMobileDialog = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="lg" fullScreen={false} />;

const REMOVE_MEMBER = gql`
  mutation RemoveMember($input: RemoveMemberFromUserGroupInputType!) {
    account {
      userGroups {
        removeMember(input: $input) {
          error {
            code
            message
          }
          userGroup {
            id
            name
            members {
              totalCount
              edges {
                cursor
                node {
                  id
                  name
                  emailAddress
                }
              }
            }
          }
        }
      }
    }
  }
`;

const styles = () => ({
  error: {
    color: "red",
  },
});

const RemoveMemberDialog = ({
  open,
  onCloseDialog,
  memberId,
  userGroupId,
  classes,
}) => {
  const [deleteError, setDeleteError] = useState();
  const [removeMember, { loading: updating }] = useMutation(REMOVE_MEMBER);

  const handleRemoveMember = () => {
    const variables = { input: { memberId, userGroupId } };

    removeMember({ variables }).then(
      ({
        data: {
          account: {
            userGroups: {
              removeMember: { error, userGroup },
            },
          },
        },
      }) => {
        if (error !== null) {
          const message = `${error.message} (${error.code})`;
          setDeleteError(message);
        } else {
          onCloseDialog(userGroup.members);
        }
      }
    );
  };

  return (
    <Dialog open={open} onClose={() => onCloseDialog()}>
      <DialogTitle>Remove member</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to remove the member from this group? They may
          still have access through other groups.
        </DialogContentText>
        {deleteError && (
          <DialogContentText className={classes.error}>
            {deleteError}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onCloseDialog()}
          color="primary"
          disabled={updating}
        >
          Cancel
        </Button>
        <Button
          onClick={handleRemoveMember}
          color="primary"
          autoFocus
          disabled={updating}
        >
          Yes, remove member
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RemoveMemberDialog.propTypes = {
  onCloseDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withStyles(styles)(withMobileDialog()(RemoveMemberDialog));
