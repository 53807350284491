import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import moment from "moment";

import { InfoWindow } from "@react-google-maps/api";
import Button from "@mui/material/Button";
import CarCrashIcon from "@mui/icons-material/CarCrash";
import ClockIcon from "@mui/icons-material/AccessTime";
import SpeedIcon from "@mui/icons-material/Speed";
import MarkerIcon from "@mui/icons-material/Room";

const styles = (theme) => ({
  findFootageButton: {
    padding: "3px 10px 3px 3px",
    textTransform: "none",
    fontWeight: "normal",
    width: "100%",
    marginTop: theme.spacing(2),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  container: {
    display: "inline-flex",
    alignItems: "center",
  },
  icons: {
    paddingRight: 5,
  },
});

const VehicleSafetyEventNodeInfoWindow = ({
  node,
  onCloseWindow,
  onCreateIncident,
  classes,
  supportsVideo,
  incidentsEnabled,
}) => (
  <InfoWindow
    position={node.coord}
    onCloseClick={onCloseWindow}
    options={{ pixelOffset: new window.google.maps.Size(0, -16) }}
  >
    <div>
      <h3>{node.title}</h3>
      <p>
        <div className={classes.container}>
          <ClockIcon className={classes.icons} fontSize="small" />
          <b>Time</b>: {moment(node.occurredAt).format("LTS")}{" "}
        </div>
        <br />
        <div className={classes.container}>
          <SpeedIcon className={classes.icons} fontSize="small" />
          <b>Speed</b>: {node.speed}{" "}
          <small>
            <abbr>MPH</abbr>
          </small>
          {node.speedLimit && (
            <span>
              {" "}
              ({node.speedLimit}
              <small>
                <abbr>MPH</abbr>
              </small>{" "}
              limit)
            </span>
          )}
        </div>
        <br />
        <div className={classes.container}>
          <MarkerIcon className={classes.icons} fontSize="small" />
          <b>Position</b>:{" "}
          {node.coord && `${node.coord.lat}, ${node.coord.lng}`}
        </div>
      </p>
      <br />
      {supportsVideo && incidentsEnabled && (
        <Button
          size="small"
          variant="outlined"
          className={classes.findFootageButton}
          onClick={onCreateIncident(node.occurredAt)}
        >
          <CarCrashIcon className={classes.leftIcon} /> Create incident
        </Button>
      )}
    </div>
  </InfoWindow>
);

VehicleSafetyEventNodeInfoWindow.propTypes = {
  node: PropTypes.object.isRequired,
  onCloseWindow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VehicleSafetyEventNodeInfoWindow);
