import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles";

const SelectAutocomplete = ({
  label,
  options,
  getOptionLabel,
  renderOption,
  defaultValues,
  handleChange,
  handleChangeInput,
  inputValue,
  ...rest
}) => {
  const useStyles = makeStyles(
    {
      root: { minWidth: 200, width: "100%" },
      popup: { zIndex: 1500 },
      paper: { width: "fit-content", minWidth: "100%" },
      listbox: { width: "max-content", minWidth: "100%" },
    },
    { name: "MuiAutocomplete" }
  );

  useStyles();

  return (
    <Autocomplete
      value={defaultValues}
      freeSolo
      options={options}
      getOptionLabel={(option) => getOptionLabel(option)}
      renderOption={renderOption}
      onChange={(_, value) => {
        handleChange(value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth
          onInput={handleChangeInput}
          style={{ backgroundColor: "white" }}
          {...rest}
        />
      )}
    />
  );
};

export default SelectAutocomplete;
