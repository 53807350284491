import React from "react";
import withStyles from '@mui/styles/withStyles';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Icon } from "@mui/material";
import AnchorLink from "@mui/material/Link";
import AdapterLink from "../components/form/Link";

import { blue } from '@mui/material/colors';

const styles = theme => ({
  root: {
    width: "100%"
  },
  table: {
    minWidth: "600px"
  },
  tableWrapper: {
    overflowX: "auto"
  },
  roleChip: {
    marginRight: theme.spacing(2)
  },
  justifyCell: {
    textAlign: "center"
  },
  link: {
    fontWeight: 500
  },
  adminBadge: {
    backgroundColor: blue[500],
    color: "white",
    padding: "5px 3px",
    marginLeft: theme.spacing(1)
  }
});

const GroupsList = ({ classes = {}, onOpenDialog, data }) => {
  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Group</TableCell>
              <TableCell>Members</TableCell>
              <TableCell>Depots</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.edges &&
              data.edges.map(({ node: userGroup, cursor }) => (
                <TableRow hover key={cursor}>
                  <TableCell variant="head">
                    <AnchorLink
                      component={AdapterLink}
                      className={classes.link}
                      to={`/admin/groups/${userGroup.id}`}
                    >
                      {userGroup.name}
                    </AnchorLink>
                    {userGroup.isTeamAdmin && (
                      <span className={classes.adminBadge}>ADMIN</span>
                    )}
                  </TableCell>
                  <TableCell>{userGroup.members.totalCount}</TableCell>
                  <TableCell>{userGroup.depots.totalCount}</TableCell>
                  <TableCell>
                    <Tooltip title="Remove group">
                      <IconButton onClick={() => onOpenDialog(userGroup.id, userGroup.name)} size="large">
                        <Icon>cancel</Icon>
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default withStyles(styles)(GroupsList);
