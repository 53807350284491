import React from "react";
import withStyles from "@mui/styles/withStyles";
import { Redirect } from "react-router-dom";
import { useAuth } from "../AuthContext";
import classNames from "classnames";

import AppBar from "./AppBar";
import Typography from "@mui/material/Typography";

import { default as AppDrawer } from "../components/Drawer";
import { PermissionValues } from "../containers/GroupPermissions";

const DRAWER_WIDTH = 240;

const styles = (theme) => ({
  "@global": {
    html: {
      background: theme.palette.background.default,
      WebkitFontSmoothing: "antialiased", // Antialiasing.
      MozOsxFontSmoothing: "grayscale", // Antialiasing.
      boxSizing: "border-box",
      "@media print": {
        background: theme.palette.common.white,
      },
    },
  },
  root: {
    display: "flex",
    alignItems: "stretch",
    minHeight: "100vh",
    width: "100%",
  },
  appFrame: {
    position: "relative",
    display: "flex",
    width: "100%",
  },
  flexBoxContent: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  content: {
    backgroundColor: theme.palette.background.default,
    width: "100%",
    marginTop: 56,
    [theme.breakpoints.up("sm")]: {
      marginTop: 64,
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: DRAWER_WIDTH,
    },
  },
  children: {
    margin: theme.spacing(3),
  },
});

const DefaultLayout = ({ children, classes }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { isAuthenticated, account } = useAuth();

  const tenant = account?.tenant;

  const tenantFeatures = tenant?.features || {
    isSupportTracking: tenant?.isSupportTracking || false,
    nearMissEnabled: tenant?.nearMissEnabled || false,
    vehiclesEnabled: true,
    incidentsEnabled: true,
    driverReviewEnabled: true,
  };

  const externalLinks = tenant?.externalLinks;

  const groupPermissions = account?.groupPermissions || [
    "vehicles",
    "incidents",
    "driver-review",
    "near-miss",
    "live-share-tracking",
  ];

  const { Incidents } = PermissionValues;

  const isEnabled = (property, value) =>
    tenantFeatures?.[property] && groupPermissions.includes(value);

  const incidentsEnabled = isEnabled("incidentsEnabled", Incidents);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.root}>
      <div className={classes.appFrame}>
        <AppBar
          onToggleDrawer={handleDrawerToggle}
          isAuthenticated={isAuthenticated}
          account={account}
          incidentsEnabled={incidentsEnabled}
        />
        <AppDrawer
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          tenantFeatures={tenantFeatures}
          externalLinks={externalLinks}
          groupPermissions={groupPermissions}
        />
        <div className={classNames(classes.content, classes.flexBoxContent)}>
          <Typography component="div" className={classes.flexBoxContent}>
            <div
              className={classNames(classes.children, classes.flexBoxContent)}
            >
              {children}
            </div>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(DefaultLayout);
