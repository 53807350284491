export const ACTIVE = "ACTIVE";
export const CLOSED = "CLOSED";
export const FILE_CLAIM_REQUESTED = "FILE_CLAIM_REQUESTED";
export const CLAIM_FILED = "CLAIM_FILED";

export const mapToDisplay = key => {
  if (key === ACTIVE) return "Active";
  if (key === CLOSED) return "Closed";
  if (key === FILE_CLAIM_REQUESTED) return "Filing Claim";
  if (key === CLAIM_FILED) return "Filed";

  return key;
};

export const isValidKey = key => {
  return (
    key === ACTIVE ||
    key === CLOSED ||
    key === FILE_CLAIM_REQUESTED ||
    key === CLAIM_FILED
  );
};
