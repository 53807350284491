import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import makeStyles from "@mui/styles/makeStyles";
import { Icon, IconButton, Tooltip } from "@mui/material";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";

import LoadingContent from "./LoadingContent";
import { copy } from "../../../Clipboard";

// FIXME checkout https://mui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
const withMobileDialog = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="lg" fullScreen={false} />;

const ACTIVATE = gql`
  mutation Activate {
    integrations {
      orbcomm {
        activate {
          credentials {
            accessKey
            secretAccessKey
          }
          error {
            code
            message
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    transform: "rotate(-270deg)",
    fontSize: 18,
  },
  accessKey: {
    margin: 0,
    marginTop: theme.spacing(2),
  },
  secretAccessKey: {
    margin: 0,
    marginTop: theme.spacing(-1),
  },
}));

const ActivateDialog = ({ open, fullScreen, handleClose, onActivate }) => {
  const classes = useStyles();
  const [activate] = useMutation(ACTIVATE);
  const [errorMess, setErrorMess] = useState();
  const [info, setInfo] = useState();
  const [loading, setLoading] = useState(false);

  const handleActivate = () => {
    setLoading(true);
    activate().then(
      ({
        data: {
          integrations: {
            orbcomm: {
              activate: { error, credentials },
            },
          },
        },
      }) => {
        if (error !== null) {
          switch (error.code) {
            case 200640:
              setErrorMess(
                "You do not have permission to enable Orbcomm integration"
              );
              break;
            case 200641:
              setErrorMess("Orbcomm integration is already active");
              break;
            default:
              setErrorMess("Error!!!");
              break;
          }
          setLoading(false);
          return;
        }
        onActivate(credentials);
        setInfo(credentials);
        setLoading(false);
      }
    );
  };

  const func = React.useCallback(handleActivate, []);

  React.useEffect(() => {
    open === "activate" && func();
  }, [open, func]);

  const id = "dialogContent";

  return (
    <Dialog
      open={Boolean(open === "activate")}
      fullScreen={fullScreen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Orbcomm integration</DialogTitle>
      {loading ? (
        <DialogContent>
          <div className={classes.center}>
            <LoadingContent />
          </div>
        </DialogContent>
      ) : (
        <Fragment>
          <DialogContent id={id}>
            {!errorMess && (
              <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                This is the only time that the secret access keys can be viewed.
                You cannot receive them later. However, you can create new
                access keys at any time.
              </Alert>
            )}
            <p className={classes.accessKey}>
              Access key ID: {info && info.accessKey}{" "}
              {info && (
                <Tooltip title="Copy">
                  <IconButton
                    onClick={() => copy(id, info.accessKey)}
                    size="large"
                  >
                    <Icon className={classes.icon}>filter_none</Icon>
                  </IconButton>
                </Tooltip>
              )}
            </p>
            <p className={classes.secretAccessKey}>
              Secret access key: {info && info.secretAccessKey}{" "}
              {info && (
                <Tooltip title="Copy">
                  <IconButton
                    onClick={() => copy(id, info.secretAccessKey)}
                    size="large"
                  >
                    <Icon className={classes.icon}>filter_none</Icon>
                  </IconButton>
                </Tooltip>
              )}
            </p>
            {errorMess && (
              <FormHelperText error={true}>{errorMess}</FormHelperText>
            )}
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Fragment>
      )}
    </Dialog>
  );
};

ActivateDialog.propTypes = {
  open: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withMobileDialog()(ActivateDialog);
