import React from "react";
import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';

import TextField from "../components/form/TextField";
import Select from "../components/form/Select";
import { Form, Field } from "react-final-form";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { MenuItem, FormControl } from "@mui/material";
import {
  ACTIVE,
  CLOSED,
  CLAIM_FILED,
  mapToDisplay as mapStatusToDisplay
} from "../IncidentStatus";

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  applyButton: {
    height: "56px",
    marginRight: theme.spacing(3)
  }
}));

const IncidentFilterForm = ({ initialFormValues, clear, apply, className }) => {
  const classes = useStyles();

  const status = [
    { name: mapStatusToDisplay(ACTIVE), id: ACTIVE },
    { name: mapStatusToDisplay(CLOSED), id: CLOSED },
    { name: mapStatusToDisplay(CLAIM_FILED), id: CLAIM_FILED }
  ];

  const statusOptions = [
    <MenuItem key="" value={null}>
      All
    </MenuItem>,
    ...status.map(status => (
      <MenuItem key={status.id} value={status.id}>
        {status.name}
      </MenuItem>
    ))
  ];

  const handleApplyFilters = form => {
    apply(form);
  };

  const handleResetFilters = formApi => () => {
    formApi.reset();
    clear();
  };

  return (
    <div className={className}>
      <Form
        onSubmit={handleApplyFilters}
        initialValues={initialFormValues}
        render={({ handleSubmit, dirty, form }) => (
          <form onSubmit={handleSubmit}>
            <FormControl className={classes.formControl}>
              <Field
                component={TextField}
                name="reference"
                autoComplete="off"
                label="Reference"
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Field
                component={Select}
                name="status"
                autoComplete="off"
                label="Status"
                children={statusOptions}
              />
            </FormControl>
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              className={classes.applyButton}
            >
              Apply
            </Button>

            <Tooltip title="Clear filters">
              <Button
                variant="text"
                size="small"
                onClick={handleResetFilters(form)}
                // disabled={!dirty}
              >
                clear filters
              </Button>
            </Tooltip>
          </form>
        )}
      />
    </div>
  );
};

IncidentFilterForm.propTypes = {
  initialFormValues: PropTypes.object,
  clear: PropTypes.func.isRequired,
  apply: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default IncidentFilterForm;
