import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import withStyles from "@mui/styles/withStyles";
import moment from "moment";
import { useForm, Controller, useWatch } from "react-hook-form";
import { Field } from "react-final-form";

import { mapToDisplay as mapCameraToDisplay } from "../CameraNames";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";

import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import VehicleTimeAtLocationButton from "../components/VehicleTimeAtLocationButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import INCIDENT_FRAGMENT from "../fragments/incident";

const withMobileDialog = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="lg" fullScreen={false} />;

const REQUEST_FOOTAGE = gql`
  mutation RequestVehicleMedia($input: RequestVehicleMediaInput!) {
    incidents {
      requestFootage(input: $input) {
        incident {
          ...incident
        }
      }
    }
  }

  ${INCIDENT_FRAGMENT}
`;

const styles = (theme) => ({
  errorText: {
    paddingTop: theme.spacing(2),
    color: theme.palette.error.main,
  },
  flex: {
    display: "flex",
    alignItems: "baseline",
  },
  iconRight: {
    marginLeft: theme.spacing(1),
  },
  itemContainer: {
    paddingTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
});

const endTimeDisplayText = (startTime, duration) => {
  return Boolean(startTime)
    ? `${moment(`2000-01-01T${startTime}`).format("LT")}-${moment(
        `2000-01-01T${startTime}`
      )
        .add(moment.duration(`00:${duration}:00`))
        .format("LT")}`
    : null;
};

const RequestVehicleFootageDialog = ({
  incidentId,
  availableCameras,
  defaultDate,
  open,
  classes,
  vehicleId,
  fullScreen,
  onClose,
  onRequested,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { isSubmitting: submitting },
    reset,
  } = useForm({
    defaultValues: {
      date: defaultDate,
      time: "",
      duration: "00:02:00",
    },
  });

  const duration = useWatch({
    control,
    name: "duration",
  });
  const time = useWatch({
    control,
    name: "time",
  });
  const [freeTimespanAllowed, setFreeTimespanAllowed] = React.useState(false);
  const [error, setError] = React.useState(null);

  const [selectedCameras, setSelectedCameras] = React.useState([]);
  const handleSelectCamera = (value) => {
    const isPresent = selectedCameras.indexOf(value);
    if (isPresent !== -1) {
      const remaining = selectedCameras.filter((item) => item !== value);
      setSelectedCameras(remaining);
    } else {
      setSelectedCameras((prevItems) => [...prevItems, value]);
    }
  };

  React.useEffect(() => {
    setValue("cameras", selectedCameras);
  }, [selectedCameras]);

  const [requestFootage] = useMutation(REQUEST_FOOTAGE);

  React.useEffect(() => {
    if (duration === "custom") setFreeTimespanAllowed(true);
  }, [duration]);

  React.useEffect(() => {
    reset();
    setError(null);
  }, [open]);

  const onSubmit = (data) => {
    const startAt = moment(`${data.date}T${data.time}`);

    console.log("startAt", startAt);
    let duration = data.duration;
    const cameraIds = data.cameras;

    if (moment.utc().isBefore(startAt)) {
      setError("Cannot request footage in the future");
      return;
    }

    if (error) return;

    if (data.minutes) {
      let hours = Math.floor(data.minutes / 60);
      let minutes = Math.floor(data.minutes % 60);
      duration = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:00`;
    }

    const variables = {
      input: { incidentId, startAt: startAt.format(), duration, cameraIds },
    };

    return requestFootage({
      variables,
    }).then(
      () => {
        onRequested();
      },
      (error) => {
        setError(error.message);
      }
    );
  };

  const handleDateTimeSelected = (dt) => {
    setValue("date", moment(dt).format("YYYY-MM-DD"));
    setValue("time", moment(dt).format("HH:mm"));
  };

  const handleDateChange = (value) => {
    try {
      setError(null);
      if (!value.isValid()) {
        setError("Date is required");
        return;
      }
      if (moment().isBefore(value)) {
        setError("Cannot request footage in the future");
        return;
      }

      if (moment().subtract(1, "year").isAfter(value)) {
        setError(
          `Please select a date after ${moment()
            .subtract(1, "year")
            .format("YYYY")}`
        );
        return;
      }

      setValue("date", value.format("YYYY-MM-DD"));
    } catch (error) {
      setError("Date is required");
    }
  };

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      open={open}
      disableEscapeKeyDown={submitting}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="alert-dialog-title" sx={{ m: 0, p: 2 }}>
          Request extra footage
          {onClose ? (
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>

        <DialogContent>
          <div>
            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <div className={classes.itemContainer}>
                    <Field name="date">
                      {() => (
                        <DatePicker
                          disableFuture={true}
                          label="Date"
                          value={moment(defaultDate)}
                          required
                          onChange={handleDateChange}
                        />
                      )}
                    </Field>
                  </div>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6} sm={6}>
                <div className={classes.flex}>
                  <Controller
                    name={"time"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        value={value}
                        onChange={onChange}
                        margin="normal"
                        required
                        onBlur={() => {
                          setError(null);
                        }}
                        label="Start time"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="time"
                        fullWidth
                      />
                    )}
                  />
                  <VehicleTimeAtLocationButton
                    vehicleId={vehicleId}
                    className={classes.iconRight}
                    onSelected={handleDateTimeSelected}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={7}>
                {freeTimespanAllowed ? (
                  <Controller
                    name="minutes"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        value={value}
                        onChange={onChange}
                        InputProps={{ inputProps: { min: 1, max: 120 } }}
                        margin="normal"
                        required
                        label="Minutes of footage"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="number"
                        fullWidth
                      />
                    )}
                  />
                ) : (
                  <Controller
                    name="duration"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControl fullWidth>
                        <InputLabel>Minutes of footage</InputLabel>
                        <Select
                          value={value}
                          onChange={onChange}
                          label="Minutes of footage"
                          fullWidth
                          required
                        >
                          {[1, 2, 5, 10, 15].map((mins) => (
                            <MenuItem
                              value={`00:${mins
                                .toString()
                                .padStart(2, "0")}:00`}
                              key={`mins-${mins}`}
                            >
                              {mins} minutes
                              {time && ` (${endTimeDisplayText(time, mins)})`}
                            </MenuItem>
                          ))}
                          <MenuItem value="custom">Custom</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                )}
              </Grid>
              {Boolean(availableCameras) &&
                Boolean(availableCameras.length) && (
                  <Grid item xs={12}>
                    <div>
                      <FormControl
                        component="fieldset"
                        name="cameras"
                        className={classes.formControl}
                      >
                        <FormLabel component="legend">Cameras</FormLabel>
                        <FormGroup row>
                          {availableCameras.map((camera) => (
                            <FormControlLabel
                              key={camera.id}
                              name="cameras"
                              control={
                                <Controller
                                  render={({}) => {
                                    return (
                                      <Checkbox
                                        type="checkbox"
                                        checked={selectedCameras.includes(
                                          camera.id
                                        )}
                                        onChange={() =>
                                          handleSelectCamera(camera.id)
                                        }
                                      />
                                    );
                                  }}
                                  control={control}
                                />
                              }
                              label={mapCameraToDisplay(camera.location)}
                            />
                          ))}
                        </FormGroup>
                      </FormControl>
                    </div>
                  </Grid>
                )}
            </Grid>
          </div>

          {error && (
            <DialogContentText className={classes.errorText}>
              {error}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            disabled={submitting}
            onClick={onClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={submitting || Boolean(error)}
            onClick={handleSubmit}
            color="primary"
            autoFocus
          >
            Request footage
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

RequestVehicleFootageDialog.propTypes = {
  incidentId: PropTypes.string.isRequired,
  vehicleId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onRequested: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  availableCameras: PropTypes.array,
  defaultDate: PropTypes.string,
};

export default withMobileDialog()(
  withStyles(styles)(RequestVehicleFootageDialog)
);
