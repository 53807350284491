import React, { Fragment } from "react";
import PropTypes from "prop-types";
import ordinal from "ordinal";
import withStyles from '@mui/styles/withStyles';

import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import Switch from "../components/form/Switch";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import ThirdPartyDetailForm from "../components/ThirdPartyDetailForm";

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
});

const IncidentCoreDetailsForm = ({ fieldName, value, src, classes, formValues }) => (
  <div>
    <Field
      component={Switch}
      name={`thirdParties.thirdPartiesInvolved`}
      margin="normal"
      label="Third party involved"
      type="checkbox"
    />

    {value.thirdPartiesInvolved && (
      <FieldArray name="thirdParties.thirdParties">
        {({ fields }) => (
          <Fragment>
            <Grid spacing={4} container>
              {fields.map((fieldName, idx) => (
                <Grid key={`tp-${idx}`} item xs={12} lg={6}>
                  <Card className={classes.card}>
                    <CardHeader
                      title={`${ordinal(idx + 3)} party`}
                      action={
                        <IconButton
                          onClick={() => {
                            fields.remove(idx);
                          }}
                          size="large">
                          <DeleteIcon />
                        </IconButton>
                      }
                    />
                    <CardContent>
                      <ThirdPartyDetailForm fieldName={fieldName} formValues={formValues} idx={idx} />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            {value.thirdPartiesInvolved && (
              <div>
                <p>
                  <Button onClick={() => fields.push({})}>
                    Add another parties details
                  </Button>
                </p>
              </div>
            )}
          </Fragment>
        )}
      </FieldArray>
    )}
  </div>
);

IncidentCoreDetailsForm.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(IncidentCoreDetailsForm);
