import React, { useState } from "react";
import gql from "graphql-tag";

import VehicleAdminList from "../components/VehicleAdminList";
import { Query } from "@apollo/client/react/components";
import { Mutation } from "@apollo/client/react/components";
import LinearProgress from "@mui/material/LinearProgress";
import EditVehicleDialog from "../containers/EditVehicleDialog";
import MoveDepotDialog from "../containers/MoveDepotDialog";
import NextPreviousPagination from "../components/NextPreviousPagination";
 
const QUERY = gql`
  query AllVehiclesAdmin($after: String, $before: String, $filter: VehicleFilter) {
    vehicles {
      all(after: $after, before: $before, filter: $filter) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          endCursor
          startCursor
        }
        edges {
          cursor
          node {
            id
            deviceId
            driverId
            vrm
            vin
            depot {
              id
              name
            }
            driver {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const MOVE_DEPOT = gql`
  mutation moveDepot($input: MoveDepotInput!) {
    vehicles {
      moveDepot(input: $input) {
        error {
          code
          message
        }
      }
    }
  }
`; 

const VehiclesAdmin = ({search}) => {
  const [editOpen, setEditOpen] = useState(false);
  const [vehicle, setVehicle] = useState({});
  const [depotId, setDepotId] = useState();
  const [vehicleId, setVehicleId] = useState();

  const variablesGraphQL = { filter: { vrmLike: search.name} };
 
  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleEditClick = (vehicle) => {
    setEditOpen(true);
    setVehicle(vehicle);
  };

  const onMoveDepot = (vehicleId, depotId) => {
    setVehicleId(vehicleId);
    setDepotId(depotId);
  };

  const handleFormSubmit = (moveDepot) => (form) => {
    const variables = {
      input: { vehicleId: vehicleId, depotIdToMoveVehiclesTo: form.depotId },
    };
    moveDepot({ variables }).then(setDepotId());
  };

  const handleMoveDepotClose = () => {
    setDepotId();
  };
  
  return (
    <Query query={QUERY} variables={variablesGraphQL} fetchPolicy="cache-and-network">
      {({ loading, error, data, fetchMore }) => {
        if (loading) return <LinearProgress />;
        if (error) return JSON.stringify(error);

        return (
          <Mutation
            mutation={MOVE_DEPOT}
            refetchQueries={(data) => [
              {
                query: QUERY,
              },
            ]}
          >
            {(moveDepot) => (
              <section> 
                <VehicleAdminList
                  allVehicles={data.vehicles.all}
                  handleEditClick={handleEditClick}
                  onMoveDepot={onMoveDepot}
                />

                <NextPreviousPagination
                  {...{
                    fetchMore,
                    loading,
                    pageInfo: data.vehicles.all.pageInfo,
                  }}
                />

                <MoveDepotDialog
                  open={Boolean(depotId)}
                  initialFormValues={{
                    depotId: depotId,
                  }}
                  handleFormSubmit={handleFormSubmit(moveDepot)}
                  handleClose={handleMoveDepotClose}
                />

                <EditVehicleDialog
                  open={editOpen}
                  handleClose={handleEditClose}
                  vehicle={vehicle}
                  onUpdated={handleEditClose}
                />
              </section>
            )}
          </Mutation>
        );
      }}
    </Query>
  );
};

export default VehiclesAdmin;