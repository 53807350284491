import React, { useState } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import withStyles from "@mui/styles/withStyles";
import { useMutation } from "@apollo/client";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";

import { USER_GROUP_QUERY } from "../containers/Groups";

// FIXME checkout https://mui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
const withMobileDialog = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="lg" fullScreen={false} />;

const REMOVE_GROUP = gql`
  mutation DeleteUserGroup($input: DeleteUserGroupInputType!) {
    account {
      userGroups {
        delete(input: $input) {
          error {
            code
            message
          }
          userGroupId
        }
      }
    }
  }
`;

const styles = () => ({
  error: {
    color: "red",
  },
});

const RemoveGroupDialog = ({
  onCloseDialog,
  groupName,
  groupId,
  open,
  classes,
}) => {
  const [deleteError, setDeleteError] = useState();

  const [removeGroup, { loading: updating }] = useMutation(REMOVE_GROUP);

  const handleRemoveGroup = () => {
    const variables = { input: { userGroupId: groupId } };
    removeGroup({
      variables,
      update: (
        cache,
        {
          data: {
            account: {
              userGroups: {
                delete: { userGroupId },
              },
            },
          },
        }
      ) => {
        const groups = cache.readQuery({
          query: USER_GROUP_QUERY,
        });

        const newGroups = groups.account.userGroups.all.edges.filter(
          ({ node }) => node.id !== userGroupId
        );
        groups.account.userGroups.all.edges = newGroups;

        cache.writeQuery({
          query: USER_GROUP_QUERY,
          data: groups,
        });
      },
    }).then(
      ({
        data: {
          account: {
            userGroups: {
              delete: { error },
            },
          },
        },
      }) => {
        if (error !== null) {
          const message = `${error.message} (${error.code})`;
          setDeleteError(message);
        } else onCloseDialog("removed");
      }
    );
  };

  return (
    <Dialog open={open} onClose={onCloseDialog}>
      <DialogTitle>Remove group</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to remove the group {groupName}?
        </DialogContentText>
        {deleteError && (
          <DialogContentText className={classes.error}>
            {deleteError}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseDialog} color="primary" disabled={updating}>
          Cancel
        </Button>
        <Button
          onClick={handleRemoveGroup}
          color="primary"
          autoFocus
          disabled={updating}
        >
          Yes, remove group
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RemoveGroupDialog.propTypes = {
  onCloseDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withStyles(styles)(withMobileDialog()(RemoveGroupDialog));
