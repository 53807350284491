import React, { useState } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";

// FIXME checkout https://mui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
const withMobileDialog = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="lg" fullScreen={false} />;

const DEACTIVATE = gql`
  mutation Deactivate {
    integrations {
      tomTom {
        deactivate {
          error {
            code
            message
          }
        }
      }
    }
  }
`;

const DeactivateDialog = ({ open, fullScreen, handleClose, onDeactivate }) => {
  const [errorMess, setErrorMess] = useState();
  const [deactivate] = useMutation(DEACTIVATE);
  const [loading, setLoading] = useState(false);

  const handleDeactivate = () => {
    setLoading(true);
    deactivate().then(
      ({
        data: {
          integrations: {
            tomTom: {
              deactivate: { error },
            },
          },
        },
      }) => {
        if (error !== null) {
          const message = `${error.message} (${error.code})`;
          setErrorMess(message);
          setLoading(false);
          return;
        }
        setLoading(false);
        onDeactivate();
        handleClose();
      }
    );
  };

  return (
    <Dialog
      open={Boolean(open === "deactivate")}
      fullScreen={fullScreen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Deactivate</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to deactive TomTom integration?
        </DialogContentText>
        {errorMess && <FormHelperText error={true}>{errorMess}</FormHelperText>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDeactivate} disabled={loading} color="primary">
          Deactivate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeactivateDialog.propTypes = {
  open: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withMobileDialog()(DeactivateDialog);
