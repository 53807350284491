import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";

import DefaultLayout from "../components/DefaultLayout";
import WhichVehicleMap from "../containers/WhichVehicleMap";

const styles = (theme) => ({
  root: {
    margin: theme.spacing(-3),
  },
  map: {
    height: "calc(100vh - 64px);",
  },
});

const WhichVehiclePage = ({ classes }) => {
  return (
    <DefaultLayout>
      <div className={classes.root}>
        <div className={classes.map}>
          <WhichVehicleMap />
        </div>
      </div>
    </DefaultLayout>
  );
};

WhichVehiclePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WhichVehiclePage);
