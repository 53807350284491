export const DISMISSED = "DISMISSED";
export const NEEDS_REVIEW = "NEEDS_REVIEW";
export const REVIEWED = "REVIEWED";

// const toDisplayName

export const toDisplayName = (status) => {
  if (DISMISSED === status) return "Dismissed";
  if (NEEDS_REVIEW === status) return "Needs Review";
  if (REVIEWED === status) return "Reviewed";
  return `** ${status}`;
};
