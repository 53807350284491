import React from "react";
import PropTypes from "prop-types";

import List from "../components/VehicleFootageList";

import VehicleStreamRecordedFootageDialog from "../components/VehicleStreamRecordedFootageDialog";

const VehicleFootageList = ({ footage }) => {
  const [streamPlayerSource, setStreamPlayerSource] = React.useState(null);

  const handlePlayStream = (stream) => {
    setStreamPlayerSource(stream);
  };

  const handleCloseStreamPlayer = () => {
    setStreamPlayerSource(null);
  };

  return (
    <>
      <List footage={footage} playStream={handlePlayStream} />
      <VehicleStreamRecordedFootageDialog
        open={Boolean(streamPlayerSource)}
        onClose={handleCloseStreamPlayer}
        stream={streamPlayerSource}
      />
    </>
  );
};

VehicleFootageList.propTypes = {
  footage: PropTypes.object.isRequired,
};

export default VehicleFootageList;
