import React from "react";
import qs from "querystringify";
import DriverReview from "../components/DriverReview/Root";
import DefaultLayout from "../components/DefaultLayout";
import { Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

const DriverReviewPage = () => {
  let history = useHistory();

  return (
    <DefaultLayout>
      <Typography variant="h5" gutterBottom>
        Driver review
      </Typography>
      <DriverReview
        search={qs.parse(history.location.search)}
        onSearch={(q) =>
          history.push(`${history.location.pathname}?${qs.stringify(q)}`)
        }
      />
    </DefaultLayout>
  );
};

export default DriverReviewPage;
