import React from "react";
import PropTypes from "prop-types";

import { Form } from "react-final-form";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormHelperText from "@mui/material/FormHelperText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import MoveDepotForm from "../components/MoveDepotForm";
import { DialogContentText } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

// FIXME checkout https://mui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
const withMobileDialog = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="lg" fullScreen={false} />;

const MoveDepotDialog = ({
  open,
  handleClose,
  handleFormSubmit,
  initialFormValues,
  errorCode,
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
    maxWidth="xs"
    fullWidth
    aria-labelledby="form-dialog-title"
  >
    <Form
      onSubmit={handleFormSubmit}
      initialValues={initialFormValues}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">
            Move vehicle to another depot
            {handleClose ? (
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>

          <DialogContent>
            <DialogContentText>&nbsp;</DialogContentText>
            <MoveDepotForm errorCode={errorCode} />
            {errorCode && (
              <FormHelperText error={true}>
                {"Move to same depot"}
              </FormHelperText>
            )}
          </DialogContent>
          <DialogActions>
            <Button disabled={submitting} onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button disabled={submitting} type="submit" color="primary">
              Move
            </Button>
          </DialogActions>
        </form>
      )}
    />
  </Dialog>
);

MoveDepotDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withMobileDialog()(MoveDepotDialog);
