import { Chip } from "@mui/material";

const StatusChip = ({ status }) => {
  if (status === "IN_PROGRESS")
    return (
      <Chip
        label={"In progress"}
        size="small"
        // color="info"
        variant="outlined"
      />
    );
  if (status === "COMPLETE")
    return (
      <Chip
        label={"Complete"}
        size="small"
        color="success"
        variant="outlined"
      />
    );
  if (status === "ISSUE_RAISED")
    return (
      <Chip
        label={"Issue raised"}
        size="small"
        color="error"
        variant="outlined"
      />
    );

  return <Chip label={status} size="small" color="error" variant="outlined" />;
};

export default StatusChip;
