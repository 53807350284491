import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import moment from "moment";

import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  Toolbar,
  AppBar,
  Slide,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  Grid,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { DISMISSED, REVIEWED, NEEDS_REVIEW, toDisplayName } from "./Statuses";
import FootageGrid from "./FootageGrid";
import GoogleMap from "../../containers/GoogleMap";
import { Marker } from "@react-google-maps/api";

const hexToRgb = (hex) => {
  function calculateFull(hex) {
    var r = parseInt(hex.substring(0, 2), 16);
    var g = parseInt(hex.substring(2, 4), 16);
    var b = parseInt(hex.substring(4, 6), 16);

    return [r, g, b];
  }

  function calculatePartial(hex) {
    var r = parseInt(hex.substring(0, 1) + hex.substring(0, 1), 16);
    var g = parseInt(hex.substring(1, 2) + hex.substring(1, 2), 16);
    var b = parseInt(hex.substring(2, 3) + hex.substring(2, 3), 16);

    return [r, g, b];
  }

  if (hex.length === 6) return calculateFull(hex);

  return calculatePartial(hex);
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  occurredAt: {
    ...theme.typography.subtitle2,
  },
  html5Video: {
    maxWidth: "100%",
  },
  formControl: {
    marginLeft: theme.spacing(2),
  },
  formSelectRoot: {
    color: theme.palette.primary.contrastText,
  },
  formSelectSelect: {
    color: theme.palette.primary.contrastText,
  },
  formLabel: {
    color: `rgba(${hexToRgb(theme.palette.primary.contrastText.substr(1)).join(
      ", "
    )}, 0.54)`,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EventDetailDialog = ({ open, onSetStatus, onClose, safetyEvent }) => {
  const classes = useStyles();

  const handleClose = onClose;

  const [settingStatus, setSettingStatus] = React.useState(false);
  const handleStatusChanged = (event) => {
    setSettingStatus(true);
    onSetStatus(safetyEvent.id, event.target.value).then(() => {
      setSettingStatus(false);
    });
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      {safetyEvent && (
        <>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                size="large">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {safetyEvent.title || "Safety Event"}
                <div className={classes.occurredAt}>
                  {moment(safetyEvent.occurredAt).format("lll")}
                </div>
              </Typography>
              <div>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Select
                    labelId="status-label"
                    value={safetyEvent.status}
                    onChange={handleStatusChanged}
                    disabled={settingStatus}
                    classes={{
                      root: classes.formSelectRoot,
                      icon: classes.formSelectSelect,
                    }}
                  >
                    <MenuItem value={NEEDS_REVIEW}>
                      {toDisplayName(NEEDS_REVIEW)}
                    </MenuItem>
                    <MenuItem value={REVIEWED}>
                      {toDisplayName(REVIEWED)}
                    </MenuItem>
                    <MenuItem value={DISMISSED}>
                      {toDisplayName(DISMISSED)}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Toolbar>
          </AppBar>
          <DialogContent className={classes.root}>
            <Container maxWidth="xl" disableGutters>
              {safetyEvent?.linkedFootage.footage.length > 1 && (
                <div>
                  <FootageGrid footage={safetyEvent.linkedFootage.footage} />
                  <GoogleMap
                    defaultZoom={12}
                    center={safetyEvent.coord}
                    options={{ mapTypeControl: false }}
                  >
                    <Marker position={safetyEvent.coord} />
                  </GoogleMap>
                </div>
              )}
              {safetyEvent?.linkedFootage.footage.length === 1 && (
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <video
                      className={classes.html5Video}
                      controls
                      muted
                      autoPlay
                    >
                      Sorry, your browser does not support embedded videos
                      <source
                        src={safetyEvent.linkedFootage.footage[0].signedUrl}
                      />
                    </video>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <GoogleMap
                      defaultZoom={12}
                      center={safetyEvent.coord}
                      options={{ mapTypeControl: false }}
                    >
                      <Marker position={safetyEvent.coord} />
                    </GoogleMap>
                  </Grid>
                </Grid>
              )}
            </Container>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

EventDetailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  safetyEvent: PropTypes.object,
  onSetStatus: PropTypes.func.isRequired,
};

export default EventDetailDialog;
