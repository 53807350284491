import React, { useState } from "react";
import DefaultLayout from "../../components/DefaultLayout";
import {
  Breadcrumbs,
  Typography,
  Link as MuiLink,
  Drawer,
  Container,
  useMediaQuery,
  Button,
  useTheme,
  IconButton,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Unstable_Grid2";
import { Link as RouterLink, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

import SidePanel from "./SidePanel";
import MainPanel from "./MainPanel";
import SidebarPanel from "./SidebarPanel";

import Query from "./Query";
import { useMutation, useQuery } from "@apollo/client";
import { CommentMutation, RemoveAttachEvidenceMutation } from "./Mutation";
import HistoryIcon from "@mui/icons-material/History";

const NearMissPage = () => {
  let { id } = useParams();
  const theme = useTheme();

  const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.down("xl"));

  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const { loading, error, data } = useQuery(Query, {
    fetchPolicy: "cache-and-network",
    variables: { id },
  });

  const [removeAttachEvidence, { loading: removingAttachEvidence }] =
    useMutation(RemoveAttachEvidenceMutation, {
      refetchQueries: () => {
        return [{ query: Query, variables: { id } }];
      },
    });

  const handleSubmitRemoveAttachEvidence = (attachEvidenceId) => {
    removeAttachEvidence({ variables: { attachEvidenceId } });
  };

  const nearMiss = data?.node;
  const [addComment, { loading: addingComment }] = useMutation(CommentMutation);

  const handleSubmitAddCommentForm = (ev) => {
    ev.preventDefault();
    const formData = new FormData(ev.target);
    const mdText = formData.get("comment");

    addComment({ variables: { nearMissId: id, mdText } });
  };

  return (
    <DefaultLayout>
      <Container maxWidth={"xl"} disableGutters sx={{ margin: "0" }}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
          <MuiLink
            underline="hover"
            color="inherit"
            to="/near-miss"
            component={RouterLink}
          >
            Near-miss
          </MuiLink>
          <Typography color="text.primary">
            {loading ? (
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={50} />
            ) : (
              nearMiss.reference
            )}
          </Typography>
        </Breadcrumbs>

        <Grid container spacing={4}>
          {!isTabletScreen && (
            <Grid xs={12} md={4} lg={4} xl={3}>
              <SidebarPanel />
            </Grid>
          )}
          <Grid xs={12} md={8} lg={8} xl={6}>
            <MainPanel
              nearMiss={nearMiss}
              loading={loading}
              removingAttachEvidence={removingAttachEvidence}
              onSubmitRemoveAttachEvidence={handleSubmitRemoveAttachEvidence}
            />
          </Grid>

          {isExtraLargeScreen ? (
            <>
              <Button
                sx={{
                  position: "fixed",
                  right: -42,
                  top: "25%",
                  zIndex: 999,
                  transform: "rotate(90deg)",
                  borderRadius: "0px 0px 4px 4px",
                }}
                variant="contained"
                onClick={toggleDrawer(true)}
                startIcon={<HistoryIcon />}
              >
                Activity
              </Button>
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Grid
                  xs={12}
                  style={{
                    right: 0,
                    zIndex: 999,
                    margin: "1rem",
                    marginTop: "80px",
                  }}
                >
                  <IconButton
                    sx={{
                      position: "absolute",
                      right: "10px",
                    }}
                    onClick={toggleDrawer(false)}
                  >
                    <CloseIcon />
                  </IconButton>
                  <SidePanel
                    nearMiss={nearMiss}
                    loading={loading}
                    onSubmitAddComment={handleSubmitAddCommentForm}
                    addingComment={addingComment}
                  />
                </Grid>
              </Drawer>
            </>
          ) : (
            <Grid xs={12} md={4} lg={5} xl={3}>
              <SidePanel
                nearMiss={nearMiss}
                loading={loading}
                onSubmitAddComment={handleSubmitAddCommentForm}
                addingComment={addingComment}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </DefaultLayout>
  );
};

export default NearMissPage;
