import React from "react";
import PropTypes from "prop-types";
import TextField from "../components/form/TextField";
import Grid from "@mui/material/Grid";
import { Field } from "react-final-form";

const validateName = value => !value && "Name is required";

const DepotForm = ({ value }) => (
  <Grid spacing={2} container>
    <Grid item xs={12}>
      <Field
        component={TextField}
        name="name"
        required
        validate={validateName}
        margin="dense"
        autoComplete="off"
        label="Name"
        fullWidth
        autoFocus
      />
    </Grid>
  </Grid>
);

DepotForm.propTypes = {
  value: PropTypes.object
};

export default DepotForm;
