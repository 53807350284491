import React, { Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";

import AdapterLink from "../components/form/Link";
import { Field } from "react-final-form";
import TextField from "../components/form/TextField";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";

const validateRequired = (fieldName) => (value) =>
  !value && `${fieldName} is required`;

const styles = (theme) => ({
  progress: {
    margin: `${theme.spacing(4)} auto ${theme.spacing(2)} auto`,
  },
});

const ForgottenPasswordForm = ({
  classes,
  disabled,
  valid,
  authenticating,
  submitted,
  error,
}) => (
  <div>
    {submitted ? (
      <p>
        We've sent you an email with a secure link to reset your password. The
        link is valid for 20 minutes, after that, you'll need to start the
        process again.
      </p>
    ) : (
      <Fragment>
        <p>
          Please enter your email address. We will send you an email with a
          secure link to reset your password.
        </p>

        <Field
          component={TextField}
          name="email"
          required
          validate={validateRequired("Email")}
          margin="normal"
          label="Email address"
          fullWidth
          disabled={disabled}
          autoFocus
        />

        <div>
          <Button disabled={disabled} component={AdapterLink} to="/login">
            Back
          </Button>
          <Button
            disabled={!valid || disabled}
            type="submit"
            variant="contained"
            color="secondary"
          >
            Send email
          </Button>
          {authenticating && (
            <LinearProgress color="secondary" className={classes.progress} />
          )}
          {error && <p>{error}</p>}
        </div>
      </Fragment>
    )}
  </div>
);

ForgottenPasswordForm.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  authenticating: PropTypes.bool,
  error: PropTypes.string,
};

export default withStyles(styles)(ForgottenPasswordForm);
