import React from "react";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { All as AllCameraNames, mapToDisplay } from "../CameraNames";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const EditCameraLocationDialog = ({
  open,
  working,
  initialLocation,
  error,
  onClose,
  onUpdateClick,
}) => {
  const [location, setLocation] = React.useState(initialLocation || "");

  React.useEffect(() => {
    setLocation(initialLocation || "");
  }, [open]);

  const handleChange = (ev) => {
    setLocation(ev.target.value);
  };

  const handleUpdateClick = () => {
    onUpdateClick(location);
  };

  if (!open) return null;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        Change camera name
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <p>
            To change the name of this camera, please select a new value here.
          </p>
        </DialogContentText>
        <FormControl fullWidth error={Boolean(error)}>
          <InputLabel id="camera-location-label" required>
            Camera
          </InputLabel>
          <Select
            labelId="camera-location-label"
            required
            id="demo-simple-select"
            value={location}
            label="Camera"
            onChange={handleChange}
            disabled={working}
          >
            {AllCameraNames.map((location) => (
              <MenuItem key={`location-${location}`} value={location}>
                {mapToDisplay(location)}
              </MenuItem>
            ))}
          </Select>
          {error && (
            <FormHelperText>Failed to update camera name</FormHelperText>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button disabled={working} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={working} onClick={handleUpdateClick}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCameraLocationDialog;
