import React, { Fragment } from "react";
import PropTypes from "prop-types";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import LocationIcon from "@mui/icons-material/LocationSearching";
import VehicleTimeAtLocationDialog from "../containers/VehicleTimeAtLocationDialog";

class VehicleTimeAtLocationButton extends React.Component {
  state = {
    open: false,
  };

  render() {
    const { vehicleId, className } = this.props;
    const { open } = this.state;

    const hasVehicleId = Boolean(vehicleId);

    return (
      <Fragment>
        <Tooltip title="Select incident time by vehicle location">
          <span>
            <IconButton
              disabled={!hasVehicleId}
              className={className}
              onClick={this.handleOpen.bind(this)}
              size="large"
            >
              <LocationIcon />
            </IconButton>
          </span>
        </Tooltip>
        {hasVehicleId && (
          <VehicleTimeAtLocationDialog
            vehicleId={vehicleId}
            open={open}
            onSelected={this.handleSubmit.bind(this)}
            onClose={this.handleClose.bind(this)}
          />
        )}
      </Fragment>
    );
  }

  handleSubmit = (dt) => {
    this.setState({ open: false });
    this.props.onSelected(dt);
  };

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }
}

VehicleTimeAtLocationButton.propTypes = {
  vehicleId: PropTypes.string,
  onSelected: PropTypes.func.isRequired,
};

export default VehicleTimeAtLocationButton;
