import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import GoogleMap from "../containers/GoogleMap";
import { Marker } from "@react-google-maps/api";
import LinearProgress from "@mui/material/LinearProgress";

import Overlayview from "../components/Overlayview";

const QUERY = gql`
  query LiveVehicleNode($id: ID!) {
    node(id: $id) {
      id
      ... on Vehicle {
        id
        live {
          online
          coord {
            lng
            lat
          }
          speed(unit: MPH)
          heading
          timestamp
          driver {
            name
            id
          }
        }
        driver {
          name
        }
        driverId
        vrm
      }
    }
  }
`;

const VehicleLiveMap = ({ id }) => {
  const [mapRef, setMapRef] = useState(null);
  const [expand, setExpand] = useState(false);
  const [zoom, setZoom] = useState(16);
  const [center, setCenter] = useState();

  const { data, loading } = useQuery(QUERY, {
    variables: { id },
    fetchPolicy: "cache-and-network",
    pollInterval: 10000,
  });

  const loadHandler = (map) => {
    setMapRef(map);
  };

  const handleOpenExpansion = (vrm) => {
    expand === vrm ? setExpand(false) : setExpand(vrm);
  };

  const handleCloseExpansion = () => {
    setExpand(false);
  };

  const handleZoomIn = (location) => {
    setZoom(16);
    setCenter(location);
  };

  const handleZoomChanged = () => {
    mapRef && setZoom(mapRef.zoom);
    mapRef && setCenter(mapRef.center);
  };

  const handleDragEnd = () => {
    mapRef && setCenter(mapRef.center);
  };

  const { node: { live = {}, driver = {}, vrm } = {} } = data || {};

  React.useEffect(() => {
    if (!loading && !live.coord)
      console.log("No live coordinates found", { id, live });
  }, [id, loading, live]);

  if (loading && !live.coord) return <LinearProgress />;
  // if (!live.coord) return <LinearProgress />;

  return (
    <Fragment>
      <GoogleMap
        loadHandler={loadHandler}
        defaultZoom={zoom}
        center={center || live.coord}
        options={{ mapTypeControl: true }}
        onZoomChanged={handleZoomChanged}
        onDragEnd={handleDragEnd}
      >
        {Boolean(live.coord) && Boolean(window.google?.maps) && (
          <Marker
            position={live.coord}
            icon={{
              url: "/icons/my_location.png",
              fillOpacity: 0.8,
              anchor: new window.google.maps.Point(17.5, 17.5),
              scaledSize: new window.google.maps.Size(35, 35),
            }}
          >
            <Overlayview
              id={id}
              live={live}
              expand={expand}
              vrm={vrm}
              handleOpenExpansion={handleOpenExpansion}
              driver={driver}
              handleCloseExpansion={handleCloseExpansion}
              onZoomIn={handleZoomIn}
              livePage={true}
            />
          </Marker>
        )}
      </GoogleMap>
    </Fragment>
  );
};

VehicleLiveMap.propTypes = {
  id: PropTypes.string.isRequired,
};

export default VehicleLiveMap;
