import React, { Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";

import { Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import { Switch, Route, Link, Redirect, useHistory } from "react-router-dom";

import RoutedTabs from "../components/RoutedTabs";
import GroupMembersTab from "../containers/GroupMembersTab";
import GroupDepotsTab from "../containers/GroupDepotsTab";
import ActionGroupMemberFab from "../components/ActionGroupMemberFab";

const styles = (theme) => ({
  fab: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
});

const TabContainer = ({ children }) => {
  return (
    <Typography
      component="div"
      style={{
        paddingTop: 8 * 3,
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      {children}
    </Typography>
  );
};

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const Group = ({ groupId, data }) => {
  const history = useHistory();

  return (
    <Fragment>
      <RoutedTabs
        location={history.location}
        variant="scrollable"
        scrollButtons="auto"
        selectors={[
          { tab: 0, path: `/admin/groups/${groupId}/members` },
          { tab: 1, path: `/admin/groups/${groupId}/depots` },
        ]}
      >
        <Tab
          label="Members"
          component={Link}
          to={`/admin/groups/${groupId}/members`}
        />
        <Tab
          label="Depots"
          component={Link}
          to={`/admin/groups/${groupId}/depots`}
        />
      </RoutedTabs>
      <Switch>
        <Route
          path={`/admin/groups/${groupId}/members`}
          render={() => (
            <TabContainer>
              <GroupMembersTab
                userGroupId={groupId}
                members={data?.node?.members}
              />
            </TabContainer>
          )}
        />
        <Route
          path={`/admin/groups/${groupId}/depots`}
          render={() => (
            <TabContainer>
              <GroupDepotsTab
                userGroupId={groupId}
                depots={data && data.node && data.node.depots}
              />
            </TabContainer>
          )}
        />
        <Route
          render={() => <Redirect to={`/admin/groups/${groupId}/members`} />}
        />
      </Switch>

      <ActionGroupMemberFab groupId={groupId} data={data} history={history} />
    </Fragment>
  );
};

Group.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Group);
