import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import { Form } from "react-final-form";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

import CreatePolicyForm from "../components/CreatePolicyForm";

// FIXME checkout https://mui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
const withMobileDialog = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="lg" fullScreen={false} />;

const UPDATE_POLICY = gql`
  mutation UpdatePolicy($input: UpdatePolicyInput!) {
    policies {
      update(input: $input) {
        policy {
          id
          number
          insurerName
          fileClaimSubmissionEmail
        }
      }
    }
  }
`;

const POLICY_QUERY = gql`
  {
    policies {
      all {
        totalCount
        edges {
          cursor
          node {
            id
            number
            insurerName
            fileClaimSubmissionEmail
          }
        }
      }
    }
  }
`;

const UpdatePolicyDialog = ({ open, fullScreen, handleClose, data }) => {
  const [update, { loading: submitting }] = useMutation(UPDATE_POLICY, {
    refetchQueries: () => [
      {
        query: POLICY_QUERY,
      },
    ],
  });

  const handleFormSubmit = (form) => {
    const variables = { input: form };

    update({ variables }).then(() => handleClose());
  };

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <Form
        initialValues={data}
        onSubmit={handleFormSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">Update policy</DialogTitle>
            <DialogContent>
              <CreatePolicyForm />
            </DialogContent>
            <DialogActions>
              <Button
                disabled={submitting}
                onClick={handleClose}
                color="primary"
              >
                Cancel
              </Button>
              <Button disabled={submitting} type="submit" color="primary">
                Save
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

UpdatePolicyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withMobileDialog()(UpdatePolicyDialog);
