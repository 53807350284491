import React, { Fragment } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import withStyles from "@mui/styles/withStyles";
import AdapterLink from "../components/form/Link";

import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

import VehicleIncidentTable from "../components/VehicleIncidentTable";

const QUERY = gql`
  query VehicleIncidents($vehicleId: ID!, $first: Int!) {
    incidents {
      all(first: $first, filter: { vehicle_id: $vehicleId }) {
        totalCount
        edges {
          cursor
          node {
            id
            status
            reference
            driver {
              id
              name
            }
            occurredAt
            claim {
              id
            }
          }
        }
      }
    }
  }
`;

const FIRST = 3;

const styles = (theme) => ({
  loading: {
    marginBottom: theme.spacing(2),
  },
});

class VehicleIncidents extends React.Component {
  // state = { q: "" };

  render() {
    const { classes, vehicleId } = this.props;

    return (
      <Fragment>
        <Query
          query={QUERY}
          variables={{ vehicleId, first: FIRST }}
          fetchPolicy="cache-and-network"
        >
          {({ loading, error, data }) => {
            if (loading && (!data || !data.incidents))
              return <LinearProgress className={classes.loading} />;
            if (error) return JSON.stringify(error);

            return (
              <Fragment>
                {loading && <LinearProgress className={classes.loading} />}
                <VehicleIncidentTable allIncidents={data.incidents.all} />
                {data.incidents.all.totalCount > FIRST && (
                  <Typography variant="body1" gutterBottom>
                    <Button
                      component={AdapterLink}
                      to={`/incidents?vehicle=${vehicleId}`}
                    >
                      More...
                    </Button>
                  </Typography>
                )}
                {data.incidents.all.totalCount === 0 && (
                  <Typography component="p">
                    No incidents found for vehicle.
                  </Typography>
                )}
              </Fragment>
            );
          }}
        </Query>
      </Fragment>
    );
  }
}

VehicleIncidents.propTypes = {
  classes: PropTypes.object.isRequired,
  vehicleId: PropTypes.string.isRequired,
};

export default withStyles(styles)(VehicleIncidents);
