import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";

import withStyles from "@mui/styles/withStyles";
import { Form, Field } from "react-final-form";
import { Grid, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useMutation, useQuery } from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";

import Autocomplete from "../components/form/Autocomplete";
import GroupDepotsList from "../components/GroupDepotsList";
import { USER_GROUP } from "../components/GroupPage";
import { delay } from "../utils";

const REMOVE_DEPOT = gql`
  mutation RemoveDepot($input: RemoveDepotFromUserGroupInputType!) {
    account {
      userGroups {
        removeDepot(input: $input) {
          error {
            code
            message
          }
          userGroup {
            name
            depots {
              totalCount
              edges {
                cursor
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

const ADD_DEPOT = gql`
  mutation AddDepot($input: AddDepotToUserGroupInputType!) {
    account {
      userGroups {
        addDepot(input: $input) {
          error {
            code
            message
          }
          userGroup {
            name
            depots {
              totalCount
              edges {
                cursor
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

const SEARCH_DEPOT = gql`
  query Depots($filter: DepotFilterType) {
    depots {
      all(filter: $filter) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            name
          }
        }
      }
    }
  }
`;

var delayId;

const styles = (theme) => ({
  fab: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  button: {
    alignSelf: "center",
    textAlign: "center",
  },
  form: {
    backgroundColor: "#e6e6e6",
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

const GroupDepotsTab = ({ classes, userGroupId, depots }) => {
  const [open, setOpen] = useState(false);
  const [depotId, setDepotId] = useState();
  const [deleteError, setDeleteError] = useState();
  const [filter, setFilter] = useState();
  const [depotsData, setDepotsData] = useState(depots);
  const [defaultValues, setDefaultValues] = useState();
  const [error, setError] = useState();

  const [removeDepot, { loading: updating }] = useMutation(REMOVE_DEPOT);

  const [addDepot, { loading: adding }] = useMutation(ADD_DEPOT);

  const { data } = useQuery(SEARCH_DEPOT, {
    variables: { filter },
    skip: !Boolean(filter),
    fetchPolicy: "cache-and-network",
  });

  const handleRemoveDepot = () => {
    const variables = { input: { depotId, userGroupId } };

    removeDepot({
      variables,
      update: (
        cache,
        {
          data: {
            account: {
              userGroups: {
                removeDepot: { userGroup },
              },
            },
          },
        }
      ) => {
        const depots = cache.readQuery({
          query: USER_GROUP,
          variables: { id: userGroupId },
        });
        depots.node.depots = userGroup.depots;

        cache.writeQuery({
          query: USER_GROUP,
          variables: { id: userGroupId },
          data: depots,
        });
      },
    }).then(
      ({
        data: {
          account: {
            userGroups: {
              removeDepot: { error, userGroup },
            },
          },
        },
      }) => {
        if (error !== null) {
          const message = `${error.message} (${error.code})`;
          setDeleteError(message);
        } else {
          setDepotsData(userGroup.depots);
          setDepotId();
          setOpen(false);
        }
      }
    );
  };

  const handleOpenDialog = (id) => {
    setDepotId(id);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setDepotId();
    setDeleteError(null);
    setOpen(false);
  };

  const handleSearchDepot = (event) => {
    let value = event.target.value;
    if (!(event.target.value === undefined)) {
      delayId = delay(
        delayId,
        function () {
          setFilter({ nameContains: value || "" });
        },
        1000
      );
    }
  };

  const options =
    data &&
    data.depots &&
    data.depots.all &&
    data.depots.all.edges &&
    data.depots.all.edges.map(({ node: depot }) => {
      return {
        id: depot.id,
        name: depot.name,
      };
    });

  const getOptionLabel = (value) => {
    return value.name;
  };

  const renderOption = (props, value) =>
    value && <li {...props}>{value.name}</li>;

  const handleChange = (value) => {
    value && setDepotId(value.id);
  };

  const handleAddDepot = () => {
    setError();
    const variables = { input: { depotId, userGroupId } };
    addDepot({
      variables,
      update: (
        cache,
        {
          data: {
            account: {
              userGroups: {
                addDepot: { userGroup },
              },
            },
          },
        }
      ) => {
        const depots = cache.readQuery({
          query: USER_GROUP,
          variables: { id: userGroupId },
        });
        depots.node.depots = userGroup.depots;

        cache.writeQuery({
          query: USER_GROUP,
          variables: { id: userGroupId },
          data: depots,
        });
      },
    }).then(
      ({
        data: {
          account: {
            userGroups: {
              addDepot: { userGroup, error },
            },
          },
        },
      }) => {
        if (error !== null) {
          const message = `${error.message} (${error.code})`;
          setError(message);
        } else {
          setDepotsData(userGroup.depots);
          setDepotId();
        }
      }
    );
    setDefaultValues({ id: null, name: "" });
  };

  return (
    <Fragment>
      <Form
        onSubmit={handleAddDepot}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1} className={classes.form}>
              {defaultValues && (
                <Grid item sm={10} md={10} lg={11}>
                  <Field
                    component={Autocomplete}
                    name="depotId"
                    margin="dense"
                    autoComplete="off"
                    placeholder="Add a depot by its name"
                    handleChangeInput={handleSearchDepot}
                    handleChange={handleChange}
                    options={options || []}
                    getOptionLabel={getOptionLabel}
                    renderOption={renderOption}
                    defaultValues={defaultValues}
                    fullWidth
                  />
                </Grid>
              )}
              {!defaultValues && (
                <Grid item sm={10} md={10} lg={11}>
                  <Field
                    component={Autocomplete}
                    name="depotId"
                    margin="dense"
                    autoComplete="off"
                    placeholder="Add a depot by its name"
                    handleChangeInput={handleSearchDepot}
                    handleChange={handleChange}
                    options={options || []}
                    getOptionLabel={getOptionLabel}
                    renderOption={renderOption}
                    fullWidth
                  />
                </Grid>
              )}
              <Grid item sm={2} md={2} lg={1} className={classes.button}>
                <Button type="submit" disabled={adding}>
                  Add
                  {adding && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </Grid>
              {error && (
                <Grid item xs={12} className={classes.error}>
                  {error}
                </Grid>
              )}
            </Grid>
          </form>
        )}
      />

      <GroupDepotsList onOpenDialog={handleOpenDialog} depots={depotsData} />

      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>Remove depot</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove the depot from this group?
          </DialogContentText>
          {deleteError && (
            <DialogContentText className={classes.error}>
              {deleteError}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="primary"
            disabled={updating}
          >
            Cancel
          </Button>
          <Button
            onClick={handleRemoveDepot}
            color="primary"
            autoFocus
            disabled={updating}
          >
            Yes, remove depot
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

GroupDepotsTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GroupDepotsTab);
