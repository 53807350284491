import React, { Fragment } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { useAuth } from "../AuthContext";

import { execute as recaptcha } from "../recaptcha";
import { Form } from "react-final-form";
import { FORM_ERROR } from "final-form";
import SignUpForm from "../components/SignUpForm";

const SIGN_UP = gql`
  mutation signUp($input: SignUpInput!) {
    users {
      signUp(input: $input) {
        success
        user {
          id
          name
          email
        }
        account {
          role
          groupPermissions
          tenant {
            id
            name
            externalLinks {
              name
              url
              iconName
            }
            features {
              isSupportTracking
              nearMissEnabled
              vehiclesEnabled
              incidentsEnabled
              driverReviewEnabled
            }
          }
        }
        token
        expiresIn
        error {
          code
          message
        }
      }
    }
  }
`;

const SignUp = ({ ticket, continueTo }) => {
  const { signIn: setSignIn } = useAuth();
  const [signUp] = useMutation(SIGN_UP);

  const handleSignUpSubmit = (form) => {
    return recaptcha("sign_up").then((captchaResponse) => {
      const variables = { input: { ...form, ticket, captchaResponse } };

      return signUp({ variables }).then(
        ({
          data: {
            users: { signUp },
          },
        }) => {
          if (signUp.success) {
            return setSignIn(signUp).then((_) => (window.location.href = "/"));
          }

          const message = `${signUp.error.message} (${signUp.error.code})`;
          return { [FORM_ERROR]: message };
        }
      );
    });
  };

  return (
    <Form
      onSubmit={handleSignUpSubmit}
      render={({ handleSubmit, submitting, submitError }) => (
        <form onSubmit={handleSubmit}>
          <Fragment>
            <SignUpForm
              ticket={ticket}
              authenticating={submitting}
              disabled={submitting}
              error={submitError}
              continueTo={continueTo}
            />
          </Fragment>
        </form>
      )}
    />
  );
};

SignUp.propTypes = {
  ticket: PropTypes.string.isRequired,
  continueTo: PropTypes.string.isRequired,
};

export default SignUp;
