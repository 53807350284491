import React from "react";
import qs from "querystringify";
import { useLocation } from "react-router-dom";
import CenteredPaperLayout from "../components/CenteredPaperLayout";

import SignUp from "../containers/SignUp";
import AbovePaperLogo from "../components/AbovePaperLogo";

const SignUpPage = () => {
  const { search } = useLocation();

  return (
    <CenteredPaperLayout
      title="Sign up to join your team"
      above={<AbovePaperLogo />}
    >
      <SignUp
        continueTo={qs.parse(search).continue}
        ticket={qs.parse(search).ticket}
      />
    </CenteredPaperLayout>
  );
};

export default SignUpPage;
