import React, { useState } from "react";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import VehicleAdminFilterForm from "../components/VehicleAdminFilterForm";
import qs from "querystringify";
import VehiclesAdmin from "../containers/VehiclesAdmin";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
}));

const DEFAULT_FILTERS = {
  name: "",
};

const VehiclesAdminPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { search } = useLocation();

  const [filters, setFilters] = React.useState({ ...DEFAULT_FILTERS });

  React.useEffect(() => {
    const filters = search === "" ? { ...DEFAULT_FILTERS } : qs.parse(search);

    setFilters(filters);
  }, [search]);

  const handleApplyFilters = (form) => {
    history.push(`/admin/vehicles?${qs.stringify(form)}`);
  };

  return (
    <section>
      <Breadcrumbs aria-label="breadcrumb" className={classes.marginBottom}>
        <Link color="inherit" to="/admin" component={RouterLink}>
          Admin
        </Link>
        <Typography color="textPrimary">Vehicles</Typography>
      </Breadcrumbs>
      <Typography variant="h5" gutterBottom>
        Vehicle admin
      </Typography>

      <VehicleAdminFilterForm
        initialFormValues={filters}
        apply={handleApplyFilters}
        className={classes.filterRoot}
      />

      <VehiclesAdmin search={filters} />
    </section>
  );
};

export default VehiclesAdminPage;
