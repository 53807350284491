import React from "react";

import SelectAutoComplete from "./form/SelectAutoComplete";
import { Field } from "react-final-form";
import Grid from "@mui/material/Grid";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

const CreateLiveShareTrackingForm = ({
  handleChange,
  getOptionLabel,
  options,
  classes,
  defaultDateStart,
  defaultDateEnd,
}) => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <div className={classes.itemContainer}>
              <Field name="accessStart">
                {({ input }) => (
                  <DateTimePicker
                    label="Access Start"
                    value={defaultDateStart}
                    onChange={input.onChange}
                  />
                )}
              </Field>
            </div>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={12}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <div className={classes.itemContainer}>
              <Field name="accessEnd">
                {({ input }) => (
                  <DateTimePicker
                    label="Access End"
                    value={defaultDateEnd}
                    onChange={input.onChange}
                    disablePast
                  />
                )}
              </Field>
            </div>
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Field
            component={SelectAutoComplete}
            name="vehicles"
            label="Vehicles"
            options={options}
            getOptionLabel={getOptionLabel}
            handleChange={handleChange}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateLiveShareTrackingForm;
