import React from "react";
import { Route, Switch } from "react-router-dom";

import GroupsPage from "../components/GroupsPage";
import GroupPage from "../components/GroupPage";

const GroupsRoute = () => (
  <Switch>
    <Route exact path="/admin/groups">
      <GroupsPage />
    </Route>
    <Route path="/admin/groups/:id">
      <GroupPage />
    </Route>
  </Switch>
);

export default GroupsRoute;
