import React from "react";
import PropTypes from "prop-types";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import { Form } from "react-final-form";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DriverForm from "../components/DriverForm";
import { DRIVERS_QUERY } from "../containers/DriverSelect";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

// FIXME checkout https://mui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
const withMobileDialog = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="lg" fullScreen={false} />;

const CREATE_DRIVER = gql`
  mutation createDriver($input: CreateDriverInput!) {
    drivers {
      create(input: $input) {
        driver {
          id
          name
          email
          telephone
        }
      }
    }
  }
`;

const ADMIN_DRIVERS_QUERY = gql`
  query {
    allDrivers {
      edges {
        node {
          id
          name
          telephone
          email
          active
        }
      }
    }
  }
`;

const NewDriverDialog = ({
  open,
  fullScreen,
  onCreated,
  handleClose: onClose,
}) => {
  const [createDriver] = useMutation(CREATE_DRIVER, {
    refetchQueries: [
      {
        query: ADMIN_DRIVERS_QUERY,
      },
    ],
  });

  const handleSubmit = (form) => {
    const variables = { input: { driver: { ...form } } };
    createDriver({
      variables,
      create: (
        store,
        {
          data: {
            drivers: { create: createDriver },
          },
        }
      ) => {
        const data = store.readQuery({
          query: DRIVERS_QUERY,
        });

        const newData = {
          ...data,
          allDrivers: {
            ...data.allDrivers,
            edges: [{ node: createDriver.driver }, ...data.allDrivers.edges],
          },
        };

        store.writeQuery({
          query: DRIVERS_QUERY,
          data: newData,
        });
      },
    }).then(
      ({
        data: {
          drivers: { create: createDriver },
        },
      }) => {
        onCreated(createDriver.driver);
      }
    );
  };

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit, submitting }) => (
        <Dialog
          open={open}
          fullScreen={fullScreen}
          onClose={onClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            New driver details
            {onClose ? (
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
          <DialogContent>
            <DriverForm />
          </DialogContent>
          <DialogActions>
            <Button disabled={submitting} onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button
              disabled={submitting}
              onClick={handleSubmit}
              color="primary"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    />
  );
};

NewDriverDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onCreated: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog()(NewDriverDialog);
