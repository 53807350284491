import gql from "graphql-tag";

const CLAIM_FRAGMENT = gql`
  fragment claim on Claim {
    id
    status
    reference
    occurredAt
    location
    vehicle {
      id
      vrm
    }
    driver {
      id
      name
      email
      telephone
    }
    file {
      src
      recipients
      filedAt
    }
  }
`;

export default CLAIM_FRAGMENT;
