import React from "react";
import withStyles from '@mui/styles/withStyles';
import classNames from "classnames";
import moment from "moment";
import AdapterLink from "../components/form/Link";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { ACTIVE, mapToDisplay } from "../ClaimStatus";
import AnchorLink from "@mui/material/Link";

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  link: {
    fontWeight: "500"
  },
  status: {
    color: theme.palette.text.hint,
    paddingLeft: theme.spacing(2)
  },
  statusActive: {
    color: theme.palette.secondary.main
  }
});

const ClaimTable = ({ classes = {}, allClaims }) => (
  <div className={classes.table}>
    {allClaims.edges.map(({ node }) => {
      return (
        <Paper key={node.id} className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={3}>
              <AnchorLink
                component={AdapterLink}
                className={classes.link}
                to={`/claims/${node.id}`}
              >
                {node.reference}
              </AnchorLink>
              <span
                className={classNames(classes.status, {
                  [classes.statusActive]: node.status === ACTIVE
                })}
              >
                {mapToDisplay(node.status)}
              </span>
            </Grid>
            <Grid item xs={6} sm={4}>
              <span>{moment(node.occurredAt).format("LLL")}</span>
            </Grid>
            <Grid item xs={6} sm={2}>
              {node.vehicle.vrm}
            </Grid>
            <Grid item xs={6} sm={3}>
              {node.driver && node.driver.name}
            </Grid>
            {node.location && (
              <Grid item xs={12}>
                {node.location}
              </Grid>
            )}
          </Grid>
        </Paper>
      );
    })}
  </div>
);

export default withStyles(styles)(ClaimTable);
