import PropTypes from "prop-types";
import gql from "graphql-tag";
import { withApollo } from "@apollo/client/react/hoc";
import { useQuery } from "@apollo/client";

const QUERY = gql`
  query Node($id: ID!, $start: DateTimeOffset!, $end: DateTimeOffset!) {
    node(id: $id) {
      ...Journey_safety_events
    }
  }

  fragment Journey_safety_events on Vehicle {
    id
    safetyEvents(filter: { start: $start, end: $end }) {
      totalCount
      edges {
        cursor
        node {
          id
          title
          occurredAt
          speed(unit: MPH)
          speedLimit(unit: MPH)
          coord {
            lat
            lng
          }
        }
      }
    }
  }
`;

const VehicleSafetyEventsQuery = ({ id, start, end, children }) => {
  const { loading, error, data } = useQuery(QUERY, {
    variables: { id, start, end },
  });

  if (error) {
    console.error(error);
    return null;
  }

  const safetyEvents = data?.node?.safetyEvents || {};

  return children({ loading, data: safetyEvents });
};

VehicleSafetyEventsQuery.propTypes = {
  id: PropTypes.string.isRequired,
  start: PropTypes.string,
  end: PropTypes.string,
  children: PropTypes.func.isRequired,
};

export default withApollo(VehicleSafetyEventsQuery);
