import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import withStyles from "@mui/styles/withStyles";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AnchorLink from "@mui/material/Link";
import AdapterLink from "../components/form/Link";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
// import FormHelperText from "@mui/material/FormHelperText";

import INCIDENT_FRAGMENT from "../fragments/incident";
import { QUERY as INCIDENT_QUERY } from "../containers/Incident";
import { Checkbox } from "@mui/material";

const FILE_CLAIM = gql`
  mutation FileIncidentAsClaim($input: FileIncidentAsClaimInput!) {
    incidents {
      fileAsClaim(input: $input) {
        incident {
          ...incident
        }
      }
    }
  }

  ${INCIDENT_FRAGMENT}
`;

const POLICY_QUERY = gql`
  {
    policies {
      all {
        totalCount
        edges {
          cursor
          node {
            id
            number
            insurerName
          }
        }
      }
    }
  }
`;

const styles = (theme) => ({
  errorText: {
    color: theme.palette.error.main,
  },
  warningText: {
    fontWeight: "bold",
  },
});

const FileIncidentAsClaimDialog = ({
  incidentId,
  onClose,
  open,
  classes,
  hasEvidencePendingReview,
}) => {
  const [working, setWorking] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [policyId, setPolicyId] = React.useState(null);
  const [fileAnywayConfirmed, setFileAnywayConfirmed] = React.useState(
    !hasEvidencePendingReview
  );

  const policyQuery = useQuery(POLICY_QUERY, {
    fetchPolicy: "cache-and-network",
  });
  const [fileAsClaim] = useMutation(FILE_CLAIM);

  React.useEffect(() => {
    if (
      !policyId &&
      policyQuery.data &&
      policyQuery.data.policies &&
      policyQuery.data.policies.all.edges[0]
    )
      setPolicyId(policyQuery.data.policies.all.edges[0].node.id);
  }, [policyId, policyQuery]);

  const handlePolicyChange = (event) => {
    setPolicyId(event.target.value);
  };

  const handleFileClaim = () => {
    if (!fileAnywayConfirmed) {
      return;
    }

    if (!policyId) {
      setError("Select a policy");
      return;
    }

    const variables = {
      input: { incidentId, policy: { id: policyId } },
    };
    setWorking(true);

    fileAsClaim({
      variables,
      update: (
        store,
        {
          data: {
            incidents: { fileAsClaim },
          },
        }
      ) => {
        const data = store.readQuery({
          query: INCIDENT_QUERY,
          variables: { id: incidentId },
        });

        data.node = { ...data.node, ...fileAsClaim.incident };

        store.writeQuery({
          query: INCIDENT_QUERY,
          variables: { id: incidentId },
          data,
        });
      },
    }).then(
      () => {
        // console.log("done");
        // setWorking(false);
        // onSent();
      },
      (error) => {
        setWorking(false);
        setError(error.message);
      }
    );
  };

  const handleClose = () => {
    onClose();
  };

  const handleFileAnyway = (ev) => {
    setFileAnywayConfirmed(ev.target.checked);
  };

  const edges =
    policyQuery.data &&
    policyQuery.data.policies &&
    policyQuery.data.policies.all.edges;
  const loading = policyQuery.loading;

  return (
    <Dialog
      fullWidth
      open={open}
      disableEscapeKeyDown={working}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        File a claim
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {!loading && edges.length === 0 ? (
        <>
          <DialogContent>
            <DialogContentText>
              <span>
                Claims cannot be raised without policy details. Please{" "}
              </span>
              <AnchorLink component={AdapterLink} to="/admin/insurer-policy">
                add policy details
              </AnchorLink>
              <span> and try again</span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent>
            {loading && !edges && "Loading policies..."}
            {!loading && edges && (
              <div>
                <FormControl
                  component="fieldset"
                  required
                  className={classes.formControl}
                >
                  <FormLabel component="legend">Policy</FormLabel>
                  <RadioGroup
                    className={classes.group}
                    value={policyId}
                    onChange={handlePolicyChange}
                  >
                    {edges.map(({ node: policy }) => (
                      <FormControlLabel
                        key={policy.id}
                        value={policy.id}
                        control={<Radio />}
                        label={`${policy.insurerName}: ${policy.number}`}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </div>
            )}
            {hasEvidencePendingReview && (
              <div>
                <FormControl required error={!fileAnywayConfirmed}>
                  <FormLabel component="legend">
                    There is evidence still pending review. If a claim is filed
                    now, these will not appear on the claim
                  </FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fileAnywayConfirmed}
                        onChange={handleFileAnyway}
                      />
                    }
                    label="File anyway"
                  />
                  {/* <FormHelperText>
                    Confirm before filing the claim
                  </FormHelperText> */}
                </FormControl>
              </div>
            )}
            {error && (
              <DialogContentText className={classes.errorText}>
                {error}
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button disabled={working} onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              disabled={working}
              onClick={handleFileClaim}
              color="primary"
              autoFocus
            >
              File claim
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

FileIncidentAsClaimDialog.propTypes = {
  incidentId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSent: PropTypes.func.isRequired,
  hasEvidencePendingReview: PropTypes.bool.isRequired,
};

export default withStyles(styles)(FileIncidentAsClaimDialog);
