import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";

import UpdateIncident from "../containers/UpdateIncident";
import IncidentThirdParties from "../components/IncidentThirdParties";
import IncidentThirdPartiesForm from "../components/IncidentThirdPartiesForm";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import EditIcon from "@mui/icons-material/Edit";

const styles = (theme) => ({
  actions: {
    display: "flex",
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonBox: {
    marginLeft: "auto",
  },
});

class IncidentThirdPartiesCard extends React.Component {
  state = {
    incident: [],
    editing: false,
    initialFormValues: {
      attachEvidence: {
        evidence: [],
      },
    },
  };

  componentDidMount() {
    this.setState((prev) => ({
      initialFormValues: {
        ...prev.initialFormValues,
        ...this.getInitialFormValuesFrom(this.props.incident),
      },
      incident: this.props.incident,
    }));
  }

  componentDidUpdate() {
    const { incident, editing } = this.state;

    if (incident !== this.props.incident && !editing)
      this.setState((prev) => ({
        initialFormValues: {
          ...prev.initialFormValues,
          ...this.getInitialFormValuesFrom(this.props.incident),
        },
        incident: this.props.incident,
      }));
  }

  render() {
    const { classes, className, incident, editable } = this.props;
    const { editing, initialFormValues } = this.state;

    let actions = null;
    if (editable && !editing)
      actions = (
        <Button
          variant="text"
          startIcon={<EditIcon />}
          onClick={this.handleEditClick}
        >
          Edit
        </Button>
      );

    return (
      <UpdateIncident
        incidentId={incident.id}
        initialFormValues={initialFormValues}
        onUpdated={this.handleUpdated}
        render={({ handleSubmit, submitting, dirty, reset, values, error }) => (
          <Card className={className}>
            <CardHeader title="Third parties" action={actions} />
            <CardContent>
              {!editing && (
                <IncidentThirdParties value={incident.thirdParties} />
              )}

              {editing && (
                <IncidentThirdPartiesForm
                  value={values.thirdParties}
                  formValues={values.thirdParties}
                />
              )}
              {error && <p>{error}</p>}
            </CardContent>
            {editing && (
              <CardActions className={classes.actions} disableSpacing>
                <div className={classes.buttonBox}>
                  <Button
                    aria-label="Cancel"
                    onClick={this.handleCancelEdit(reset)}
                    disabled={submitting}
                    color="primary"
                    className={classes.button}
                  >
                    Cancel
                  </Button>
                  <Button
                    aria-label="Save changes"
                    onClick={handleSubmit}
                    disabled={submitting || !dirty}
                    color="primary"
                    className={classes.button}
                  >
                    Save changes
                  </Button>
                </div>
              </CardActions>
            )}
          </Card>
        )}
      />
    );
  }

  getInitialFormValuesFrom = (incident) => {
    return {
      thirdParties: {
        thirdPartiesInvolved: incident.thirdParties.thirdPartiesInvolved,
        thirdParties: incident.thirdParties.thirdParties.map(
          ({
            detailsOfDamage,
            email,
            driverName,
            ownerName,
            address,
            telephoneNumber,
            vehicleMake,
            vehicleRegistration,
            insurer,
            injuries,
          }) => ({
            detailsOfDamage,
            email,
            driverName,
            ownerName,
            address,
            telephoneNumber,
            vehicleMake,
            vehicleRegistration,
            insurer: {
              name: insurer.name,
              policyNumber: insurer.policyNumber,
            },
            injuries: {
              any: (injuries || {}).any,
              details: (injuries || {}).details,
              hospitalName: (injuries || {}).hospitalName,
              takenToHospital: (injuries || {}).takenToHospital,
            },
          })
        ),
      },
    };
  };

  handleUpdated = () => {
    this.setState({ editing: false });
  };

  handleEditClick = () => {
    this.setState({ dirtied: true, editing: true });
  };

  handleCancelEdit = (reset) => () => {
    this.setState({ editing: false });
    reset();
  };
}

IncidentThirdPartiesCard.propTypes = {
  incident: PropTypes.object.isRequired,
  editable: PropTypes.bool,
};

export default withStyles(styles)(IncidentThirdPartiesCard);
