import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import qs from "querystringify";

import { useAuth } from "../AuthContext";

const defaultSearchRedirect = (location) => ({
  continue: `${location.pathname}${location.search}`,
});

const RequireAuth = ({
  redirectPathname,
  redirectSearch,
  roles,
  children,
  skipAccountCheck = false,
}) => {
  const location = useLocation();
  const { isAuthenticated, isInRole, account } = useAuth();

  if (!skipAccountCheck && isAuthenticated && account == null) {
    return <Redirect to="/no-accounts" />;
  }

  return isAuthenticated ? (
    isInRole(roles) ? (
      children
    ) : (
      <Redirect
        to={{
          pathname: redirectPathname || "/",
        }}
      />
    )
  ) : (
    <Redirect
      to={{
        pathname: redirectPathname || "/login",
        search: qs.stringify(
          {
            ...(redirectSearch || defaultSearchRedirect)(location),
          },
          true
        ),
      }}
    />
  );
};

export default RequireAuth;
