import React from "react";
import classNames from "classnames";
import OnlineIcon from "@mui/icons-material/GpsFixed";
import OfflineIcon from "@mui/icons-material/GpsOff";
import Tooltip from "@mui/material/Tooltip";
import withStyles from '@mui/styles/withStyles';
import { red, green } from '@mui/material/colors';

const styles = () => ({
  offlineIcon: {
    color: red[500]
  },
  onlineIcon: {
    color: green[500]
  }
});

const OnlineStatusIcon = ({ online, classes, className }) =>
  online ? (
    <Tooltip title="Vehicle is online">
      <OnlineIcon className={classNames(classes.onlineIcon, className)} />
    </Tooltip>
  ) : (
    <Tooltip title="Vehicle is offline">
      <OfflineIcon className={classNames(classes.offlineIcon, className)} />
    </Tooltip>
  );

export default withStyles(styles)(OnlineStatusIcon);
