export const OK = "OK";
export const UNSPECIFIED_ERROR = "UNSPECIFIED_ERROR";
export const UNKNOWN = "UNKNOWN";

export const mapToDisplay = key => {
  if (key === OK) return "Ok";
  if (key === UNKNOWN) return "Unknown";
  if (key === UNSPECIFIED_ERROR) return "Not storing camera footage";

  return key;
};

export const isErrored = key => {
  return key !== OK && key !== UNKNOWN;
};
