import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import CenteredPaperLayout from "../components/CenteredPaperLayout";
import AbovePaperLogo from "../components/AbovePaperLogo";

const NoRoutePage = () => {
  const location = useLocation();

  return (
    <CenteredPaperLayout
      title="Page not found"
      above={
        <Link to="/">
          <AbovePaperLogo />
        </Link>
      }
    >
      <p>This might be because:</p>
      <ul>
        <li>You have typed the web address incorrectly, or</li>
        <li>
          the page you were looking for may have been moved, updated or deleted.
        </li>
      </ul>
      <p>
        <Link to="/">Return to the homepage</Link>
      </p>
      <hr style={{ border: "1px solid #ccc" }} />
      <div style={{ color: "#ccc", textAlign: "left" }}>
        <pre>
          Pathname: {location?.pathname}
          <br />
          Search: {location?.search}
          <br />
          Hash: {location?.hash}
        </pre>
      </div>
    </CenteredPaperLayout>
  );
};

export default NoRoutePage;
