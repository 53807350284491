import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";

import { Field } from "react-final-form";
import Grid from "@mui/material/Grid";
import TextField from "../components/form/TextField";

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

const WitnessDetailForm = ({ fieldName, value, src, classes }) => (
  <div>
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <Field
          component={TextField}
          name={`${fieldName}.name`}
          margin="normal"
          label="Name"
          multiline
          maxRows={3}
          fullWidth
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <Field
          component={TextField}
          name={`${fieldName}.contactNumber`}
          margin="normal"
          label="Contact number"
          maxRows={3}
          fullWidth
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <Field
          component={TextField}
          name={`${fieldName}.emailAddress`}
          margin="normal"
          label="Email address"
          maxRows={3}
          fullWidth
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <Field
          component={TextField}
          name={`${fieldName}.address`}
          margin="normal"
          label="Address"
          maxRows={3}
          fullWidth
        />
      </Grid>
    </Grid>
  </div>
);

WitnessDetailForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WitnessDetailForm);
