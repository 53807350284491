import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import IconButton from "@mui/material/IconButton";
import NextPageIcon from "@mui/icons-material/ChevronRight";
import PreviousPageIcon from "@mui/icons-material/ChevronLeft";

const styles = () => ({});

const NextPreviousPagination = ({ pageInfo, loading, fetchMore }) => (
  <div>
    {Boolean(pageInfo.hasPreviousPage || pageInfo.hasNextPage) && (
      <div>
        <IconButton
          disabled={loading || !Boolean(pageInfo.hasPreviousPage)}
          onClick={() =>
            fetchMore({
              variables: {
                before: pageInfo.startCursor
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return fetchMoreResult;
              }
            })
          }
          size="large">
          <PreviousPageIcon />
        </IconButton>
        <IconButton
          disabled={loading || !Boolean(pageInfo.hasNextPage)}
          onClick={() =>
            fetchMore({
              variables: {
                after: pageInfo.endCursor
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return fetchMoreResult;
              }
            })
          }
          size="large">
          <NextPageIcon />
        </IconButton>
      </div>
    )}
  </div>
);

NextPreviousPagination.propTypes = {
  pageInfo: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  fetchMore: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NextPreviousPagination);
