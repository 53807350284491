import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import { useHistory } from "react-router-dom";

import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import EditIcon from "@mui/icons-material/Edit";
import { Icon } from "@mui/material";

import RemoveGroupDialog from "../containers/RemoveGroupDialog";
import EditGroupDialog from "../containers/EditGroupDialog";

const styles = (theme) => ({
  speedDial: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
});

const ActionGroupMemberFab = ({ classes, groupId, data }) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openRenameDialog, setOpenRenameDialog] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = (removed) => {
    if (removed === "removed") history.push("/admin/groups");
    else setOpenDeleteDialog(false);
  };

  const handleOpenEditDialog = () => {
    setOpenRenameDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenRenameDialog(false);
  };

  const actions = [
    {
      icon: <Icon onClick={() => handleOpenEditDialog()}>edit</Icon>,
      name: "Edit",
    },
    {
      icon: <Icon onClick={() => handleOpenDeleteDialog()}>delete</Icon>,
      name: "Delete",
    },
  ];

  return (
    <Fragment>
      <SpeedDial
        ariaLabel="SpeedDial openIcon example"
        className={classes.speedDial}
        icon={<SpeedDialIcon openIcon={<EditIcon />} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={handleClose}
          />
        ))}
      </SpeedDial>

      <RemoveGroupDialog
        open={openDeleteDialog}
        groupId={groupId}
        groupName={data.node.name}
        onCloseDialog={handleCloseDeleteDialog}
      />

      <EditGroupDialog
        open={openRenameDialog}
        groupId={groupId}
        groupData={data.node}
        onCloseDialog={handleCloseEditDialog}
      />
    </Fragment>
  );
};

ActionGroupMemberFab.propTypes = {
  className: PropTypes.string,
};

export default withStyles(styles)(ActionGroupMemberFab);
