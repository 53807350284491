import React, { Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";

import { FieldArray } from "react-final-form-arrays";
import Grid from "@mui/material/Grid";
import EvidenceUpload from "../containers/EvidenceUpload";

import {
  PENDING_REVIEW,
  ACCEPTED,
  DISMISSED,
  map as evidenceStatusMap,
} from "../EvidenceStatus";

import Select from "../components/form/Select";
import TextField from "../components/form/TextField";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { Field } from "react-final-form";

const styles = () => ({});

const IncidentEvidenceUploadForm = ({
  incidentId,
  attachEvidence,
  classes,
}) => (
  <FieldArray name="attachEvidence.evidence">
    {({ fields }) => (
      <Fragment>
        {fields.map((fieldName, idx) => {
          const file = attachEvidence.evidence[idx];

          if (!file) return null;
          return (
            <Grid key={`file-${idx}`} item xs={12} sm={6} md={4}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.media}
                  image={file.media.source.src}
                  title={file.filename}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {file.filename}
                  </Typography>
                  <Field
                    component={Select}
                    name={`${fieldName}.status`}
                    margin="normal"
                    label="Status"
                    fullWidth
                  >
                    <MenuItem value={PENDING_REVIEW}>
                      {evidenceStatusMap(PENDING_REVIEW)}
                    </MenuItem>
                    <MenuItem value={ACCEPTED}>
                      {evidenceStatusMap(ACCEPTED)}
                    </MenuItem>
                    <MenuItem value={DISMISSED}>
                      {evidenceStatusMap(DISMISSED)}
                    </MenuItem>
                  </Field>
                  <Field
                    component={TextField}
                    name={`${fieldName}.description`}
                    margin="normal"
                    multiline
                    maxRows={3}
                    label="Description"
                    fullWidth
                  />
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => {
                      fields.remove(idx);
                    }}
                  >
                    Remove
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
        <Grid item xs={12} sm={6} md={4} sx={{ display: "flex" }}>
          <Card
            className={`${classes.card} `}
            sx={{ display: "flex", alignItems: "stretch" }}
          >
            <Box
              sx={{ padding: "8px", display: "flex", alignItems: "stretch" }}
            >
              <EvidenceUpload
                incidentId={incidentId}
                onFinish={(uploaded) => {
                  fields.push({
                    fileKey: uploaded.fileKey,
                    filename: uploaded.filename,
                    contentType: uploaded.contentType,
                    description: "",
                    status: ACCEPTED,
                    media: {
                      source: {
                        src: uploaded.media.source.src,
                      },
                    },
                  });
                }}
              />
            </Box>
          </Card>
        </Grid>
      </Fragment>
    )}
  </FieldArray>
);

IncidentEvidenceUploadForm.propTypes = {
  classes: PropTypes.object.isRequired,
  incidentId: PropTypes.string.isRequired,
  attachEvidence: PropTypes.object.isRequired,
};

export default withStyles(styles)(IncidentEvidenceUploadForm);
