import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import MarkdownIt from "markdown-it";

import TranslateIcon from "@mui/icons-material/Translate";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const markdown = new MarkdownIt();

const styles = theme => ({
  iconLeft: {
    marginRight: theme.spacing(1)
  },
  translationInfo: {
    display: "flex",
    alignItems: "center"
  },
  info: {
    flexGrow: 1
  },
  formControlRight: {
    // flexGrow: 1
  }
});

class DriverAccountText extends React.Component {
  state = { showOriginal: false };

  render() {
    const { showOriginal } = this.state;
    const { text, translation, classes } = this.props;

    const hasTranslation = Boolean(translation);
    const displayText = showOriginal || !hasTranslation ? text : translation;

    return (
      <div>
        {hasTranslation && (
          <div className={classes.translationInfo}>
            <TranslateIcon className={classes.iconLeft} />
            <span className={classes.info}>
              The driver account has automatically been translated.
            </span>
            <FormControlLabel
              className={classes.formControlRight}
              control={
                <Switch
                  checked={showOriginal}
                  onClick={this.toggleShowOriginal.bind(this)}
                />
              }
              label="Show original"
            />
          </div>
        )}
        <blockquote
          dangerouslySetInnerHTML={{
            __html: markdown.render(displayText)
          }}
        />
      </div>
    );
  }

  toggleShowOriginal() {
    this.setState(prev => ({ showOriginal: !prev.showOriginal }));
  }
}

DriverAccountText.propTypes = {
  text: PropTypes.string.isRequired,
  translation: PropTypes.string
};

export default withStyles(styles)(DriverAccountText);
