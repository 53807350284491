import React from "react";
import PropTypes from "prop-types";

const MapCustomControl = ({
  mapHolderRef,
  controlPosition,
  children,
  styles,
}) => {
  const rootRef = React.useRef(null);

  React.useEffect(() => {
    const root = rootRef.current;
    root && mapHolderRef.controls[controlPosition].push(root);

    return () => {
      if (!root) return;
      const controls = mapHolderRef.controls[controlPosition];
      const index = controls.indexOf(root);
      controls.removeAt(index);
    };
  }, [controlPosition, mapHolderRef, rootRef]);

  return (
    <div ref={rootRef} className={styles}>
      {children}
    </div>
  );
};

MapCustomControl.propTypes = {
  className: PropTypes.string,
  mapHolderRef: PropTypes.any.isRequired,
  controlPosition: PropTypes.any.isRequired,
};

export default MapCustomControl;
