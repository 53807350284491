import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import withStyles from "@mui/styles/withStyles";
import { useQuery } from "@apollo/client";

import LinearProgress from "@mui/material/LinearProgress";

import GroupsList from "../components/GroupsList";
import CreateGroupFab from "../components/CreateGroupFab";
import RemoveGroupDialog from "../containers/RemoveGroupDialog";

export const USER_GROUP_QUERY = gql`
  {
    account {
      userGroups {
        all {
          totalCount
          pageInfo {
            hasNextPage
            endCursor
            startCursor
            hasPreviousPage
          }
          edges {
            cursor
            node {
              id
              name
              groupPermissions
              defaultAccessToNewDepots
              isTeamAdmin
              members {
                totalCount
              }
              depots {
                totalCount
              }
            }
          }
        }
      }
    }
  }
`;

const styles = (theme) => ({
  fab: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  error: {
    color: "red",
  },
});

const Groups = ({ classes }) => {
  const [groupId, setGroupId] = useState();
  const [groupName, setGroupName] = useState();
  const [open, setOpen] = useState(false);

  const { loading, error, data } = useQuery(USER_GROUP_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  if (loading && !data) return <LinearProgress className={classes.loading} />;

  if (error) return JSON.stringify(error);

  const handleOpenDialog = (id, name) => {
    setGroupId(id);
    setGroupName(name);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <GroupsList
        data={data?.account?.userGroups?.all}
        onOpenDialog={handleOpenDialog}
      />
      <CreateGroupFab className={classes.fab} />

      <RemoveGroupDialog
        open={open}
        groupId={groupId}
        groupName={groupName}
        onCloseDialog={handleCloseDialog}
      />
    </Fragment>
  );
};

Groups.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Groups);
