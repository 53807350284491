import React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

const Select = ({
  children,
  input: { name, onChange, value, type, onBlur },
  meta,
  ...rest
}) => {
  return (
    <TextField
      variant="outlined"
      select
      helperText={(meta.touched && meta.error) || meta.submitError || undefined}
      error={Boolean((meta.error && meta.touched) || meta.submitError)}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      type={type}
      SelectProps={{
        MenuProps: {}
      }}
      {...rest}
    >
      {children || <MenuItem />}
    </TextField>
  );
};

export default Select;
