import React, { useState } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

import Members from "../containers/Members";

const styles = (theme) => ({
  root: {
    marginBottom: 56,
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
});

const MembersPage = ({ classes }) => {
  const [totalMember, setTotalMember] = useState();
  const getTotal = (total) => {
    setTotalMember(total);
  };
  return (
    <section className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" className={classes.marginBottom}>
        <Link color="inherit" to="/admin" component={RouterLink}>
          Admin
        </Link>
        <Typography color="textPrimary">Members</Typography>
      </Breadcrumbs>
      <Typography variant="h5" gutterBottom className={classes.marginBottom}>
        Users
      </Typography>
      <Typography variant="body2" gutterBottom className={classes.marginBottom}>
        You've got {totalMember} users (including you) with access to one or
        more of your team's depots.
      </Typography>
      <Members getTotal={getTotal} />
    </section>
  );
};

MembersPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MembersPage);
