import React from "react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import moment from "moment";

const shortcutsItems = [
  {
    label: "This Week",
    getValue: () => {
      const today = moment();
      return [today.clone().startOf("week"), today.clone().endOf("week")];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = moment();
      const prevWeek = today.clone().subtract(1, "week");
      return [prevWeek.clone().startOf("week"), prevWeek.clone().endOf("week")];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = moment();
      return [today.clone().subtract(6, "day"), today.clone()];
    },
  },
  {
    label: "Last Month",
    getValue: () => {
      const today = moment();
      const lastMonth = today.clone().subtract(1, "month");
      return [
        lastMonth.clone().startOf("month"),
        lastMonth.clone().endOf("month"),
      ];
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = moment();
      return [today.clone().startOf("month"), today.clone().endOf("month")];
    },
  },
  {
    label: "Reset",
    getValue: () => {
      const today = moment();
      return [today.clone().subtract(6, "day"), today.clone()];
    },
  },
];

function DateRangeFilter({ dateRangeValue, onChangeDateRange, size }) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateRangePicker
        slots={{ field: SingleInputDateRangeField }}
        slotProps={{
          shortcuts: {
            items: shortcutsItems,
          },
          textField: { size: size ?? "small" },
        }}
        calendars={1}
        value={dateRangeValue}
        onChange={(newValue) => onChangeDateRange(newValue)}
      />
    </LocalizationProvider>
  );
}

export default DateRangeFilter;
