import React from "react";
import withStyles from '@mui/styles/withStyles';

import GoogleMap from "../containers/GoogleMap";

import { Marker, StandaloneSearchBox } from "@react-google-maps/api";
import MapCustomControl from "../components/MapCustomControl";

class PlaceAMarkerMap extends React.Component {
  state = { center: null, zoom: null, mapRef: null };

  render() {
    const { position, classes } = this.props;
    const { center } = this.state;

    const loadHandler = map => {
      this.setState({ mapRef: map });
    };

    return (
      <GoogleMap
        loadHandler={loadHandler}
        center={center}
        onClick={this.handleClick.bind(this)}
      >
        {this.state.mapRef && (
          <MapCustomControl
            mapHolderRef={this.state.mapRef}
            controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
          >
            <StandaloneSearchBox
              onLoad={ref => (this.searchBox = ref)}
              onPlacesChanged={this.onPlacesChanged.bind(this)}
              bounds={null}
            >
              <input
                type="text"
                placeholder="Search"
                className={classes.searchBox}
              />
            </StandaloneSearchBox>
          </MapCustomControl>
        )}
        {position && (
          <Marker
            draggable={true}
            position={position}
            onDragEnd={this.handleClick.bind(this)}
          />
        )}
      </GoogleMap>
    );
  }

  handleClick = ev => {
    const latLng = { lat: ev.latLng.lat(), lng: ev.latLng.lng() };

    this.setState({
      center: latLng
    });

    const { onClick } = this.props;

    onClick && onClick(latLng);
  };

  onPlacesChanged = () => {
    const places = this.searchBox.getPlaces();
    const bounds = new window.google.maps.LatLngBounds();

    places.forEach(place => {
      if (place.geometry.viewport) {
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
    });
    const nextMarkers = places.map(place => ({
      position: place.geometry.location
    }));

    const nextMarker = nextMarkers[0];

    if (!nextMarker) return;

    const nextCenter = nextMarker.position || this.state.center;

    this.setState({
      center: nextCenter,
      markers: nextMarkers
    });
    this.state.mapRef.fitBounds(bounds);
  };
}

const mapStyles = theme => ({
  input: {
    boxSizing: `border-box`,
    border: `1px solid transparent`,
    width: `240px`,
    height: `32px`,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    padding: `0 12px`,
    borderRadius: `3px`,
    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
    fontSize: `14px`,
    outline: `none`,
    textOverflow: `ellipses`
  },
  searchBox: {
    width: "240px",
    border: "1px solid transparent",
    height: "32px",
    outline: "none",
    padding: "0 12px",
    fontSize: "14px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
    boxSizing: "border-box",
    marginTop: "8px",
    marginLeft: "8px",
    borderRadius: "3px"
  }
});

export default withStyles(mapStyles)(PlaceAMarkerMap);
