import "./App.css";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import apolloClient from "./apolloClient";
import { loadReCaptcha } from "./recaptcha";
import "moment/locale/en-gb";
import LogRocket from "logrocket";
import Routes from "./components/Routes";

import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import localizedFormat from "dayjs/plugin/localizedFormat";

import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import theme from "./theme";
import { AuthProvider } from "./AuthContext";
import AppVersionSnackbar from "./components/AppVersionSnackbar";
import ReactGA from "react-ga4";
import { LicenseInfo } from "@mui/x-license";

dayjs.locale("en-gb");
dayjs.extend(localizedFormat);

const {
  REACT_APP_LOG_ROCKET_SITE_ID,
  REACT_APP_ID_GOOGLE_ANALYTICS,
  REACT_APP_MUI_X_LICENSE_KEY,
} = process.env;

LicenseInfo.setLicenseKey(REACT_APP_MUI_X_LICENSE_KEY);

if (REACT_APP_ID_GOOGLE_ANALYTICS)
  ReactGA.initialize(REACT_APP_ID_GOOGLE_ANALYTICS);

if (Boolean(REACT_APP_LOG_ROCKET_SITE_ID)) {
  // console.warn("LogRocket disabled due to issue with live stream playback");
  LogRocket.init(REACT_APP_LOG_ROCKET_SITE_ID);
}

class AppWrapper extends React.Component {
  componentDidMount() {
    loadReCaptcha();
  }
  render() {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
              <ApolloProvider client={apolloClient}>
                <Routes />
                <AppVersionSnackbar />
              </ApolloProvider>
            </BrowserRouter>
          </AuthProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

export default AppWrapper;
