import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import qs from "querystringify";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import withStyles from "@mui/styles/withStyles";

import VehicleTrackingForm from "../components/VehicleTrackingForm";
// import VehicleTrackingQuery from "../containers/VehicleTrackingQuery";
import { Query as VehicleTrackingQuery } from "../containers/VehicleTrackingQuery";
import { useQuery } from "@apollo/client";
import VehicleSafetyEventsQuery from "../containers/VehicleSafetyEventsQuery";
import VehicleTrackingMap from "../containers/VehicleTrackingMap";
import VehicleCreateIncidentDialog from "../containers/VehicleCreateIncidentDialog";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import INCIDENT_FRAGMENT from "../fragments/incident";

const RAISE_INCIDENT = gql`
  mutation raiseIncident($input: RaiseIncidentInput!) {
    incidents {
      raise(input: $input) {
        incident {
          id
          occurredAt
          location
          vehicle {
            id
            vrm
          }
          driver {
            id
            name
          }
        }
      }
    }
  }
`;

const REQUEST_FOOTAGE = gql`
  mutation RequestVehicleMedia($input: RequestVehicleMediaInput!) {
    incidents {
      requestFootage(input: $input) {
        incident {
          ...incident
        }
      }
    }
  }

  ${INCIDENT_FRAGMENT}
`;

const styles = () => ({
  map: { minHeight: "500px" },
});

const VehicleReplayADay = ({
  id,
  classes,
  supportsVideo,
  incidentsEnabled,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [createIncident, setCreateIncident] = React.useState(null);
  const [raiseIncident] = useMutation(RAISE_INCIDENT);
  const [requestFootage] = useMutation(REQUEST_FOOTAGE);

  const [{ start, end, date }, setDateRange] = useState({
    date: moment().format("YYYY-MM-DD"),
    start: moment().startOf("day").format(),
    end: moment().endOf("day").format(),
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    var date = searchParams.get("date");

    if (
      date == null ||
      !moment(date).isValid() ||
      moment().isBefore(moment(date))
    ) {
      date = moment().format("YYYY-MM-DD");
    }

    const start = moment(date).startOf("day").format();
    const end = moment(date).endOf("day").format();

    setDateRange({ start, end, date });
  }, [location]);

  const { loading: loadingTrackingData, data: trackingData } = useQuery(
    VehicleTrackingQuery,
    {
      variables: { id, filter: { from: start, until: end } },
      fetchPolicy: "cache-and-network",
    }
  );

  const handleQuerySubmit = (q) => {
    if (moment(start).format("YYYY-MM-DD") != q.date) {
      history.push(
        `${history.location.pathname}?${new URLSearchParams(q).toString()}`
      );
    }
  };

  const handleCreateIncident = (q) => {
    setCreateIncident({
      date: moment(q.start).format("YYYY-MM-DD"),
      time: moment(q.start).format("HH:mm"),
      from: moment(q.start).format("HH:mm:ss"),
      end: moment(q.end).format("HH:mm"),
    });
  };

  const handleCreateIncidentClose = () => {
    setCreateIncident(null);
  };

  const handleCreateIncidentSubmit = async (form) => {
    const variables = {
      input: {
        driverId: "",
        location: "",
        occurredAt: form.occurredAt,
        vehicleId: id,
      },
    };

    const {
      data: {
        incidents: { raise },
      },
    } = await raiseIncident({ variables });

    form.requestFootageVehicle &&
      handleRequestFootage({
        ...form,
        incidentId: raise.incident.id,
      });

    handleCreateIncidentClose();

    history.push(`/incidents/${raise.incident.id}?new`);
  };

  const handleRequestFootage = (form) => {
    const variables = {
      input: {
        incidentId: form.incidentId,
        cameraIds: form.availableCameraSelected,
        startAt: form.startAt,
        duration: form.duration,
      },
    };

    return requestFootage({ variables });
  };

  if (loadingTrackingData) {
    return <p> Loading...</p>;
  }

  if (
    !(trackingData?.node.journey.markers?.length ?? 0) &&
    !(trackingData?.node.live.video?.length ?? 0)
  ) {
    return (
      <div className={classes.map}>
        <VehicleTrackingForm initialDate={date} onSubmit={handleQuerySubmit} />
        <p>
          No information was found for the specified range, try expanding the
          search.
        </p>
      </div>
    );
  }

  return (
    <>
      <VehicleSafetyEventsQuery {...{ start, end, id }}>
        {({ data: safetyEventData }) => (
          <>
            <VehicleTrackingMap
              {...{
                vehicleId: id,
                vehicleIsOnline: trackingData.node.live?.online,
                trackingData: trackingData.node.journey,
                cameraData: trackingData.node.live,
                safetyEventData,
                incidentsEnabled,
                onCreateIncident: handleCreateIncident,
                supportsVideo,
                mapClassName: classes.map,
                initialDate: date,
                onSubmitQueryData: handleQuerySubmit,
              }}
            />
            {incidentsEnabled && (
              <VehicleCreateIncidentDialog
                open={Boolean(createIncident)}
                onClose={handleCreateIncidentClose}
                onSubmit={handleCreateIncidentSubmit}
                date={createIncident?.date}
                time={createIncident?.time}
                from={createIncident?.from}
                end={createIncident?.end}
                availableCameras={trackingData.node.device?.cameras || []}
              />
            )}
          </>
        )}
      </VehicleSafetyEventsQuery>
    </>
  );
};

VehicleReplayADay.propTypes = {
  id: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  supportsVideo: PropTypes.bool.isRequired,
};

export default withStyles(styles)(VehicleReplayADay);
