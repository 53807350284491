import React, { useState, useRef, useEffect } from "react";
import { Box, Grid, FormControl, InputLabel, TextField } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import moment from "moment";

import Chip from "@mui/material/Chip";
import {
  ISSUE_RAISED,
  IN_PROGRESS,
  COMPLETE,
  toDisplayName,
} from "./../near-miss/Statuses";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";

import DateRangeFilter from "../near-miss/DateRangeFilter";
import Divider from "@mui/material/Divider";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";

const useStyles = makeStyles((theme) => ({
  renderValue: {
    ...theme.typography.button,
    backgroundColor: "#e5e7eb",
    paddingLeft: "8px",
    paddingRight: "8px",
  },
  renderLabel: {
    fontWeight: 500,
  },
}));

function Filters({
  initialValues,
  onChange,
  depotConnection,
  categoryConnection,
}) {
  const theme = useTheme();
  const classes = useStyles();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const filters = initialValues;

  const handleChangeDateRange = (dateRange) => {
    onChange({
      ...initialValues,
      ["fromDate"]: dateRange[0]?.startOf("day").format(),
      ["toDate"]: dateRange[1]?.endOf("day").format(),
    });
  };

  const handleChangeFilter = (filterName, event) => {
    const {
      target: { value },
    } = event;

    onChange({
      ...initialValues,
      [filterName]: typeof value === "string" ? value.split(",") : value,
    });
  };

  const typingTimeoutRef = useRef(null);
  const handleChangeTextBox = (filterName, e) => {
    const value = e.target.value;
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      onChange({
        ...initialValues,
        [filterName]: value,
      });
    }, 500);
  };

  const getStyles = (name, values, theme) => ({
    fontWeight:
      values.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  });

  const getCategoryName = (id) => {
    const category = categoryConnection?.edges.find(
      ({ node }) => node.id === id
    );
    return category?.node?.name;
  };

  const getDepotName = (id) => {
    const depot = depotConnection?.edges.find(({ node }) => node.id === id);
    return depot?.node?.name;
  };

  const dateRangeValue = [
    moment(filters.fromDate ?? moment().subtract(6, "day")),
    moment(filters.toDate ?? moment()),
  ];

  return (
    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
      {!isMobile ? (
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between">
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" />}
              spacing={1}
            >
              <FormControl variant="outlined" fullWidth sx={{ minWidth: 220 }}>
                <DateRangeFilter
                  dateRangeValue={dateRangeValue}
                  onChangeDateRange={handleChangeDateRange}
                />
              </FormControl>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  name="locationLike"
                  autoComplete="off"
                  label="Location"
                  type="search"
                  size="small"
                  defaultValue={filters.locationLike}
                  onChange={(event) =>
                    handleChangeTextBox("locationLike", event)
                  }
                />
              </FormControl>
            </Stack>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" />}
              spacing={1}
            >
              <FormControl size="small">
                <Select
                  labelId="status-select"
                  id="status-select"
                  label="Status"
                  multiple
                  value={filters.statuses}
                  onChange={(event) => handleChangeFilter("statuses", event)}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "right",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                  }}
                  size="small"
                  input={
                    <OutlinedInput
                      sx={{
                        "& fieldset": {
                          border: "none",
                        },
                      }}
                    />
                  }
                  displayEmpty
                  renderValue={(selected) => (
                    <Stack direction="row" spacing={1}>
                      <Typography className={classes.renderLabel}>
                        Status
                      </Typography>
                      {selected.length > 0 && (
                        <div className={classes.renderValue}>
                          {selected.length}
                        </div>
                      )}
                    </Stack>
                  )}
                >
                  <MenuItem
                    style={getStyles(ISSUE_RAISED, filters.statuses, theme)}
                    value={ISSUE_RAISED}
                  >
                    <Checkbox
                      checked={filters.statuses.indexOf(ISSUE_RAISED) > -1}
                    />
                    <ListItemText primary={toDisplayName(ISSUE_RAISED)} />
                  </MenuItem>
                  <MenuItem
                    style={getStyles(IN_PROGRESS, filters.statuses, theme)}
                    value={IN_PROGRESS}
                  >
                    <Checkbox
                      checked={filters.statuses.indexOf(IN_PROGRESS) > -1}
                    />
                    <ListItemText primary={toDisplayName(IN_PROGRESS)} />
                  </MenuItem>
                  <MenuItem
                    style={getStyles(COMPLETE, filters.statuses, theme)}
                    value={COMPLETE}
                  >
                    <Checkbox
                      checked={filters.statuses.indexOf(COMPLETE) > -1}
                    />
                    <ListItemText primary={toDisplayName(COMPLETE)} />
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl size="small">
                <Select
                  labelId="depot-select"
                  id="depot-select"
                  label="Depot"
                  multiple
                  value={filters.depotIds}
                  onChange={(event) => handleChangeFilter("depotIds", event)}
                  size="small"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "right",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                  }}
                  input={
                    <OutlinedInput
                      sx={{
                        "& fieldset": {
                          border: "none",
                        },
                      }}
                    />
                  }
                  displayEmpty
                  renderValue={(selected) => (
                    <Stack direction="row" spacing={1}>
                      <Typography className={classes.renderLabel}>
                        Depot
                      </Typography>
                      {selected.length > 0 && (
                        <div className={classes.renderValue}>
                          {selected.length}
                        </div>
                      )}
                    </Stack>
                  )}
                >
                  {depotConnection &&
                    depotConnection.edges.map(({ node }) => (
                      <MenuItem
                        key={node.id}
                        value={node.id}
                        style={getStyles(node.id, filters.depotIds, theme)}
                      >
                        <Checkbox
                          checked={filters.depotIds.indexOf(node.id) > -1}
                        />
                        <ListItemText primary={node.name} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl size="small">
                <Select
                  labelId="category-select"
                  id="category-select"
                  label="Category"
                  multiple
                  value={filters.categoryIds}
                  onChange={(event) => handleChangeFilter("categoryIds", event)}
                  size="small"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "right",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                  }}
                  input={
                    <OutlinedInput
                      sx={{
                        "& fieldset": {
                          border: "none",
                        },
                      }}
                    />
                  }
                  displayEmpty
                  renderValue={(selected) => (
                    <Stack direction="row" spacing={1}>
                      <Typography className={classes.renderLabel}>
                        Category
                      </Typography>
                      {selected.length > 0 && (
                        <div className={classes.renderValue}>
                          {selected.length}
                        </div>
                      )}
                    </Stack>
                  )}
                >
                  {categoryConnection &&
                    categoryConnection.edges.map(({ node }) => (
                      <MenuItem
                        key={node.id}
                        value={node.id}
                        style={getStyles(node.id, filters.categoryIds, theme)}
                      >
                        <Checkbox
                          checked={filters.categoryIds.indexOf(node.id) > -1}
                        />
                        <ListItemText primary={node.name} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Stack>
          </Stack>
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                labelId="status-select"
                id="status-select"
                label="Status"
                multiple
                value={filters.statuses}
                onChange={(event) => handleChangeFilter("statuses", event)}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={toDisplayName(value)}
                        size="small"
                      />
                    ))}
                  </Box>
                )}
              >
                <MenuItem
                  style={getStyles(ISSUE_RAISED, filters.statuses, theme)}
                  value={ISSUE_RAISED}
                >
                  {toDisplayName(ISSUE_RAISED)}
                </MenuItem>
                <MenuItem
                  style={getStyles(IN_PROGRESS, filters.statuses, theme)}
                  value={IN_PROGRESS}
                >
                  {toDisplayName(IN_PROGRESS)}
                </MenuItem>
                <MenuItem
                  style={getStyles(COMPLETE, filters.statuses, theme)}
                  value={COMPLETE}
                >
                  {toDisplayName(COMPLETE)}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="depot-select">Depot</InputLabel>
              <Select
                labelId="depot-select"
                id="depot-select"
                label="Depot"
                multiple
                value={filters.depotIds}
                onChange={(event) => handleChangeFilter("depotIds", event)}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {depotConnection &&
                      selected.map((value) => (
                        <Chip
                          key={value}
                          label={getDepotName(value)}
                          size="small"
                        />
                      ))}
                  </Box>
                )}
              >
                {depotConnection &&
                  depotConnection.edges.map(({ node }) => (
                    <MenuItem
                      key={node.id}
                      value={node.id}
                      style={getStyles(node.id, filters.depotIds, theme)}
                    >
                      {node.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="category-select">Category</InputLabel>
              <Select
                labelId="category-select"
                id="category-select"
                label="Category"
                multiple
                value={filters.categoryIds}
                onChange={(event) => handleChangeFilter("categoryIds", event)}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {categoryConnection &&
                      selected.map((value) => (
                        <Chip
                          key={value}
                          label={getCategoryName(value)}
                          size="small"
                        />
                      ))}
                  </Box>
                )}
              >
                {categoryConnection &&
                  categoryConnection.edges.map(({ node }) => (
                    <MenuItem
                      key={node.id}
                      value={node.id}
                      style={getStyles(node.id, filters.categoryIds, theme)}
                    >
                      {node.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                name="locationLike"
                autoComplete="off"
                label="Location"
                type="search"
                defaultValue={filters.locationLike}
                onChange={(event) => handleChangeTextBox("locationLike", event)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <DateRangeFilter
                dateRangeValue={dateRangeValue}
                onChangeDateRange={handleChangeDateRange}
              />
            </FormControl>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default Filters;
