import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import withStyles from "@mui/styles/withStyles";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const SEND_REQUEST = gql`
  mutation sendDriverIncidentAccountRequest(
    $input: SendDriverIncidentAccountRequestInput!
  ) {
    incidents {
      requestDriverAccount(input: $input) {
        incident {
          id
        }
      }
    }
  }
`;

const styles = (theme) => ({
  errorText: {
    color: theme.palette.error.main,
  },
});

class RequestDriverIncidentAccountDialog extends React.Component {
  state = { working: false, error: null };

  render() {
    const { open, classes } = this.props;
    const { working, error } = this.state;

    return (
      <Mutation mutation={SEND_REQUEST}>
        {(requestDriverAccount, { data }) => (
          <Dialog
            open={open}
            disableEscapeKeyDown={working}
            onClose={this.handleClose.bind(this)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Request driver account and evidence"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Send a request to the driver via SMS for their account of the
                incident. They can also upload photographic evidence which will
                help with the investigation.
              </DialogContentText>
              {error && (
                <DialogContentText
                  className={classes.errorText}
                  id="alert-dialog-description"
                >
                  {error}
                </DialogContentText>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                disabled={working}
                onClick={this.handleClose.bind(this)}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                disabled={working}
                onClick={this.handleSendRequest(requestDriverAccount)}
                color="primary"
                autoFocus
              >
                Send request
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Mutation>
    );
  }

  handleSendRequest = (requestDriverAccount) => () => {
    const { incidentId } = this.props;
    const variables = { input: { incidentId, method: "SMS" } };
    this.setState({ working: true });

    requestDriverAccount({ variables }).then(
      () => {
        this.setState({ working: false });
        this.handleSent();
      },
      (error) => {
        this.setState({ working: false, error: error.message });
      }
    );
  };

  handleClose() {
    this.props.onClose();
  }

  handleSent() {
    this.props.onSent();
  }
}

RequestDriverIncidentAccountDialog.propTypes = {
  incidentId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSent: PropTypes.func.isRequired,
};

export default withStyles(styles)(RequestDriverIncidentAccountDialog);
