import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";

import VehicleLiveMap from "../containers/VehicleLiveMap";
import LiveVideo from "./LiveVideo";
import EditCameraLocationDialog from "../components/EditCameraLocationDialog";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

const SET_CAMERA_LOCATION_MUTATION = gql`
  mutation Mutation($input: Vehicles_SetCameraLocation_Input!) {
    vehicles {
      setCameraLocation(input: $input) {
        vehicle {
          id
          live {
            video {
              camera
              label
              cameraId
            }
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  loading: {
    marginBottom: theme.spacing(2),
  },
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  videoContainer: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
      marginRight: theme.spacing(2),
      flex: 2,
    },
  },
  mapContainer: {
    flex: 1,
  },
}));

const VehicleLiveTab = ({ vehicleId, vehicle, supportsVideo }) => {
  const classes = useStyles();

  const [setCameraLocationError, setSetCameraLocationError] =
    React.useState(null);
  const [selectedCameraToChangeLocation, setSelectedCameraToChangeLocation] =
    React.useState(null);
  const handleSetCameraLocationClick = ({ cameraId, existingLocation }) => {
    setSelectedCameraToChangeLocation({ cameraId, existingLocation });
  };

  const handleSetCameraLocationCancel = () => {
    setSetCameraLocationError(null);
    setSelectedCameraToChangeLocation(null);
  };

  const [setCameraLocation] = useMutation(SET_CAMERA_LOCATION_MUTATION);
  const [working, setWorking] = React.useState(false);

  const handleSetCameraLocation = (value) => {
    const cameraId = selectedCameraToChangeLocation.cameraId;

    const input = {
      vehicleId,
      cameraId,
      location: value,
    };

    setSetCameraLocationError(null);
    setWorking(true);

    return setCameraLocation({ variables: { input } })
      .then(() => {
        setSelectedCameraToChangeLocation(null);
      })
      .catch((error) => {
        console.error("Failed to set camera location", error);
        setSetCameraLocationError(error);
      })
      .finally(() => {
        setWorking(false);
      });
  };

  // if (vehicleId !== vehicle?.id) return <div>Loading...</div>;

  return (
    <div className={classes.root}>
      {supportsVideo && (
        <div className={classes.videoContainer}>
          {vehicle.live.online ? (
            vehicle.deviceId ? (
              <>
                <LiveVideo
                  deviceSerialNumber={vehicle.deviceId}
                  streams={vehicle.live.video}
                  onSetCameraLocationClick={handleSetCameraLocationClick}
                />
              </>
            ) : (
              <p>Vehicle not set up for device.</p>
            )
          ) : (
            <p>Vehicle must be online for live video</p>
          )}
          <EditCameraLocationDialog
            open={Boolean(selectedCameraToChangeLocation)}
            working={working}
            initialLocation={selectedCameraToChangeLocation?.existingLocation}
            error={setCameraLocationError}
            onClose={handleSetCameraLocationCancel}
            onUpdateClick={handleSetCameraLocation}
          />
        </div>
      )}
      <div className={classes.mapContainer}>
        <VehicleLiveMap id={vehicleId} />
      </div>
    </div>
  );
};

VehicleLiveTab.propTypes = {
  vehicle: PropTypes.object.isRequired,
  supportsVideo: PropTypes.bool.isRequired,
  vehicleId: PropTypes.string.isRequired,
};

export default VehicleLiveTab;
