import React from "react";

import gql from "graphql-tag";
import { Query } from "@apollo/client/react/components";

import { Field } from "react-final-form";
import Select from "../components/form/Select";
import MenuItem from "@mui/material/MenuItem";

const DEPOTS_QUERY = gql`
  query AllDepots {
    depots {
      all {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            id
            name
            closed
          }
        }
      }
    }
  }
`;

const CloseDepotForm = ({ errorCode, depotId }) => (
  <Query query={DEPOTS_QUERY}>
    {({ loading, error, data }) => {
      return (
        <Field
          component={Select}
          name="depotId"
          label="Depot"
          fullWidth
          disabled={Boolean(loading || error)}
          error={Boolean(errorCode)}
        >
          {!Boolean(loading || error) &&
            data.depots.all.edges.map(({ node }) => (
              <MenuItem
                key={node.id}
                value={node.id}
                disabled={node.id === depotId}
              >
                {node.name}
              </MenuItem>
            ))}
        </Field>
      );
    }}
  </Query>
);

CloseDepotForm.propTypes = {};

export default CloseDepotForm;
