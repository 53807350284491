import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import moment from "moment";

import { Link } from "@mui/material";

import { InfoWindow } from "@react-google-maps/api";
import Button from "@mui/material/Button";
import CarCrashIcon from "@mui/icons-material/CarCrash";

import ClockIcon from "@mui/icons-material/AccessTime";
import MarkerIcon from "@mui/icons-material/Room";
import BusinessIcon from "@mui/icons-material/Business";
import TimerIcon from "@mui/icons-material/Timer";

const styles = (theme) => ({
  findFootageButton: {
    padding: "3px 10px 3px 3px",
    textTransform: "none",
    fontWeight: "normal",
    width: "100%",
    marginTop: theme.spacing(2),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  container: {
    display: "inline-flex",
    "align-items": "center",
  },
  icons: {
    paddingRight: 5,
  },
  text: {
    "overflow-wrap": "break-word",
  },
  coordsLink: {
    "margin-left": ".2rem",
  },
});

const VehicleStopInfoWindow = ({
  stop,
  onCloseWindow,
  onCreateIncident,
  classes,
  supportsVideo,
  incidentsEnabled,
}) => (
  <InfoWindow
    position={stop.coord}
    onCloseClick={onCloseWindow}
    options={{ pixelOffset: new window.google.maps.Size(0, -16) }}
  >
    <div>
      <h3>Stop</h3>
      <div className={classes.container}>
        <ClockIcon className={classes.icons} fontSize="small" />
        <b>Stopped</b>: {moment(stop.from).format("LTS")}
      </div>
      <br />
      {stop.to && stop.to > stop.from && (
        <>
          <div className={classes.container}>
            <TimerIcon className={classes.icons} fontSize="small" />
            <b>Duration</b>: {stop.duration}
          </div>
          <br />
        </>
      )}

      {stop.stopaddressstring != "" && (
        <div className={classes.container}>
          <BusinessIcon className={classes.icons} fontSize="small" />
          <span className={classes.text}>
            <b>Address</b>: {stop.stopaddressstring}
          </span>
        </div>
      )}
      {stop.stopaddressstring != "" && <br />}
      <div className={classes.container}>
        <MarkerIcon className={classes.icons} fontSize="small" />
        <b>Position</b>:{" "}
        <Link
          className={classes.coordsLink}
          href={`https://maps.google.com?q=${stop.coord.lat},${stop.coord.lng}`}
          target="_blank"
          title="Open location in Google maps"
        >
          {stop.coord.lat}, {stop.coord.lng}
        </Link>
      </div>
      <br />
      {supportsVideo && incidentsEnabled && (
        <Button
          size="small"
          variant="outlined"
          className={classes.findFootageButton}
          onClick={onCreateIncident(stop.from)}
        >
          <CarCrashIcon className={classes.leftIcon} /> Create incident
        </Button>
      )}
    </div>
  </InfoWindow>
);

VehicleStopInfoWindow.propTypes = {
  stop: PropTypes.object.isRequired,
  onCloseWindow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VehicleStopInfoWindow);
