import React from "react";
import PropTypes from "prop-types";

import { ListItem, FormControl, InputAdornment, Input } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const DrawerVehicleSearchBox = ({ onSearch }) => {
  const [q, setQ] = React.useState("");

  const handleSubmit = (ev) => {
    ev.preventDefault();
    onSearch(q);
  };

  const handleChange = (ev) => {
    const q = ev.target.value;
    setQ(q);
    onSearch(q);
  };

  return (
    <ListItem sx={{ bgcolor: "secondary.light" }}>
      <form onSubmit={handleSubmit}>
        <FormControl
          variant="standard"
          sx={{ bgcolor: "secondary.contrastText" }}
        >
          <Input
            id="input-with-icon-adornment"
            type="search"
            onChange={handleChange}
            placeholder="Search"
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </FormControl>
      </form>
    </ListItem>
  );
};

DrawerVehicleSearchBox.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default DrawerVehicleSearchBox;
