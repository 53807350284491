import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  IconButton,
} from "@mui/material";
import { PlayCircleFilled as PlayIcon } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  progressIconWrapper: {
    position: "relative",
  },
  iconProgress: {
    position: "absolute",
    top: 12,
    left: 12,
    zIndex: 1,
  },
  playButton: {
    backgroundColor: "green",
  },
  fab: {
    margin: theme.spacing(1),
    right: theme.spacing(1),
    bottom: theme.spacing(1),
    display: "flex",
    position: "fixed",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  iconsContainer: {
    display: "flex",
  },
}));

const VehicleFootageList = ({ footage, playStream }) => {
  const classes = useStyles();

  const handlePlayStreamClick = (stream) => () => {
    return playStream(stream);
  };

  const formatDate = (date) => moment(date).format("LT");

  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Camera</TableCell>
            <TableCell>Start time</TableCell>
            <TableCell>Duration</TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(footage ?? []).edges.map(({ node, cursor }) => (
            <TableRow key={cursor} hover>
              <TableCell>{node.camera}</TableCell>
              <TableCell>
                <Tooltip
                  title={`${moment(node.startTime).format("ll")} ${moment(
                    node.startTime
                  ).format("LTS")}`}
                >
                  <span>{formatDate(node.startTime)}</span>
                </Tooltip>
              </TableCell>
              <TableCell>{node.duration}</TableCell>
              <TableCell padding="checkbox">
                <div className={classes.iconsContainer}>
                  {playStream && node.streams && (
                    <div>
                      <IconButton
                        color="primary"
                        onClick={handlePlayStreamClick({
                          label: `${node.camera} - ${moment(
                            node.startTime
                          ).format("ll")} ${moment(node.startTime).format(
                            "LTS"
                          )}`,
                          sources: node.streams.map(({ type, source }) => ({
                            type,
                            source,
                          })),
                        })}
                        size="large"
                      >
                        <PlayIcon />
                      </IconButton>
                    </div>
                  )}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

VehicleFootageList.propTypes = {
  footage: PropTypes.object.isRequired,
  playStream: PropTypes.func.isRequired,
};

export default VehicleFootageList;
