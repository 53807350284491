import React from "react";
import PropTypes from "prop-types";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import Button from "@mui/material/Button";

import INCIDENT_FRAGMENT from "../fragments/incident";
import { QUERY as INCIDENT_QUERY } from "../containers/Incident";

const REACTIVATE_INCIDENT = gql`
  mutation reactivateIncident($input: ReactivateIncidentInput!) {
    incidents {
      reactivate(input: $input) {
        incident {
          ...incident
        }
      }
    }
  }

  ${INCIDENT_FRAGMENT}
`;

const ReactivateIncidentButton = ({ incidentId }) => {
  const [reactivate] = useMutation(REACTIVATE_INCIDENT);

  const handleSubmit = (event) => {
    const variables = { input: { incidentId } };

    return reactivate({
      variables,
      update: (
        store,
        {
          data: {
            incidents: { reactivateIncident },
          },
        }
      ) => {
        const data = store.readQuery({
          query: INCIDENT_QUERY,
          variables: { id: incidentId },
        });

        const newData = {
          ...data,
          node: { ...data.node, status: "ACTIVE", closedReason: "" },
        };

        store.writeQuery({
          query: INCIDENT_QUERY,
          variables: { id: incidentId },
          data: newData,
        });
      },
    });
  };

  return (
    <Button onClick={handleSubmit} variant="outlined" color="inherit">
      Reactivate
    </Button>
  );
};

ReactivateIncidentButton.propTypes = {
  incidentId: PropTypes.string.isRequired,
};

export default ReactivateIncidentButton;
