import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";

import { Field } from "react-final-form";
import Switch from "./form/Switch";
import Grid from "@mui/material/Grid";
import TextField from "./form/TextField";

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

const IncidentPoliceDetailsForm = ({ fieldName, value, src, classes }) => (
  <div>
    <Field
      component={Switch}
      name={"policeDetails.policeInvolved"}
      margin="normal"
      label="Are the police involved?"
      type="checkbox"
    />

    {value.policeInvolved && (
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <Field
            component={TextField}
            name={"policeDetails.incidentNumber"}
            margin="normal"
            label="Incident number"
            multiline
            maxRows={3}
            fullWidth
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Field
            component={TextField}
            name={"policeDetails.policeOfficerName"}
            margin="normal"
            label="Police officer’s name"
            maxRows={3}
            fullWidth
          />
        </Grid>
      </Grid>
    )}
  </div>
);

IncidentPoliceDetailsForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IncidentPoliceDetailsForm);
