import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import moment from "moment";

import { Form, Field } from "react-final-form";
import TextField from "../components/form/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DriverSelect from "../containers/DriverSelect";
import VehicleSelect from "../containers/VehicleSelect";
import VehicleTimeAtLocationButton from "../components/VehicleTimeAtLocationButton";

// FIXME checkout https://mui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
const withMobileDialog = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="lg" fullScreen={false} />;

const styles = (theme) => ({
  flex: {
    display: "flex",
    alignItems: "baseline",
  },
  iconRight: {
    marginLeft: theme.spacing(1),
  },
});

const validateRequired = (fieldName) => (value) =>
  !value && `${fieldName} is required`;

const validateDateFormat = (value) =>
  value &&
  !/^[\d]{2,4}[/-][\d]{2}[/-][\d]{2,4}$/.test(value) &&
  "Invalid date format. dd/mm/yyyy";

const validateDateInPast = (value) =>
  moment().isBefore(moment(value)) && "Cannot be in the future";

const validateDateAfter = (value) =>
  moment().subtract(1, "year").isAfter(moment(value)) &&
  `Please select a date after ${moment().subtract(1, "year").format("YYYY")}`;

const composeValidators =
  (...validators) =>
  (value, form) =>
    validators.reduce(
      (error, validator) => error || validator(value, form),
      undefined
    );

const validateDate = (fieldName) =>
  composeValidators(
    validateRequired(fieldName),
    validateDateFormat,
    validateDateInPast,
    validateDateAfter
  );

const NewIncidentDialog = ({
  classes,
  open,
  fullScreen,
  form,
  handleSubmit: handleFormSubmit,
  handleClose,
  defaultVehicle,
}) => (
  <Dialog
    open={open}
    fullScreen={fullScreen}
    onClose={handleClose}
    aria-labelledby="form-dialog-title"
  >
    <Form
      mutators={{
        setFieldValue: ([name, value], state, utils) => {
          utils.changeValue(state, name, () => value);
        },
        setDriver: ([value], state, utils) => {
          utils.changeValue(state, "driverId", () => value);
        },
        setVehicle: ([value], state, utils) => {
          utils.changeValue(state, "vehicleId", () => value);
        },
      }}
      onSubmit={handleFormSubmit}
      initialValues={form}
      render={({ form, handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          <DialogTitle sx={{ m: 0, p: 2 }}>
            New Incident
            {handleClose ? (
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
          <DialogContent>
            <DialogContentText mb={2}>
              To create a new incident, please select a vehicle and a date. All
              other details are optional at this point.
            </DialogContentText>
            {/* <code>{JSON.stringify(values)}</code> */}
            <Grid spacing={2} container>
              <Grid item xs={12} sm={6}>
                <VehicleSelect
                  name="vehicleId"
                  required
                  defaultValue={defaultVehicle}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DriverSelect
                  name="driverId"
                  allowNew
                  onNewDriverCreated={(value) => {
                    form.mutators.setFieldValue("driverId", value);
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <Field
                  component={TextField}
                  name="incidentDate"
                  validate={validateDate("Incident date")}
                  margin="normal"
                  required
                  label="Incident date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="date"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <div className={classes.flex}>
                  <Field
                    component={TextField}
                    name="incidentTime"
                    validate={validateRequired("Incident time")}
                    margin="normal"
                    required
                    label="Incident time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="time"
                    fullWidth
                  />

                  <VehicleTimeAtLocationButton
                    vehicleId={values.vehicleId}
                    className={classes.iconRight}
                    onSelected={(dt) => {
                      form.change(
                        "incidentDate",
                        moment(dt).format("YYYY-MM-DD")
                      );
                      form.change("incidentTime", moment(dt).format("HH:mm"));
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  name="location"
                  margin="normal"
                  autoComplete="off"
                  label="Location"
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button disabled={submitting} onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button disabled={submitting} type="submit" color="primary">
              Create
            </Button>
          </DialogActions>
        </form>
      )}
    />
  </Dialog>
);

NewIncidentDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withMobileDialog()(withStyles(styles)(NewIncidentDialog));
