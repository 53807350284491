import React from "react";
import { makeStyles } from "@mui/styles";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default ({
  options,
  handleChange,
  getOptionLabel,
  styles,
  label,
  meta,
  children,
  defaultValues,
  ...rest
}) => {
  const useStyles = makeStyles(
    {
      root: { minWidth: 200, width: "100%" },
      popup: { zIndex: 1500 },
      paper: { width: "fit-content", minWidth: "100%" },
      listbox: { width: "max-content", minWidth: "100%" },
    },
    { name: "MuiAutocomplete" }
  );

  useStyles();

  return (
    <Autocomplete
      id="has-default-value"
      multiple
      options={options}
      disableCloseOnSelect
      getOptionLabel={(option) => getOptionLabel(option)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={defaultValues}
      onChange={(_, value) => {
        handleChange(value);
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {getOptionLabel(option)}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          fullWidth
          {...rest}
        />
      )}
    />
  );
};
