import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import withStyles from "@mui/styles/withStyles";
import { useQuery, useMutation } from "@apollo/client";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";

import MembersList from "../components/MembersList";
import CreateMemberFab from "../components/CreateMemberFab";
import ViewAccessDialog from "../containers/ViewAccessDialog";

const MEMBERS_QUERY = gql`
  {
    account {
      members {
        all {
          totalCount
          pageInfo {
            hasNextPage
            endCursor
            startCursor
            hasPreviousPage
          }
          edges {
            cursor
            node {
              id
              name
              lastLoggedIn
              emailAddress
              memberSince
              userGroups {
                totalCount
                edges {
                  cursor
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const REMOVE_MEMBER = gql`
  mutation RemoveMember($id: ID!) {
    account {
      members {
        remove(id: $id) {
          error {
            code
            message
          }
        }
      }
    }
  }
`;

const styles = (theme) => ({
  fab: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  error: {
    color: "red",
  },
});

const Members = ({ classes, getTotal }) => {
  const [memberId, setMemberId] = useState();
  const [open, setOpen] = useState(false);
  const [groups, setGroups] = useState(false);
  const [deleteError, setDeleteError] = useState();

  const { loading, error, data } = useQuery(MEMBERS_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  const [remove, { loading: updating }] = useMutation(REMOVE_MEMBER, {
    refetchQueries: () => [
      {
        query: MEMBERS_QUERY,
      },
    ],
  });

  data &&
    data.account &&
    data.account.members &&
    data.account.members.all &&
    getTotal(data.account.members.all.totalCount);

  if (loading && !data) return <LinearProgress className={classes.loading} />;

  if (error) return JSON.stringify(error);

  const handleOpenDialog = (id) => {
    setMemberId(id);
  };

  const handleCloseDialog = () => {
    setMemberId(null);
  };

  const handleRemoveMember = () => {
    const variables = { id: memberId };
    remove({ variables }).then(
      ({
        data: {
          account: {
            members: {
              remove: { error },
            },
          },
        },
      }) => {
        if (error !== null) {
          const message = `${error.message} (${error.code})`;
          setDeleteError(message);
        } else {
          setMemberId(null);
        }
      }
    );
  };

  const openViewAccessDialog = (groups) => {
    setGroups(groups);
    setOpen(true);
  };

  const closeViewAccessDialog = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <MembersList
        data={data?.account?.members.all}
        onOpenDialog={handleOpenDialog}
        openViewAccessDialog={openViewAccessDialog}
      />
      <CreateMemberFab className={classes.fab} />

      <ViewAccessDialog
        open={open}
        closeViewAccessDialog={closeViewAccessDialog}
        groups={groups}
      />

      <Dialog open={Boolean(memberId)} onClose={handleCloseDialog}>
        <DialogTitle>Remove user</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Confirm you wish to remove this user's access from this account.
          </DialogContentText>
          <DialogContentText>&nbsp;</DialogContentText>
          <DialogContentText>
            You can invite a user at any time to allow access to this account.
          </DialogContentText>
          {deleteError && (
            <DialogContentText className={classes.error}>
              {deleteError}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="primary"
            disabled={updating}
          >
            Cancel
          </Button>
          <Button
            onClick={handleRemoveMember}
            color="primary"
            autoFocus
            disabled={updating}
          >
            Yes, remove user
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

Members.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Members);
