import React from "react";
import qs from "querystringify";
import { useLocation } from "react-router-dom";

import CenteredPaperLayout from "../components/CenteredPaperLayout";

import SignIn from "../containers/SignIn";
import AbovePaperLogo from "../components/AbovePaperLogo";

const SignInPage = () => {
  const { search } = useLocation();
  const searchObject = qs.parse(search);

  return (
    <CenteredPaperLayout title="Sign in" above={<AbovePaperLogo />}>
      <SignIn
        from={searchObject.from}
        continueTo={searchObject.continue}
        email={searchObject.email}
        tenant={searchObject.tenant}
      />
    </CenteredPaperLayout>
  );
};

export default SignInPage;
