import React from "react";

import TextField from "../components/form/TextField";
import SelectAutoComplete from "../components/form/SelectAutoComplete";
import { Field } from "react-final-form";
import Grid from "@mui/material/Grid";
// import { validateEmail } from "../utils";

const validateInput = value => !value && "Input is required";

const CreateMemberForm = ({ handleChange, getOptionLabel, options }) => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Field
            component={TextField}
            type="email"
            name="emailAddress"
            required
            validate={validateInput}
            margin="normal"
            autoComplete="off"
            label="Email address"
            fullWidth
            autoFocus
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Field
            component={SelectAutoComplete}
            name="groups"
            label="Groups"
            options={options}
            getOptionLabel={getOptionLabel}
            handleChange={handleChange}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateMemberForm;
