import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import AnchorLink from "@mui/material/Link";

import AdapterLink from "../components/form/Link";

// FIXME checkout https://mui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
const withMobileDialog = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="lg" fullScreen={false} />;

const styles = (theme) => ({
  content: {
    minWidth: "500px",
    marginBottom: theme.spacing(4),
  },
});

const ViewAccessDialog = ({ classes, open, closeViewAccessDialog, groups }) => {
  return (
    <Dialog
      open={open}
      onClose={closeViewAccessDialog}
      className={classes.dialog}
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        View access
        {closeViewAccessDialog ? (
          <IconButton
            aria-label="close"
            onClick={closeViewAccessDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        {groups.totalCount > 0 ? (
          <>
            <h4>Groups</h4>
            {groups &&
              groups.edges &&
              groups.edges.map(({ node: group, cursor }) => (
                <p key={cursor}>
                  <AnchorLink
                    component={AdapterLink}
                    className={classes.link}
                    to={`/admin/groups/${group.id}`}
                  >
                    {group.name}
                  </AnchorLink>
                </p>
              ))}
          </>
        ) : (
          <DialogContentText className={classes.content}>
            No user group
          </DialogContentText>
        )}
      </DialogContent>
    </Dialog>
  );
};

ViewAccessDialog.propTypes = {
  closeViewAccessDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withStyles(styles)(withMobileDialog()(ViewAccessDialog));
