import React, { Fragment } from "react";

import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import NewDriverDialog from "../containers/NewDriverDialog";

class CreateDriverFab extends React.Component {
  state = {
    open: false,
  };

  render() {
    const { className } = this.props;
    const { open } = this.state;

    return (
      <Fragment>
        <Fab
          color="secondary"
          className={className}
          onClick={this.handleOpen.bind(this)}
        >
          <AddIcon />
        </Fab>
        <NewDriverDialog
          open={open}
          handleClose={this.handleClose.bind(this)}
          onCreated={this.handleNewCreated.bind(this)}
        />
      </Fragment>
    );
  }

  handleOpen() {
    this.setState((_) => ({ open: true }));
  }

  handleClose() {
    this.setState((_) => ({ open: false }));
  }

  handleNewCreated(id) {
    this.setState((_) => ({ open: false }));
  }
}

export default CreateDriverFab;
