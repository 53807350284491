import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import Grid from "@mui/material/Grid";

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
});

const IncidentPoliceDetails = ({ value, classes }) => (
  <div>
    {!value.policeInvolved && (<p>Police not involved</p>)}
    {value.policeInvolved && (
      <Grid container spacing={2}>
        {value.incidentNumber && (
          <Grid item md={6}>
            Incident number: {value.incidentNumber}
          </Grid>
        )}
        {value.policeOfficerName && (
          <Grid item md={6}>
            Police officers' name: {value.policeOfficerName}
          </Grid>
        )}
      </Grid>
      )}
  </div>
  );
  
IncidentPoliceDetails.propTypes = {
      classes: PropTypes.object.isRequired
  };
  
  export default withStyles(styles)(IncidentPoliceDetails);
