import React from "react";
import { Route, Switch } from "react-router-dom";

import ClaimsPage from "../components/ClaimsPage";
import ClaimPage from "../components/ClaimPage";

import DefaultLayout from "../components/DefaultLayout";

const ClaimsRoute = () => (
  <DefaultLayout>
    <Switch>
      <Route exact path="/claims">
        <ClaimsPage />
      </Route>
      <Route path="/claims/:id">
        <ClaimPage />
      </Route>
    </Switch>
  </DefaultLayout>
);

export default ClaimsRoute;
