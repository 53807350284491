import React, { useState } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";
import moment from "moment";

import MapCustomControl from "../components/MapCustomControl";
import Paper from "@mui/material/Paper";
import SafetyEventsTable from "../components/SafetyEventsTable";
import IconButton from "@mui/material/IconButton";
import SpeedGraphIcon from "@mui/icons-material/BarChart";
import Tooltip from "@mui/material/Tooltip";
import SafetyWarningIcon from "@mui/icons-material/Warning";
import VehicleTrackingForm from "../components/VehicleTrackingForm";

const styles = (theme) => ({
  root: {},
  mapControlRoot: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    padding: `0 ${theme.spacing(1)}`,
  },
  mapControlExpanded: {
    width: "90%",
  },
});

const VehicleTrackingMapControls = ({
  initialDate,
  onSubmitQueryData,
  expandContent,
  onSetExpandedContent,
  speedData,
  onTrackingNodeSelected,
  safetyEventData,
  onSafetyEventNodeSelected,
  mapRef,
  classes,
}) => {
  if (!mapRef) return null;

  return (
    <>
      <MapCustomControl
        mapHolderRef={mapRef}
        controlPosition={window.google.maps.ControlPosition.LEFT_TOP}
        styles={classes.mapControlExpanded2}
      >
        <Paper className={classNames(classes.mapControlRoot)}>
          <VehicleTrackingForm
            initialDate={initialDate}
            onSubmit={onSubmitQueryData}
          />
        </Paper>
      </MapCustomControl>
      <MapCustomControl
        mapHolderRef={mapRef}
        controlPosition={window.google.maps.ControlPosition.LEFT_TOP}
        styles={Boolean(expandContent) ? classes.mapControlExpanded : null}
      >
        <Paper className={classNames(classes.mapControlRoot)}>
          <Tooltip title="Click to toggle safety warnings table">
            <IconButton
              color={expandContent === "SAFETY_EVENTS" ? "primary" : "default"}
              onClick={() =>
                onSetExpandedContent(
                  expandContent === "SAFETY_EVENTS" ? null : "SAFETY_EVENTS"
                )
              }
              size="large"
            >
              <SafetyWarningIcon fontSize="small" />
            </IconButton>
          </Tooltip>

          {expandContent === "SAFETY_EVENTS" && (
            <SafetyEventsTable
              {...{
                safetyEvents: safetyEventData,
                onNodeSelected: onSafetyEventNodeSelected,
              }}
            />
          )}
        </Paper>
      </MapCustomControl>
    </>
  );
};

VehicleTrackingMapControls.propTypes = {
  expandContent: PropTypes.string,
  initialDate: PropTypes.string,
  onSubmitQueryData: PropTypes.func.isRequired,
  onSetExpandedContent: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  speedData: PropTypes.array.isRequired,
  onTrackingNodeSelected: PropTypes.func.isRequired,
  safetyEventData: PropTypes.object.isRequired,
  onSafetyEventNodeSelected: PropTypes.func.isRequired,
};

export default withStyles(styles)(VehicleTrackingMapControls);
