import React, { Fragment } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import classNames from "classnames";
import withStyles from "@mui/styles/withStyles";

import INCIDENT_FRAGMENT from "../fragments/incident";

import { mapToDisplay, ACTIVE, CLOSED } from "../IncidentStatus";
import ReactivateIncidentButton from "./ReactivateIncidentButton";
import FileClaimButton from "./FileIncidentAsClaimButton";
import CloseIncidentDialog from "./CloseIncidentDialog";
import IncidentCoreDetailsCard from "./IncidentCoreDetailsCard";
import IncidentEvidenceCard from "./IncidentEvidenceCard";
import IncidentThirdPartiesCard from "./IncidentThirdPartiesCard";
import IncidentDriverAccount from "../components/IncidentDriverAccount";
import IncidentPoliceDetailsCard from "../containers/IncidentPoliceDetailsCard";
import IncidentWitnessCard from "../containers/IncidentWitnessCard";
import IncidentPropertyDamageCard from "../containers/IncidentPropertyDamageCard";

import AdapterLink from "../components/form/Link";
import Anchor from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import IdleTimer from "react-idle-timer";

const IDLE_TIMEOUT = 5 * 60 * 1000;
const DEFAULT_REFRESH_INTERVAL = 10 * 1000;

const QUERY = gql`
  query Node($id: ID!) {
    node(id: $id) {
      ...incident
    }
  }

  ${INCIDENT_FRAGMENT}
`;

export { QUERY };

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  closedPaper: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  smsIcon: {
    marginRight: theme.spacing(1),
  },
  status: {
    textAlign: "right",
  },
  fileAsClaimButton: {
    marginRight: theme.spacing(2),
  },
  fab: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  loading: {
    marginBottom: theme.spacing(2),
  },
  circularProgress: {
    marginLeft: theme.spacing(2),
  },
});

class Incident extends React.Component {
  state = {
    refreshInterval: DEFAULT_REFRESH_INTERVAL,
    requestDriverAccountOpen: false,
    requestDriverAccountSent: false,
    stateMenuAnchorEl: null,
    closeIncidentOpen: false,
  };

  render() {
    const { id, classes } = this.props;
    const { stateMenuAnchorEl, closeIncidentOpen, refreshInterval } =
      this.state;

    return (
      <Fragment>
        <IdleTimer
          onIdle={this.onUserIdle.bind(this)}
          onActive={this.onUserActive.bind(this)}
          timeout={IDLE_TIMEOUT}
        />
        <Query
          query={QUERY}
          variables={{ id }}
          fetchPolicy="cache-and-network"
          pollInterval={refreshInterval}
          onCompleted={this.onQueryCompleted.bind(this)}
        >
          {({ loading, error, data }) => {
            if (loading && (!data || !data.node))
              return <LinearProgress className={classes.loading} />;
            if (error) return JSON.stringify(error);

            if (!loading && data.node === null)
              return (
                <div>
                  <Typography variant="h5" gutterBottom>
                    Incident
                  </Typography>
                  <p>
                    The incident cannot be found or you do not have permission
                    to view it. Try{" "}
                    <Anchor
                      component={AdapterLink}
                      to={`/logout?continue=/incidents/${id}`}
                    >
                      signing out
                    </Anchor>{" "}
                    and using a different account.
                  </p>
                </div>
              );

            const { node: incident } = data;
            const isActive = incident.status === ACTIVE;

            return (
              <Fragment>
                <Typography variant="h5" gutterBottom>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      Incident: <b>{incident.reference}</b>
                      {loading && (
                        <CircularProgress
                          className={classes.circularProgress}
                          size={24}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.status}>
                      {isActive && (
                        <FileClaimButton
                          incidentId={incident.id}
                          hasEvidencePendingReview={incident.attachedEvidence.some(
                            (x) => x.status === "PENDING_REVIEW"
                          )}
                          className={classes.fileAsClaimButton}
                        />
                      )}
                      {mapToDisplay(incident.status)}
                      {incident.claim && (
                        <Tooltip title="View claim">
                          <IconButton
                            component={AdapterLink}
                            to={`/claims/${incident.claim.id}`}
                            size="large"
                          >
                            <Icon>forward</Icon>
                          </IconButton>
                        </Tooltip>
                      )}
                      {isActive && (
                        <Fragment>
                          <IconButton onClick={this.openStateMenu} size="large">
                            <Icon>more_vert</Icon>
                          </IconButton>
                          <Menu
                            anchorEl={stateMenuAnchorEl}
                            open={Boolean(stateMenuAnchorEl)}
                            onClose={() =>
                              this.setState({ stateMenuAnchorEl: null })
                            }
                          >
                            <MenuItem
                              onClick={() =>
                                this.setState({
                                  closeIncidentOpen: true,
                                  stateMenuAnchorEl: null,
                                })
                              }
                            >
                              Close incident
                            </MenuItem>
                          </Menu>
                          <CloseIncidentDialog
                            incidentId={incident.id}
                            open={Boolean(closeIncidentOpen)}
                            handleClose={this.closeIncident}
                            onIncidentClosed={this.handleClosed}
                          />
                        </Fragment>
                      )}
                    </Grid>
                  </Grid>
                </Typography>

                {incident.status === CLOSED && (
                  <Paper
                    className={classNames(classes.paper, classes.closedPaper)}
                  >
                    <Typography
                      variant="subtitle1"
                      color="inherit"
                      gutterBottom
                    >
                      This incident has been closed and can no longer be edited
                    </Typography>
                    <div>{incident.closedReason}</div>
                    <br />

                    <ReactivateIncidentButton incidentId={incident.id} />
                  </Paper>
                )}

                <IncidentCoreDetailsCard
                  editable={isActive}
                  className={classes.card}
                  incident={incident}
                />

                <IncidentEvidenceCard
                  editable={isActive}
                  className={classes.card}
                  incident={incident}
                />

                <Card className={classes.card}>
                  <CardHeader title="Driver account" />
                  <CardContent>
                    <IncidentDriverAccount
                      incidentId={incident.id}
                      value={incident.driverAccount}
                      driver={incident.driver}
                    />
                  </CardContent>
                </Card>

                <IncidentThirdPartiesCard
                  editable={isActive}
                  className={classes.card}
                  incident={incident}
                />

                <IncidentPoliceDetailsCard
                  editable={isActive}
                  className={classes.card}
                  incident={incident}
                />

                <IncidentWitnessCard
                  editable={isActive}
                  className={classes.card}
                  incident={incident}
                />

                <IncidentPropertyDamageCard
                  editable={isActive}
                  className={classes.card}
                  incident={incident}
                />
              </Fragment>
            );
          }}
        </Query>
      </Fragment>
    );
  }

  onQueryCompleted = (data) => {
    if (!data.node && this.state.refreshInterval !== 0)
      this.setState({ refreshInterval: 0 });
    else {
      this.incidentLoaded = true;
    }
  };

  onUserIdle = () => {
    this.setState({ refreshInterval: 0 });
  };

  onUserActive = () => {
    this.setState({
      refreshInterval: this.incidentLoaded ? DEFAULT_REFRESH_INTERVAL : 0,
    });
  };

  openStateMenu = (event) =>
    this.setState({
      stateMenuAnchorEl: event.currentTarget,
    });

  closeIncident = () => this.setState({ closeIncidentOpen: false });

  handleClosed = () => this.setState({ closeIncidentOpen: false });
}

Incident.propTypes = {
  id: PropTypes.string.isRequired,
};

export default withStyles(styles)(Incident);
