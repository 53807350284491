import React, { Fragment } from "react";
import PropTypes from "prop-types";
import ordinal from "ordinal";
import withStyles from '@mui/styles/withStyles';

import { FieldArray } from "react-final-form-arrays";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import WitnessDetailForm from "./WitnessDetailForm";

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
});

const IncidentWitnessForm = ({ fieldName, value, src, classes }) => (
  <div>
    <FieldArray name="witnesses.witnesses">
      {({ fields }) => (
        <Fragment>
          <Grid spacing={4} container>
            {fields.map((fieldName, idx) => (
              <Grid key={`tp-${idx}`} item xs={12} lg={6}>
                <Card className={classes.card}>
                  <CardHeader
                    title={`${ordinal(idx + 3)} witness`}
                    action={
                      <IconButton
                        onClick={() => {
                          fields.remove(idx);
                        }}
                        size="large">
                        <DeleteIcon />
                      </IconButton>
                    }
                  />
                  <CardContent>
                    <WitnessDetailForm fieldName={fieldName} />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <div>
            <p>
              <Button onClick={() => fields.push({})}>
                Add another witness details
              </Button>
            </p>
          </div>
        </Fragment>
      )}
    </FieldArray>
  </div>
);

IncidentWitnessForm.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(IncidentWitnessForm);
