import gql from "graphql-tag";

const Fragment = gql`
  fragment nearMiss on NearMiss {
    reference
    location
    description
    status
    eventDate
    createdAt
    createdByUser {
      id
      name
    }
    category {
      id
      name
    }
    depot {
      id
      name
    }
    attachments {
      edges {
        cursor
        node {
          id
          contentType
          fileKey
          type
        }
      }
    }
    comments {
      edges {
        cursor
        node {
          id
          viewerCanEdit
          mdText
          createdAt
          createdByUser {
            id
            name
          }
        }
      }
    }
  }
`;

export { Fragment };

const QUERY = gql`
  query NearMiss($id: ID!) {
    node(id: $id) {
      id
      ...nearMiss
    }
  }

  ${Fragment}
`;

export default QUERY;
