import React, { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Button, Alert, AlertTitle, ButtonGroup } from "@mui/material";
import { PlayArrow as PlayIcon, Stop as StopIcon } from "@mui/icons-material";
import { useIdleTimer } from "react-idle-timer";
import Html5VideoPlayer from "./VideoPlayer";
import { mapToDisplay } from "../CameraNames";

const IDLE_TIMEOUT = 2 * 60 * 1000;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridGap: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: `repeat(auto-fit, minmax(190px, 1fr))`,
    },
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: `repeat(auto-fit, minmax(220px, 1fr))`,
    },
  },
  videoControlContainer: {
    marginBottom: theme.spacing(2),
  },
}));

const LiveVideo = ({ streams, onSetCameraLocationClick }) => {
  const classes = useStyles();
  const [userIdle, setUserIdle] = React.useState(false);

  const onUserIdle = () => {
    setUserIdle(true);
  };

  const onUserActive = () => {
    setUserIdle(false);
  };

  useIdleTimer({
    timeout: IDLE_TIMEOUT,
    onIdle: onUserIdle,
  });

  const handleSetCameraLocationClick = ({
    cameraId,
    camera: existingLocation,
  }) => {
    onSetCameraLocationClick({ cameraId, existingLocation });
  };

  const [playing, setPlaying] = React.useState({});

  const handlePlayClick = (idx) => {
    setPlaying((prev) => ({ ...prev, [idx]: true }));
  };

  const handlePauseClick = (idx) => {
    setPlaying((prev) => ({ ...prev, [idx]: false }));
  };

  const handlePlayAllClick = () => {
    setAllPlayersPlaying(true);
  };

  const handleStopAllClick = () => {
    setAllPlayersPlaying(false);
  };

  const setAllPlayersPlaying = (playing) => {
    setPlaying(
      streams.reduce(
        (agg, _, idx) => ({
          ...agg,
          [idx]: playing,
        }),
        {}
      )
    );
  };

  useEffect(() => {
    return () => {
      setAllPlayersPlaying(false);
    };
  }, [streams]);

  return (
    <>
      {userIdle && (
        <div>
          <Alert severity="info">
            <AlertTitle>Live stream stopped</AlertTitle>
            <div>
              <p>Live video streams have been stopped due to inactivity.</p>
              <Button variant="outlined" onClick={onUserActive}>
                Click here to resume
              </Button>
            </div>
          </Alert>
        </div>
      )}
      {!userIdle && (
        <div className="">
          <div className={classes.videoControlContainer}>
            <ButtonGroup
              variant="outlined"
              size="small"
              aria-label="outlined button group"
            >
              <Button startIcon={<PlayIcon />} onClick={handlePlayAllClick}>
                Play all
              </Button>
              <Button startIcon={<StopIcon />} onClick={handleStopAllClick}>
                Stop all
              </Button>
            </ButtonGroup>
          </div>
          <div className={classes.root}>
            {streams.map((camera, idx) => (
              <div key={`video-${idx}`}>
                <Html5VideoPlayer
                  label={mapToDisplay(camera.camera)}
                  sources={camera.streams}
                  onSetCameraLocationClick={() =>
                    handleSetCameraLocationClick(camera)
                  }
                  play={playing[idx]}
                  onPlayClick={() => handlePlayClick(idx)}
                  onPauseClick={() => handlePauseClick(idx)}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default LiveVideo;
