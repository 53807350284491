import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';

import { Form } from "react-final-form";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import FormHelperText from "@mui/material/FormHelperText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import CreateDepotForm from "../components/CreateDepotForm";

// FIXME checkout https://mui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
const withMobileDialog = () => (WrappedComponent) => (props) => <WrappedComponent {...props} width="lg" fullScreen={false} />;

const styles = theme => ({});

const CreateDepotDialog = ({
  open,
  fullScreen,
  handleSubmit: handleFormSubmit,
  handleClose
}) => (
  <Dialog
    open={open}
    fullScreen={fullScreen}
    onClose={handleClose}
    aria-labelledby="form-dialog-title"
  >
    <Form
      onSubmit={handleFormSubmit}
      render={({ submitError, handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">
            Create a new depot
          </DialogTitle>
          <DialogContent>
            {/* <DialogContentText>
              To create a new incident, please select a vehicle and a date. All
              other details are optional at this point.
            </DialogContentText> */}
            <CreateDepotForm />
            {submitError && (
              <FormHelperText error={true}>{submitError}</FormHelperText>
            )}
          </DialogContent>
          <DialogActions>
            <Button disabled={submitting} onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button disabled={submitting} type="submit" color="primary">
              Create
            </Button>
          </DialogActions>
        </form>
      )}
    />
  </Dialog>
);

CreateDepotDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default withMobileDialog()(withStyles(styles)(CreateDepotDialog));
