import React from "react";
import PropTypes from "prop-types";
import { matchPath } from "react-router-dom";

import Tabs from "@mui/material/Tabs";

const RoutedTabs = ({ location, selectors, children, ...others }) => {
  const match = selectors.find(
    selector => !!matchPath(location.pathname, { ...selector })
  );
  const selected = match === undefined ? false : match.tab;

  return (
    <Tabs value={selected} {...others}>
      {children}
    </Tabs>
  );
};

RoutedTabs.propTypes = {
  selectors: PropTypes.arrayOf(
    PropTypes.shape({
      tab: PropTypes.any.isRequired,
      path: PropTypes.string.isRequired
    })
  ).isRequired,
  location: PropTypes.object,
  children: PropTypes.node.isRequired
};

RoutedTabs.contextTypes = {
  router: PropTypes.shape({
    route: PropTypes.object.isRequired
  })
};

export default RoutedTabs;
