import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import moment from "moment";

import { InfoWindow } from "@react-google-maps/api";
import Button from "@mui/material/Button";
import VideoIcon from "@mui/icons-material/Videocam";
import ClockIcon from "@mui/icons-material/AccessTime";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import PersonIcon from "@mui/icons-material/Person";

const styles = (theme) => ({
  findFootageButton: {
    padding: 0,
    textTransform: "none",
    fontWeight: "normal",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  container: {
    display: "inline-flex",
    "align-items": "center",
  },
  icons: {
    paddingRight: 5,
  },
});

const WhichVehicleInfoWindow = ({
  edge,
  onCloseWhichVehicleInfoWindow,
  onFootageSearch,
  markerMap,
  classes,
}) => (
  <InfoWindow
    anchor={markerMap[edge.cursor]}
    onCloseClick={onCloseWhichVehicleInfoWindow}
  >
    <div>
      <div>
        <b>{edge.node.vehicle.vrm}</b>
        <br />
        <br />

        {edge.node.driver && (
          <>
            <div className={classes.container}>
              <PersonIcon className={classes.icons} fontSize="small" />
              <b>Driver</b>: <>{edge.node.driver.name}</>
              <br></br>
            </div>
            <br></br>
          </>
        )}

        <div className={classes.container}>
          <ClockIcon className={classes.icons} fontSize="small" />
          <b>Time</b>: {moment(edge.node.timestamp).format("LLL")}
        </div>
      </div>
      {edge.node.vehicle.hasVideoSupport ? (
        <Button
          size="small"
          className={classes.findFootageButton}
          onClick={() =>
            onFootageSearch({
              id: edge.node.vehicle.id,
              timestamp: edge.node.timestamp,
            })
          }
        >
          <VideoIcon className={classes.videoIcon} /> Find footage
        </Button>
      ) : null}
    </div>
  </InfoWindow>
);

WhichVehicleInfoWindow.propTypes = {
  edge: PropTypes.object.isRequired,
  onFootageSearch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WhichVehicleInfoWindow);
