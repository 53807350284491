import { createTheme } from "@mui/material/styles";
import palette from "./theme-palette";

// https://material.io/tools/color/#!/?view.left=0&view.right=1&primary.color=263238&secondary.color=00a9f4
const theme = createTheme({
  palette: {
    primary: {
      main: "#0085cd",
    },
    secondary: {
      main: "#3f0099",
    },
    ...palette,
    flexBox: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
  },
});

export default theme;
