import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';

import AdapterLink from "../components/form/Link";
import { Field } from "react-final-form";
import TextField from "../components/form/TextField";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import FormHelperText from "@mui/material/FormHelperText";

const validateRequired = fieldName => value =>
  !value && `${fieldName} is required`;

const styles = theme => ({
  progress: {
    margin: `${theme.spacing(4)} auto ${theme.spacing(2)} auto`
  },
  version: {
    color: theme.palette.text.hint,
    fontSize: "0.8em"
  }
});

const SignUpForm = ({
  classes,
  continueTo,
  disabled,
  authenticating,
  error
}) => (
  <div>
    <p>We need a few details to create an account.</p>
    <Field
      component={TextField}
      name="fullName"
      required
      validate={validateRequired("Full name")}
      margin="normal"
      label="Full name"
      fullWidth
      disabled={disabled}
      autoFocus
    />
    <Field
      component={TextField}
      name="email"
      type="email"
      required
      validate={validateRequired("Email")}
      margin="normal"
      label="Email address"
      fullWidth
      disabled={disabled}
    />
    <Field
      component={TextField}
      name="password"
      type="password"
      required
      validate={validateRequired("Password")}
      margin="normal"
      autoComplete="off"
      label="Password"
      disabled={disabled}
      fullWidth
    />
    <div>
      <Button
        disabled={disabled}
        type="submit"
        variant="contained"
        color="secondary"
      >
        Sign up
      </Button>

      {error && <FormHelperText error>{error}</FormHelperText>}
      <p>
        <Button
          size="small"
          component={AdapterLink}
          to={`/login?continue=${encodeURIComponent(continueTo)}`}
        >
          Already have an account?
        </Button>
      </p>
      {authenticating && (
        <LinearProgress color="secondary" className={classes.progress} />
      )}
    </div>
  </div>
);

SignUpForm.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  authenticating: PropTypes.bool,
  error: PropTypes.string,
  continueTo: PropTypes.string
};

export default withStyles(styles)(SignUpForm);
