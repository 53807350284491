import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";

import ActiveContent from "./ActiveContent";
import ActivateDialog from "./ActivateDialog";
import DeactivateDialog from "./DeactivateDialog";
import LoadingContent from "./LoadingContent";

const { PUBLIC_URL } = process.env;

const useStyles = makeStyles((theme) => ({
  tomTomAvatar: {
    background: "#df1b12",
  },
  buttonBox: {
    marginLeft: "auto",
  },
}));

const SettingsPanel = ({ status, error, settings, loading }) => {
  const classes = useStyles();
  const [open, setOpen] = useState("");
  const [info, setInfo] = useState();
  const [tomTomStatus, setTomTomStatus] = useState();

  useEffect(() => {
    setInfo(settings);
    setTomTomStatus(status);
  }, [settings, status]);

  const handleClose = () => setOpen("");
  const handleOpen = (method) => setOpen(method);
  const handleActivate = (info) => {
    setTomTomStatus("ACTIVE");
    setInfo(info);
  };
  const handleDeactivate = () => setTomTomStatus("INACTIVE");

  let content = null;
  let actions = null;

  if (tomTomStatus === "INACTIVE" || tomTomStatus === null) {
    actions = (
      <CardActions>
        <div className={classes.buttonBox}>
          <Button
            size="small"
            color="primary"
            onClick={() => handleOpen("activate")}
          >
            Add
          </Button>
        </div>
      </CardActions>
    );
  } else if (error) {
    content = (
      <CardContent>
        <p>!!ERROR!!</p>
      </CardContent>
    );
  } else {
    actions = (
      <CardActions>
        <div className={classes.buttonBox}>
          <Button size="small" onClick={() => handleOpen("deactivate")}>
            Deactivate
          </Button>{" "}
        </div>
      </CardActions>
    );
    content = (
      <CardContent>
        <ActiveContent settings={info} />
      </CardContent>
    );
  }

  if (loading) return <LoadingContent />;

  return (
    <Fragment>
      <Card>
        <CardHeader
          avatar={
            <Avatar
              alt="TomTom"
              src={`${PUBLIC_URL}/icons/tomtom-144x144.png`}
              aria-label="recipe"
              className={classes.tomTomAvatar}
            ></Avatar>
          }
          title="TomTom&trade;"
          subheader="Sync vehicles, drivers and safety events with TomTom WEBFLEET"
        />
        {content}
        {actions}
      </Card>
      <ActivateDialog
        handleClose={handleClose}
        open={open}
        onActivate={handleActivate}
      />
      <DeactivateDialog
        handleClose={handleClose}
        open={open}
        onDeactivate={handleDeactivate}
      />
    </Fragment>
  );
};

SettingsPanel.propTypes = {
  status: PropTypes.string,
  error: PropTypes.any,
  settings: PropTypes.object,
};

export default SettingsPanel;
