import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import CloseIncidentForm from "../components/CloseIncidentForm";

import { Form } from "react-final-form";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import INCIDENT_FRAGMENT from "../fragments/incident";
import { QUERY as INCIDENT_QUERY } from "../containers/Incident";

// FIXME checkout https://mui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
const withMobileDialog = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="lg" fullScreen={false} />;

const CLOSE_INCIDENT = gql`
  mutation closeIncident($input: CloseIncidentInput!) {
    incidents {
      close(input: $input) {
        incident {
          ...incident
        }
      }
    }
  }

  ${INCIDENT_FRAGMENT}
`;

const styles = (theme) => ({
  error: {
    // background: theme.palette.error.main,
    color: theme.palette.error.main,
  },
});

const CloseIncidentDialog = ({
  incidentId,
  classes,
  open,
  fullScreen,
  onIncidentClosed,
  handleClose: onClose,
}) => {
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [close] = useMutation(CLOSE_INCIDENT);

  const handleSubmit = (form) => {
    const variables = { input: { incidentId, ...form } };

    return close({
      variables,
      update: (
        store,
        {
          data: {
            incidents: { closeIncident },
          },
        }
      ) => {
        const data = store.readQuery({
          query: INCIDENT_QUERY,
          variables: { id: incidentId },
        });

        const newData = {
          ...data,
          node: { ...data.node, status: "CLOSED", closedReason: form.reason },
        };

        store.writeQuery({
          query: INCIDENT_QUERY,
          variables: { id: incidentId },
          data: newData,
        });
      },
    }).then(
      ({
        data: {
          incidents: { closeIncident },
        },
      }) => {
        onIncidentClosed();
      },
      (err) => {
        setErrorMessage(err.message);
        console.log("Error submitting form", err);
      }
    );
  };

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit, submitting }) => (
        <Dialog
          open={open}
          fullWidth
          fullScreen={fullScreen}
          onClose={onClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" sx={{ m: 0, p: 2 }}>
            Close incident
            {onClose ? (
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="form-dialog-description">
              Please give a reason for closing this incident. Once closed, the
              incident will no longer be editable.
            </DialogContentText>
            <CloseIncidentForm />
            {errorMessage && <p className={classes.error}>{errorMessage}</p>}
          </DialogContent>
          <DialogActions>
            <Button disabled={submitting} onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button
              disabled={submitting}
              onClick={handleSubmit}
              color="primary"
            >
              Close incident
            </Button>
          </DialogActions>
        </Dialog>
      )}
    />
  );
};

CloseIncidentDialog.propTypes = {
  incidentId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onIncidentClosed: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog()(withStyles(styles)(CloseIncidentDialog));
