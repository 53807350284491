import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import Moment from "moment";

import { InfoWindow } from "@react-google-maps/api";
import { Icon, Button } from "@mui/material";
import CarCrashIcon from "@mui/icons-material/CarCrash";

import ClockIcon from "@mui/icons-material/AccessTime";
import SpeedIcon from "@mui/icons-material/Speed";
import DriverIcon from "@mui/icons-material/PermIdentity";
import JourneyTimeIcon from "@mui/icons-material/HourglassEmptyOutlined";

const styles = (theme) => ({
  createIncidentButton: {
    padding: "3px 10px 3px 3px",
    textTransform: "none",
    fontWeight: "normal",
    width: "100%",
    marginTop: theme.spacing(2),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  directionIcon: {
    fontSize: 16,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    color: theme.palette.grey["900"],
  },
  container: {
    display: "inline-flex",
    "align-items": "center",
  },
  icons: {
    paddingRight: 5,
  },
});

const VehicleTrackingNodeInfoWindow = ({
  node,
  journey,
  onCloseTrackingInfoWindow,
  onCreateIncident,
  incidentsEnabled,
  supportsVideo,
  classes,
}) => (
  <InfoWindow position={node.coord} onCloseClick={onCloseTrackingInfoWindow}>
    <div>
      {Boolean(node.heading) && (
        <>
          <Icon
            className={classes.clockIcon}
            style={{ transform: `rotate(${node.heading}deg)` }}
          >
            navigation
          </Icon>
          <br />
        </>
      )}
      <div className={classes.container}>
        <ClockIcon className={classes.icons} fontSize="small" />
        <b>Time</b>: {Moment(node.timestamp).format("LTS")}
      </div>
      <br />
      <div className={classes.container}>
        <SpeedIcon className={classes.icons} fontSize="small" />
        <b>Speed</b>: {node.speed}
        <small>
          <abbr>MPH</abbr>
        </small>
      </div>
      <br />
      {journey && (
        <>
          <br />
          <div className={classes.container}>
            <JourneyTimeIcon className={classes.icons} fontSize="small" />
            <b>Driving time</b>: {Moment(journey.start).format("LT")} -{" "}
            {Moment(journey.end).format("LT")}
          </div>
          <br />
          <div className={classes.container}>
            <DriverIcon className={classes.icons} fontSize="small" />
            <b>Driver</b>: {journey.driver?.name ?? "-"}
          </div>
          <br />
          <br />
        </>
      )}
      {supportsVideo && incidentsEnabled && (
        <Button
          size="small"
          variant="outlined"
          className={classes.createIncidentButton}
          onClick={onCreateIncident(node.timestamp)}
        >
          <CarCrashIcon className={classes.leftIcon} /> Create incident
        </Button>
      )}
    </div>
  </InfoWindow>
);

VehicleTrackingNodeInfoWindow.propTypes = {
  node: PropTypes.object.isRequired,
  onCloseTrackingInfoWindow: PropTypes.func.isRequired,
  onCreateIncident: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  supportsVideo: PropTypes.bool.isRequired,
  incidentsEnabled: PropTypes.bool.isRequired,
};

export default withStyles(styles)(VehicleTrackingNodeInfoWindow);
