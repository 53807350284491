export const QUEUED = "QUEUED";
export const DOWNLOADING = "DOWNLOADING";
export const PROCESSING = "PROCESSING";
export const COMPLETED = "COMPLETED";
export const ERRORED = "ERRORED";

// const toDisplayName

export const toDisplayName = (status) => {
  if (QUEUED === status) return "Queued";
  if (DOWNLOADING === status) return "Downloading";
  if (PROCESSING === status) return "Processing";
  if (COMPLETED === status) return "Completed";
  if (ERRORED === status) return "Errored";
  return `** ${status}`;
};

export default {
  QUEUED,
  DOWNLOADING,
  PROCESSING,
  COMPLETED,
  ERRORED,
  toDisplayName,
};
