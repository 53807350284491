import React, { useState, Fragment } from "react";
import makeStyles from "@mui/styles/makeStyles";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemText from "@mui/material/ListItemText";
import DrawerVehicleSearchBox from "../components/DrawerVehicleSearchBox";
import VehicleMenuListItem from "../components/VehicleMenuListItem";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const useDepotMenuStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const VrmMatches = (q) => (vehicle) =>
  vehicle.vrm.toLowerCase().includes(q.trim().toLowerCase());

const VehicleList = ({ vehicles, itemClassName }) => {
  return (
    <List component="div" disablePadding>
      {vehicles.map(({ cursor, node: vehicle }) => (
        <VehicleMenuListItem
          key={`${cursor}`}
          vehicle={vehicle}
          className={itemClassName}
        />
      ))}
    </List>
  );
};

const DepotMenu = ({ node: depot, q }) => {
  const classes = useDepotMenuStyles();
  const [open, setOpen] = React.useState(true);

  const vrmFilterPredicate = VrmMatches(q);
  const filteredVehicles = depot.vehicles.edges.filter(({ node: vehicle }) =>
    vrmFilterPredicate(vehicle)
  );

  if (depot.vehicles.totalCount === 0) return null;

  const onlineVehiclesCount = filteredVehicles.filter(
    ({ node }) => node.live.online
  ).length;

  function handleClick() {
    setOpen(!open);
  }

  return (
    filteredVehicles.length != 0 && (
      <>
        <ListItem button onClick={handleClick}>
          <ListItemText
            primary={depot.name}
            secondary={`${onlineVehiclesCount}/${filteredVehicles.length}`}
          />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <VehicleList
            vehicles={filteredVehicles}
            itemClassName={classes.nested}
          />
          {/* <ListItem>
          <ListItemText primary={<em>No vehicles found</em>} />
        </ListItem> */}
        </Collapse>
      </>
    )
  );
};

const useVehiclesMenuListStyles = makeStyles((theme) => ({
  faded: {
    color: theme.palette.action.disabled,
  },
}));

const VehiclesMenuList = ({ vehicleConnection, q }) => {
  const classes = useVehiclesMenuListStyles();
  const vrmFilterPredicate = VrmMatches(q);
  const filteredVehicles = vehicleConnection.edges.filter(({ node: vehicle }) =>
    vrmFilterPredicate(vehicle)
  );

  const [totalVehicles, setTotalVehicles] = React.useState(
    vehicleConnection.totalCount
  );

  React.useEffect(() => {
    setTotalVehicles(vehicleConnection.totalCount);
  }, [vehicleConnection]);

  return (
    <List
      component="nav"
      subheader={
        <ListSubheader component="div">
          Vehicles{" "}
          {totalVehicles && (
            <em className={classes.faded}>({totalVehicles})</em>
          )}
        </ListSubheader>
      }
    >
      <VehicleList vehicles={filteredVehicles} />
      {filteredVehicles.length === 0 && (
        <ListItem>
          <ListItemText primary={<em>No vehicles found</em>} />
        </ListItem>
      )}
    </List>
  );
};

const useDepotMenuListStyles = makeStyles((theme) => ({
  faded: {
    color: theme.palette.action.disabled,
  },
}));

const DepotMenuList = ({ allDepots, q }) => {
  const classes = useDepotMenuListStyles();
  const [totalVehicles, setTotalVehicles] = React.useState(null);

  React.useEffect(() => {
    const total = allDepots.edges.reduce((prev, { node }) => {
      return prev + node.vehicles.totalCount;
    }, 0);
    setTotalVehicles(total);
  }, [allDepots]);

  return (
    <List
      component="nav"
      subheader={
        <ListSubheader component="div">
          Vehicles{" "}
          {totalVehicles && (
            <em className={classes.faded}>({totalVehicles})</em>
          )}
        </ListSubheader>
      }
    >
      {allDepots.edges.map(({ cursor, node }) => (
        <DepotMenu key={cursor} {...{ cursor, node, q }} />
      ))}
    </List>
  );
};

const FilterableVehiclesDrawerList = ({ allDepots }) => {
  const [q, setQ] = useState("");

  return (
    <Fragment>
      <DrawerVehicleSearchBox onSearch={setQ} />
      {allDepots.totalCount > 1 && <DepotMenuList {...{ allDepots, q }} />}

      {allDepots.totalCount === 1 && (
        <VehiclesMenuList
          vehicleConnection={allDepots.edges[0].node.vehicles}
          q={q}
        />
      )}
      {allDepots.totalCount === 0 && (
        <ListItem>
          <ListItemText primary={<em>No depots found</em>} />
        </ListItem>
      )}
    </Fragment>
  );
};

export default FilterableVehiclesDrawerList;
