import React from "react";
import moment from "moment";
import { Tabs, Tab } from "@mui/material";

import Form from "./Form";
import DriverSafetyEvents from "./DriverSafetyEvents";
import { DISMISSED, NEEDS_REVIEW } from "./Statuses";

const TabToStatusFilterMapper = {
  0: {},
  1: { status: NEEDS_REVIEW },
  2: { status: DISMISSED },
  3: { mustBeStarred: true },
};

const Root = ({ search: searchParams, onSearch }) => {
  const start =
    (searchParams.start &&
      moment(searchParams.start).startOf("day").format()) ||
    moment().startOf("day").format();

  const end =
    (searchParams.end && moment(searchParams.end).endOf("day").format()) ||
    moment().endOf("day").format();

  const driverId = searchParams.driver || null;
  const vehicleId = searchParams.vehicle || null;

  const pushQuery = (q) => {
    onSearch(q);
  };

  const [selectedTab, setSelectedTab] = React.useState(1);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSubmitForm = (form) => {
    const q = {
      driver: form.driverId,
      vehicle: form.vehicleId,
      start: moment(form.start).format("YYYY-MM-DD"),
      end: moment(form.end).format("YYYY-MM-DD"),
      // tab: selectedTab,
    };
    pushQuery(q);
  };

  const extraFilter = TabToStatusFilterMapper[selectedTab];

  return (
    <>
      <Tabs value={selectedTab} onChange={handleTabChange}>
        <Tab label="All" />
        <Tab label="Needs Review" />
        <Tab label="Dismissed" />
        <Tab label="Starred" />
      </Tabs>
      <br />
      <Form
        onSubmit={handleSubmitForm}
        initialValues={{
          start: moment(start).format("YYYY-MM-DD"),
          end: moment(end).format("YYYY-MM-DD"),
          driverId,
          vehicleId,
        }}
      />
      <DriverSafetyEvents
        vehicleId={vehicleId}
        driverId={driverId}
        start={start}
        end={end}
        {...extraFilter}
      />
    </>
  );
};

Root.propTypes = {
  // classes: PropTypes.object.isRequired,
};

export default Root;
