import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import DefaultLayout from "../components/DefaultLayout";
import DashboardOpenIncidentCountCard from "./DashboardOpenIncidentCountCard";
import DashboardLiveMapCard from "./DashboardLiveMapCard";
import DashboardVehicles from "../containers/DashboardVehicles";
import DashboardDataUsageCard from "./DashboardDataUsageCard";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  topMargin: {
    marginTop: theme.spacing(2),
  },
  permission: {
    fontSize: "1em",
    margin: 0,
  },
}));

const DashboardPage = ({ incidentsEnabled, vehiclesEnabled }) => {
  const classes = useStyles();
  return (
    <DefaultLayout>
      <div>
        <Grid spacing={2} container>
          <Grid item xs={12} md={6} lg={4}>
            <DashboardOpenIncidentCountCard
              rowCount={2}
              incidentsEnabled={incidentsEnabled}
            />
            <div className={classes.topMargin}>
              <DashboardDataUsageCard />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <DashboardLiveMapCard vehiclesEnabled={vehiclesEnabled} />
          </Grid>
          <Grid item xs={12} md={6} lg={8} />
        </Grid>

        <div className={classes.topMargin}>
          {vehiclesEnabled ? (
            <DashboardVehicles />
          ) : (
            <Card>
              <CardContent>
                <p className={classes.permission}>
                  You do not have permission to view vehicles
                </p>
              </CardContent>
            </Card>
          )}
        </div>
      </div>
    </DefaultLayout>
  );
};

export default DashboardPage;
