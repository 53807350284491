import React from "react";
import moment from "moment";
import clsx from "clsx";
import makeStyles from '@mui/styles/makeStyles';
import { Link as RouterLink } from "react-router-dom";
import { Link, Grid, Button } from "@mui/material";
import { blue, green } from "@mui/material/colors";

import { NotificationImportant as IncidentIcon } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  p2: { padding: theme.spacing(2) },
  px2: { padding: `0 ${theme.spacing(2)}` },
  noIssuesContainer: {
    background: green[100],
    display: "flex",
    alignItems: "center",
  },
  noIssuesIcon: { color: green[500], marginRight: theme.spacing(2) },
  lastUpdatedHint: {
    color: theme.palette.text.hint,
    ...theme.typography.caption,
  },
  cameraSince: {
    // color: theme.palette.text.hint,
  },
  issueTypeIcon: {
    marginRight: theme.spacing(1),
    color: blue[900],
  },
  tableContainer: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  row: {
    background: blue[50],
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    paddingLeft: 0,
    "&:hover": {
      backgroundColor: blue[100],
    },
  },
  alternateRow: {
    background: theme.palette.background.default,
    // background: blue[50],
  },
  cellContent: {
    display: "flex",
    alignItems: "center",
  },
  detailCellRoot: {
    display: "flex",
  },
  vrmLink: {
    ...theme.typography.button,
  },
  incidentReferenceLink: {
    ...theme.typography.button,
  },
  incidentDetailContainer: {
    flexGrow: 1,
  },
}));

const DashboardVehiclesWithEventsForAttentionTable = ({ data, error }) => {
  const classes = useStyles();

  return (
    <>
      {error && <p className={""}>err</p>}

      {data?.vehicles.allWithEventsForAttention.totalCount > 0 && (
        <div className={classes.tableContainer}>
          {data?.vehicles.allWithEventsForAttention.edges.map(
            ({ node, cursor }, idx) => (
              <div
                key={cursor}
                className={clsx(classes.row, {
                  [classes.alternateRow]: idx % 2 !== 0,
                })}
              >
                <div className={classes.px2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3} lg={2}>
                      <div className={classes.cellContent}>
                        <Link
                          className={classes.vrmLink}
                          color="inherit"
                          to={`/vehicles/${node.vehicle.id}`}
                          component={RouterLink}
                        >
                          {node.vehicle.vrm}
                        </Link>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={9} lg={10}>
                      <div className={classes.detailCellRoot}>
                        <div className={classes.detailIconContainer}>
                          {node.__typename === "VehicleWithIncidentEvent" && (
                            <IncidentIcon className={classes.issueTypeIcon} />
                          )}
                        </div>
                        {node.__typename === "VehicleWithIncidentEvent" && (
                          <div className={classes.incidentDetailContainer}>
                            <div>
                              <div>
                                New incident: <b>{node.incident.reference}</b>
                              </div>
                              <div>
                                Occurred:{" "}
                                {moment(node.incident.occurredAt).format("lll")}
                              </div>
                              <div>{node.incident.location}</div>
                            </div>
                          </div>
                        )}
                        <div>
                          <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            to={`/incidents/${node.incident.id}`}
                            component={RouterLink}
                          >
                            View
                          </Button>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            )
          )}
        </div>
      )}
    </>
  );
};

DashboardVehiclesWithEventsForAttentionTable.propTypes = {};

export default DashboardVehiclesWithEventsForAttentionTable;
