import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { execute as recaptcha } from "../recaptcha";

import { Form } from "react-final-form";
import ResetPasswordForm from "../components/ResetPasswordForm";

const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    users {
      resetPassword(input: $input) {
        error {
          code
          message
        }
      }
    }
  }
`;

const ResetPassword = ({ token }) => {
  const history = useHistory();
  const [error, setError] = React.useState(null);
  const [submitted, setSubmitted] = React.useState(false);

  const [resetPassword] = useMutation(RESET_PASSWORD);

  const handleResetPasswordSubmit = (form) => {
    return recaptcha("reset_password").then((captchaResponse) => {
      const input = { ...form, token: encodeURI(token), captchaResponse };

      setError(null);

      return resetPassword({ variables: { input } }).then(
        ({
          data: {
            users: { resetPassword },
          },
          error,
        }) => {
          if (Boolean(error || resetPassword.error)) {
            setError(
              JSON.stringify(
                error ||
                  `${resetPassword.error.message} (${resetPassword.error.code})`
              )
            );
            return;
          }

          setSubmitted(true);
          setError(null);
          history.push(`/?from=reset`);
        }
      );
    });
  };

  return (
    <Form
      onSubmit={handleResetPasswordSubmit}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <ResetPasswordForm
            authenticating={submitting}
            submitted={submitted}
            disabled={submitting}
            valid={true}
            error={error}
          />
        </form>
      )}
    />
  );
};

ResetPassword.propTypes = {
  token: PropTypes.string.isRequired,
};

export default ResetPassword;
