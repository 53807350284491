import React from "react";
import qs from "querystringify";
import { useLocation } from "react-router-dom";
import ResetPassword from "../containers/ResetPassword";
import AbovePaperLogo from "../components/AbovePaperLogo";

import CenteredPaperLayout from "../components/CenteredPaperLayout";

const ResetPasswordPage = () => {
  const { search } = useLocation();
  const searchObject = qs.parse(search);

  return (
    <CenteredPaperLayout title="Account recovery" above={<AbovePaperLogo />}>
      <p>Set a strong password to gain access to your account.</p>
      <ResetPassword token={searchObject.ticket} />
    </CenteredPaperLayout>
  );
};

export default ResetPasswordPage;
