import React from "react";

import { useAuth } from "../AuthContext";
import SuspendedPage from "./SuspendedPage";

const RequireActiveAccount = ({ children }) => {
  const { account } = useAuth();

  const tenantSuspended = account?.tenant?.isSuspended ?? true;

  return tenantSuspended ? <SuspendedPage /> : children;
};

export default RequireActiveAccount;
