import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import LinearProgress from "@mui/material/LinearProgress";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import classNames from "classnames";
import NoAccessIcon from "@mui/icons-material/RemoveCircleOutline";
import AccessIcon from "@mui/icons-material/CheckCircleOutline";
import { Link as RouterLink, useParams } from "react-router-dom";
import GroupPermissions from "../containers/GroupPermissions";

import Group from "../containers/Group";

export const USER_GROUP = gql`
  query Node($id: ID!) {
    node(id: $id) {
      ...userGroup_userGroup
    }
  }

  fragment userGroup_userGroup on UserGroupType {
    id
    name
    defaultAccessToNewDepots
    groupPermissions
    isTeamAdmin
    members {
      totalCount
      edges {
        cursor
        node {
          id
          name
          emailAddress
        }
      }
    }
    depots {
      totalCount
      edges {
        cursor
        node {
          id
          name
        }
      }
    }
  }
`;

const styles = (theme) => ({
  root: {
    marginBottom: 56,
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
  marginBottom2: {
    marginBottom: theme.spacing(6),
  },
  title1: {
    marginBottom: theme.spacing(2),
  },
  marginLeft: {
    marginLeft: theme.spacing(4),
  },
  iconMarginRight: {
    marginRight: theme.spacing(1),
  },
  acccessContainer: {
    display: "flex",
    alignItems: "center",
  },
  accessTitle: {
    display: "inline-block",
    marginRight: theme.spacing(2),
  },
});

const GroupPage = ({ classes }) => {
  const { id: groupId } = useParams();

  const { data, loading, error } = useQuery(USER_GROUP, {
    variables: { id: groupId },
    fetchPolicy: "cache-and-network",
  });

  const groupPermissions = GroupPermissions();
  const groupPermissionsData = groupPermissions.map((p) => ({
    value: p.value,
    name: p.name,
    selected: data?.node?.groupPermissions.includes(p.value) || false,
  }));

  if (loading && (!data || !data.node))
    return <LinearProgress className={classes.loading} />;
  if (error) return JSON.stringify(error);

  return (
    !loading &&
    data &&
    data.node && (
      <section className={classes.root}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.marginBottom}>
          <Link color="inherit" to="/admin" component={RouterLink}>
            Admin
          </Link>
          <Link color="inherit" to="/admin/groups" component={RouterLink}>
            User Groups
          </Link>
          <Typography color="textPrimary">
            {data && data.node && data.node.name}
          </Typography>
        </Breadcrumbs>
        <Typography variant="h5" gutterBottom className={classes.marginBottom}>
          {data && data.node && data.node.name}
        </Typography>
        <Typography variant="body2" gutterBottom className={classes.title1}>
          This group has the following depot access
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          className={classNames(classes.marginLeft, classes.marginBottom)}
        >
          {data.node.defaultAccessToNewDepots ? (
            <span>
              <b>Access</b> to new team depots
            </span>
          ) : (
            <span>
              <b>No</b> access to new team depots
            </span>
          )}
        </Typography>
        <Typography variant="body2" gutterBottom className={classes.title1}>
          This group has the following team access
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          className={classNames(classes.marginLeft, classes.marginBottom2)}
        >
          {data.node.isTeamAdmin ? (
            <span className={classes.acccessContainer}>
              <AccessIcon className={classes.iconMarginRight} />
              <b className={classes.accessTitle}>Administer team</b> Can update
              team settings and administer depots
            </span>
          ) : (
            <span className={classes.acccessContainer}>
              <NoAccessIcon className={classes.iconMarginRight} />
              <b className={classes.accessTitle}>Administer team</b> Can't
              update team settings or administer depots
            </span>
          )}
        </Typography>
        <Typography variant="body2" gutterBottom className={classes.title1}>
          This group has the following access permission
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          className={classNames(classes.marginLeft, classes.marginBottom2)}
        >
          <span className={classes.acccessContainer}>
            {groupPermissionsData.map((item) => (
              <React.Fragment key={item.value}>
                {item.selected ? (
                  <AccessIcon className={classes.iconMarginRight} />
                ) : (
                  <NoAccessIcon className={classes.iconMarginRight} />
                )}
                <b className={classes.accessTitle}>{item.name}</b>
              </React.Fragment>
            ))}
          </span>
        </Typography>
        <Group groupId={groupId} data={data} />
      </section>
    )
  );
};

GroupPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GroupPage);
