import React from "react";
import { Group } from "@visx/group";
import { AreaClosed } from "@visx/shape";
import { AxisLeft, AxisTop } from "@visx/axis";
import { LinearGradient } from "@visx/gradient";
import { curveMonotoneX } from "@visx/curve";
import { timeFormat } from "@visx/vendor/d3-time-format";
import { GridRows } from "@visx/grid";

// Initialize some variables
const axisColor = "#000";
const formatTime = timeFormat("%H:%M");

const axisTopTickLabelProps = {
  fill: axisColor,
  fontSize: 11,
  textAnchor: "middle",
};
const axisLeftTickLabelProps = {
  dx: "-0.25em",
  dy: "0.25em",
  fontFamily: "Arial",
  fontSize: 10,
  textAnchor: "end",
  fill: axisColor,
};

// accessors
const getDateDataValue = (d) => new Date(d.timestamp);
const getSpeedDataValue = (d) => d.speed;

export default function AreaChart({
  data,
  gradientColor,
  width,
  margin,
  yMax,
  xMax,
  xScale,
  yScale,
  hideGridRowAxis = false,
  hideTopAxis = false,
  hideLeftAxis = false,
  top,
  left,
  children,
}) {
  if (width < 10) return null;
  return (
    <Group left={left || margin.left} top={top || margin.top}>
      {!hideGridRowAxis && (
        <GridRows
          scale={yScale}
          width={xMax}
          height={yMax}
          stroke="#939393"
          strokeOpacity={1.4}
          numTicks={3}
        />
      )}
      <LinearGradient
        id="gradient"
        from={gradientColor}
        fromOpacity={1}
        to={gradientColor}
        toOpacity={0.3}
      />
      <AreaClosed
        data={data}
        x={(d) => xScale(getDateDataValue(d)) || 0}
        y={(d) => yScale(getSpeedDataValue(d)) || 0}
        yScale={yScale}
        strokeWidth={1}
        stroke="url(#gradient)"
        fill="url(#gradient)"
        curve={curveMonotoneX}
      />
      {!hideTopAxis && (
        <AxisTop
          top={0}
          scale={xScale}
          tickFormat={formatTime}
          stroke={axisColor}
          tickStroke={axisColor}
          tickLabelProps={() => axisTopTickLabelProps}
        />
      )}
      {!hideLeftAxis && (
        <AxisLeft
          scale={yScale}
          numTicks={3}
          stroke={axisColor}
          tickStroke={axisColor}
          tickLabelProps={() => axisLeftTickLabelProps}
        />
      )}
      {children}
    </Group>
  );
}
