import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Stack,
  Box,
  Divider,
  Paper,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  CircularProgress,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Unstable_Grid2";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import StatusChip from "../near-miss/StatusChip";
import markdown from "../../markdown";
import CloseIcon from "@mui/icons-material/Close";

import moment from "moment";
import { useMutation } from "@apollo/client";
import {
  LocationMutation,
  DescriptionMutation,
  AttachEvidenceMutation,
  StatusCategoryDepotMutation,
} from "./Mutation";
import { CategoriesQuery, DepotsQuery } from "./../near-miss.index/Query";
import { useQuery } from "@apollo/client";
import {
  ISSUE_RAISED,
  IN_PROGRESS,
  COMPLETE,
  toDisplayName,
} from "./../near-miss/Statuses";
import EvidenceUpload from "./EvidenceUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

// Header component
function Header({ nearMiss, loading }) {
  const [inEditMode, setInEditMode] = useState(false);
  const [eventDate, setEventDate] = useState();

  const [selectedValue, setSelectedValue] = useState({});
  useEffect(() => {
    setSelectedValue({
      categoryId: nearMiss?.category?.id,
      depotId: nearMiss?.depot?.id,
      status: nearMiss?.status,
    });
    setEventDate(moment(nearMiss?.eventDate));
  }, [nearMiss]);

  const handleChangeDateSelected = (value) => {
    setEventDate(value);
  };

  const handleChangeSelectChange = (nameInput, value) => {
    setSelectedValue((prev) => ({
      ...prev,
      [nameInput]: value,
    }));
  };

  const { data: categoriesData } = useQuery(CategoriesQuery, {
    fetchPolicy: "cache-and-network",
  });
  const { data: DepotsData } = useQuery(DepotsQuery, {
    fetchPolicy: "cache-and-network",
  });

  const categoryConnection = categoriesData?.nearMissCategories?.all;
  const depotConnection = DepotsData?.depots?.all;

  const handleSwitchToEditModeClick = () => {
    setInEditMode(true);
  };

  const [editStatusCategoryDepot, { loading: saving }] = useMutation(
    StatusCategoryDepotMutation
  );

  const handleSubmit = (ev) => {
    ev.preventDefault();
    const input = {
      id: nearMiss.id,
      categoryId: selectedValue.categoryId,
      depotId: selectedValue.depotId,
      status: selectedValue.status,
      eventDate: eventDate.format("YYYY-MM-DDTHH:mm:ssZ"),
    };

    editStatusCategoryDepot({ variables: { input } })
      .then(() => {
        setInEditMode(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCancelEditClick = () => {
    setInEditMode(false);
  };

  return (
    <Box mb={4}>
      <Stack direction={"row"} spacing={2}>
        <div>
          <Typography variant="h5" gutterBottom>
            {loading ? (
              <Skeleton variant="text" sx={{ fontSize: "2rem" }} width={300} />
            ) : (
              <Stack
                spacing={2}
                direction={{ xs: "column", sm: "row" }}
                flexWrap={"wrap"}
              >
                <Box color={"text.secondary"} whiteSpace={"nowrap"}>
                  # {nearMiss.reference}
                </Box>
                <Box whiteSpace={"nowrap"}>{nearMiss.category.name}</Box>
                <div>
                  <StatusChip status={nearMiss.status} />
                </div>
              </Stack>
            )}
          </Typography>

          {loading ? (
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={300} />
          ) : (
            <Stack direction="row" spacing={2} divider={<Divider flexItem />}>
              <Box flexGrow={1}>
                <Stack
                  color={"text.secondary"}
                  direction="row"
                  alignItems="center"
                  gap={2}
                >
                  <div>
                    <small>
                      Raised on {moment(nearMiss.eventDate).format("lll")}
                    </small>
                  </div>
                  {nearMiss.assignee && (
                    <>
                      <svg
                        viewBox="0 0 2 2"
                        style={{
                          width: "0.125rem",
                          height: "0.125rem",
                          fill: "currentcolor",
                        }}
                      >
                        <circle cx={1} cy={1} r={1} />
                      </svg>
                      <div>
                        <small>Assigned to {nearMiss.assignee.fullName}</small>
                      </div>
                    </>
                  )}
                  <svg
                    viewBox="0 0 2 2"
                    style={{
                      width: "0.125rem",
                      height: "0.125rem",
                      fill: "currentcolor",
                    }}
                  >
                    <circle cx={1} cy={1} r={1} />
                  </svg>
                  <div>
                    <small>{nearMiss.depot.name}</small>
                  </div>
                </Stack>
              </Box>
            </Stack>
          )}
        </div>
        {!inEditMode && (
          <Box ml={2}>
            <Button
              variant="text"
              startIcon={<EditIcon />}
              size="small"
              onClick={handleSwitchToEditModeClick}
            >
              Edit
            </Button>
          </Box>
        )}
      </Stack>
      <Dialog
        open={inEditMode}
        onClose={handleCancelEditClick}
        fullWidth
        maxWidth="sm"
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle>Edit</DialogTitle>
          <DialogContent>
            <Box mb={2}>
              <Grid container spacing={2} sx={{ mt: 1, mb: 2 }}>
                <Grid xs={12} sm={46} md={6}>
                  <FormControl variant="outlined" fullWidth>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DateTimePicker
                        label="Event Date"
                        value={eventDate}
                        onChange={(e) => handleChangeDateSelected(e)}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={46} md={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="status-select-outlined-label">
                      Status
                    </InputLabel>
                    <Select
                      labelId="status-select-outlined-label"
                      id="status-select-outlined"
                      label="Status"
                      value={selectedValue.status || ""}
                      onChange={(e) =>
                        handleChangeSelectChange("status", e.target.value)
                      }
                    >
                      <MenuItem value={ISSUE_RAISED}>
                        {toDisplayName(ISSUE_RAISED)}
                      </MenuItem>
                      <MenuItem value={IN_PROGRESS}>
                        {toDisplayName(IN_PROGRESS)}
                      </MenuItem>
                      <MenuItem value={COMPLETE}>
                        {toDisplayName(COMPLETE)}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
                <InputLabel id="category-select-outlined-label">
                  Category
                </InputLabel>
                <Select
                  labelId="category-select-outlined-label"
                  id="category-select-outlined"
                  label="Category"
                  value={selectedValue.categoryId || ""}
                  onChange={(e) =>
                    handleChangeSelectChange("categoryId", e.target.value)
                  }
                >
                  {categoryConnection &&
                    categoryConnection.edges.map(({ node }) => (
                      <MenuItem key={node.id} value={node.id}>
                        {node.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="depot-select-outlined-label">Depot</InputLabel>
                <Select
                  labelId="depot-select-outlined-label"
                  id="depot-select-outlined"
                  label="Depot"
                  value={selectedValue.depotId || ""}
                  onChange={(e) =>
                    handleChangeSelectChange("depotId", e.target.value)
                  }
                >
                  {depotConnection &&
                    depotConnection.edges.map(({ node }) => (
                      <MenuItem key={node.id} value={node.id}>
                        {node.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelEditClick} disabled={saving}>
              Cancel
            </Button>
            <Button type="submit" disabled={saving}>
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}

// LocationInfo component
function LocationInfo({ id, location, loading }) {
  const [inEditMode, setInEditMode] = useState(false);
  const [editLocation, { loading: saving }] = useMutation(LocationMutation);

  const handleSwitchToEditModeClick = () => {
    setInEditMode(true);
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    const formData = new FormData(ev.target);
    const location = formData.get("location");

    editLocation({ variables: { nearMissId: id, location } })
      .then(() => {
        setInEditMode(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCancelEditClick = () => {
    setInEditMode(false);
  };

  return (
    <Box mb={4}>
      <Typography variant="h6" gutterBottom>
        <Stack direction={"row"}>
          Location{" "}
          {!inEditMode && (
            <Box ml={2}>
              <Button
                variant="text"
                startIcon={<EditIcon />}
                size="small"
                onClick={handleSwitchToEditModeClick}
              >
                Edit
              </Button>
            </Box>
          )}
        </Stack>
      </Typography>
      {loading ? (
        <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} width={"35%"} />
      ) : (
        <>
          {!location && (
            <Box
              color={"text.secondary"}
              onClick={handleSwitchToEditModeClick}
              sx={{ cursor: "pointer" }}
            >
              Exact location not set, click to edit
            </Box>
          )}
          <Box>{location}</Box>
        </>
      )}

      {inEditMode && (
        <form onSubmit={handleSubmit} disabled={saving}>
          <Stack direction={"row"} spacing={1}>
            <TextField
              disabled={saving}
              autoFocus
              name="location"
              placeholder="Add the exact location"
              required
              fullWidth
              defaultValue={location ?? ""}
            />
            <Button type="submit" variant="text" size="small" disabled={saving}>
              {saving ? "Saving" : "Save"}
            </Button>
            <Button
              variant="text"
              size="small"
              onClick={handleCancelEditClick}
              disabled={saving}
            >
              Cancel
            </Button>
          </Stack>
        </form>
      )}
    </Box>
  );
}

// DescriptionInfo component
function DescriptionInfo({ id, description, loading }) {
  const [inEditMode, setInEditMode] = useState(false);
  const [editDescription, { loading: saving }] =
    useMutation(DescriptionMutation);

  const handleSwitchToEditModeClick = () => {
    setInEditMode(true);
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    const formData = new FormData(ev.target);
    const description = formData.get("description");

    editDescription({ variables: { nearMissId: id, description } })
      .then(() => {
        setInEditMode(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCancelEditClick = () => {
    setInEditMode(false);
  };

  return (
    <Box mb={4}>
      <Typography variant="h6" gutterBottom>
        <Stack direction={"row"}>
          Description of near miss{" "}
          {!inEditMode && (
            <Box ml={2}>
              <Button
                variant="text"
                startIcon={<EditIcon />}
                size="small"
                onClick={handleSwitchToEditModeClick}
              >
                Edit
              </Button>
            </Box>
          )}
        </Stack>
      </Typography>
      {loading ? (
        <Skeleton width={"100%"} variant="rounded" sx={{ fontSize: "5rem" }} />
      ) : !inEditMode ? (
        <>
          {!description && <div>-</div>}
          {description && (
            <Paper variant="outlined">
              <Box
                p={1}
                dangerouslySetInnerHTML={{
                  __html: markdown.render(description ?? ""),
                }}
              />
            </Paper>
          )}
        </>
      ) : (
        <form onSubmit={handleSubmit} disabled={saving}>
          <Stack spacing={1}>
            <TextField
              disabled={saving}
              autoFocus
              name="description"
              placeholder="Add the exact location"
              multiline
              required
              rows={5}
              fullWidth
              defaultValue={description ?? ""}
            />
            <Stack direction={"row"} spacing={2}>
              <Box flexGrow={1} />
              <Button
                type="submit"
                variant="text"
                size="small"
                disabled={saving}
              >
                {saving ? "Saving" : "Save"}
              </Button>
              <Button
                variant="text"
                size="small"
                onClick={handleCancelEditClick}
                disabled={saving}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </form>
      )}
    </Box>
  );
}

// AttachmentsInfo component
function AttachmentsInfo({
  id,
  attachments,
  loading,
  onSubmitRemoveAttachEvidence,
  removingAttachEvidence,
}) {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [deleteRequested, setDeleteRequested] = useState(false);
  const timerForDeleteConfirmation = useRef(false);

  const [attachEvidence] = useMutation(AttachEvidenceMutation);

  const [open, setOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);

  useEffect(() => {
    return () => clearTimeout(timerForDeleteConfirmation.current);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setDeleteRequested(removingAttachEvidence);
    }, 1000);
  }, [removingAttachEvidence]);

  const handleRemoveAttachment = (id) => {
    clearTimeout(timerForDeleteConfirmation.current);
    onSubmitRemoveAttachEvidence(id);
  };

  const onRequestDelete = () => {
    setDeleteRequested(true);
    timerForDeleteConfirmation.current = setTimeout(() => {
      setDeleteRequested(false);
    }, 2000);
  };

  const handleOpen = (fileKey, type) => {
    if (removingAttachEvidence) return;
    if (type == "PICTURE") {
      setSelectedVideo(null);
      setSelectedImage(fileKey);
    } else {
      setSelectedImage(null);
      setSelectedVideo(fileKey);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
    setSelectedVideo(null);
  };

  const handleSubmit = (data) => {
    var variables = {
      nearMissId: id,
      contentType: data.contentType,
      objectKey: data.fileKey,
    };
    attachEvidence({ variables })
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  };

  const handlePlay = () => {
    setIsPlaying(true);
  };
  const handlePause = () => {
    setIsPlaying(false);
  };

  return (
    <Box mb={4}>
      <Typography variant="h6" gutterBottom>
        Evidence
      </Typography>
      {loading ? (
        <Grid container spacing={2}>
          <Grid xs={12} sm={4} md={4}>
            <Skeleton
              variant="rounded"
              sx={{ fontSize: "6rem" }}
              width={"100%"}
            />
          </Grid>
          <Grid xs={12} sm={4} md={4}>
            <Skeleton
              variant="rounded"
              sx={{ fontSize: "6rem" }}
              width={"100%"}
            />
          </Grid>
        </Grid>
      ) : !attachments?.edges.length ? (
        <Grid container spacing={2}>
          <Grid
            xs={12}
            sm={4}
            md={4}
            sx={{ boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)" }}
          >
            <Box>
              <EvidenceUpload nearMissId={id} onFinish={handleSubmit} />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <div>
          <Grid container spacing={2}>
            {attachments?.edges.map(({ node, cursor }, index) => (
              <Grid
                key={cursor}
                xs={12}
                sm={4}
                md={4}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <Box style={{ position: "relative", cursor: "pointer" }}>
                  {hoveredIndex === index && (
                    <>
                      {deleteRequested ? (
                        <Button
                          style={{
                            zIndex: 1,
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            backgroundColor: "rgba(255, 255, 255, 0.9)",
                            "&:hover": {
                              backgroundColor: "rgba(255, 255, 255, 0.9)",
                            },
                            color: "#d32f2f",
                          }}
                          disabled={removingAttachEvidence}
                          onClick={() => handleRemoveAttachment(node.id)}
                        >
                          {removingAttachEvidence && (
                            <CircularProgress size={18} sx={{ mr: 1 }} />
                          )}
                          Click to confirm
                        </Button>
                      ) : (
                        <IconButton
                          aria-label="Remove"
                          onClick={onRequestDelete}
                          style={{
                            zIndex: 1,
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            backgroundColor: "rgba(255, 255, 255, 0.9)",
                            "&:hover": {
                              backgroundColor: "rgba(255, 255, 255, 0.9)",
                            },
                          }}
                        >
                          <DeleteIcon
                            variant="text"
                            color="primary"
                            size="small"
                          />
                        </IconButton>
                      )}
                    </>
                  )}
                  {node.type === "PICTURE" && (
                    <img
                      src={node.fileKey}
                      style={{ maxWidth: "100%" }}
                      onClick={() => handleOpen(node.fileKey, node.type)}
                    />
                  )}
                  {node.type === "VIDEO" && (
                    <Box
                      style={{
                        position: "relative",
                        display: "inline-block",
                        cursor: "pointer",
                      }}
                    >
                      <video
                        controls
                        style={{ maxWidth: "100%" }}
                        onPlay={handlePlay}
                        onPause={handlePause}
                        onClick={() => handleOpen(node.fileKey, node.type)}
                      >
                        <source src={node.fileKey} type={node.contentType} />
                      </video>
                      {isPlaying ? null : (
                        <IconButton
                          aria-label="Open Video"
                          onClick={() => handleOpen(node.fileKey, node.type)}
                          style={{
                            zIndex: 100,
                            position: "absolute",
                            top: "20%",
                            left: "38%",
                            backgroundColor: "rgba(255, 255, 255, 0.6)",
                            "&:hover": {
                              backgroundColor: "rgba(255, 255, 255, 0.9)",
                            },
                          }}
                        >
                          <PlayArrowIcon
                            variant="text"
                            color="primary"
                            size="small"
                            style={{ fontSize: 50 }}
                          />
                        </IconButton>
                      )}
                    </Box>
                  )}
                </Box>
              </Grid>
            ))}
            <Grid xs={12} sm={4} md={4} sx={{ mb: 1 }}>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
                  paddingLeft: 1,
                  paddingRight: 1,
                }}
              >
                <EvidenceUpload nearMissId={id} onFinish={handleSubmit} />
              </Box>

              <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
              >
                <IconButton
                  aria-label="Close"
                  onClick={handleClose}
                  style={{
                    position: "absolute",
                    top: "2px",
                    right: "2px",
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.9)",
                    },
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <DialogContent>
                  {selectedImage && (
                    <img src={selectedImage} style={{ maxWidth: "100%" }} />
                  )}
                  {selectedVideo && (
                    <video controls autoPlay style={{ width: "100%" }}>
                      <source src={selectedVideo} type="video/mp4" />
                    </video>
                  )}
                </DialogContent>
              </Dialog>
            </Grid>
          </Grid>
        </div>
      )}
    </Box>
  );
}

// MainPanel component
function MainPanel({
  nearMiss,
  loading,
  onSubmitRemoveAttachEvidence,
  removingAttachEvidence,
}) {
  return (
    <>
      <Header nearMiss={nearMiss} loading={loading} />
      <LocationInfo
        id={nearMiss?.id}
        location={nearMiss?.location}
        loading={loading}
      />
      <DescriptionInfo
        id={nearMiss?.id}
        description={nearMiss?.description}
        loading={loading}
      />
      <AttachmentsInfo
        id={nearMiss?.id}
        attachments={nearMiss?.attachments}
        loading={loading}
        removingAttachEvidence={removingAttachEvidence}
        onSubmitRemoveAttachEvidence={onSubmitRemoveAttachEvidence}
      />
    </>
  );
}

export default MainPanel;
