import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import withStyles from "@mui/styles/withStyles";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import DialogActions from "@mui/material/DialogActions";
import FormLabel from "@mui/material/FormLabel";

import { Form, Field } from "react-final-form";
import TextField from "../components/form/TextField";
import Checkbox from "../components/form/Checkbox";
import { OnChange } from "react-final-form-listeners";
import { mapToDisplay as mapCameraToDisplayName } from "../CameraNames";

const styles = (theme) => ({
  errorText: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(2),
  },
  form: {
    display: "flex",
    alignItems: "center",
  },
  formField: {
    marginRight: theme.spacing(2),
  },
  loading: {
    marginTop: theme.spacing(2),
  },
});

const VehicleCreateIncidentDialog = ({
  date,
  time,
  from,
  end,
  open,
  classes,
  onClose,
  onSubmit,
  availableCameras,
}) => {
  const [initialFormValues, setInitialFormValues] = useState();
  const [error, setError] = useState(null);

  useEffect(() => {
    setInitialFormValues({
      date: date || moment().format("YYYY-MM-DD"),
      time: time || moment().subtract(2, "minutes").format("HH:mm"),
      from: from || moment().subtract(2, "minutes").format("HH:mm:ss"),
      end: end || moment().format("HH:mm"),
      requestFootageVehicle: true,
      availableCameraSelected: availableCameras.map((x) => x.id) ?? [],
    });
    setError(null);
  }, [date, from, end, setInitialFormValues, setError]);

  const [selectedRequestFootage, setSelectedRequestFootage] = useState(true);

  const handleRequestFootageOnchange = () => {
    setSelectedRequestFootage(!selectedRequestFootage);
  };

  const handleFormSubmit = (form) => {
    var dateTime = moment(`${form.date}T${form.time}`);
    var dateFrom = moment(`${form.date}T${form.from}`);
    var dateEnd = moment(`${form.date}T${form.end}`);
    let diffDate = dateEnd.diff(dateFrom);

    if (diffDate <= 0) {
      setError("End at should be greater then incident time.");
      return;
    }

    let durationData = moment.utc(diffDate).format("HH:mm:ss");
    onSubmit({
      ...form,
      occurredAt: dateTime.format(),
      startAt: dateFrom.format(),
      duration: durationData,
    });
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Form
        onSubmit={handleFormSubmit}
        initialValues={initialFormValues}
        render={({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="alert-dialog-title">
              Create an incident
              {handleClose ? (
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              ) : null}
            </DialogTitle>
            <DialogContent>
              <Grid spacing={2} container>
                <Grid item xs={7} sm={6}>
                  <Field
                    className={classes.formField}
                    component={TextField}
                    name="date"
                    margin="normal"
                    required
                    label="Incident date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="date"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={5} sm={4}>
                  <Field
                    className={classes.formField}
                    component={TextField}
                    name="time"
                    margin="normal"
                    required
                    label="Incident time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="time"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Field
                    component={Checkbox}
                    name="requestFootageVehicle"
                    label="Request footage from the vehicle?"
                    type="checkbox"
                    margin="normal"
                  />
                  <OnChange name="requestFootageVehicle">
                    {handleRequestFootageOnchange}
                  </OnChange>
                </Grid>

                <Grid item xs={7} sm={4}>
                  <Field
                    className={classes.formField}
                    component={TextField}
                    name="from"
                    margin="normal"
                    required
                    label="Start from"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="time"
                    fullWidth
                    disabled={!selectedRequestFootage}
                  />
                </Grid>

                <Grid item xs={5} sm={4}>
                  <Field
                    className={classes.formField}
                    component={TextField}
                    name="end"
                    margin="normal"
                    required
                    label="End at"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="time"
                    fullWidth
                    disabled={!selectedRequestFootage}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormLabel component="legend">Cameras</FormLabel>
                  {availableCameras.map((camera) => (
                    <Field
                      key={camera.id}
                      id={camera.id}
                      value={camera.id}
                      component={Checkbox}
                      name="availableCameraSelected"
                      label={mapCameraToDisplayName(camera.location)}
                      disabled={!selectedRequestFootage}
                      type="checkbox"
                      margin="normal"
                    />
                  ))}
                </Grid>
              </Grid>
              {error && (
                <DialogContentText className={classes.errorText}>
                  {error}
                </DialogContentText>
              )}
              {submitting && <LinearProgress className={classes.loading} />}
            </DialogContent>
            <DialogActions>
              <Button
                disabled={submitting}
                onClick={handleClose}
                color="primary"
              >
                Cancel
              </Button>
              <Button disabled={submitting} type="submit" color="primary">
                Create incident
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

VehicleCreateIncidentDialog.propTypes = {
  date: PropTypes.string,
  from: PropTypes.string,
  end: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(VehicleCreateIncidentDialog);
