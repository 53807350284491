import React, { useMemo } from "react";
import { Bar } from "@visx/shape";
import { Group } from "@visx/group";
import { AxisLeft } from "@visx/axis";
import { scaleBand, scaleLinear } from "@visx/scale";
import { useTheme } from "@mui/material/styles";

const getCategory = (d) => d.category;
const getCategoryFrequency = (d) => d.frequency;

const defaultMargin = { top: 0, right: 8, bottom: 0, left: 8 };

function StatsByCategory({ data, width, height, margin = defaultMargin }) {
  const theme = useTheme();
  const barFill = theme.palette.primary.light;
  const barLabelFill = theme.palette.primary.dark;
  const barLabelFillAlt = theme.palette.primary.contrastText;
  const axisColor = theme.palette.text.primary;

  const leftAxisWidth = 120;
  const xMax = width - margin.left - margin.right - leftAxisWidth;
  const yMax = height - margin.top - margin.bottom;

  const yScale = useMemo(
    () =>
      scaleBand({
        range: [0, yMax],
        round: true,
        domain: data.map(getCategory),
        padding: 0.1,
      }),
    [yMax]
  );

  const xScale = useMemo(
    () =>
      scaleLinear({
        range: [0, xMax],
        round: true,
        domain: [0, Math.max(...data.map(getCategoryFrequency))],
      }),
    [xMax]
  );

  return (
    <svg width={width} height={height}>
      <Group top={margin.top} left={margin.left + leftAxisWidth}>
        {data.map((d) => {
          const category = getCategory(d);
          const frequency = getCategoryFrequency(d);

          const barWidth = yScale.bandwidth();
          const barHeight = xScale(frequency || 0) ?? 0;
          const barY = yScale(category) || 0;
          const altLabelPosition = barHeight + 5 >= xMax;

          return (
            <Group key={`bar-${category}`}>
              <Bar
                x={0}
                y={barY}
                width={barHeight}
                height={barWidth}
                fill={barFill}
              />
              <text
                x={
                  altLabelPosition
                    ? xMax - 15
                    : xScale(frequency) + margin.left + margin.right
                }
                y={barY + 5 + yScale.bandwidth() / 2}
                fontSize={frequency > 99 ? 10 : 12}
                fill={altLabelPosition ? barLabelFillAlt : barLabelFill}
                textAnchor="middle"
              >
                {frequency}
              </text>
            </Group>
          );
        })}
      </Group>
      <AxisLeft
        top={margin.top}
        left={margin.left + leftAxisWidth}
        scale={yScale}
        stroke={axisColor}
        tickStroke={axisColor}
        tickLabelProps={{
          fill: axisColor,
          fontSize: 11,
          textAnchor: "end",
        }}
      />
    </svg>
  );
}

export default StatsByCategory;
