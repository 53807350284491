import gql from "graphql-tag";

const Query = gql`
  query Node(
    $id: ID!
    $source: VehicleFootageSourceFilter!
    $from: DateTimeOffset!
    $until: DateTimeOffset!
    $after: String
  ) {
    node(id: $id) {
      id
      ... on Vehicle {
        id
        footage(
          first: 50
          filter: { source: $source, start: $from, end: $until }
          after: $after
        ) {
          totalCount
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            cursor
            node {
              source
              startTime
              duration
              cameraId
              camera
              ... on DeviceVehicleFootageType {
                key
                streams {
                  type
                  source
                  ... on WebSocketStream {
                    binaryType
                    openParams
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Query;
