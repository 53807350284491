import React from "react";
import withStyles from "@mui/styles/withStyles";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Icon } from "@mui/material";
import AnchorLink from "@mui/material/Link";
import { blue } from "@mui/material/colors";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: "600px",
  },
  tableWrapper: {
    overflowX: "auto",
  },
  roleChip: {
    marginRight: theme.spacing(2),
  },
  justifyCell: {
    textAlign: "center",
  },
  link: {
    fontWeight: 500,
    cursor: "pointer",
  },
  adminBadge: {
    backgroundColor: blue[500],
    color: "white",
    padding: "5px 3px",
    marginLeft: theme.spacing(1),
  },
});

const MembersList = ({
  classes = {},
  onOpenDialog,
  data,
  openViewAccessDialog,
}) => {
  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Member since</TableCell>
              <TableCell>Last sign in</TableCell>
              <TableCell>Action</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.edges &&
              data.edges.map(({ node: member, cursor }) => (
                <TableRow key={cursor} hover>
                  <TableCell variant="head">{member.name}</TableCell>
                  <TableCell>{member.emailAddress}</TableCell>
                  <TableCell>
                    <span>{moment(member.memberSince).format("L")}</span>
                  </TableCell>
                  <TableCell>
                    <span>
                      {Boolean(member.lastLoggedIn) ? (
                        <Tooltip
                          title={`${moment(member.lastLoggedIn).format(
                            "L"
                          )} ${moment(member.lastLoggedIn).format("LT")}`}
                        >
                          <span>{moment(member.lastLoggedIn).fromNow()}</span>
                        </Tooltip>
                      ) : (
                        <span>Never</span>
                      )}
                    </span>
                  </TableCell>
                  <TableCell>
                    <AnchorLink
                      className={classes.link}
                      onClick={() => openViewAccessDialog(member.userGroups)}
                    >
                      View access
                    </AnchorLink>
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Remove this user's access to this account">
                      <IconButton
                        onClick={() => onOpenDialog(member.id)}
                        size="large"
                      >
                        <Icon>cancel</Icon>
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default withStyles(styles)(MembersList);
