export const ALL = "ALL";
export const ACTIVE = "ACTIVE";
export const HIDDEN = "HIDDEN";
 
export const mapToDisplay = key => {
  if (key === ALL) return "All";
  if (key === ACTIVE) return "Active";
  if (key === HIDDEN) return "Hidden";

  return key;
};

export const mapToStatus = key => {
  if (key === ACTIVE) return true;
  if (key === HIDDEN) return false;

  return null;
};

export const isValidKey = key => {
  return (
    key === ACTIVE ||
    key === HIDDEN||
    key === ALL);
};
