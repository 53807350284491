import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import gql from "graphql-tag";
import moment from "moment";
import { Mutation } from "@apollo/client/react/components";

import NewIncidentDialog from "../components/NewIncidentDialog";

const RAISE_INCIDENT = gql`
  mutation raiseIncident($input: RaiseIncidentInput!) {
    incidents {
      raise(input: $input) {
        incident {
          id
          occurredAt
          location
          vehicle {
            id
            vrm
          }
          driver {
            id
            name
          }
        }
      }
    }
  }
`;

const defaultForm = () => ({
  vehicleId: "",
  driverId: "",
  incidentDate: moment().format("YYYY-MM-DD"),
  incidentTime: moment().format("HH:mm"),
  location: "",
});

const NewIncidentOnClick = ({ children, defaultVehicle }) => {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [form, setForm] = React.useState({
    ...defaultForm(),
    ...(defaultVehicle ? { vehicleId: defaultVehicle.id } : {}),
  });

  const handleSubmit = (raise) => (form) => {
    const variables = { input: { ...form } };

    variables.input.occurredAt = moment(
      `${form.incidentDate}T${form.incidentTime}`
    ).format();
    delete variables.input.incidentDate;
    delete variables.input.incidentTime;

    return raise({ variables }).then(
      ({
        data: {
          incidents: { raise },
        },
      }) => {
        handleClose();
        history.push(`/incidents/${raise.incident.id}?new`);
      }
    );
  };

  const handleOpen = () => {
    setForm({
      ...defaultForm(),
      ...(defaultVehicle ? { vehicleId: defaultVehicle.id } : {}),
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      {children({ onClick: handleOpen })}

      <Mutation mutation={RAISE_INCIDENT}>
        {(raise, { data }) => (
          <NewIncidentDialog
            form={form}
            open={open}
            handleSubmit={handleSubmit(raise)}
            handleClose={handleClose}
            defaultVehicle={defaultVehicle}
          />
        )}
      </Mutation>
    </Fragment>
  );
};

NewIncidentOnClick.propTypes = {
  children: PropTypes.any,
};

export default NewIncidentOnClick;
