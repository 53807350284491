import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import TextField from "./form/TextField";
import { Form, Field } from "react-final-form";
import Button from "@mui/material/Button";
import { FormControl } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  applyButton: {
    height: "56px",
    marginRight: theme.spacing(3),
    display: "none",
  },
}));

const DriverFilterForm = ({ initialFormValues, apply, className }) => {
  const classes = useStyles();

  const handleApplyFilters = (form) => {
    apply(form);
  };

  return (
    <div className={className}>
      <Form
        onSubmit={handleApplyFilters}
        initialValues={initialFormValues}
        render={({ handleSubmit, dirty, form }) => (
          <form onSubmit={handleSubmit}>
            <FormControl className={classes.formControl} fullWidth>
              <Field
                component={TextField}
                name="name"
                autoComplete="off"
                label="Name"
                type="search"
              />
            </FormControl>
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              className={classes.applyButton}
            >
              Apply
            </Button>
          </form>
        )}
      />
    </div>
  );
};

DriverFilterForm.propTypes = {
  initialFormValues: PropTypes.object,
  apply: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default DriverFilterForm;
