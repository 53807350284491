import React from "react";
import PropTypes from "prop-types";
import ordinal from "ordinal";
import withStyles from '@mui/styles/withStyles';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import ThirdPartyDetail from "../components/ThirdPartyDetail";

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  card: {
    marginBottom: theme.spacing(2)
  }
});

const IncidentThirdParties = ({ value, classes }) => (
  <div>
    <p>Third parties involved: {value.thirdPartiesInvolved ? "Yes" : "No"}</p>
    {value.thirdParties.map((x, idx) => (
      <Card key={`tp-${idx}`} className={classes.card}>
        <CardHeader title={`${ordinal(idx + 3)} party`} />
        <CardContent>
          <ThirdPartyDetail value={x} />
        </CardContent>
      </Card>
    ))}
  </div>
);

IncidentThirdParties.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(IncidentThirdParties);
