import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import LinearProgress from "@mui/material/LinearProgress";

import ActiveContent from "./ActiveContent";
import ActivateDialog from "./ActivateDialog";
import DeactivateDialog from "./DeactivateDialog";

const { PUBLIC_URL } = process.env;

const useStyles = makeStyles((theme) => ({
  buttonBox: {
    marginLeft: "auto",
  },
  icon: {
    fontSize: 38,
  },
}));

const SettingsPanel = ({ status, error, settings, loading }) => {
  const classes = useStyles();
  const [open, setOpen] = useState("");
  const [info, setInfo] = useState();
  const [automatically, setAutomatically] = useState();
  const [orbcommStatus, setOrbcommStatus] = useState();

  useEffect(() => {
    setInfo(settings);
    setOrbcommStatus(status);
    settings && setAutomatically(settings.automaticallyFetchFiles);
  }, [settings, status]);

  const handleClose = () => setOpen("");
  const handleOpen = (method) => setOpen(method);
  const handleActivate = (info) => {
    setOrbcommStatus("ACTIVE");
    setInfo(info);
  };
  const handleDeactivate = () => setOrbcommStatus("INACTIVE");

  let content = null;
  let actions = null;

  if (orbcommStatus === "INACTIVE" || orbcommStatus === null) {
    actions = (
      <CardActions>
        <div className={classes.buttonBox}>
          <Button
            size="small"
            color="primary"
            onClick={() => handleOpen("activate")}
          >
            Activate
          </Button>
        </div>
      </CardActions>
    );
  } else if (error) {
    content = (
      <CardContent>
        <p>!!ERROR!!</p>
      </CardContent>
    );
  } else {
    actions = (
      <CardActions>
        <div className={classes.buttonBox}>
          <Button size="small" onClick={() => handleOpen("deactivate")}>
            Deactivate
          </Button>{" "}
        </div>
      </CardActions>
    );
    content = (
      <CardContent>
        <ActiveContent
          settings={info}
          automaticallyFetchFiles={automatically}
        />
      </CardContent>
    );
  }

  if (loading) return <LinearProgress variant="indeterminate" />;

  return (
    <Fragment>
      <Card>
        <CardHeader
          avatar={
            <Avatar
              alt="TomTom"
              src={`${PUBLIC_URL}/icons/orbcomm-logo-164x164.png`}
              aria-label="recipe"
            ></Avatar>
          }
          title="Orbcomm FleetManager"
          subheader="Sync drivers and safety events with Orbcomm FleetManager"
        />
        {content}
        {actions}
      </Card>
      <ActivateDialog
        handleClose={handleClose}
        open={open}
        onActivate={handleActivate}
      />
      <DeactivateDialog
        handleClose={handleClose}
        open={open}
        onDeactivate={handleDeactivate}
      />
    </Fragment>
  );
};

SettingsPanel.propTypes = {
  status: PropTypes.string,
  error: PropTypes.any,
  settings: PropTypes.object,
};

export default SettingsPanel;
