import React from "react";

import LogRocket from "logrocket";
import Auth from "./Auth";
const auth = new Auth();

const AnonStatus = { isAuthenticated: false };

const AuthContext = React.createContext();

function AuthProvider(props) {
  const [status, setStatus] = React.useState(() => auth.status());

  const isInRole = allowedRoles => {
    return auth.isAuthorized(allowedRoles);
  };

  const signIn = data => {
    return new Promise((resolve, _) => {
      const { user, account } = data;

      auth.setSession({
        idToken: data.token,
        expiresIn: data.expiresIn,
        userId: user.id,
        user,
        account
      });

      setStatus({
        isAuthenticated: true,
        user,
        account
      });

      LogRocket.identify(data.user.id, {
        name: user.name,
        email: user.email
      });

      LogRocket.track(
        `Tenant:${account && account.tenant && account.tenant.name}`
      );

      resolve();
    });
  };

  const logout = continueTo => {
    return new Promise((resolve, _) => {
      auth.logout(continueTo);
      setStatus(AnonStatus);
      resolve();
    });
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: status.isAuthenticated,
        account: status.account,
        user: status.user,
        isInRole,
        signIn,
        logout
      }}
      {...props}
    />
  );
}

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
