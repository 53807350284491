import React, { useRef, useEffect } from "react";
import flvjs from "flv.js";
import Hls from "hls.js";

import makeStyles from "@mui/styles/makeStyles";
import { PlayCircleOutline as PlayIcon } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  player: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  root: {
    margin: 0,
    position: "relative",
    // display: "flex",
  },
  caption: {
    top: 0,
    position: "absolute",
    left: 0,
    right: 0,
    textAlign: "center",
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.85) 10%, rgba(0,0,0,0) 100%)",
    paddingBottom: theme.spacing(2),
    color: "#fff",
    display: "flex",
    alignItems: "center",
  },
  cationLabel: {
    flexGrow: 1,
  },
  playIconOverlay: {
    display: "grid",
    placeItems: "center",
    top: "25%",
    bottom: "35%",
    left: "35%",
    right: "35%",
    position: "absolute",
    textAlign: "center",
    background: "rgba(0,0,0,0.85)",
    borderRadius: theme.spacing(2),
    cursor: "pointer",
    zIndex: 1,
    color: "#fff",
    "&:hover": {
      background: `radial-gradient(#2e2e2e, rgba(0,0,0,0.85))`,
    },
  },
  playIcon: {
    width: "90%",
    height: "90%",
  },

  videoOffLabel: {
    color: "#c5c5c5",
    bottom: "35%",
    width: "100%",
    position: "absolute",
    textAlign: "center",
    fontWeight: "600",
    fontSize: "20px",
  },
}));

const rtmpPlayerSetup = (video, src, onPauseClick) => {
  let flvPlayer = flvjs.createPlayer({
    type: "flv",
    url: src,
  });

  flvPlayer.attachMediaElement(video);
  flvPlayer.load();
  var playPromise = flvPlayer.play();
  if (playPromise !== undefined) {
    playPromise
      .then((_) => {
        // video.pause();
      })
      .catch((error) => {
        console.error("Play request interrupted:", error.message);
      });
  }

  const destroyPlayer = (player) => {
    player.pause();
    player.unload();
    player.detachMediaElement();
    player.destroy();
  };

  const volumnchangeEventListener = () => {
    flvPlayer.muted = true;
  };

  video.addEventListener("pause", onPauseClick);
  video.addEventListener("volumechange", volumnchangeEventListener);

  return () => {
    video.removeEventListener("pause", onPauseClick);
    video.removeEventListener("volumechange", volumnchangeEventListener);

    destroyPlayer(flvPlayer);
  };
};

const hlsPlayerSetup = (video, src, onPauseClick) => {
  let hls = new Hls({
    enableWorker: true,
    autoStartLoad: false,
  });

  hls.loadSource(src);
  hls.attachMedia(video);
  hls.startLoad();

  hls.on(Hls.Events.MEDIA_ATTACHED, function () {
    console.log("video and hls.js are now bound together !");
  });

  hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
    console.log(
      "manifest loaded, found " + data.levels.length + " quality level"
    );

    video.play();
  });

  hls.on(Hls.Events.ERROR, (event, data) => {
    var errorType = data.type;
    var errorDetails = data.details;
    var errorFatal = data.fatal;

    console.error({ errorType, errorDetails, errorFatal });

    if (data.fatal) {
      switch (data.type) {
        case Hls.ErrorTypes.NETWORK_ERROR:
          console.log("fatal network error encountered, try to recover");
          errorDetails === "manifestLoadTimeOut"
            ? hls.loadSource(src)
            : hls.startLoad();
          break;
        case Hls.ErrorTypes.MEDIA_ERROR:
          console.log("fatal media error encountered, try to recover");
          hls.recoverMediaError();
          break;
        default:
          console.log("fatal error encountered, can not recover");
          // cannot recover
          hls.destroy();
          hls = null;
          break;
      }
    }
  });

  const volumnchangeEventListener = () => {
    hls.muted = true;
  };
  video.addEventListener("pause", onPauseClick);
  video.addEventListener("volumechange", volumnchangeEventListener);

  return () => {
    video.removeEventListener("pause", onPauseClick);
    video.removeEventListener("volumechange", volumnchangeEventListener);

    hls.detachMedia();
    hls.destroy();
    hls = null;
  };
};
const VehicleTrackingVideoPlayer = ({
  sources,
  onPlayClick,
  onPauseClick,
  play,
}) => {
  const classes = useStyles();
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (play && video) {
      if (!sources) return;
      const hlsSupported = Hls.isSupported();
      const hlsNativeSupported = video.canPlayType(
        "application/vnd.apple.mpegurl"
      );
      const flvSupported = flvjs.isSupported();

      if (flvSupported && sources.find((x) => x.type === "rtmp")) {
        const src = sources.find((x) => x.type === "rtmp")?.source;

        return rtmpPlayerSetup(video, src, onPauseClick);
      } else if (hlsNativeSupported) {
        const src = sources.find((x) => x.type === "hls")?.source;
        video.src = src;
      } else if (hlsSupported) {
        const src = sources.find((x) => x.type === "hls")?.source;
        return hlsPlayerSetup(video, src, onPauseClick);
      }
    }
  }, [play, sources]);

  const srcVideoFound = sources.find(
    (x) => x.type === "rtmp" || x.type === "hls"
  )?.source;

  return (
    <>
      {!play ? (
        <>
          {srcVideoFound ? (
            <div className={classes.playIconOverlay} onClick={onPlayClick}>
              <PlayIcon className={classes.playIcon} />
            </div>
          ) : (
            <div className={classes.videoOffLabel}>Not found footage</div>
          )}
          <video
            className={classes.player}
            poster="/live-video-poster.svg"
          ></video>
        </>
      ) : (
        <video
          className={classes.player}
          ref={videoRef}
          poster="/live-video-poster.svg"
          controls
          muted
          autoPlay={false}
        >
          Sorry, your browser doesn't support embedded videos.
        </video>
      )}
    </>
  );
};

export default VehicleTrackingVideoPlayer;
