import { Box, Divider, Skeleton, Stack } from "@mui/material";

function ListItemSkeleton() {
  return (
    <Stack direction="column" spacing={2}>
      <Box flexGrow={1}>
        <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
          <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} width={"20%"} />
          <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} width={"60%"} />
          <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} width={"10%"} />
        </Stack>
        <Stack
          color={"text.secondary"}
          direction="row"
          alignItems="center"
          gap={2}
        >
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"40%"} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"30%"} />
        </Stack>
      </Box>
    </Stack>
  );
}

function NearMissListSkeleton() {
  return (
    <Stack
      component={"ul"}
      role="list"
      style={{ listStyleType: "none", padding: 0, margin: 0 }}
      spacing={1}
      divider={<Divider flexItem />}
    >
      <li>
        <ListItemSkeleton />
      </li>
      <li>
        <ListItemSkeleton />
      </li>
    </Stack>
  );
}

export default NearMissListSkeleton;
