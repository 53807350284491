import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "center"
  },
  inner: {
    textAlign: "center",
    maxWidth: "400px",
    width: "100%",
    margin: theme.spacing(2),
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      maxWidth: "none",
      margin: theme.spacing(1)
    }
  },
  progress: {
    margin: `${theme.spacing(4)} auto ${theme.spacing(2)} auto`
  },
  version: {
    color: theme.palette.text.hint,
    fontSize: "0.8em"
  },
  header: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(4),
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius
  },
  logo: {
    height: "30px",
    marginRight: theme.spacing(1)
  },
  body: {
    padding: `${theme.spacing(4)} ${theme.spacing(2)}`
  }
});

class CenteredPaperLayout extends React.Component {
  state = {
    mobileOpen: false
  };

  render() {
    const { classes, children, title, above } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.inner}>
          {above}
          <Paper className={classes.paper}>
            {title && (
              <div className={classes.header}>
                <Typography variant="h5" component="h3" color="inherit">
                  {title}
                </Typography>
              </div>
            )}
            <div className={classes.body}>{children}</div>
          </Paper>
        </div>
      </div>
    );
  }
}

CenteredPaperLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  above: PropTypes.any
};

export default withStyles(styles)(CenteredPaperLayout);
