export const PENDING_REVIEW = "PENDING_REVIEW";
export const ACCEPTED = "ACCEPTED";
export const DISMISSED = "DISMISSED";

export const map = key => {
  if (key === PENDING_REVIEW) return "Pending Review";
  if (key === ACCEPTED) return "Accepted";
  if (key === DISMISSED) return "Dismissed";

  return "??";
};
