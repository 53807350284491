import React, { useMemo, useCallback, useState, useRef } from "react";
import { scaleTime, scaleLinear } from "@visx/scale";
import { Brush } from "@visx/brush";
import { PatternLines } from "@visx/pattern";
import { Group } from "@visx/group";
import { LinearGradient } from "@visx/gradient";
import AreaChart from "./JourneyAreaChart";
import { max, min, extent, bisector } from "d3-array";
import { Line, Bar } from "@visx/shape";
import { timeFormat } from "@visx/vendor/d3-time-format";
import { withTooltip, defaultStyles, TooltipWithBounds } from "@visx/tooltip";
import { GlyphTriangle } from "@visx/glyph";
import { localPoint } from "@visx/event";

const brushMargin = { top: 10, bottom: 0, left: 50, right: 20 };
const chartSeparation = 5;
const PATTERN_ID = "brush_pattern";
const GRADIENT_ID = "brush_gradient";
const background = "#0085cdd6";
const accentColor = "#0085cd";
const fromBackgroundGradient = "#fff";
const toBackgroundGradient = "#fff";
const strokeLineSelected = "red";

const selectedBrushStyle = {
  fill: `url(#${PATTERN_ID})`,
  stroke: "#868686",
};
const tooltipStyles = {
  ...defaultStyles,
  background,
  border: "1px solid white",
  color: "white",
};

const formatTimeFull = timeFormat("%H:%M");
const getDate = (d) => new Date(d);
const getDateDataValue = (d) => getDate(d.timestamp);
const getSpeedDataValue = (d) => d.speed;
const bisectDate = bisector((d) => getDateDataValue(d)).left;

const JourneyGraph = withTooltip(
  ({
    compact = false,
    width,
    height,
    margin = {
      top: 20,
      left: 25,
      bottom: 0,
      right: 0,
    },
    speedData,
    onTimeSelected,
    onSafetyEventSelected,
    timeSelected,
    safetyEventData,
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipTop = 0,
    tooltipLeft = 0,
    dateSelected,
  }) => {
    const brushRef = useRef(null);
    const fullHourArray = Array.from({ length: 24 }, (_, i) => i);
    const dataHours = new Set(
      speedData.map((d) => getDateDataValue(d).getHours())
    );

    const createMissingData = useMemo(
      () => (hour, minute) => ({
        coord: {},
        speed: 0,
        timestamp: getDate(dateSelected).setHours(hour, minute, 0, 0),
      }),
      [dateSelected]
    );

    const speedDataCopy = [...speedData];
    fullHourArray.forEach((hour) => {
      if (!dataHours.has(hour)) {
        speedDataCopy.push(
          createMissingData(hour, 0),
          createMissingData(hour, 30),
          createMissingData(hour, 59)
        );
      }
    });

    const newSpeedData = speedDataCopy.sort(
      (a, b) => getDate(b.timestamp) - getDate(a.timestamp)
    );

    const enableBrush = newSpeedData.some((item) => item.speed > 0);

    const getFilteredJourneyData = (speedData) => {
      let dateStart = null;
      let dateEnd = null;

      speedData.forEach((e) => {
        if (e.speed !== 0) {
          dateEnd = dateEnd || e.timestamp;
          dateStart = e.timestamp;
        }
      });

      return speedData.filter((e) => {
        return e.timestamp >= dateStart && e.timestamp <= dateEnd;
      });
    };

    const [filteredSpeedData, setFilteredSpeedData] = useState(
      enableBrush ? getFilteredJourneyData(newSpeedData) : newSpeedData
    );

    const onBrushEndChange = (domain) => {
      if (!domain) return;
      const { x0, x1 } = domain;
      const hour = 10 * 60 * 1000;
      if (x1 - x0 < hour) {
        if (brushRef?.current) {
          const updater = (prevBrush) => {
            const newExtent = brushRef.current.getExtent(
              initialBrushPosition.start,
              initialBrushPosition.end
            );

            const newState = {
              ...prevBrush,
              start: { y: newExtent.y0, x: newExtent.x0 },
              end: { y: newExtent.y1, x: newExtent.x1 },
              extent: newExtent,
            };

            return newState;
          };
          brushRef.current.updateBrush(updater);
        }
      }
    };

    const onBrushChange = (domain) => {
      if (!domain) return;
      const { x0, x1, y0, y1 } = domain;

      const newSpeedDataCopy = newSpeedData.filter((s) => {
        const x = getDateDataValue(s).getTime();
        const y = getSpeedDataValue(s);
        return x > x0 && x < x1 && y > y0 && y < y1;
      });

      if (!newSpeedDataCopy.length) return;

      setFilteredSpeedData(newSpeedDataCopy);
    };

    const innerHeight = height - margin.top - margin.bottom;
    const topChartBottomMargin = compact
      ? chartSeparation / 2
      : chartSeparation + 10;
    const topChartHeight = 0.8 * innerHeight - topChartBottomMargin;
    const bottomChartHeight = innerHeight - topChartHeight - chartSeparation;

    // bounds
    const xMax = Math.max(width - margin.left - margin.right, 0);
    const yMax = Math.max(enableBrush ? topChartHeight : innerHeight, 0);
    const xBrushMax = Math.max(width - brushMargin.left - brushMargin.right, 0);
    const yBrushMax = Math.max(
      bottomChartHeight - brushMargin.top - brushMargin.bottom,
      0
    );

    // scales
    const dateValueScale = useMemo(
      () =>
        scaleTime({
          range: [0, xMax],
          domain: extent(filteredSpeedData, getDateDataValue),
        }),
      [xMax, filteredSpeedData]
    );

    const speedValueScale = useMemo(
      () =>
        scaleLinear({
          range: [yMax, 0],
          domain: [0, max(filteredSpeedData, getSpeedDataValue) || 0],
          nice: true,
        }),
      [yMax, filteredSpeedData]
    );

    const brushDateValueScale = useMemo(
      () =>
        scaleTime({
          range: [0, xBrushMax],
          domain: extent(newSpeedData, getDateDataValue),
        }),
      [xBrushMax]
    );

    const brushSpeedValueScale = useMemo(
      () =>
        scaleLinear({
          range: [yBrushMax, 0],
          domain: [0, max(newSpeedData, getSpeedDataValue) || 0],
          nice: true,
        }),
      [yBrushMax]
    );

    const initialBrushPosition = useMemo(
      () => ({
        start: {
          x: brushDateValueScale(min(filteredSpeedData, getDateDataValue)),
        },
        end: {
          x: brushDateValueScale(max(filteredSpeedData, getDateDataValue)),
        },
      }),
      [brushDateValueScale]
    );

    // positions
    const getX = (d) => dateValueScale(new Date(d)) ?? 0;

    // tooltip handler
    const handleSelect = useCallback(
      (event) => {
        const { x } = localPoint(event) || { x: 0 };
        var xSubtractMargin = x - margin.left;
        const x0 = dateValueScale.invert(xSubtractMargin);
        var index = bisectDate(speedData, x0, 1);
        const d0 = speedData[index - 1];
        const d1 = speedData[index];
        let d = d0;
        if (d1 && getDateDataValue(d1)) {
          index =
            x0.valueOf() - getDateDataValue(d0).valueOf() >
            getDateDataValue(d1).valueOf() - x0.valueOf()
              ? index
              : index - 1;

          d = speedData[index];
        }

        onTimeSelected(x0);
      },
      [speedValueScale, dateValueScale]
    );

    // tooltip handler
    const handleTooltip = useCallback(
      (event) => {
        const { x } = localPoint(event) || { x: 0 };
        var xSubtractMargin = x - margin.left;
        const x0 = dateValueScale.invert(xSubtractMargin);
        var index = bisectDate(speedData, x0, 1);
        const d0 = speedData[index - 1];
        const d1 = speedData[index];

        const d =
          d1 && getDateDataValue(d1)
            ? x0 - getDateDataValue(d0).valueOf() >
              getDateDataValue(d1).valueOf() - x0
              ? d1
              : d0
            : { ...d0, speed: 0 };

        showTooltip({
          tooltipData: { ...d, timestamp: x0 },
          tooltipLeft: xSubtractMargin,
          tooltipTop: speedValueScale(getSpeedDataValue(d)),
        });
      },
      [showTooltip, speedValueScale, dateValueScale]
    );

    return (
      <div>
        <svg width={width} height={height}>
          <LinearGradient
            id={GRADIENT_ID}
            from={fromBackgroundGradient}
            to={toBackgroundGradient}
            rotate={45}
          />
          <rect
            x={0}
            y={0}
            width={width}
            height={height}
            fill={`url(#${GRADIENT_ID})`}
            rx={14}
          />
          <AreaChart
            hideBottomAxis={compact}
            data={filteredSpeedData}
            width={width}
            margin={{ ...margin, bottom: topChartBottomMargin }}
            yMax={yMax}
            xMax={xMax}
            xScale={dateValueScale}
            yScale={speedValueScale}
            gradientColor={background}
          >
            <Bar
              x={0}
              y={0}
              width={width}
              height={height}
              fill="transparent"
              rx={14}
              onClick={handleSelect}
              onTouchStart={handleTooltip}
              onTouchMove={handleTooltip}
              onMouseMove={handleTooltip}
              onMouseLeave={() => hideTooltip()}
            />
            {timeSelected && (
              <Line
                from={{ x: getX(timeSelected), y: -margin.top }}
                to={{ x: getX(timeSelected), y: innerHeight }}
                stroke={strokeLineSelected}
                strokeWidth={2}
                pointerEvents="none"
                strokeDasharray="0"
              />
            )}
            {safetyEventData?.totalCount > 0 &&
              safetyEventData.edges.map(({ cursor, node }) => (
                <GlyphTriangle
                  key={cursor}
                  left={getX(node.occurredAt)}
                  top={margin.top + 32}
                  fill="#f90"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    onSafetyEventSelected(node);
                  }}
                />
              ))}
            {tooltipData && (
              <g>
                <Line
                  from={{ x: tooltipLeft, y: 0 }}
                  to={{ x: tooltipLeft, y: innerHeight }}
                  stroke={accentColor}
                  strokeWidth={1}
                  pointerEvents="none"
                  strokeDasharray="0"
                />
                <circle
                  cx={tooltipLeft}
                  cy={tooltipTop + 1}
                  r={4}
                  fill="black"
                  fillOpacity={0.1}
                  stroke="black"
                  strokeOpacity={0.1}
                  strokeWidth={2}
                  pointerEvents="none"
                />
                <circle
                  cx={tooltipLeft}
                  cy={tooltipTop}
                  r={4}
                  fill={accentColor}
                  stroke="white"
                  strokeWidth={2}
                  pointerEvents="none"
                />
              </g>
            )}
          </AreaChart>
          {enableBrush && (
            <AreaChart
              hideTopAxis
              hideGridRowAxis
              hideLeftAxis
              data={newSpeedData}
              width={width}
              yMax={yBrushMax}
              xMax={xBrushMax}
              xScale={brushDateValueScale}
              yScale={brushSpeedValueScale}
              margin={brushMargin}
              top={topChartHeight + topChartBottomMargin + margin.top}
              gradientColor={background}
            >
              <PatternLines
                id={PATTERN_ID}
                height={6}
                width={6}
                stroke={accentColor}
                strokeWidth={1}
                orientation={["diagonal"]}
              />
              <Brush
                xScale={brushDateValueScale}
                yScale={brushSpeedValueScale}
                width={xBrushMax}
                height={yBrushMax}
                margin={brushMargin}
                innerRef={brushRef}
                handleSize={8}
                resizeTriggerAreas={["left", "right"]}
                brushDirection="horizontal"
                initialBrushPosition={initialBrushPosition}
                onChange={onBrushChange}
                onBrushEnd={onBrushEndChange}
                onClick={() => setFilteredSpeedData(newSpeedData)}
                selectedBoxStyle={selectedBrushStyle}
                useWindowMoveEvents
                renderBrushHandle={(props) => <BrushHandle {...props} />}
              />
            </AreaChart>
          )}
        </svg>

        {tooltipData && (
          <TooltipWithBounds
            top={height / 5}
            left={tooltipLeft + margin.left}
            style={tooltipStyles}
          >
            <div style={{ marginBottom: "5px" }}>
              {formatTimeFull(getDateDataValue(tooltipData))}
            </div>
            <div>Speed: {`${getSpeedDataValue(tooltipData)} MPH`}</div>
          </TooltipWithBounds>
        )}
      </div>
    );
  }
);

function BrushHandle({ x, height, isBrushActive }) {
  const pathWidth = 8;
  const pathHeight = 15;
  if (!isBrushActive) {
    return null;
  }
  return (
    <Group left={x + pathWidth / 2} top={(height - pathHeight) / 2}>
      <path
        fill="#f2f2f2"
        d="M -4.5 0.5 L 3.5 0.5 L 3.5 15.5 L -4.5 15.5 L -4.5 0.5 M -1.5 4 L -1.5 12 M 0.5 4 L 0.5 12"
        stroke="#999999"
        strokeWidth="1"
        style={{ cursor: "ew-resize" }}
      />
    </Group>
  );
}

export default JourneyGraph;
