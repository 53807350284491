import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import moment from "moment";

import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";

import { Mutation } from "@apollo/client/react/components";
import gql from "graphql-tag";
import INCIDENT_FRAGMENT from "../fragments/incident";

const UPDATE_INCIDENT = gql`
  mutation updateIncident($input: UpdateIncidentInput!) {
    incidents {
      update(input: $input) {
        incident {
          ...incident
        }
      }
    }
  }

  ${INCIDENT_FRAGMENT}
`;

const styles = {
  actions: {
    display: "flex",
  },
};

class UpdateIncident extends React.Component {
  state = {
    error: null,
  };

  render() {
    const { initialFormValues } = this.props;
    const { error } = this.state;

    return (
      <Mutation mutation={UPDATE_INCIDENT}>
        {(update) => (
          <Form
            onSubmit={this.handleSubmit(update)}
            mutators={{
              ...arrayMutators,
              setValue: ([fieldName, value], state, utils) => {
                utils.changeValue(state, fieldName, () => value);
              },
            }}
            initialValues={initialFormValues}
            render={({ handleSubmit, submitting, form, dirty, values }) => (
              <form onSubmit={handleSubmit}>
                {this.props.render({
                  handleSubmit,
                  submitting,
                  dirty,
                  form,
                  reset: form.reset,
                  values,
                  error,
                })}
              </form>
            )}
          />
        )}
      </Mutation>
    );
  }

  handleSubmit = (update) => (form) => {
    this.setState({ error: null });

    const { incidentId } = this.props;
    const variables = {
      input: {
        incidentId,
        ...form,
        attachEvidence: {
          evidence: ((form.attachEvidence || {}).evidence || [])
            .filter((x) => x.filename)
            .map(({ filename, fileKey, contentType, status, description }) => ({
              filename,
              fileKey,
              contentType,
              status,
              description,
            })),
        },
      },
    };

    if (variables.input.incident && variables.input.incident.incidentDate) {
      const {
        input: {
          incident: { incidentDate, incidentTime },
        },
      } = variables;
      variables.input.incident.occurredAt = moment(
        `${incidentDate}T${incidentTime}`
      ).format();
      delete variables.input.incident.incidentDate;
      delete variables.input.incident.incidentTime;
    }

    return update({ variables }).then(
      () => {
        this.setState({ error: null });
        this.props.onUpdated && this.props.onUpdated();
      },
      (err) => {
        console.log("Error submitting form", err);
        this.setState({ error: err.message });
      }
    );
  };
}

UpdateIncident.propTypes = {
  incidentId: PropTypes.string.isRequired,
  initialFormValues: PropTypes.object.isRequired,
  render: PropTypes.func.isRequired,
  onUpdated: PropTypes.func,
};

export default withStyles(styles)(UpdateIncident);
