import React from "react";
import { useAuth } from "../AuthContext";

export const PermissionValues = {
  Vehicles: "vehicles",
  Incidents: "incidents",
  DriverReview: "driver-review",
  NearMiss: "near-miss",
  LiveShareTracking: "live-share-tracking",
};

const GroupPermissions = () => {
  const { account } = useAuth();
  const permissions = [
    {
      key: "vehiclesEnabled",
      value: PermissionValues.Vehicles,
      name: "Vehicles",
    },
    {
      key: "incidentsEnabled",
      value: PermissionValues.Incidents,
      name: "Incidents",
    },
    {
      key: "driverReviewEnabled",
      value: PermissionValues.DriverReview,
      name: "Driver Review",
    },
    {
      key: "nearMissEnabled",
      value: PermissionValues.NearMiss,
      name: "Near Miss",
    },
    {
      key: "vehiclesEnabled",
      value: PermissionValues.LiveShareTracking,
      name: "Shared live tracking",
    },
  ];

  const groupPermissions = React.useMemo(() => {
    if (!account) return [];
    const tenantFeatures = account?.tenant?.features || {
      isSupportTracking: false,
      nearMissEnabled: false,
      vehiclesEnabled: true,
      incidentsEnabled: true,
      driverReviewEnabled: true,
    };

    return permissions
      .filter((p) => tenantFeatures?.[p.key])
      .map((p) => ({ value: p.value, name: p.name }));
  }, [account]);

  return groupPermissions;
};

export default GroupPermissions;
