import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import withStyles from "@mui/styles/withStyles";
import { Query } from "@apollo/client/react/components";
import gql from "graphql-tag";

import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import VehicleFootageList from "../containers/VehicleFootageList";

import { Form, Field } from "react-final-form";
import TextField from "../components/form/TextField";

const VIDEO_QUERY = gql`
  query Node(
    $id: ID!
    $source: VehicleFootageSourceFilter!
    $from: DateTimeOffset!
    $until: DateTimeOffset!
    $after: String
  ) {
    node(id: $id) {
      id
      ... on Vehicle {
        id
        footage(
          first: 50
          filter: { source: $source, start: $from, end: $until }
          after: $after
        ) {
          totalCount
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            cursor
            node {
              source
              startTime
              duration
              camera
              ... on DeviceVehicleFootageType {
                key
                streams {
                  type
                  source
                }
              }
            }
          }
        }
      }
    }
  }
`;

const styles = (theme) => ({
  errorText: {
    color: theme.palette.error.main,
  },
  form: {
    display: "flex",
    alignItems: "center",
  },
  formField: {
    marginRight: theme.spacing(2),
  },
  html5Video: {
    maxWidth: "100%",
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  alignCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

const FiltersForm = ({ handleFormSubmit, initialFormValues, classes }) => {
  return (
    <Form
      onSubmit={handleFormSubmit}
      initialValues={initialFormValues}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <Field
            className={classes.formField}
            component={TextField}
            name="date"
            margin="normal"
            required
            label="Date"
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
          />
          <Field
            className={classes.formField}
            component={TextField}
            name="from"
            margin="normal"
            required
            label="From"
            InputLabelProps={{
              shrink: true,
            }}
            type="time"
          />
          <Field
            className={classes.formField}
            component={TextField}
            name="until"
            margin="normal"
            required
            label="Until"
            InputLabelProps={{
              shrink: true,
            }}
            type="time"
          />
          <div>
            <Button type="submit">Search</Button>
          </div>
        </form>
      )}
    />
  );
};

const VehicleFootageSearchDialog = ({
  date,
  from,
  until,
  vehicleId,
  open,
  classes,
  onClose,
}) => {
  const handleFormSubmit = (form) => {
    const queryVariables = createQueryVariables(form);

    setQueryVariables(queryVariables);
  };

  const [initialFormValues, setInitialFormValues] = React.useState({
    date: date || moment().format("YYYY-MM-DD"),
    from: from || moment().subtract(2, "minutes").format("HH:mm"),
    until: until || moment().format("HH:mm"),
  });

  React.useEffect(() => {
    setInitialFormValues({
      date: date || moment().format("YYYY-MM-DD"),
      from: from || moment().subtract(2, "minutes").format("HH:mm"),
      until: until || moment().format("HH:mm"),
    });
  }, [date, from, until, setInitialFormValues]);

  const [queryVariables, setQueryVariables] = React.useState(null);

  const createQueryVariables = (form) => {
    const from = moment(`${form.date}T${form.from}`).format();
    const until = moment(`${form.date}T${form.until}`).format();

    return { source: "ALL", id: vehicleId, from, until };
  };

  const readyForQuery = Boolean(
    queryVariables && queryVariables.from && queryVariables.until
  );

  React.useEffect(() => {
    if (!(date && from && until)) return;

    setQueryVariables({
      id: vehicleId,
      source: "ALL",
      from: moment(`${date}T${from}`).format(),
      until: moment(`${date}T${until}`).format(),
    });
  }, [from, until, date, vehicleId, setQueryVariables]);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Find vehicle footage
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>

      <DialogContent>
        <Query
          query={VIDEO_QUERY}
          variables={queryVariables}
          skip={!readyForQuery}
          fetchPolicy="cache-and-network"
          notifyOnNetworkStatusChange={true}
        >
          {({ loading: fetching, error, data }) => {
            return (
              <>
                <FiltersForm
                  {...{ handleFormSubmit, initialFormValues, classes }}
                />
                {fetching && !error && (
                  <>
                    <p>
                      Attempting to find footage on the device...
                      <br />
                      <small>
                        Please note, the vehicle must be online to retrieve
                        footage
                      </small>
                    </p>
                    <LinearProgress />
                  </>
                )}
                {error && (
                  <div className={classes.alignCenter}>
                    <DialogContentText className={classes.errorText}>
                      {
                        "Failed to connect to device, vehicle may be in a low signal area."
                      }
                    </DialogContentText>
                  </div>
                )}

                {data && !error && !fetching && (
                  <VehicleFootageList footage={data.node.footage} />
                )}
              </>
            );
          }}
        </Query>
      </DialogContent>
    </Dialog>
  );
};

VehicleFootageSearchDialog.propTypes = {
  vehicleId: PropTypes.string.isRequired,
  date: PropTypes.string,
  from: PropTypes.string,
  until: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(VehicleFootageSearchDialog);
