import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import { ACTIVE, isValidKey as isValidStatusValue } from "../DriverStatus";
import DriverFilterForm from "../components/DriverFilterForm";
import qs from "querystringify";
import Drivers from "../containers/Drivers";

const styles = (theme) => ({
  root: {
    marginBottom: 56,
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
  filterRoot: {
    marginBottom: theme.spacing(2),
  },
});

const DEFAULT_FILTERS = {
  status: ACTIVE,
};

const DriversPage = ({ classes }) => {
  const history = useHistory();
  const { search } = useLocation();

  const [filters, setFilters] = React.useState({ ...DEFAULT_FILTERS });

  const SanitiseFilters = (value) => {
    let filters = { ...value };

    if (!isValidStatusValue(filters.status)) delete filters.status;

    return filters;
  };

  React.useEffect(() => {
    const grossFilters =
      search === "" ? { ...DEFAULT_FILTERS } : qs.parse(search);

    const filters = SanitiseFilters(grossFilters);
    setFilters(filters);
  }, [search]);

  const handleApplyFilters = (form) => {
    history.push(`/admin/drivers?${qs.stringify(form)}`);
  };

  return (
    <section className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" className={classes.marginBottom}>
        <Link color="inherit" to="/admin" component={RouterLink}>
          Admin
        </Link>
        <Typography color="textPrimary">Drivers</Typography>
      </Breadcrumbs>
      <Typography variant="h5" gutterBottom>
        Drivers
      </Typography>
      <DriverFilterForm
        initialFormValues={filters}
        apply={handleApplyFilters}
        className={classes.filterRoot}
      />
      <Drivers search={filters} />
    </section>
  );
};
DriversPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DriversPage);
