import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';

import AdapterLink from "../components/form/Link";
import { Field } from "react-final-form";
import TextField from "../components/form/TextField";
import Checkbox from "../components/form/Checkbox";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import FormHelperText from "@mui/material/FormHelperText";

const { REACT_APP_VERSION = "local" } = process.env;

const validateRequired = fieldName => value =>
  !value && `${fieldName} is required`;

const styles = theme => ({
  progress: {
    margin: `${theme.spacing(4)} auto ${theme.spacing(2)} auto`
  },
  version: {
    color: theme.palette.text.hint,
    fontSize: "0.8em"
  }
});

const SignInForm = ({ classes, disabled, authenticating, error, note }) => (
  <div>
    <Field
      component={TextField}
      name="email"
      type="email"
      required
      validate={validateRequired("Email")}
      margin="normal"
      label="Email address"
      fullWidth
      disabled={disabled}
      autoFocus
    />
    <Field
      component={TextField}
      name="password"
      type="password"
      required
      validate={validateRequired("Password")}
      margin="normal"
      autoComplete="off"
      label="Password"
      disabled={disabled}
      fullWidth
    />
    <Field
      component={Checkbox}
      name="rememberMe"
      type="checkbox"
      margin="normal"
      label="Remember me"
      disabled={disabled}
    />
    <div>
      <Button
        disabled={disabled}
        type="submit"
        variant="contained"
        color="secondary"
      >
        Sign in
      </Button>
      {error && <FormHelperText error>{error}</FormHelperText>}
      <p>
        <Button size="small" component={AdapterLink} to="/lo/forgotten">
          Don't remember your password?
        </Button>
      </p>
      {authenticating && (
        <LinearProgress color="secondary" className={classes.progress} />
      )}
      <p className={classes.version}>
        <small>version: {REACT_APP_VERSION}</small>
      </p>
    </div>
  </div>
);

SignInForm.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  authenticating: PropTypes.bool,
  error: PropTypes.string
};

export default withStyles(styles)(SignInForm);
