import React, { Fragment, useState } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import Map from "../components/LiveMap";
import { useIdleTimer } from "react-idle-timer";

const IDLE_TIMEOUT = 1000 * 60 * 20;
const DEFAULT_REFRESH_INTERVAL = 10 * 1000;

const QUERY = gql`
  query AllVehicleLive {
    vehicles {
      all(first: 800) {
        edges {
          cursor
          node {
            id
            vrm
            live {
              online
              heading
              driverId
              driver {
                name
                id
              }
              coord {
                lat
                lng
              }
              speed(unit: MPH)
              timestamp
            }
            driver {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const LiveMap = () => {
  const [pause, setPause] = React.useState(false);
  const { data } = useQuery(QUERY, {
    fetchPolicy: "cache-and-network",
    pollInterval: DEFAULT_REFRESH_INTERVAL,
    skip: pause,
  });

  const handleOnIdle = () => {
    setPause(true);
  };

  const handleOnActive = () => {
    setPause(false);
  };

  const {} = useIdleTimer({
    timeout: IDLE_TIMEOUT,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    // onAction: handleOnAction,
    debounce: 500,
  });

  return <Map allVehicles={data?.vehicles.all} />;
};

LiveMap.propTypes = {};

export default LiveMap;
