import React, { useState } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import gql from "graphql-tag";
import { Query } from "@apollo/client/react/components";

import { Field } from "react-final-form";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Select from "../components/form/Select";
import MenuItem from "@mui/material/MenuItem";
import NewDriverDialog from "../containers/NewDriverDialog";

const DRIVERS_QUERY = gql`
  query {
    allDrivers(first: 200, filter: { isActive: true }) {
      edges {
        node {
          id
          name
          telephone
          email
          fobId
        }
      }
    }
  }
`;
export { DRIVERS_QUERY };

const styles = (theme) => ({
  root: {
    display: "flex",
    alignItems: "baseline",
  },
  contactMethod: {
    marginLeft: theme.spacing(2),
  },
});

const DriverSelect = ({
  name,
  classes,
  required,
  allowNew,
  onNewDriverCreated,
  noContacts,
}) => {
  const [newOpen, setNewOpen] = useState(false);
  const fieldName = name;

  const handleNewCreated = ({ id }) => {
    setNewOpen(false);
    onNewDriverCreated && onNewDriverCreated(id);
  };

  const handleNewClick = () => {
    setNewOpen(true);
  };

  const handleNewClose = () => {
    setNewOpen(false);
  };

  return (
    <Query query={DRIVERS_QUERY}>
      {({ loading, error, data }) => {
        return (
          <div className={classes.root}>
            <Field
              component={Select}
              name={fieldName}
              disabled={Boolean(loading || error)}
              label="Driver"
              fullWidth
              required={required}
              helperText={error}
            >
              <MenuItem key="empty" value="">
                <em>None</em>
              </MenuItem>
              {!Boolean(loading || error) &&
                data.allDrivers.edges.map(({ node }) => (
                  <MenuItem key={node.id} value={node.id}>
                    {node.name}
                    {!noContacts && node.telephone && (
                      <small className={classes.contactMethod}>
                        ({node.telephone})
                      </small>
                    )}
                    {!noContacts && node.email && (
                      <small className={classes.contactMethod}>
                        ({node.email.substring(0, 10)}
                        {node.email.length > 10 && "..."})
                      </small>
                    )}
                  </MenuItem>
                ))}
            </Field>

            {allowNew && (
              <Tooltip id="tooltip-fab" title="Add a new driver">
                <IconButton onClick={handleNewClick} size="large">
                  <Icon>add</Icon>
                </IconButton>
              </Tooltip>
            )}
            {allowNew && (
              <NewDriverDialog
                open={newOpen}
                handleClose={handleNewClose}
                onCreated={handleNewCreated}
              />
            )}
          </div>
        );
      }}
    </Query>
  );
};

DriverSelect.propTypes = {
  defaultValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  onNewDriverCreated: PropTypes.func,
  allowNew: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  noContacts: PropTypes.bool,
};

export default withStyles(styles)(DriverSelect);
