import React from "react";
import makeStyles from '@mui/styles/makeStyles';

import NoEventsIcon from "@mui/icons-material/Done";

const useStyles = makeStyles(theme => ({
  noEventsIcon: {
    color: "green"
  }
}));

const DriverSafetyNoEventsContent = () => {
  const classes = useStyles();

  return (
    <p>
      <NoEventsIcon className={classes.noEventsIcon} fontSize="large" /> Looking
      good! There are no events for the given driver and date range.
    </p>
  );
};

DriverSafetyNoEventsContent.propTypes = {};

export default DriverSafetyNoEventsContent;
