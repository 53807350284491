import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import moment from "moment";

import gql from "graphql-tag";
import { Query } from "@apollo/client/react/components";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import SelectLocationMap from "../components/SelectLocationMap";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

// FIXME checkout https://mui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
const withMobileDialog = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="lg" fullScreen={false} />;

const QUERY = gql`
  query VehicleTimesAtLocation($filter: VehicleTimesAtLocationFilter!) {
    vehicles {
      timesAtLocation(filter: $filter) {
        totalCount
        edges {
          node {
            timestamp
            coord {
              lat
              lng
            }
          }
        }
      }
    }
  }
`;

const styles = (theme) => ({
  error: {
    color: theme.palette.error.main,
  },
  mapContainer: {
    minHeight: "200px",
    background: "#ccc",
    marginBottom: theme.spacing(2),
  },
});

class VehicleTimeAtLocationDialog extends React.Component {
  state = { error: null, selectedDt: null };

  render() {
    const { classes, open, fullScreen } = this.props;
    const {
      latLng,
      selectedDt,
      loading,
      data,
      error: errorMessage,
    } = this.state;
    return (
      <Dialog
        open={open}
        fullWidth
        fullScreen={fullScreen}
        onClose={this.handleClose.bind(this)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Find times at location</DialogTitle>
        <DialogContent>
          <DialogContentText id="form-dialog-description">
            Click a location on the map to find when the vehicle was at that
            location.
          </DialogContentText>
          <div className={classes.mapContainer}>
            <SelectLocationMap
              defaultZoom={8}
              defaultCenter={{ lat: -34.397, lng: 150.644 }}
              onLocationSelected={this.handleLocationSelected.bind(this)}
            />
          </div>
          {Boolean(latLng) && (
            <Query
              query={QUERY}
              variables={{
                filter: {
                  vehicleId: this.props.vehicleId,
                  ...latLng,
                },
              }}
            >
              {({ loading, error, data }) => {
                if (error) return JSON.stringify(error);
                if (loading)
                  return (
                    <div>
                      <CircularProgress /> Fetching vehicle data
                    </div>
                  );

                const {
                  vehicles: {
                    timesAtLocation: { edges },
                  },
                } = data;

                if (edges.length === 0)
                  return (
                    <p>
                      No data found at pin drop. Try moving the pin or dropping
                      it in another location.
                    </p>
                  );

                return (
                  <FormControl
                    component="fieldset"
                    required
                    className={classes.formControl}
                  >
                    <FormLabel component="legend">Date and time</FormLabel>
                    <RadioGroup
                      className={classes.group}
                      value={selectedDt}
                      onChange={this.handleSelectionChange.bind(this)}
                    >
                      {edges.map(({ node }, idx) => (
                        <FormControlLabel
                          key={idx}
                          value={node.timestamp}
                          control={<Radio />}
                          label={moment(node.timestamp).format("LLL")}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                );
              }}
            </Query>
          )}
          {loading && (
            <div>
              <CircularProgress /> Fetching vehicle data
            </div>
          )}
          {data && (
            <div>
              <FormControl
                component="fieldset"
                required
                className={classes.formControl}
              >
                <FormLabel component="legend">Time at location</FormLabel>
                <RadioGroup
                  className={classes.group}
                  value={selectedDt}
                  onChange={this.handleSelectionChange.bind(this)}
                >
                  {data.map((node, idx) => (
                    <FormControlLabel
                      key={idx}
                      value={node.dt}
                      control={<Radio />}
                      label={moment(node.dt).format("LLL")}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
          )}
          {errorMessage && <p className={classes.error}>{errorMessage}</p>}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose.bind(this)} color="primary">
            Cancel
          </Button>
          <Button
            disabled={!selectedDt}
            onClick={this.handleSubmit.bind(this)}
            color="primary"
          >
            Use selected
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  handleSelectionChange = (event) => {
    this.setState({ selectedDt: event.target.value });
  };

  handleLocationSelected = (latLng) => {
    this.setState({ latLng });
    // console.log("location selected", latLng);
    // console.warn(`TODO:
    // - Kick off API query for times at location
    // - Display results for selection`);

    // this.setState({ loading: true, data: null, error: null, selectedDt: null });
    // setTimeout(() => {
    //   const data = [
    //     { dt: "2018-06-27T12:06:00Z" },
    //     { dt: "2018-06-21T04:05:00Z" }
    //   ];
    //   console.log("got results");
    //   this.setState({
    //     loading: false,
    //     data,
    //     selectedDt: data[0].dt
    //   });
    // }, 1200);
  };

  handleSubmit = () => {
    const { selectedDt } = this.state;
    this.props.onSelected(selectedDt);
  };

  handleClose = () => {
    this.props.onClose();
  };
}

VehicleTimeAtLocationDialog.propTypes = {
  vehicleId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelected: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog()(
  withStyles(styles)(VehicleTimeAtLocationDialog)
);
