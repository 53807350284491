import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import withStyles from "@mui/styles/withStyles";
import { useLocation, useHistory } from "react-router-dom";
import qs from "querystringify";

import { ACTIVE, isValidKey as isValidStatusValue } from "../IncidentStatus";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import IncidentFilterForm from "../components/IncidentFilterForm";

import IncidentSearch from "../containers/IncidentSearch";
import Fab from "@mui/material/Fab";
import NewIncidentOnClick from "../components/NewIncidentOnClick";

const styles = (theme) => ({
  fab: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  filterRoot: {
    marginBottom: theme.spacing(2),
  },
});

const DEFAULT_FILTERS = {
  status: ACTIVE,
};

const IncidentsPage = ({ classes }) => {
  const { search } = useLocation();
  const history = useHistory();

  const [filters, setFilters] = React.useState({ ...DEFAULT_FILTERS });

  const SanitiseFilters = (value) => {
    let filters = { ...value };

    if (filters.occurredAtGte)
      filters = {
        ...filters,
        occurredAtGte: moment(filters.occurredAtGte).format(),
      };

    if (filters.occurredAtLt)
      filters = {
        ...filters,
        occurredAtLt: moment(filters.occurredAtLt).format(),
      };

    if (!isValidStatusValue(filters.status)) delete filters.status;

    return filters;
  };

  React.useEffect(() => {
    const grossFilters =
      search === "" ? { ...DEFAULT_FILTERS } : qs.parse(search);

    const filters = SanitiseFilters(grossFilters);

    setFilters(filters);
  }, [search]);

  const handleApplyFilters = (form) => {
    history.push(`/incidents?${qs.stringify(form)}`);
  };

  const handleClearFilters = () => {
    history.push(`/incidents?${qs.stringify(DEFAULT_FILTERS)}`);
  };

  return (
    <section>
      <Typography variant="h5" gutterBottom>
        Incidents
      </Typography>

      <IncidentFilterForm
        initialFormValues={filters}
        clear={handleClearFilters}
        apply={handleApplyFilters}
        className={classes.filterRoot}
      />

      <IncidentSearch search={filters} />
      <NewIncidentOnClick>
        {({ onClick }) => (
          <Fab
            onClick={onClick}
            color="primary"
            aria-label="Add"
            className={classes.fab}
          >
            <AddIcon />
          </Fab>
        )}
      </NewIncidentOnClick>
    </section>
  );
};

IncidentsPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IncidentsPage);
