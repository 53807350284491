import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "querystringify";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import moment from "moment";

import Map from "../components/WhichVehicleMap";

const QUERY = gql`
  query WhichVehicleSearch($search: WhichVehicleInput!) {
    vehicles {
      which(search: $search) {
        totalCount
        edges {
          cursor
          node {
            vehicleId
            vehicle {
              vrm
              id
              hasVideoSupport
              driver {
                name
              }
            }
            driver {
              name
            }
            timestamp
            coord {
              lat
              lng
            }
          }
        }
      }
    }
  }
`;

const WhichVehicleMap = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [searchParams, setSearchParams] = React.useState(qs.parse(search));

  React.useEffect(() => {
    setSearchParams(qs.parse(search));
  }, [search, setSearchParams]);

  const [selectedInstanceCursor, setSelectedInstanceCursor] =
    React.useState(null);

  const [queryVariables, setQueryVariables] = React.useState({
    start: moment().startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    end: moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
  });

  React.useEffect(() => {
    const start = (
      (searchParams.start && moment(searchParams.start)) ||
      moment().startOf("day")
    ).format("YYYY-MM-DDTHH:mm:ss");
    const end = (
      (searchParams.end && moment(searchParams.end)) ||
      moment(start).endOf("day")
    ).format("YYYY-MM-DDTHH:mm:ss");

    const lat = searchParams.lat ? Number(searchParams.lat) : undefined;
    const lng = searchParams.lng ? Number(searchParams.lng) : undefined;

    setQueryVariables({
      start,
      end,
      lat,
      lng,
    });
  }, [searchParams]);

  const { data, loading } = useQuery(QUERY, {
    variables: { search: queryVariables },
    fetchPolicy: "cache-and-network",
    skip: !Boolean(queryVariables?.lat),
  });

  const pushQuery = (q) => {
    setSelectedInstanceCursor(null);
    history.push(`${history.location.pathname}?${qs.stringify(q)}`);
  };

  const handleLocationSet = (coords) => {
    if (searchParams?.lat === coords.lat && searchParams?.lng === coords.lng) {
      return;
    }

    const q = {
      ...searchParams,
      ...coords,
    };
    pushQuery(q);
  };

  const handleClearLocation = () => {
    pushQuery({ lat: undefined, lng: undefined });
  };

  const handleDateRangeSet = (form) => {
    if (searchParams.start === form.start && searchParams.end === form.end) {
      return;
    }

    const q = {
      ...searchParams,
      ...form,
    };

    pushQuery(q);
  };

  return (
    <Map
      initialValues={{ start: queryVariables?.start, end: queryVariables?.end }}
      onDateRangeChanged={handleDateRangeSet}
      onLocationSelected={handleLocationSet}
      onClearLocationSelection={handleClearLocation}
      onVehicleInstanceSelected={(cursor) => setSelectedInstanceCursor(cursor)}
      {...{
        vehiclesConnection: data?.vehicles.which,
        fetchingVehicles: loading,
        selectedLocation: {
          lat: queryVariables?.lat,
          lng: queryVariables?.lng,
        },
        selectedInstanceCursor,
      }}
      firstQuery={!Boolean(queryVariables?.lat && queryVariables?.lng)}
    />
  );
};

export default WhichVehicleMap;
