import React, { Fragment } from "react";

import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SwitchTenantButton from "../containers/SwitchTenantButton";
import AdapterNavLink from "../components/form/NavLink";
import Can from "../Can";
import { ADMIN_PAGE_VISIT } from "../Rules";

import { useAuth } from "../AuthContext";
import { PermissionValues } from "../containers/GroupPermissions";

const AppBarUserButton = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { account } = useAuth();
  const [switchTenantOpen, setSwitchTenantOpen] = React.useState(false);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSwitchTenantOpen(false);
    setAnchorEl(null);
  };

  const liveShareTrackingEnabled =
    account?.tenant?.features?.vehiclesEnabled &&
    account?.groupPermissions?.includes(PermissionValues?.LiveShareTracking);

  return (
    <Fragment>
      <IconButton
        aria-owns={Boolean(anchorEl) ? "menu-appbar" : null}
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        size="large"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!switchTenantOpen && (
          <MenuItem
            component={AdapterNavLink}
            to="/account"
            onClick={handleClose}
          >
            My account
          </MenuItem>
        )}
        {!switchTenantOpen && (
          <Can
            role={account && account.role}
            perform={ADMIN_PAGE_VISIT}
            yes={() => (
              <MenuItem
                component={AdapterNavLink}
                to="/admin"
                onClick={handleClose}
              >
                Admin
              </MenuItem>
            )}
          />
        )}

        <SwitchTenantButton onClose={handleClose}>
          {({ click }) =>
            switchTenantOpen ? null : (
              <MenuItem
                onClick={() => {
                  setSwitchTenantOpen(true);
                  click();
                }}
              >
                Switch tenant
              </MenuItem>
            )
          }
        </SwitchTenantButton>
        {!switchTenantOpen && liveShareTrackingEnabled && (
          <MenuItem
            component={AdapterNavLink}
            to="/settings/shared-live-tracking"
            onClick={handleClose}
          >
            Shared live tracking
          </MenuItem>
        )}
        {!switchTenantOpen && (
          <MenuItem
            component={AdapterNavLink}
            to="/logout"
            onClick={handleClose}
          >
            Log out
          </MenuItem>
        )}
      </Menu>
    </Fragment>
  );
};

export default AppBarUserButton;
