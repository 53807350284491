import React from "react";
import { useParams } from "react-router-dom";

import Claim from "../containers/Claim";

const ClaimPage = () => {
  const { id: claimId } = useParams();

  return (
    <section>
      <Claim id={claimId} />
    </section>
  );
};

export default ClaimPage;
