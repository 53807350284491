import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Switch,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CSVLink } from "react-csv";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { mapToDisplay } from "../CameraNames";
import AdapterLink from "../components/form/Link";
import NavigationIcon from "@mui/icons-material/Navigation";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import DashboardAllVehicles from "./DashboardAllVehicles";
import DashboardVehiclesWithIssues from "./DashboardVehiclesForAttention";

const useStyles = makeStyles((theme) => ({
  noPadding: {
    padding: 0,
  },
}));

const HEALTH_ISSUES_QUERY = gql`
  query DashboardVehiclesWithIssues {
    vehicles {
      allWithHealthIssues {
        totalCount
        edges {
          cursor
          node {
            issueType
            vehicle {
              id
              vrm
              device {
                health {
                  dvr {
                    status
                    asAt
                    since
                  }
                  cameras {
                    camera
                    since
                    status
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const VEHICLES_QUERY = gql`
  query DashboardVehicles(
    $first: Int!
    # $after: String
    # $before: String
    $incidentsOccurredGte: DateTimeOffset!
  ) {
    depots {
      all {
        totalCount
        edges {
          cursor
          node {
            id
            name
            vehicles(first: $first) {
              totalCount
              pageInfo {
                hasNextPage
                hasPreviousPage
                endCursor
                startCursor
              }
              edges {
                cursor
                node {
                  id
                  vrm
                  vin
                  hasVideoSupport
                  driver {
                    id
                    name
                  }
                  live {
                    online
                  }
                  incidents(filter: { occurredAtGte: $incidentsOccurredGte }) {
                    totalCount
                  }
                  device {
                    health {
                      status
                      dvr {
                        status
                      }
                      cameras {
                        camera
                        status
                        asAt
                        since
                      }
                    }
                    dataUsage {
                      monthlyAllocation
                      monthToDate {
                        total
                        lastUpdated
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const ROWS_PER_PAGE = 800;

const DashboardVehicles = () => {
  const classes = useStyles();
  const [healthCheckData, setHealthCheckData] = useState([]);
  const [showAllVehicles, setShowAllVehicles] = useState(
    Boolean(localStorage.getItem("dashboard.vehicles.showAll"))
  );
  const filenameExport = `Vehicles for attention ${moment().format(
    "YYYYMMDD-HHMMSS"
  )}.csv`;

  const {
    data: healthData,
    loading: healthLoading,
    error: healthError,
  } = useQuery(HEALTH_ISSUES_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (localStorage.getItem("dashboard.vehicles.showAll") === null) {
      localStorage.setItem("dashboard.vehicles.showAll", "1");
      setShowAllVehicles(true);
    }
  }, []);

  useEffect(() => {
    if (!healthLoading) {
      const healthIssuesData =
        healthData?.vehicles?.allWithHealthIssues?.edges.map(({ node }) => ({
          Registration: node.vehicle.vrm,
          Issue: mapToDisplayIssue(
            node.issueType,
            node.vehicle.device.health.dvr.since,
            node.vehicle.device.health.dvr.asAt
          ),
          Cameras:
            node.issueType === "CAMERA_NOT_RECORDING"
              ? mapToDisplayCameras(
                  node.vehicle.device.health.cameras,
                  node.vehicle.device.health.dvr.since
                ).join("; ")
              : "",
          LastUpdated: moment(node.vehicle.device.health.dvr.asAt).format(
            "lll"
          ),
        }));
      setHealthCheckData(healthIssuesData);
    }
  }, [healthData, healthLoading]);

  const mapToDisplayIssue = (key, since, at) => {
    if (key === "SYSTEM_FAULT")
      return `No recorded footage detected on any camera for 
      ${moment(since).fromNow(true)}`;
    if (key === "CAMERA_NOT_RECORDING") return "No recorded footage detected";
    if (key === "PROLONGED_OFFLINE")
      return `Health check not completed in ${moment(at).fromNow(true)}`;

    return key;
  };

  const mapToDisplayCameras = (cameras, since) => {
    return cameras
      .filter((x) => x.status === "NO_FOOTAGE")
      .map((x) => `${mapToDisplay(x.camera)} (${moment(since).format("ll")})`);
  };

  const handleShowAllToggle = (event) => {
    const showAll = event.target.checked;
    localStorage.setItem("dashboard.vehicles.showAll", showAll ? "1" : "");
    setShowAllVehicles(showAll);
  };

  const incidentsOccurredGte = moment()
    .subtract(1, "day")
    .startOf("day")
    .format();

  const {
    data: vehicleData,
    loading: vehicleLoading,
    error: vehicleError,
    fetchMore: vehicleFetchMore,
  } = useQuery(VEHICLES_QUERY, {
    variables: {
      first: ROWS_PER_PAGE,
      incidentsOccurredGte,
    },
    fetchPolicy: "cache-and-network",
  });

  const vehicleDataExport = vehicleData?.depots?.all?.edges.flatMap(
    ({ node: depot }) =>
      depot?.vehicles?.edges.map(({ node: vehicle }) => ({
        VRM: vehicle.vrm,
        chassis: vehicle.vin,
        depot: depot.name,
      }))
  );

  return (
    <Card>
      <CardHeader
        title={showAllVehicles ? "All vehicles" : "Vehicles for attention"}
        action={
          <div>
            {!healthLoading &&
              !showAllVehicles &&
              healthCheckData?.length > 0 && (
                <FormControlLabel
                  control={
                    <CSVLink
                      data={healthCheckData}
                      filename={filenameExport}
                      className="Button"
                    >
                      <IconButton size="large">
                        <FileDownloadIcon />
                      </IconButton>
                    </CSVLink>
                  }
                  label="Export csv"
                />
              )}

            {!healthLoading &&
              showAllVehicles &&
              vehicleDataExport?.length > 0 && (
                <FormControlLabel
                  control={
                    <CSVLink
                      data={vehicleDataExport}
                      filename={filenameExport}
                      className="Button"
                    >
                      <IconButton size="large">
                        <FileDownloadIcon />
                      </IconButton>
                    </CSVLink>
                  }
                  label="Export csv"
                />
              )}

            <FormControlLabel
              control={
                <Switch
                  checked={showAllVehicles}
                  onChange={handleShowAllToggle}
                  inputProps={{ "aria-label": "Show all vehicles" }}
                />
              }
              label="Show all"
            />

            <IconButton component={AdapterLink} to="/live-map" size="large">
              <NavigationIcon />
            </IconButton>
          </div>
        }
      />
      {!healthLoading && (
        <CardContent className={classes.noPadding}>
          {showAllVehicles ? (
            <DashboardAllVehicles
              loading={vehicleLoading}
              error={vehicleError}
              data={vehicleData}
              fetchMore={vehicleFetchMore}
              incidentsOccurredGte={incidentsOccurredGte}
            />
          ) : (
            <DashboardVehiclesWithIssues
              healthData={healthData}
              healthLoading={healthLoading}
              healthError={healthError}
            />
          )}
        </CardContent>
      )}
    </Card>
  );
};

export default DashboardVehicles;
