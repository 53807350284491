import React from "react";
import PropTypes from "prop-types";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import { Form } from "react-final-form";
import { FORM_ERROR } from "final-form";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import VehicleForm from "../components/VehicleForm";
import { VEHICLES_QUERY } from "../containers/VehicleSelect";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

// FIXME checkout https://mui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
const withMobileDialog = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="lg" fullScreen={false} />;

const UPDATE_VEHICLE = gql`
  mutation UpdateVehicle($input: UpdateVehicleInput!) {
    vehicles {
      update(input: $input) {
        vehicle {
          id
          vrm
          vin
          driverId
          driver {
            id
            name
          }
        }
        error {
          code
          message
        }
      }
    }
  }
`;

const EditVehicleDialog = ({
  open,
  fullScreen,
  vehicle,
  handleClose: onClose,
  onUpdated,
}) => {
  const [updateVehicle, { data, loading, error }] = useMutation(
    UPDATE_VEHICLE,
    {
      refetchQueries: [
        {
          query: VEHICLES_QUERY,
        },
      ],
    }
  );

  const handleSubmit =
    (updateVehicle) =>
    ({ driverId, vehicleId, vrm, vin = "" }) => {
      const variables = { input: { driverId, vehicleId, vrm, vin } };

      return updateVehicle({ variables }).then(
        ({
          data: {
            vehicles: {
              update: { vehicle, error },
            },
          },
        }) => {
          if (!error) {
            onUpdated(vehicle);
            return;
          }

          const message = `${error.message} (${error.code})`;
          return { [FORM_ERROR]: message, vrm: message };
        }
      );
    };

  const handleClose = () => {
    onClose();
  };

  return (
    <Form
      mutators={{
        setFieldValue: ([name, value], state, utils) => {
          utils.changeValue(state, name, () => value);
        },
      }}
      onSubmit={handleSubmit(updateVehicle)}
      initialValues={{
        vehicleId: vehicle.id,
        driverId: vehicle.driverId,
        vrm: vehicle.vrm,
        vin: vehicle.vin,
      }}
      render={({ form, handleSubmit, submitting }) => (
        <Dialog
          open={open}
          fullScreen={fullScreen}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="alert-dialog-title">
            Edit Vehicle
            {handleClose ? (
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
          <DialogContent>
            <VehicleForm setFieldValue={form.mutators.setFieldValue} />
          </DialogContent>
          <DialogActions>
            <Button disabled={submitting} onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              disabled={submitting}
              onClick={handleSubmit}
              color="primary"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    />
  );
};

EditVehicleDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onUpdated: PropTypes.func,
  vehicle: PropTypes.object.isRequired,
};

export default withMobileDialog()(EditVehicleDialog);
