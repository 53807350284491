import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { LinearProgress } from "@mui/material";

import DashboardVehiclesWithHealthIssuesTable from "./DashboardVehiclesWithHealthIssuesTable";
import DashboardVehiclesWithEventsForAttentionTable from "./DashboardVehiclesWithEventsForAttentionTable";

const EVENTS_QUERY = gql`
  query DashboardVehiclesWithEventsForAttention {
    vehicles {
      allWithEventsForAttention {
        totalCount
        edges {
          cursor
          node {
            __typename
            ... on VehicleWithIncidentEvent {
              incident {
                id
                reference
                occurredAt
                status
                location
                driver {
                  id
                  name
                }
              }
            }
            vehicle {
              id
              vrm
            }
          }
        }
      }
    }
  }
`;

const DashboardVehiclesWithIssues = ({ healthData, healthLoading }) => {
  const {
    data: eventsData,
    loading: eventsLoading,
    error: eventsError,
  } = useQuery(EVENTS_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  return (
    <>
      {(eventsLoading || healthLoading) && <LinearProgress variant="query" />}
      <DashboardVehiclesWithEventsForAttentionTable
        {...{ data: eventsData, loading: eventsLoading, error: eventsError }}
      />
      <DashboardVehiclesWithHealthIssuesTable
        {...{
          data: healthData,
        }}
      />
    </>
  );
};

DashboardVehiclesWithIssues.propTypes = {};

export default DashboardVehiclesWithIssues;
