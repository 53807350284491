import React from "react";
import { makeStyles } from "@mui/styles";
import { useAuth } from "../AuthContext";
import classNames from "classnames";
import AppBar from "./AppBar";
import Typography from "@mui/material/Typography";

const DRAWER_WIDTH = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "stretch",
    minHeight: "100vh",
    width: "100%",
  },
  appFrame: {
    position: "relative",
    display: "flex",
    width: "100%",
  },
  flexBoxContent: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  content: {
    backgroundColor: theme.palette.background.default,
    width: "100%",
    marginTop: 56,
    [theme.breakpoints.up("sm")]: {
      marginTop: 64,
    },
  },
  children: {
    margin: theme.spacing(3),
  },
}));

const RestrictLayout = ({ children }) => {
  const classes = useStyles();
  const { isAuthenticated, account } = useAuth();

  return (
    <div className={classes.root}>
      <div className={classes.appFrame}>
        <AppBar isAuthenticated={isAuthenticated} account={account} />
        <div className={classNames(classes.content, classes.flexBoxContent)}>
          <Typography component="div" className={classes.flexBoxContent}>
            <div
              className={classNames(classes.children, classes.flexBoxContent)}
            >
              {children}
            </div>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default RestrictLayout;
