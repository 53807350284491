import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import Switch from "../components/form/Switch";
import { Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { Form } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

const UPDATE_PREFERENCES = gql`
  mutation updateEmailPreferences($input: UpdateEmailPreferencesInput!) {
    users {
      updateEmailPreferences(input: $input) {
        error {
          code
          message
        }
        user {
          id
          emailPreferences {
            eventButtonIncidents
            newNearMissIsRecorded
          }
        }
      }
    }
  }
`;

const EditUserEmailPreferences = ({
  userId,
  emailPreferences,
  nearMissEnabled,
}) => {
  const [updateEmailPreferences] = useMutation(UPDATE_PREFERENCES);

  const handleSubmit = async (form) => {
    const variables = {
      input: {
        userId,
        ...form,
      },
    };

    const {
      data: {
        users: {
          updateEmailPreferences: { error },
        },
      },
    } = await updateEmailPreferences({ variables });
    if (!error) {
      return;
    }
    const message_1 = `${error.message} (${error.code})`;
    return { [FORM_ERROR]: message_1, vrm: message_1 };
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        eventButtonIncidents: emailPreferences.eventButtonIncidents,
        newNearMissIsRecorded: emailPreferences.newNearMissIsRecorded,
      }}
      render={({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          <Stack spacing={1} sx={{ ml: 2 }}>
            <Box>
              <Field
                component={Switch}
                name="eventButtonIncidents"
                type="checkbox"
                margin="normal"
                disabled={submitting}
                label="When someone presses the event button"
              />
              <OnChange name="eventButtonIncidents">
                {(value, previous) =>
                  value !== previous && handleSubmit(values)
                }
              </OnChange>
            </Box>
            {nearMissEnabled && (
              <Box>
                <Field
                  component={Switch}
                  name="newNearMissIsRecorded"
                  type="checkbox"
                  margin="normal"
                  disabled={submitting}
                  label="When a new near miss is recorded"
                />
                <OnChange name="newNearMissIsRecorded">
                  {(value, previous) =>
                    value !== previous && handleSubmit(values)
                  }
                </OnChange>
              </Box>
            )}
          </Stack>
        </form>
      )}
    />
  );
};

EditUserEmailPreferences.propTypes = {
  userId: PropTypes.string.isRequired,
  emailPreferences: PropTypes.object.isRequired,
};

export default EditUserEmailPreferences;
