import React from "react";

import { Field } from "react-final-form";
import TextField from "../components/form/TextField";
import Select from "../components/form/Select";
import MenuItem from "@mui/material/MenuItem";

const required = value => !value && "Required";

const CloseIncidentForm = () => (
  <div>
    <Field
      component={Select}
      name="partyAtFault"
      label="Who was at fault?"
      fullWidth
      validate={required}
    >
      <MenuItem value="THIRD_PARTY">3rd party</MenuItem>
      <MenuItem value="DRIVER">Driver</MenuItem>
      <MenuItem value="FIFTY_FIFTY">50/50</MenuItem>
      <MenuItem value="NONE">No one</MenuItem>
    </Field>
    <Field
      component={TextField}
      name="reason"
      validate={required}
      margin="normal"
      autoComplete="off"
      label="Reason for closing the incident"
      fullWidth
      // autoFocus
    />
  </div>
);

CloseIncidentForm.propTypes = {};

export default CloseIncidentForm;
