import gql from "graphql-tag";
import { Fragment } from "./Query";

export const CommentMutation = gql`
  mutation ($nearMissId: ID!, $mdText: String!) {
    nearMisses {
      comment(input: { nearMissId: $nearMissId, mdText: $mdText }) {
        error {
          code
          message
        }
        nearMiss {
          id
          ...nearMiss
        }
      }
    }
  }
  ${Fragment}
`;

export const LocationMutation = gql`
  mutation ($nearMissId: ID!, $location: String!) {
    nearMisses {
      update(input: { id: $nearMissId, location: $location }) {
        error {
          code
          message
        }
        nearMiss {
          id
          ...nearMiss
        }
      }
    }
  }
  ${Fragment}
`;

export const DescriptionMutation = gql`
  mutation ($nearMissId: ID!, $description: String!) {
    nearMisses {
      update(input: { id: $nearMissId, description: $description }) {
        error {
          code
          message
        }
        nearMiss {
          id
          ...nearMiss
        }
      }
    }
  }
  ${Fragment}
`;

export const StatusCategoryDepotMutation = gql`
  mutation ($input: UpdateNearMissInput!) {
    nearMisses {
      update(input: $input) {
        error {
          code
          message
        }
        nearMiss {
          id
          ...nearMiss
        }
      }
    }
  }
  ${Fragment}
`;

export const AttachEvidenceMutation = gql`
  mutation ($nearMissId: ID!, $contentType: String!, $objectKey: String!) {
    nearMisses {
      attachEvidence(
        input: {
          nearMissId: $nearMissId
          contentType: $contentType
          objectKey: $objectKey
        }
      ) {
        error {
          code
          message
        }
        nearMiss {
          id
          ...nearMiss
        }
      }
    }
  }
  ${Fragment}
`;

export const RemoveAttachEvidenceMutation = gql`
  mutation ($attachEvidenceId: ID!) {
    nearMisses {
      removeAttachEvidence(id: $attachEvidenceId) {
        error {
          code
          message
        }
      }
    }
  }
`;
