import React, { useState } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import classnames from "classnames";

import GoogleMap from "../containers/GoogleMap";
import { DEFAULT_CENTER } from "../containers/GoogleMap";
import { Marker, Autocomplete } from "@react-google-maps/api";

import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Form, Field } from "react-final-form";
import moment from "moment";
import InputText from "@mui/material/TextField";

import MapCustomControl from "../components/MapCustomControl";
import TextField from "../components/form/TextField";
import WhichVehicleList from "../components/WhichVehicleList";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import VehicleFootageSearchDialog from "../containers/VehicleFootageSearchDialog";
import { Box } from "@mui/material";
import { randomColour } from "../utils";
import WhichVehicleDrawer from "../components/WhichVehicleDrawer";
import WhichVehicleInfoWindow from "../components/WhichVehicleInfoWindow";

const styles = (theme) => ({
  root: {
    maxHeight: "calc(100% - 48px)",
    overflow: "auto",
    display: "flex",
  },
  mapControlRoot: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  padding: {
    padding: theme.spacing(1),
  },
  form: {
    marginBottom: theme.spacing(2),
  },
  toolbar: {
    display: "flex",
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarIcon: {
    color: "inherit",
  },
  findFootageButton: {
    padding: 0,
    textTransform: "none",
    fontWeight: "normal",
  },
  submitButton: {
    display: "none",
  },
  videoIcon: {
    marginRight: theme.spacing(1),
  },
  collapseButton: {
    marginTop: theme.spacing(1),
    width: 24,
    height: 48,
    display: "grid",
    alignContent: "center",
    border: "1px solid #D4D4D4",
    backgroundColor: "white",
  },
  loading: {
    height: "auto",
    minHeight: 48,
  },
});

var vehicleCount = 0;
var colors = {};
const initWidth = 313;

const WhichVehicleMap = ({
  selectedLocation,
  selectedInstanceCursor,
  onLocationSelected,
  onDateRangeChanged,
  onClearLocationSelection,
  onVehicleInstanceSelected,
  initialValues,
  fetchingVehicles,
  vehiclesConnection,
  classes,
  firstQuery,
}) => {
  const [mapRef, setMapRef] = useState(null);
  const [markerMap, setMarkerMap] = useState({});
  const [autocomplete, setAutocomplete] = useState();
  const [openDrawer, setOpenDrawer] = React.useState(true);
  const [width, setWidth] = React.useState(initWidth);

  const loadHandler = (map) => {
    setMapRef(map);
  };

  const data = vehiclesConnection?.edges.reduce((acc, curr) => {
    var existing = acc.find((x) => x.vehicle.id === curr.node.vehicle.id);
    if (!existing) {
      return [...acc, { vehicle: curr.node.vehicle, edges: [curr] }];
    }

    existing.edges = [...existing.edges, curr];

    return acc;
  }, []);

  data?.map((value, idx) => {
    if (vehicleCount !== data.length) {
      if (idx === 0) vehicleCount = 0;
      let color = randomColour();
      colors[value.vehicle.vrm] = color;
      value.color = color;
      vehicleCount++;
    } else {
      value.color = colors[value.vehicle.vrm];
    }
    return data;
  });

  var icon = (color) => {
    return {
      path: "M 8.460938 0 L 10.085938 0 C 14.179688 0.441406 17.738281 3.777344 18 7.851562 L 18 9.382812 C 17.347656 12.722656 15.09375 15.414062 13.3125 18.234375 C 11.398438 21.164062 9.898438 24.386719 9.238281 27.816406 C 8.546875 25.277344 7.699219 22.75 6.386719 20.441406 C 4.554688 17.035156 1.867188 14.070312 0.527344 10.417969 C -0.898438 5.515625 3.351562 0.28125 8.460938 0 M 8.199219 5.457031 C 5.765625 6.089844 5.308594 9.746094 7.554688 10.894531 C 9.398438 12.214844 12.273438 10.667969 12.261719 8.484375 C 12.488281 6.441406 10.152344 4.773438 8.199219 5.457031 Z M 8.199219 5.457031 ",
      fillColor: color,
      fillOpacity: 0.8,
      anchor: new window.google.maps.Point(10.5, 30),
    };
  };

  const [defaultCenter, setDefaultCenter] = React.useState({
    ...selectedLocation,
    ...DEFAULT_CENTER,
  });
  const [defaultZoom, setDefaultZoom] = React.useState(7);
  const [footageSearch, setFootageSearch] = React.useState();

  const initialFormValues = {
    date:
      (initialValues?.start &&
        moment(initialValues.start).format("YYYY-MM-DD")) ||
      "",
    start:
      (initialValues?.start && moment(initialValues.start).format("HH:mm")) ||
      "",
    end:
      (initialValues?.end && moment(initialValues.end).format("HH:mm")) || "",
  };

  const handleCloseVehicleInfoWindow = () => {
    onVehicleInstanceSelected(null);
  };

  const handleFormSubmit = (form) => {
    onDateRangeChanged({
      start: `${form.date}T${form.start}`,
      end: `${form.date}T${form.end}`,
    });
  };

  const handleClearLocationClicked = () => {
    onClearLocationSelection();
  };

  const handleClickMap = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    onLocationSelected({ lat, lng });
  };

  const handleVehicleMarkerClicked = (cursor) => {
    onVehicleInstanceSelected(cursor);
  };

  const handleFootageSearchOpen = (value) => {
    setFootageSearch({
      id: value.id,
      date: moment(value.timestamp).format("YYYY-MM-DD"),
      from: moment(value.timestamp).format("HH:mm"),
      until: moment(value.timestamp).format("HH:mm"),
    });
  };

  const handleFootageSearchClose = () => {
    setFootageSearch(null);
  };

  const markerLoadHandler = (marker, place) => {
    return setMarkerMap((prevState) => {
      return { ...prevState, [place.cursor]: marker };
    });
  };

  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    const place = autocomplete.getPlace();
    const location = place && place.geometry && place.geometry.location;

    if (location) {
      const newLocation = {
        lat: location.lat(),
        lng: location.lng(),
      };
      setDefaultCenter(newLocation);
      setDefaultZoom(15);
      onLocationSelected(newLocation);
    }
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
    setWidth("0px");
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
    setTimeout(() => setWidth(initWidth), 200);
  };

  return (
    <GoogleMap
      loadHandler={loadHandler}
      defaultZoom={defaultZoom}
      center={defaultCenter}
      options={{ mapTypeControl: false }}
      handleClick={handleClickMap}
    >
      {mapRef && (
        <MapCustomControl
          mapHolderRef={mapRef}
          controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
        >
          <div className={classes.root}>
            <Paper
              className={classes.mapControlRoot}
              style={{ width: width, height: "100%" }}
            >
              <WhichVehicleDrawer
                className={classes.drawer}
                openDrawer={openDrawer}
                handleDrawerClose={handleDrawerClose}
              >
                <div className={classes.padding}>
                  <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                    <InputText
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      type="text"
                      placeholder="Search Google Maps"
                    />
                  </Autocomplete>
                </div>

                <Form
                  onSubmit={handleFormSubmit}
                  initialValues={initialFormValues}
                  render={({ handleSubmit }) => (
                    <form
                      onSubmit={handleSubmit}
                      className={classnames(classes.form, classes.padding)}
                    >
                      <Grid container spacing={2} className={classes.grid}>
                        <Grid item xs={12}>
                          <Field
                            id={"dateFilter"}
                            className={classes.time}
                            component={TextField}
                            fullWidth
                            name="date"
                            margin="dense"
                            required
                            label="Date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            type="date"
                            onBlur={handleSubmit}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            id={"startFilter"}
                            className={classes.time}
                            component={TextField}
                            name="start"
                            margin="dense"
                            fullWidth
                            required
                            label="Start"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            type="time"
                            onBlur={handleSubmit}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            id={"endFilter"}
                            className={classes.time}
                            component={TextField}
                            name="end"
                            margin="dense"
                            fullWidth
                            required
                            label="End"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            type="time"
                            onBlur={handleSubmit}
                          />
                        </Grid>
                      </Grid>
                      <button type="submit" className={classes.submitButton}>
                        submit
                      </button>
                    </form>
                  )}
                />

                {firstQuery && (
                  <p className={classes.padding}>
                    Click a location on the map to find vehicles at that
                    location within the selected timeframe
                  </p>
                )}
                {Boolean(fetchingVehicles || vehiclesConnection) &&
                  !firstQuery && (
                    <AppBar position="static">
                      <Toolbar variant="dense" className={classes.toolbar}>
                        <Typography className={classes.toolbarTitle}>
                          Vehicles
                        </Typography>
                        <IconButton
                          onClick={handleClearLocationClicked}
                          className={classes.toolbarIcon}
                          size="large"
                        >
                          <Icon>close</Icon>
                        </IconButton>
                      </Toolbar>
                    </AppBar>
                  )}

                {Boolean(fetchingVehicles && !firstQuery) && (
                  <Box textAlign="center" className={classes.loading}>
                    <CircularProgress />
                  </Box>
                )}

                {Boolean(
                  !fetchingVehicles &&
                    vehiclesConnection?.totalCount === 0 &&
                    !firstQuery
                ) && <p className={classes.padding}>No vehicles found</p>}
                {Boolean(
                  !fetchingVehicles &&
                    vehiclesConnection?.totalCount > 0 &&
                    !firstQuery
                ) && (
                  <WhichVehicleList
                    vehicles={data}
                    searchDate={moment(initialValues.start).format(
                      "YYYY-MM-DD"
                    )}
                    selectedVehicleInstance={selectedInstanceCursor}
                    onVehicleInstanceSelected={handleVehicleMarkerClicked}
                  />
                )}
              </WhichVehicleDrawer>
            </Paper>
            <div
              className={classes.collapseButton}
              onClick={openDrawer ? handleDrawerClose : handleDrawerOpen}
            >
              {openDrawer ? <Icon>arrow_left</Icon> : <Icon>arrow_right</Icon>}
            </div>
          </div>
        </MapCustomControl>
      )}
      {Boolean(
        !fetchingVehicles && vehiclesConnection?.totalCount > 0 && !firstQuery
      ) &&
        vehiclesConnection.edges.map((edge) => {
          const vehicleColor = data.find(
            (x) => x.vehicle.id === edge.node.vehicleId
          ).color;

          return (
            <Marker
              key={edge.cursor}
              position={edge.node.coord}
              icon={icon(vehicleColor)}
              onClick={() => handleVehicleMarkerClicked(edge.cursor)}
              onLoad={(marker) => markerLoadHandler(marker, edge)}
            >
              {selectedInstanceCursor === edge.cursor && (
                <WhichVehicleInfoWindow
                  edge={edge}
                  onCloseWhichVehicleInfoWindow={handleCloseVehicleInfoWindow}
                  onFootageSearch={handleFootageSearchOpen}
                  markerMap={markerMap}
                />
              )}
            </Marker>
          );
        })}
      {selectedLocation?.lat && (
        <Marker
          position={selectedLocation}
          draggable
          onDragEnd={handleClickMap}
        />
      )}
      {footageSearch && !firstQuery && (
        <VehicleFootageSearchDialog
          open={Boolean(footageSearch)}
          vehicleId={footageSearch && footageSearch.id}
          onClose={handleFootageSearchClose}
          date={footageSearch && footageSearch.date}
          from={footageSearch && footageSearch.from}
          until={footageSearch && footageSearch.until}
        />
      )}
    </GoogleMap>
  );
};

WhichVehicleMap.propTypes = {
  onLocationSelected: PropTypes.func.isRequired,
  onDateRangeChanged: PropTypes.func.isRequired,
  onVehicleInstanceSelected: PropTypes.func.isRequired,
  onClearLocationSelection: PropTypes.func.isRequired,
  selectedLocation: PropTypes.object,
  selectedInstanceCursor: PropTypes.string,
  initialValues: PropTypes.object,
  fetchingVehicles: PropTypes.bool,
  vehiclesConnection: PropTypes.object,
};

export default withStyles(styles)(WhichVehicleMap);
