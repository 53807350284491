import React from "react";
import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';

import EventItem from "./EventItem";
import EventDetailDialog from "./EventDetailDialog";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const EventList = ({
  eventsConnection,
  onStarClicked,
  onUnstarClicked,
  onSetStatusClicked,
  onFetchFootageClicked,
}) => {
  const classes = useStyles();
  const [selectedEventId, setSelectedEventId] = React.useState(null);
  const [selectedEvent, setSelectedEvent] = React.useState(null);

  const handleExpandEventClicked = (safetyEvent) => {
    setSelectedEventId(safetyEvent.id);
  };

  const handleClosePlayer = () => {
    setSelectedEventId(null);
  };

  React.useEffect(() => {
    if (!selectedEventId) {
      setSelectedEvent(null);
      return;
    }

    const selectedEvent = eventsConnection.edges.filter(
      ({ node }) => node.id === selectedEventId
    )[0]?.node;
    setSelectedEvent(selectedEvent);
  }, [selectedEventId, eventsConnection]);

  return (
    <div className={classes.root}>
      {eventsConnection.edges.map(({ node, cursor }) => (
        <EventItem
          key={cursor}
          node={node}
          onStarClicked={onStarClicked}
          onUnstarClicked={onUnstarClicked}
          onSetStatusClicked={onSetStatusClicked}
          onFetchFootageClicked={onFetchFootageClicked}
          onExpandEventClicked={handleExpandEventClicked}
        />
      ))}
      <EventDetailDialog
        open={Boolean(selectedEvent)}
        onClose={handleClosePlayer}
        safetyEvent={selectedEvent}
        onSetStatus={onSetStatusClicked}
      />
    </div>
  );
};

EventList.propTypes = {
  eventsConnection: PropTypes.object.isRequired,
  onStarClicked: PropTypes.func.isRequired,
  onUnstarClicked: PropTypes.func.isRequired,
  onSetStatusClicked: PropTypes.func.isRequired,
  onFetchFootageClicked: PropTypes.func.isRequired,
};

export default EventList;
