import React from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import moment from "moment";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { fuzzyFromMb } from "../FuzzyData";
import classNames from "classnames";
import {
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import { red, orange, amber, green } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  kpi: {
    fontSize: "3em",
    textAlign: "center",
    margin: 0,
  },
  small: {
    fontSize: "0.3em",
    display: "inline-block",
    paddingLeft: theme.spacing(2),
    color: theme.palette.text.hint,
  },
  cardContent: {
    paddingTop: 0,
  },
  progress: {
    height: theme.spacing(4),
  },
  progressOkColour: {
    backgroundColor: green[50],
  },
  progressOkBarColour: {
    backgroundColor: green[200],
  },
  progressWarningColour: {
    backgroundColor: amber[50],
  },
  progressWarningBarColour: {
    backgroundColor: amber[200],
  },
  progressWarning2Colour: {
    backgroundColor: orange[50],
  },
  progressWarning2BarColour: {
    backgroundColor: orange[200],
  },
  progressOverusageColour: {
    backgroundColor: red[50],
  },
  progressOverusageBarColour: {
    backgroundColor: red[200],
  },
}));

const WARNING_PERCENT = 55;
const WARNING2_PERCENT = 70;
const OVER_PERCENT = 95;
export { WARNING_PERCENT, WARNING2_PERCENT, OVER_PERCENT };

const QUERY = gql`
  query DataUsage {
    dataUsage {
      data {
        includedMegaBytes
        lastUpdated
        totalMegaBytes
      }
    }
  }
`;

const DashboardDataUsageCard = ({}) => {
  const classes = useStyles();
  const { loading, error, data } = useQuery(QUERY, {
    fetchPolicy: "cache-and-network",
  });

  const percentageUsed = !Boolean(data?.dataUsage?.data)
    ? 0
    : data.dataUsage
    ? Math.min(
        100,
        (data.dataUsage.data.totalMegaBytes /
          data.dataUsage.data.includedMegaBytes) *
          100
      )
    : 0;

  return (
    <Card>
      <CardHeader
        title="Data usage"
        action={
          <Tooltip
            title={`Percentage of monthly data allocation used - Last updated ${
              Boolean(data?.dataUsage?.data)
                ? moment(data.dataUsage.data.lastUpdated).format("lll")
                : "Unknown"
            }`}
          >
            <InfoIcon style={{ color: "#ccc" }} />
          </Tooltip>
        }
      />
      <CardContent className={classes.cardContent}>
        {loading && (!data || !data.dataUsage) && (
          <p className={classes.kpi}>...</p>
        )}

        {!Boolean(error) && data && data.dataUsage && !data.dataUsage.data && (
          <p className={classes.kpi}>-</p>
        )}

        {error && <p className={classes.kpi}>err</p>}

        {!Boolean(error) && data && data.dataUsage && data.dataUsage.data && (
          <div className={classes.kpi}>
            <LinearProgress
              variant="determinate"
              value={percentageUsed}
              classes={{
                colorPrimary: classNames(classes.progressOkColour, {
                  [classes.progressWarningColour]:
                    percentageUsed > WARNING_PERCENT,
                  [classes.progressWarning2Colour]:
                    percentageUsed > WARNING2_PERCENT,
                  [classes.progressOverusageColour]:
                    percentageUsed >= OVER_PERCENT,
                }),
                barColorPrimary: classNames(classes.progressOkBarColour, {
                  [classes.progressWarningBarColour]:
                    percentageUsed > WARNING_PERCENT,
                  [classes.progressWarning2BarColour]:
                    percentageUsed > WARNING2_PERCENT,
                  [classes.progressOverusageBarColour]:
                    percentageUsed >= OVER_PERCENT,
                }),
              }}
              className={classes.progress}
            />
            <Tooltip
              title={`${
                fuzzyFromMb(data.dataUsage.data.totalMegaBytes).formatted
              } of ${
                fuzzyFromMb(data.dataUsage.data.includedMegaBytes).formatted
              }`}
            >
              <span>{percentageUsed.toFixed(0)}%</span>
            </Tooltip>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

DashboardDataUsageCard.propTypes = {};

export default DashboardDataUsageCard;
