import React, { useState, useLayoutEffect, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import LinearProgress from "@mui/material/LinearProgress";
import { useQuery } from "@apollo/client";

import PropTypes from "prop-types";
import VehicleTrackingVideoStream from "../containers/VehicleTrackingVideoStream";
import FootageQuery from "../queries/VehicleTrackingFootageQuery";
import classNames from "classnames";

const VIDEO_QUERY = FootageQuery;

const useStyles = makeStyles((theme) => ({
  videoContent: {
    display: "grid",
    height: "100%",
    gridGap: theme.spacing(1),
  },
  loadingVideoText: {
    position: "absolute",
    overflowWrap: "break-word",
  },
  videoContent1i: {
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(1, minmax(550px, 1fr))",
    },
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "repeat(1, minmax(935px, 1fr))",
    },
  },
  videoContent2i: {
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(1, minmax(380px, 1fr))",
    },
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "repeat(1, minmax(455px, 1fr))",
    },
  },
  videoContent4i1x4: {
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(1, minmax(190px, 1fr))",
    },
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "repeat(1, minmax(224px, 1fr))",
    },
  },
  videoContent4i2x2: {
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(2, minmax(290px, 1fr))",
    },
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "repeat(2, minmax(458px, 1fr))",
    },
  },
  videoContent6i2x4: {
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(2, minmax(216px, 1fr))",
    },
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "repeat(2, minmax(300px, 1fr))",
    },
  },
  videoContent6i2x3: {
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(2, minmax(216px, 1fr))",
    },
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "repeat(2, minmax(260px, 1fr))",
    },
  },

  videoContent6i3x2: {
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(2, minmax(216px, 1fr))",
    },
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "repeat(3, minmax(320px, 1fr))",
    },
  },

  videoContent9i2x4: {
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(2, minmax(190px, 1fr))",
    },
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "repeat(2, minmax(218px, 1fr))",
    },
  },

  videoContent9i3x3: {
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(3, minmax(190px, 1fr))",
    },
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "repeat(3, minmax(260px, 1fr))",
    },
  },
  videoContent12i: {
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(3, minmax(190px, 1fr))",
    },
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "repeat(3, minmax(218px, 1fr))",
    },
  },
  videoContentMobile: {},
}));

const VehicleTrackingVideos = ({
  isMobile,
  vehicleIsOnline,
  vehicleCameras,
  paneResizer,
  footageSearchTracking,
  onHiddenPaneResizer,
}) => {
  const classes = useStyles();
  const [queryVariables, setQueryVariables] = useState(null);

  const cameraLength = vehicleCameras?.length || 0;

  useEffect(() => {
    if (cameraLength < 3 || cameraLength > 9) {
      onHiddenPaneResizer(true);
    }

    if (!footageSearchTracking) return;

    setQueryVariables(footageSearchTracking);
  }, [footageSearchTracking, cameraLength, setQueryVariables]);

  const readyForQuery = Boolean(
    queryVariables && queryVariables.from && queryVariables.until
  );

  const { loading, error, data } = useQuery(VIDEO_QUERY, {
    fetchPolicy: "cache-and-network",
    skip: !readyForQuery,
    notifyOnNetworkStatusChange: true,
    variables: queryVariables,
  });

  return (
    <>
      <div
        className={classNames(
          classes.videoContent,
          {
            [classes.videoContent1i]: cameraLength == 1,
            [classes.videoContent2i]: cameraLength == 2,
            [classes.videoContent4i1x4]:
              !paneResizer && 2 < cameraLength && cameraLength <= 4,
            [classes.videoContent4i2x2]:
              paneResizer && 2 < cameraLength && cameraLength <= 4,
            [classes.videoContent6i2x3]:
              !paneResizer && 4 < cameraLength && cameraLength <= 6,
            [classes.videoContent6i3x2]:
              paneResizer && 4 < cameraLength && cameraLength <= 6,
            [classes.videoContent9i2x4]:
              !paneResizer && 6 < cameraLength && cameraLength <= 9,
            [classes.videoContent9i3x3]:
              paneResizer && 6 < cameraLength && cameraLength <= 9,
            [classes.videoContent12i]: 9 < cameraLength,
          },
          {
            [classes.videoContentMobile]: isMobile,
          }
        )}
      >
        {loading && !error && (
          <div className={classes.loadingVideoText}>
            <p>
              Attempting to find footage on the device...
              <br />
              <small>
                Please note, the vehicle must be online to retrieve footage
              </small>
            </p>
            <LinearProgress />
          </div>
        )}
        {error && (
          <div className={classes.loadingVideoText}>
            <p>
              Failed to connect to device, vehicle may be in a low signal area.
            </p>
          </div>
        )}

        {!error && !loading && (
          <VehicleTrackingVideoStream
            {...{
              isDeviceOnline: vehicleIsOnline,
              cameraVideo: vehicleCameras,
              footageTracking: data?.node?.footage?.edges,
            }}
          />
        )}
      </div>
    </>
  );
};

VehicleTrackingVideos.propTypes = {
  vehicleCameras: PropTypes.array.isRequired,
};

export default VehicleTrackingVideos;
