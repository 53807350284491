import React from "react";
import SettingsPanel from "./IntegrationSettingsPanel/SettingsPanel";
import useIntegrationSettings from "./useIntegrationSettings";

const IntegrationPanel = () => {
  const { loading, data, error } = useIntegrationSettings();

  const tomTom = data && data.integrations.tomTom;
  const settings = tomTom && tomTom.settings;
  const status = tomTom && tomTom.status;

  return <SettingsPanel {...{ status, error, settings, loading }} />;
};

IntegrationPanel.propTypes = {};

export default IntegrationPanel;
