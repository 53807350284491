import React, { Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";

import { Field } from "react-final-form";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "../components/form/TextField";
import Switch from "../components/form/Switch";

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

const ThirdPartyDetailForm = ({
  fieldName,
  value,
  src,
  classes,
  formValues,
  idx,
}) => (
  <div>
    <Grid container spacing={2}>
      <Grid item md={12}>
        <Field
          component={TextField}
          name={`${fieldName}.detailsOfDamage`}
          margin="normal"
          label="Details of damage"
          multiline
          maxRows={3}
          fullWidth
        />
      </Grid>
      <Grid item md={6}>
        <Field
          component={TextField}
          name={`${fieldName}.driverName`}
          margin="normal"
          label="Driver name"
          fullWidth
        />
      </Grid>
      <Grid item md={6}>
        <Field
          component={TextField}
          name={`${fieldName}.ownerName`}
          margin="normal"
          label="Owner name"
          fullWidth
        />
      </Grid>
      <Grid item md={6}>
        <Field
          component={TextField}
          name={`${fieldName}.email`}
          type="email"
          margin="normal"
          label="Email address"
          fullWidth
        />
      </Grid>
      <Grid item md={6}>
        <Field
          component={TextField}
          name={`${fieldName}.address`}
          margin="normal"
          label="Home address"
          fullWidth
        />
      </Grid>
      <Grid item md={4}>
        <Field
          component={TextField}
          name={`${fieldName}.telephoneNumber`}
          margin="normal"
          label="Telephone number"
          fullWidth
        />
      </Grid>
      <Grid item md={4}>
        <Field
          component={TextField}
          name={`${fieldName}.vehicleMake`}
          margin="normal"
          label="Vehicle make"
          fullWidth
        />
      </Grid>
      <Grid item md={4}>
        <Field
          component={TextField}
          name={`${fieldName}.vehicleRegistration`}
          margin="normal"
          label="Vehicle registration"
          fullWidth
        />
      </Grid>
    </Grid>
    <Typography variant="subtitle1" gutterBottom>
      Insurer
    </Typography>
    <Grid container spacing={2}>
      <Grid item md={6}>
        <Field
          component={TextField}
          name={`${fieldName}.insurer.name`}
          margin="normal"
          label="Name"
          fullWidth
        />
      </Grid>
      <Grid item md={6}>
        <Field
          component={TextField}
          name={`${fieldName}.insurer.policyNumber`}
          margin="normal"
          label="Policy number"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} lg={12}>
        <Field
          component={Switch}
          name={`${fieldName}.injuries.any`}
          margin="normal"
          label="Injured"
          type="checkbox"
        />
      </Grid>
      {((formValues.thirdParties[idx] || {}).injuries || {}).any && (
        <Fragment>
          <Grid item xs={12} lg={6}>
            <Field
              component={TextField}
              name={`${fieldName}.injuries.details`}
              margin="normal"
              label="Details"
              multiline
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <Field
              component={Switch}
              name={`${fieldName}.injuries.takenToHospital`}
              margin="normal"
              label="Taken to hospital"
              type="checkbox"
            />
          </Grid>
          {((formValues.thirdParties[idx] || {}).injuries || {})
            .takenToHospital && (
            <Grid item xs={12} lg={6}>
              <Field
                component={TextField}
                name={`${fieldName}.injuries.hospitalName`}
                margin="normal"
                label="Hospital name"
                multiline
                fullWidth
              />
            </Grid>
          )}
        </Fragment>
      )}
    </Grid>
  </div>
);

ThirdPartyDetailForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ThirdPartyDetailForm);
