import React from "react";
import Typography from "@mui/material/Typography";
import ClaimSearch from "../containers/ClaimSearch";

const ClaimsPage = () => (
  <section>
    <Typography variant="h5" gutterBottom>
      Claims
    </Typography>

    <ClaimSearch />
  </section>
);

export default ClaimsPage;
