import React from "react";
import gql from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { execute as recaptcha } from "../recaptcha";

import { Form } from "react-final-form";
import ForgottenPasswordForm from "../components/ForgottenPasswordForm";

const RESET_PASSWORD = gql`
  mutation RequestPasswordReset($input: RequestPasswordResetInput!) {
    users {
      requestPasswordReset(input: $input) {
        error {
          code
          message
        }
      }
    }
  }
`;

class ForgottenPassword extends React.Component {
  state = {
    error: null,
    submitted: false,
  };

  render() {
    const { submitted, error } = this.state;

    return (
      <Mutation mutation={RESET_PASSWORD}>
        {(resetPassword) => (
          <Form
            onSubmit={this.handleForgottenPasswordSubmit(resetPassword)}
            render={({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit}>
                <ForgottenPasswordForm
                  authenticating={submitting}
                  submitted={submitted}
                  disabled={submitting}
                  valid={true}
                  error={error}
                />
              </form>
            )}
          />
        )}
      </Mutation>
    );
  }

  handleForgottenPasswordSubmit = (resetPassword) => (form) => {
    return recaptcha("forgotten_password").then((captchaResponse) => {
      const input = { ...form, captchaResponse };
      console.log("resetPassword", { input });

      this.setState({ error: null });

      return resetPassword({ variables: { input } }).then(
        ({
          data: {
            users: { requestPasswordReset },
          },
          error,
        }) => {
          console.log(requestPasswordReset);
          if (Boolean(error || requestPasswordReset.error)) {
            this.setState({
              error: JSON.stringify(error || requestPasswordReset.error),
            });
            return;
          }

          this.setState({ submitted: true, error: null });
        }
      );
    });
  };
}

export default ForgottenPassword;
