import React from "react";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Link,
  Tooltip,
  Chip,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  ContentCopy as CopyLinkIcon,
} from "@mui/icons-material";
import { blue } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: "600px",
  },
  tableWrapper: {
    overflowX: "auto",
  },
  actionsCell: {
    textAlign: "right",
  },
  roleChip: {
    marginRight: theme.spacing(2),
  },
  justifyCell: {
    textAlign: "center",
  },
  link: {
    fontWeight: 500,
    cursor: "pointer",
  },
  vehicleName: {
    fontWeight: 500,
  },
  adminBadge: {
    backgroundColor: blue[500],
    color: "white",
    padding: "5px 3px",
    marginLeft: theme.spacing(1),
  },
}));

const formatTime = (time) => moment(time).format("YYYY-MM-DD HH:mm:ss");

const VehicleNames = ({ vehicles, classes }) => (
  <TableCell>
    {vehicles.map(({ vrm, id }) => (
      <span key={id} className={classes.vehicleName}>
        {vrm} <br />
      </span>
    ))}
  </TableCell>
);

const LiveShareTrackingsList = ({
  data,
  onOpenDeleteDialog,
  handleCopyClick,
  copyTitleTooltip,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Vehicles</TableCell>
              <TableCell>Access Start</TableCell>
              <TableCell>Access End</TableCell>
              <TableCell>Public link</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map(({ node, cursor }) => (
                <TableRow key={cursor} hover>
                  <VehicleNames
                    vehicles={node.permission.vehicles}
                    classes={classes}
                  />
                  <TableCell>
                    <span>{formatTime(node.accessStart)}</span>
                  </TableCell>
                  <TableCell>
                    <span>{formatTime(node.accessEnd)}</span>
                  </TableCell>
                  <TableCell>
                    {node.isExpired ? (
                      <Chip label="Expired" color="error" variant="outlined" />
                    ) : (
                      <>
                        <Link
                          href={node.externalLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          underline="hover"
                          className={classes.link}
                        >
                          Open shared tracking
                        </Link>
                        <Tooltip title={copyTitleTooltip}>
                          <IconButton
                            size="large"
                            onClick={() => handleCopyClick(node.externalLink)}
                          >
                            <CopyLinkIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Remove this live share">
                      <IconButton
                        onClick={() => onOpenDeleteDialog(node.id)}
                        size="large"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default LiveShareTrackingsList;
