export const OK = "OK";
export const NO_FOOTAGE = "NO_FOOTAGE";

export const mapToDisplay = key => {
  if (key === OK) return "Ok";
  if (key === NO_FOOTAGE) return "No footage";

  return key;
};

// export const fuzzyFromGb = gb => {
//   return fuzzyFromMb(gb * 1024);
// };

export const fuzzyFromMb = mb => {
  if (mb <= 920)
    return {
      value: mb,
      postfix: "MB",
      formatted: `${mb} MB`
    };

  const value = (mb / 1024).toFixed(1);
  return {
    value,
    postfix: "GB",
    formatted: `${value} GB`
  };
};
