import React, { Fragment } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { Mutation } from "@apollo/client/react/components";
import withStyles from "@mui/styles/withStyles";

import LinearProgress from "@mui/material/LinearProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Form } from "react-final-form";

import DepotsList from "../components/DepotsList";
import DepotForm from "../components/DepotForm";
import CloseDepotDialog from "../containers/CloseDepotDialog";
import CreateDepotFab from "../components/CreateDepotFab";

const DEPOTS_QUERY = gql`
  query AllDepots {
    depots {
      all {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            id
            name
            closed
          }
        }
      }
    }
  }
`;

const UPDATE_DEPOT = gql`
  mutation Rename($input: RenameDepotInputType!) {
    depots {
      rename(input: $input) {
        depot {
          id
          closed
          name
        }
        error {
          code
          message
        }
      }
    }
  }
`;

const CLOSE_DEPOT = gql`
  mutation Close($input: CloseDepotInputType!) {
    depots {
      close(input: $input) {
        error {
          code
          message
        }
      }
    }
  }
`;

const styles = (theme) => ({
  fab: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
});

class Depots extends React.Component {
  state = {
    open: false,
    updating: false,
    depot: {},
    depotId: null,
    errorCode: null,
  };

  render() {
    const { classes } = this.props;
    const { updating, open, depot } = this.state;

    return (
      <Fragment>
        <Query query={DEPOTS_QUERY} variables={{}}>
          {({ loading, error, data }) => {
            if (loading) return <LinearProgress />;
            if (error) return JSON.stringify(error);

            return (
              <Mutation
                mutation={UPDATE_DEPOT}
                refetchQueries={(_) => [
                  {
                    query: DEPOTS_QUERY,
                  },
                ]}
              >
                {(updateDepot) => (
                  <Mutation
                    mutation={CLOSE_DEPOT}
                    refetchQueries={(_) => [
                      {
                        query: DEPOTS_QUERY,
                      },
                    ]}
                  >
                    {(closeDepot) => (
                      <Form
                        initialValues={{
                          name: depot.name,
                        }}
                        onSubmit={this.handleSubmit(updateDepot)}
                        render={({ handleSubmit, submitting, dirty }) => (
                          <Fragment>
                            <DepotsList
                              allDepots={data.depots.all}
                              handleOpenEdit={this.handleOpenEdit}
                              onOpenDialogCloseDepot={
                                this.onOpenDialogCloseDepot
                              }
                            />

                            <CreateDepotFab className={classes.fab} />

                            <Dialog open={open}>
                              <DialogTitle>Rename depot</DialogTitle>
                              <DialogContent>
                                <DepotForm value={depot} />
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={this.handleCloseEdit}
                                  color="primary"
                                  disabled={updating}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  color="primary"
                                  autoFocus
                                  onClick={handleSubmit}
                                  disabled={submitting || !dirty}
                                >
                                  Save
                                </Button>
                              </DialogActions>
                            </Dialog>

                            <CloseDepotDialog
                              open={Boolean(this.state.depotId)}
                              depotId={this.state.depotId}
                              handleFormSubmit={this.handleCloseDepot(
                                closeDepot
                              )}
                              handleClose={this.handleClose}
                              errorCode={this.state.errorCode}
                            />
                          </Fragment>
                        )}
                      />
                    )}
                  </Mutation>
                )}
              </Mutation>
            );
          }}
        </Query>
      </Fragment>
    );
  }

  onOpenDialogCloseDepot = (id) => {
    this.setState({ depotId: id });
  };

  handleCloseDepot = (closeDepot) => (form) => {
    const variables = {
      input: {
        depotId: this.state.depotId,
        depotIdToMoveVehiclesTo: form.depotId,
      },
    };
    closeDepot({ variables }).then(({ data }) => {
      if (!data.depots.close.error) {
        this.setState({ depotId: null });
        this.setState({ errorCode: null });
      } else {
        const code = data.depots.close.error.code;
        this.setState({ errorCode: code });
      }
    });
  };

  handleClose = () => {
    this.setState({ depotId: null, errorCode: null });
  };

  handleOpenEdit = (depot) => {
    this.setState({ open: true, depot: depot });
  };

  handleCloseEdit = () => {
    this.setState({ open: false });
  };

  handleSubmit = (updateDepot) => (value) => {
    const {
      depot: { id },
    } = this.state;
    const newName = value.name;
    const variables = { input: { depotId: id, newName } };

    updateDepot({ variables }).then(() => {
      this.setState({ open: false });
    });
  };
}

Depots.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Depots);
