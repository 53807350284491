import React from "react";
import makeStyles from "@mui/styles/makeStyles";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import LiveMap from "../containers/LiveMap";
import Map from "../components/LiveMap";

const useStyles = makeStyles((theme) => ({
  root: { height: "100%" },
  cardContent: { height: "100%" },
  map: { minHeight: 440, height: "100%", margin: theme.spacing(-2) },
}));

const DashboardLiveMapCard = ({ vehiclesEnabled }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent className={classes.cardContent}>
        <div className={classes.map}>
          {vehiclesEnabled ? <LiveMap /> : <Map />}
        </div>
      </CardContent>
    </Card>
  );
};

DashboardLiveMapCard.propTypes = {};

export default DashboardLiveMapCard;
