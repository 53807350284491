import React, { Fragment } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import withStyles from "@mui/styles/withStyles";
import { useQuery } from "@apollo/client";
import LinearProgress from "@mui/material/LinearProgress";

import PolicyList from "../components/PolicyList";
import CreatePolicyFab from "../components/CreatePolicyFab";
import UpdatePolicyDialog from "../containers/UpdatePolicyDialog";
import { removeTypename } from "../utils";

const POLICY_QUERY = gql`
  {
    policies {
      all {
        totalCount
        edges {
          cursor
          node {
            id
            number
            insurerName
            fileClaimSubmissionEmail
          }
        }
      }
    }
  }
`;

const styles = (theme) => ({
  fab: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  error: {
    color: "red",
  },
});

const Policies = ({ classes }) => {
  const [policy, setPolicy] = React.useState();
  const { data, fetching } = useQuery(POLICY_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  const handleOpenEditDialog = (policy) => {
    const data = removeTypename(policy);
    setPolicy(data);
  };

  const handleCloseEditDialog = () => {
    setPolicy();
  };

  if (fetching) return <LinearProgress />;

  return (
    <Fragment>
      <PolicyList
        data={data && data.policies && data.policies.all}
        onOpenEditDialog={handleOpenEditDialog}
      />

      <CreatePolicyFab className={classes.fab} />
      <UpdatePolicyDialog
        data={policy}
        handleClose={handleCloseEditDialog}
        open={Boolean(policy)}
      />
    </Fragment>
  );
};

Policies.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Policies);
