import React from "react";
import PropTypes from "prop-types";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

import CreateLiveShareTrackingDialog from "../containers/CreateLiveShareTrackingDialog";

const CreateLiveShareTrackingFab = ({
  className,
  handleCreateFormSubmit,
  isCreateDialogOpen,
  handleCreateDialogClick,
}) => {
  return (
    <>
      <Fab
        color="secondary"
        className={className}
        onClick={handleCreateDialogClick}
      >
        <AddIcon />
      </Fab>
      <CreateLiveShareTrackingDialog
        open={isCreateDialogOpen}
        handleCreateDialogClick={handleCreateDialogClick}
        handleCreateFormSubmit={handleCreateFormSubmit}
      />
    </>
  );
};

CreateLiveShareTrackingFab.propTypes = {
  className: PropTypes.string,
};

export default CreateLiveShareTrackingFab;
