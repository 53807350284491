import React from "react";

import flvjs from "flv.js";
import Hls from "hls.js";
import { PlayCircleOutline as PlayIcon } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  player: {
    maxWidth: "100%",
    width: "100%",
  },
  root: {
    margin: 0,
    position: "relative",
    // display: "flex",
  },
  caption: {
    top: 0,
    position: "absolute",
    left: 0,
    right: 0,
    textAlign: "center",
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.85) 10%, rgba(0,0,0,0) 100%)",
    paddingBottom: theme.spacing(2),
    color: "#fff",
  },
  playIconOverlay: {
    display: "grid",
    placeItems: "center",
    top: "25%",
    bottom: "35%",
    left: "35%",
    right: "35%",
    position: "absolute",
    textAlign: "center",
    background: "rgba(0,0,0,0.85)",
    borderRadius: theme.spacing(2),
    cursor: "pointer",
    zIndex: 1,
    color: "#fff",
    "&:hover": {
      background: `radial-gradient(#2e2e2e, rgba(0,0,0,0.85))`,
    },
  },
  playIcon: {
    width: "90%",
    height: "90%",
  },
}));

const rtmpPlayerSetup = (video, src, setShowPlayIcon) => {
  setShowPlayIcon(true);
  const initPlayer = () => {
    const p = flvjs.createPlayer(
      {
        type: "flv",
        url: src,
      },
      {
        // isLive: false,
        // // enableWorker: true,
        // enableStashBuffer: false,
        // lazyLoadMaxDuration: 10,
      }
    );

    p.attachMediaElement(video);

    return p;
  };

  const destroyPlayer = (player) => {
    player.pause();
    player.unload();
    player.detachMediaElement();
    player.destroy();
  };

  let player = initPlayer();

  const playEventListener = () => {
    setShowPlayIcon(false);
    player.load();
    player.play();
  };

  const pauseEventListener = () => {
    destroyPlayer(player);
    player = initPlayer();
    setShowPlayIcon(true);
  };

  video.addEventListener("play", playEventListener);
  video.addEventListener("pause", pauseEventListener);

  return () => {
    console.log(`DESTROY - ${src}`);

    video.removeEventListener("play", playEventListener);
    video.removeEventListener("pause", pauseEventListener);

    destroyPlayer(player);
  };
};

const hlsPlayerSetup = (video, src, setShowPlayIcon) => {
  setShowPlayIcon(true);

  let hls = new Hls({
    enableWorker: true,
    autoStartLoad: false,
  });
  hls.attachMedia(video);

  hls.on(Hls.Events.MEDIA_ATTACHED, () => {
    console.log("video and hls.js are now bound together !");
    hls.loadSource(src);
  });
  hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
    console.log(
      "manifest loaded, found " + data.levels.length + " quality level"
    );
  });

  hls.on(Hls.Events.ERROR, (event, data) => {
    var errorType = data.type;
    var errorDetails = data.details;
    var errorFatal = data.fatal;

    console.error({ errorType, errorDetails, errorFatal });

    if (data.fatal) {
      switch (data.type) {
        case Hls.ErrorTypes.NETWORK_ERROR:
          // try to recover network error
          console.log("fatal network error encountered, try to recover");
          errorDetails === "manifestLoadTimeOut"
            ? hls.loadSource(src)
            : hls.startLoad();
          break;
        case Hls.ErrorTypes.MEDIA_ERROR:
          console.log("fatal media error encountered, try to recover");
          hls.recoverMediaError();
          break;
        default:
          console.log("fatal error encountered, can not recover");
          // cannot recover
          hls.destroy();
          hls = null;
          break;
      }
    }
  });

  const playEventListener = () => {
    setShowPlayIcon(false);
    console.log("PLAY NOW");
    hls.startLoad();
  };

  const pauseEventListener = () => {
    setShowPlayIcon(true);
    console.log("PAUSE NOW");
    hls.stopLoad();
  };

  video.addEventListener("play", playEventListener);
  video.addEventListener("pause", pauseEventListener);

  return () => {
    video.removeEventListener("play", playEventListener);
    video.removeEventListener("pause", pauseEventListener);

    hls.detachMedia();
    hls.destroy();
    hls = null;
  };
};

const VideoPlayer = ({ label, sources }) => {
  const classes = useStyles();
  const [showPlayIcon, setShowPlayIcon] = React.useState(false);
  const videoRef = React.useRef(null);

  React.useEffect(() => {
    const video = videoRef.current;

    const hlsSupported = Hls.isSupported();
    const hlsNativeSupported = video.canPlayType(
      "application/vnd.apple.mpegurl"
    );
    const flvSupported = flvjs.isSupported();

    if (flvSupported) {
      const src = sources.find((x) => x.type === "rtmp")?.source;
      return rtmpPlayerSetup(video, src, setShowPlayIcon);
    } else if (hlsNativeSupported) {
      const src = sources.find((x) => x.type === "hls")?.source;
      video.src = src;
    } else if (hlsSupported) {
      const src = sources.find((x) => x.type === "hls")?.source;
      return hlsPlayerSetup(video, src, setShowPlayIcon);
    }
  }, [sources]);

  const handlePlayIconClick = () => {
    videoRef.current.play();
    setShowPlayIcon(false);
  };

  return (
    <figure className={classes.root}>
      <video
        className={classes.player}
        ref={videoRef}
        poster="/live-video-poster.svg"
        controls
        muted
        autoPlay={false}
      >
        Sorry, your browser doesn't support embedded videos.
      </video>
      <figcaption className={classes.caption}>{label}</figcaption>
      {showPlayIcon && (
        <div className={classes.playIconOverlay} onClick={handlePlayIconClick}>
          <PlayIcon className={classes.playIcon} />
        </div>
      )}
    </figure>
  );
};

export default VideoPlayer;
