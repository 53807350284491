import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { CircularProgress, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Autocomplete } from "@mui/material";

const TENANTS_QUERY = gql`
  query AllTenants($nameLike: String) {
    allTenants(first: 10, filter: { nameLike: $nameLike }) {
      totalCount
      pageInfo {
        startCursor
        hasPreviousPage
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          id
          name
        }
      }
    }
  }
`;

// function throttle(func, timeFrame) {
//   var lastTime = 0;
//   return function () {
//     var now = new Date();
//     if (now - lastTime >= timeFrame) {
//       func();
//       lastTime = now;
//     }
//   };
// }

const useStyles = makeStyles((theme) => ({}));

const TenantSelect = ({ label = "", onChange }) => {
  const classes = useStyles();

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [queryTerm, setQueryTerm] = React.useState("");
  const [options, setOptions] = React.useState([]);

  const { data, loading } = useQuery(TENANTS_QUERY, {
    variables: { nameLike: queryTerm },
    pause: Boolean(value),
  });

  React.useEffect(() => {
    let active = true;
    if (active) {
      let newOptions = [];

      if (value) {
        newOptions = [value];
      }

      if (data) {
        newOptions = [
          ...newOptions,
          ...data.allTenants.edges.map((x) => x.node),
        ];
      }

      setOptions(newOptions);
    }

    return () => {
      active = false;
    };
  }, [data, value]);

  React.useEffect(() => {
    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    // TODO: Throttle setting query term
    setQueryTerm(inputValue);
  }, [value, inputValue, setQueryTerm]);

  const handleChange = (_, newValue) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);
  };

  React.useEffect(() => {
    onChange(value);
  }, [onChange, value]);

  const handleInputChange = (_, newInputValue) => {
    setInputValue(newInputValue);
  };

  return (
    <div className={classes.root}>
      {/* <code>{JSON.stringify(value, null, 2)}</code> */}
      {/* <code>{JSON.stringify(value)}</code> */}
      <Autocomplete
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.name
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        onChange={handleChange}
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            autoFocus
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

export default TenantSelect;
