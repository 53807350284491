const { REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY: siteKey } = process.env;

const loadReCaptcha = () => {
  const script = document.createElement("script");
  script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
  document.body.appendChild(script);
};

export { loadReCaptcha };

const execute = (action = "homepage") =>
  new Promise(resolve => {
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(siteKey, { action }).then(token => {
        resolve(token);
      });
    });
  });

export { execute };
