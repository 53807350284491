import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";

import { Snackbar, LinearProgress } from "@mui/material";
import DriverSafetyNoEventsContent from "./DriverSafetyNoEventsContent";
import EventList from "./EventList";

const EVENTS_QUERY = gql`
  query SafetyEvents($filter: SafetyEventsFilterType!) {
    safetyEvents {
      all(filter: $filter, first: 100) {
        totalCount
        pageInfo {
          hasNextPage
        }
        edges {
          cursor
          node {
            id
            title
            starred
            status
            speed(unit: MPH)
            speedLimit(unit: MPH)
            occurredAt
            coord {
              lng
              lat
            }
            addressLabel
            driver {
              id
              name
            }
            vehicle {
              vrm
              id
              hasVideoSupport
            }
            linkedFootage {
              status
              footage {
                camera
                status
                signedUrl
                signedPosterUrl
              }
            }
          }
        }
      }
    }
  }
`;

const FETCH_FOOTAGE = gql`
  mutation FetchFootage($input: FetchSafetyEventFootageInputType!) {
    vehicles {
      safetyEvents {
        fetchFootage(input: $input) {
          safetyEvent {
            id
            linkedFootage {
              status
            }
          }
          error {
            code
            message
          }
        }
      }
    }
  }
`;

const SET_STATUS = gql`
  mutation SetStatus($input: SafetyEvents_SetStatus_Input!) {
    safetyEvents {
      setStatus(input: $input) {
        safetyEvent {
          id
          status
        }
      }
    }
  }
`;

const STAR = gql`
  mutation SetStatus($input: SafetyEvents_Star_Input!) {
    safetyEvents {
      star(input: $input) {
        safetyEvent {
          id
          starred
        }
      }
    }
  }
`;

const REMOVE_STAR = gql`
  mutation RemoveStar($input: SafetyEvents_RemoveStar_Input!) {
    safetyEvents {
      removeStar(input: $input) {
        safetyEvent {
          id
          starred
        }
      }
    }
  }
`;

const DriverSafetyEvents = ({
  vehicleId,
  driverId,
  start,
  end,
  status,
  mustBeStarred,
}) => {
  const [showFootageRequestedSnackbar, setShowFootageRequestedSnackbar] =
    React.useState(false);

  const { loading, error, data, refetch } = useQuery(EVENTS_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {
      filter: { vehicleId, driverId, start, end, status, mustBeStarred },
    },
  });
  const [fetchFootage] = useMutation(FETCH_FOOTAGE);
  const [setStatus] = useMutation(SET_STATUS);
  const [star] = useMutation(STAR);
  const [removeStar] = useMutation(REMOVE_STAR);

  if (error) {
    return <p>{JSON.stringify(error)}</p>;
  }

  const handleFetchFootage = (safetyEventId) => {
    fetchFootage({
      variables: { input: { safetyEventId } },
    }).then(() => {
      setShowFootageRequestedSnackbar(true);
    });
  };

  const handleSnackbarClose = () => {
    setShowFootageRequestedSnackbar(false);
  };

  const handleSetEventStarred = (id) => {
    return star({
      variables: { input: { id } },
    });
  };

  const handleSetEventUnstarred = (id) => {
    return removeStar({
      variables: { input: { id } },
    });
  };

  const handleSetEventStatus = (id, newStatus) => {
    return setStatus({
      variables: { input: { id, newStatus } },
    });
  };

  return (
    <>
      {(loading || !data || !data.safetyEvents) && (
        <LinearProgress variant="query" />
      )}
      {data?.safetyEvents.all.totalCount === 0 && (
        <DriverSafetyNoEventsContent />
      )}
      {data?.safetyEvents && (
        <EventList
          eventsConnection={data.safetyEvents.all}
          onStarClicked={handleSetEventStarred}
          onUnstarClicked={handleSetEventUnstarred}
          onSetStatusClicked={handleSetEventStatus}
          onFetchFootageClicked={handleFetchFootage}
        />
      )}
      <Snackbar
        open={showFootageRequestedSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={
          <span id="message-id">
            Fetching footage from device. We'll send you an email once it has
            been downloaded.
          </span>
        }
      />
    </>
  );
};

DriverSafetyEvents.propTypes = {
  vehicleId: PropTypes.string,
  driverId: PropTypes.string,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
};

export default DriverSafetyEvents;
