import React, { Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';

import { Field } from "react-final-form";
import Grid from "@mui/material/Grid";
import TextField from "../components/form/TextField";

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
});

const IncidentPropertyDamageForm = ({
  fieldName,
  value,
  src,
  classes,
  formValues
}) => (
  <div>
    <p>
      Details of damage to other vehicles or if impact was with a fixed object
      or building, please detail damage to fixed object.
    </p>
    <Fragment>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <Field
            component={TextField}
            name={`propertyDamage.detail`}
            margin="normal"
            label="Property damaged detail"
            multiline
            fullWidth
            allowNull={true}
          />
        </Grid>
      </Grid>
    </Fragment>
  </div>
);

IncidentPropertyDamageForm.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(IncidentPropertyDamageForm);
