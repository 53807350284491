import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import moment from "moment";

import UpdateIncident from "../containers/UpdateIncident";
import IncidentCoreDetails from "../components/IncidentCoreDetails";
import IncidentCoreDetailsForm from "../components/IncidentCoreDetailsForm";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import EditIcon from "@mui/icons-material/Edit";

const styles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  buttonBox: {
    marginLeft: "auto",
  },
});

class IncidentCoreDetailsCard extends React.Component {
  state = {
    editing: false,
    dirtied: false,
    initialFormValues: {
      attachEvidence: {
        evidence: [],
      },
    },
  };

  componentDidMount() {
    this.setState((prev) => ({
      initialFormValues: {
        ...prev.initialFormValues,
        ...this.getInitialFormValuesFrom(this.props.incident),
      },
    }));
  }

  componentDidUpdate() {
    const { dirtied, editing } = this.state;
    const resetInitialise = dirtied && !editing;

    if (resetInitialise)
      this.setState((prev) => ({
        dirtied: false,
        initialFormValues: {
          ...prev.initialFormValues,
          ...this.getInitialFormValuesFrom(this.props.incident),
        },
      }));
  }

  render() {
    const { className, incident, editable, classes } = this.props;
    const { editing, initialFormValues } = this.state;

    let actions = null;
    if (editable && !editing)
      actions = (
        <Button
          variant="text"
          startIcon={<EditIcon />}
          onClick={this.handleEditClick}
        >
          Edit
        </Button>
      );

    return (
      <UpdateIncident
        incidentId={incident.id}
        initialFormValues={initialFormValues}
        onUpdated={this.handleUpdated}
        render={({
          handleSubmit,
          submitting,
          dirty,
          form,
          reset,
          values,
          error,
        }) => (
          <Card className={className}>
            <CardHeader title="Details" action={actions} />
            <CardContent>
              {editing ? (
                <IncidentCoreDetailsForm
                  incident={incident}
                  values={values}
                  setFieldValue={form.mutators.setValue}
                />
              ) : (
                <IncidentCoreDetails incident={incident} />
              )}

              {error && <p>{error}</p>}
            </CardContent>
            {editing && (
              <CardActions>
                <div className={classes.buttonBox}>
                  <Button
                    aria-label="Cancel"
                    onClick={this.handleCancelEdit(reset)}
                    disabled={submitting}
                    color="primary"
                    className={classes.button}
                  >
                    Cancel
                  </Button>
                  <Button
                    aria-label="Save changes"
                    onClick={this.handleSubmitWrapper(
                      handleSubmit,
                      form,
                      values
                    )}
                    disabled={submitting}
                    color="primary"
                    className={classes.button}
                  >
                    Save changes
                  </Button>
                </div>
              </CardActions>
            )}
          </Card>
        )}
      />
    );
  }

  getInitialFormValuesFrom = (incident) => {
    return {
      vehicle: {
        vehicleId: incident.vehicleId,
      },
      driver: {
        driverId: incident.driverId,
      },
      incident: {
        incidentDate: moment(incident.occurredAt).format("YYYY-MM-DD"),
        incidentTime: moment(incident.occurredAt).format("HH:mm"),
        location: incident.location,
      },
      account: {
        detailsOfIncident: incident.accountOfIncident || "",
        vehicleSpeed: incident.vehicleSpeed,
      },
    };
  };

  handleSubmitWrapper = (handleSubmit, form, values) => (ev) => {
    values.account.vehicleSpeed &&
      form.mutators.setValue(
        "account.vehicleSpeed",
        Number(values.account.vehicleSpeed)
      );
    handleSubmit(ev);
  };

  handleUpdated = () => {
    this.setState({ editing: false });
  };

  handleEditClick = () => {
    this.setState({ dirtied: true, editing: true });
  };

  handleCancelEdit = (reset) => () => {
    this.setState({ editing: false });
    reset();
  };
}

IncidentCoreDetailsCard.propTypes = {
  incident: PropTypes.object.isRequired,
  editable: PropTypes.bool,
};

export default withStyles(styles)(IncidentCoreDetailsCard);
