import React from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import withStyles from "@mui/styles/withStyles";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import TenantSelect from "../components/TenantSelect";

const styles = (theme) => ({
  errorText: {
    color: theme.palette.error.main,
  },
  selectContainer: {
    minWidth: "300px",
    marginTop: theme.spacing(1),
  },
});

const SwitchTenantDialog = ({
  loading,
  switching,
  data,
  error,
  open,
  handleClose,
  handleSwitch,
  currentTenant,
  classes,
}) => {
  const content = () => {
    if (loading && (!data || !data.allTenants))
      return (
        <DialogContent>
          <LinearProgress className={classes.loading} />
        </DialogContent>
      );
    if (error)
      return (
        <DialogContent>
          <DialogContentText className={classes.errorText}>
            {error}
          </DialogContentText>
        </DialogContent>
      );

    if (data.allTenants.totalCout === 0)
      return (
        <DialogContentText>
          You do not have the rights to access tenants. Try logging out and back
          in.
        </DialogContentText>
      );

    if (data.allTenants.pageInfo.hasNextPage)
      return (
        <DialogContent>
          <DialogContentText>Start typing to find a tenant</DialogContentText>
          <div className={classes.selectContainer}>
            <TenantSelect
              label="Select a tenant"
              onChange={(tenant) => {
                if (switching) return;
                if (!tenant) return;

                handleSwitch(tenant.id);
              }}
            />
          </div>
        </DialogContent>
      );

    return (
      <DialogContent>
        <List>
          {data.allTenants.edges.map(({ cursor, node }) => (
            <ListItemButton
              onClick={() => handleSwitch(node.id)}
              key={cursor}
              disabled={node.id === currentTenant}
            >
              <ListItemText primary={node.name} />
            </ListItemButton>
          ))}
        </List>
      </DialogContent>
    );
  };

  return (
    <Dialog
      // fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ m: 0, p: 2 }}>
        Switch tenant
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {content()}
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SwitchTenantDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSwitch: PropTypes.func.isRequired,
  currentTenant: PropTypes.string,
};

export default withStyles(styles)(SwitchTenantDialog);
