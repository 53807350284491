import React, { Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';

import RequestDriverIncidentAccountDialog from "../containers/RequestDriverIncidentAccountDialog";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import Snackbar from "@mui/material/Snackbar";

const styles = theme => ({
  smsIcon: {
    marginRight: theme.spacing(1)
  }
});

class RequestDriverAccountButton extends React.Component {
  state = { open: false, sent: false };

  render() {
    const { classes, incidentId, accountRequestedOn, disabled } = this.props;
    return (
      <Fragment>
        <Button
          disabled={disabled}
          onClick={this.handleOpenAccountRequest.bind(this)}
        >
          <Icon className={classes.smsIcon}>textsms</Icon>
          {accountRequestedOn ? "Re-request" : "Request"} driver account
        </Button>
        <RequestDriverIncidentAccountDialog
          onClose={this.handleCloseAccountRequest.bind(this)}
          onSent={this.handleAccountRequestSent.bind(this)}
          incidentId={incidentId}
          open={this.state.open}
        />
        <Snackbar
          open={this.state.sent}
          autoHideDuration={6000}
          onClose={() => this.setState({ sent: false })}
          message={"Driver account request sent"}
        />
      </Fragment>
    );
  }

  handleOpenAccountRequest() {
    this.setState({ open: true });
  }
  handleCloseAccountRequest() {
    this.setState({ open: false });
  }
  handleAccountRequestSent() {
    this.setState({
      open: false,
      sent: true
    });
  }
}

RequestDriverAccountButton.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  accountRequestedOn: PropTypes.any
};

export default withStyles(styles)(RequestDriverAccountButton);
