import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

import CreateGroupDialog from "../containers/CreateGroupDialog";

const CreateGroupFab = ({ className }) => {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Fab color="secondary" className={className} onClick={handleOpen}>
        <AddIcon />
      </Fab>
      <CreateGroupDialog
        open={open}
        handleClose={handleClose}
        history={history}
      />
    </>
  );
};

CreateGroupFab.propTypes = {
  className: PropTypes.string,
};

export default CreateGroupFab;
