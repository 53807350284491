import React from "react";
import withStyles from "@mui/styles/withStyles";

const { PUBLIC_URL } = process.env;

const styles = (theme) => ({
  logo: {
    height: "60px",
    marginBottom: theme.spacing(2),
  },
});

const AbovePaperLogo = ({ classes }) => (
  <div>
    <img
      src={`${PUBLIC_URL}/logo.svg`}
      alt="Fastview360 logo"
      className={classes.logo}
    />
  </div>
);

export default withStyles(styles)(AbovePaperLogo);
