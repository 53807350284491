import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

export default ({
  input: { name, onChange, checked, value, ...restInput },
  meta,
  label,
  ...rest
}) => (
  <FormControlLabel
    control={
      <Switch
        {...rest}
        name={name}
        inputProps={restInput}
        onChange={onChange}
        checked={Boolean(checked)}
      />
    }
    label={label}
  />
);
