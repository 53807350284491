import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import withStyles from '@mui/styles/withStyles';

import RequestDriverAccountButton from "../containers/RequestDriverAccountButton";
import DriverAccountText from "../containers/DriverAccountText";

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  iconLeft: {
    marginRight: theme.spacing(1)
  }
});

const IncidentDriverAccount = ({ value, driver, incidentId, classes }) => (
  <div>
    {!value.account && <p>No driver account given</p>}
    {value.requestedOn &&
      !value.account && (
        <p>Requested: {moment(value.requestedOn).calendar()}</p>
      )}

    {value.account && (
      <DriverAccountText
        text={value.account}
        translation={value.translation && value.translation.text}
      />
    )}

    <div>
      {driver && (
        <RequestDriverAccountButton
          incidentId={incidentId}
          accountRequestedOn={value.requestedOn}
        />
      )}
    </div>
  </div>
);

IncidentDriverAccount.propTypes = {
  value: PropTypes.object.isRequired,
  incidentId: PropTypes.string.isRequired,
  driver: PropTypes.object,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(IncidentDriverAccount);
