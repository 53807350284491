import React from "react";

import ForgottenPassword from "../containers/ForgottenPassword";

import CenteredPaperLayout from "../components/CenteredPaperLayout";
import AbovePaperLogo from "../components/AbovePaperLogo";

const ForgottenPasswordPage = () => (
  <CenteredPaperLayout title="Reset your password" above={<AbovePaperLogo />}>
    <ForgottenPassword />
  </CenteredPaperLayout>
);

export default ForgottenPasswordPage;
