import React from "react";

import TextField from "../components/form/TextField";
import { Field } from "react-final-form";

const validateRequired = value => !value && "Required";

const CreateDepotForm = () => (
  <Field
    component={TextField}
    name="name"
    validate={validateRequired}
    required
    margin="dense"
    autoComplete="off"
    label="Name"
    fullWidth
    autoFocus
  />
);

CreateDepotForm.propTypes = {};

export default CreateDepotForm;
