import React from "react";
import PropTypes from "prop-types";
import TextField from "../components/form/TextField";
import Grid from "@mui/material/Grid";
import { Field } from "react-final-form";

const validateName = (value) => !value && "Name is required";

const validateMobile = (value) =>
  value &&
  !/^(\+\d\d)\d{1,14}$/.test(value) &&
  "Must be a valid mobile number. It should start with +447";

const DriverForm = ({ value }) => (
  <Grid spacing={2} container>
    <Grid item xs={12}>
      <Field
        component={TextField}
        name="name"
        required
        validate={validateName}
        margin="dense"
        autoComplete="off"
        label="Name"
        fullWidth
        autoFocus
      />
    </Grid>
    <Grid item xs={6} sm={6}>
      <Field
        component={TextField}
        name="contact.email"
        type="email"
        margin="dense"
        autoComplete="off"
        label="Email"
        fullWidth
      />
    </Grid>
    <Grid item xs={6} sm={6}>
      <Field
        component={TextField}
        name="contact.telephone"
        validate={validateMobile}
        margin="dense"
        autoComplete="off"
        label="Telephone"
        placeholder="+447..."
        fullWidth
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        component={TextField}
        name="fobId"
        margin="dense"
        autoComplete="off"
        label="Fob Id"
        fullWidth
      />
    </Grid>
  </Grid>
);

DriverForm.propTypes = {
  value: PropTypes.object,
};

export default DriverForm;
