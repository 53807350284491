import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import ListItem from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import CircularProgress from "@mui/material/CircularProgress";
import FilterableVehiclesDrawerList from "../containers/FilterableVehiclesDrawerList";

const DEPOTS_QUERY = gql`
  query AllDepots {
    depots {
      all {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            id
            name
            vehicles(first: 800) {
              totalCount
              pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
              }
              edges {
                cursor
                node {
                  id
                  vrm
                  live {
                    online
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const styles = (theme) => ({
  progress: {
    margin: `${theme.spacing(2)} auto`,
  },
  error: {
    // background: theme.palette.error.main,
    // color: theme.palette.error.main
  },
});

const VehicleDrawerMenu = ({ classes }) => {
  const { data, loading, error } = useQuery(DEPOTS_QUERY, {
    fetchPolicy: "cache-first",
  });

  if (loading) return <CircularProgress className={classes.progress} />;

  if (error)
    return (
      <ListItem>
        <ListItemText className={classes.error} primary={error.message} />
      </ListItem>
    );

  return <FilterableVehiclesDrawerList allDepots={data.depots.all} />;
};

VehicleDrawerMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export { DEPOTS_QUERY };
// export const DEPOTS_QUERY;

export default withStyles(styles)(VehicleDrawerMenu);
