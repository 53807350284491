import React, { Fragment } from "react";
import PropTypes from "prop-types";

import FileIncidentAsClaimDialog from "../containers/FileIncidentAsClaimDialog";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";

const FileIncidentAsClaimButton = ({
  className,
  incidentId,
  hasEvidencePendingReview
}) => {
  const [open, setOpen] = React.useState(false);
  const [sent, setSent] = React.useState(false);

  const handleFileClaimClick = () => {
    setOpen(true);
  };
  const handleCloseAccountRequest = () => {
    setOpen(false);
  };
  const handleAccountRequestSent = () => {
    setSent(true);
    setOpen(false);
  };

  return (
    <Fragment>
      <Button
        variant="outlined"
        color="secondary"
        onClick={handleFileClaimClick}
        className={className}
      >
        File as Claim
      </Button>
      <FileIncidentAsClaimDialog
        onClose={handleCloseAccountRequest}
        onSent={handleAccountRequestSent}
        incidentId={incidentId}
        open={open}
        hasEvidencePendingReview={hasEvidencePendingReview}
      />
      <Snackbar
        open={sent}
        autoHideDuration={8000}
        onClose={() => setSent(false)}
        message={"Claim is being filed"}
      />
    </Fragment>
  );
};

FileIncidentAsClaimButton.propTypes = {
  incidentId: PropTypes.string.isRequired,
  hasEvidencePendingReview: PropTypes.bool.isRequired,
  className: PropTypes.string
};

export default FileIncidentAsClaimButton;
