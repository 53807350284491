import gql from "graphql-tag";

export const Query = gql`
  query Node($id: ID!, $filter: VehicleJourneyFilter!) {
    node(id: $id) {
      ...Journey_vehicle_tracking
    }
  }

  fragment Journey_vehicle_tracking on Vehicle {
    id
    device {
      cameras {
        id
        location
      }
    }
    journey(filter: $filter) {
      drives {
        start
        end
        duration
        averageSpeedMph
        topSpeedMph
        driveLengthMiles
        driverId
        driver {
          id
          name
        }
        averageSpeed(unit: MPH)
        topSpeed(unit: MPH)
        driveLength(unit: Mile)
      }
      markers {
        coord {
          lat
          lng
        }
        timestamp
        speed(unit: MPH)
      }
      stops {
        coord {
          lat
          lng
        }
        start
        end
        duration
        stopaddressstring
      }
    }
    live {
      online
      driver {
        name
        id
      }
      video {
        camera
        label
        cameraId
        streams {
          type
          contentType
          source
        }
      }
    }
  }
`;
