import { Grid } from "@mui/material";
import StatsByTimeOfDay from "./StatsByTimeOfDay";
import StatsByCategory from "./StatsByCategory";
import { useParentSize } from "@visx/responsive";

function Stats({ timeOfDayData, categoryConnection, categoriesData }) {
  const charHeight = 150;
  const charHeightCategory = 15 + 35 * categoriesData.length;

  const { parentRef: byTimeOfDayParentRef, width: byTimeOfDayWidth } =
    useParentSize({
      debounceTime: 150,
    });
  const { parentRef: byCategoryParentRef, width: byCategoryWidth } =
    useParentSize({
      debounceTime: 150,
    });

  const categoryData = categoriesData.map((d) => {
    const category = categoryConnection?.edges.find(
      (cat) => cat.node.id === d.categoryId
    );

    return {
      ...d,
      category: category?.node?.name || "Unknown",
    };
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <div ref={byCategoryParentRef}>
          <StatsByCategory
            data={categoryData}
            width={byCategoryWidth}
            height={charHeightCategory}
          />
        </div>
      </Grid>
      <Grid item xs={12} md={12}>
        <div ref={byTimeOfDayParentRef}>
          <StatsByTimeOfDay
            data={timeOfDayData}
            width={byTimeOfDayWidth}
            height={charHeight}
          />
        </div>
      </Grid>
    </Grid>
  );
}

export default Stats;
