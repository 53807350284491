import React, { Fragment } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import withStyles from "@mui/styles/withStyles";

import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

import ClaimTable from "../components/ClaimTable";

const QUERY = gql`
  query ClaimSearch {
    allClaims(first: 1000, filter: {}) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        # cursor
        node {
          id
          status
          reference
          location
          driver {
            id
            name
          }
          vehicle {
            id
            vrm
          }
          occurredAt
        }
      }
    }
  }
`;

export { QUERY };

const styles = (theme) => ({});

class ClaimSearch extends React.Component {
  state = { q: "" };

  render() {
    return (
      <Fragment>
        <Query query={QUERY} variables={{}}>
          {({ loading, error, data }) => {
            if (loading) return <LinearProgress />;
            if (error) return JSON.stringify(error);

            return (
              <Fragment>
                <ClaimTable allClaims={data.allClaims} />
                {data.allClaims.totalCount === 0 && (
                  <Typography component="p">No claims found.</Typography>
                )}
              </Fragment>
            );
          }}
        </Query>
      </Fragment>
    );
  }
}

ClaimSearch.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ClaimSearch);
