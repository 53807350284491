import React, { Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

const ThirdPartyDetail = ({ value, src, classes }) => (
  <div>
    <Grid container spacing={2}>
      {value.detailsOfDamage && (
        <Grid item xs={12} md={12}>
          Damage: {value.detailsOfDamage}
        </Grid>
      )}
      {value.driverName && (
        <Grid item md={6} xs={12}>
          Driver name: {value.driverName}
        </Grid>
      )}
      {value.ownerName && (
        <Grid item md={6} xs={12}>
          Owner name: {value.ownerName}
        </Grid>
      )}
      {value.email && (
        <Grid item md={6} xs={12}>
          Email: {value.email}
        </Grid>
      )}
      {value.address && (
        <Grid item md={6} xs={12}>
          Address: {value.address}
        </Grid>
      )}
      {value.telephoneNumber && (
        <Grid item md={4}>
          Telephone: {value.telephoneNumber}
        </Grid>
      )}
      {value.vehicleMake && (
        <Grid item md={4}>
          Vehicle make: {value.vehicleMake}
        </Grid>
      )}
      {value.vehicleRegistration && (
        <Grid item md={4}>
          Vehicle registration: {value.vehicleRegistration}
        </Grid>
      )}
    </Grid>
    <Typography variant="subtitle1" gutterBottom>
      Insurer
    </Typography>
    <Grid container spacing={2}>
      <Grid item md={6}>
        Name: {value.insurer.name}
      </Grid>
      <Grid item md={6}>
        Policy number: {value.insurer.policyNumber}
      </Grid>
    </Grid>
    {!value.injuries.any && (
      <Typography variant="subtitle1" gutterBottom>
        No Injuries
      </Typography>
    )}
    {value.injuries.any && (
      <Fragment>
        <Typography variant="subtitle1" gutterBottom>
          Injuries
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={6}>
            Detail: {value.injuries.details}
          </Grid>
        </Grid>
        {value.injuries.takenToHospital && (
          <Grid container spacing={2}>
            <Grid item md={6}>
              Hospital name: {value.injuries.hospitalName}
            </Grid>
          </Grid>
        )}
      </Fragment>
    )}
  </div>
);

ThirdPartyDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ThirdPartyDetail);
