import React from "react";
import gql from "graphql-tag";
import moment from "moment";
import { useQuery } from "@apollo/client";

import makeStyles from "@mui/styles/makeStyles";
import { LinearProgress } from "@mui/material";
import Typography from "@mui/material/Typography";

import {
  DashboardVehiclesTableHeader,
  DashboardVehiclesTableRows,
  DashboardVehiclesDepotHeaderRow,
} from "../components/DashboardVehiclesTable";
import DashboardVehiclesTablePagination from "../components/DashboardVehiclesTablePagination";

const useStyles = makeStyles((theme) => ({
  kpiContent: {
    padding: 0,
  },
  kpi: {
    fontSize: "3em",
    textAlign: "center",
    margin: 0,
  },
}));

const DashboardAllVehicles = ({
  loading,
  error,
  data,
  fetchMore,
  incidentsOccurredGte,
}) => {
  const classes = useStyles();

  return (
    <Typography component="div">
      {loading && (!data || !data.depots || !data.depots.all) && (
        <LinearProgress variant="query" />
      )}

      {error && <p className={classes.kpi}>err</p>}
      {!Boolean(error) &&
        data &&
        data.depots &&
        data.depots.all &&
        data.depots.all.totalCount >= 1 && (
          <div>
            <DashboardVehiclesTableHeader />
            {data.depots.all.edges.map(({ cursor, node: depot }) => (
              <div key={cursor}>
                {data.depots.all.totalCount > 1 && (
                  <DashboardVehiclesDepotHeaderRow depot={depot} />
                )}
                <DashboardVehiclesTableRows
                  allVehicles={depot.vehicles}
                  {...{ incidentsOccurredGte }}
                />
                <DashboardVehiclesTablePagination
                  pageInfo={depot.vehicles.pageInfo}
                  {...{ loading, fetchMore }}
                />
              </div>
            ))}
          </div>
        )}
    </Typography>
  );
};

export default DashboardAllVehicles;
